import crossIcon from "assets/images/crossIcon.svg";
import searchIcon from "assets/images/searchIcon.svg";
import { debounce } from "lodash";
import { useCallback } from "react";

const SearchInput = (props) => {
  const {
    globalSearch = () => {},
    companyStatus = "",
    setSearchInput = () => {},
    searchInput = "",
  } = props;

  const delayedSearch = useCallback(
    debounce((q) => globalSearch(q), 600),
    [companyStatus]
  );

  const clearSearch = () => {
    setSearchInput("");
    globalSearch("");
  };

  const handleChange = (value) => {
    const regex = /^[a-zA-Z0-9@.+ _-]*$/;
    const specialCharacters = [" ", "_", "-", "+"];
    const startsWithSpecialCharacter = specialCharacters?.some((char) =>
      value.startsWith(char)
    );
    if (regex.test(value) && !value.startsWith(" ")) {
      setSearchInput(value);
      delayedSearch(value);
    }
  };

  return (
    <div className="flex relative mx-auto text-gray-600">
      <input
        className="border-1 border-gray-300 bg-white h-auto px-5 pr-16  text-sm focus:outline-none"
        type="text"
        name="search"
        placeholder="Search"
        value={searchInput}
        onChange={(e) => handleChange(e?.target?.value)}
        autoComplete={"off"}
      />
      {!!searchInput && (
        <img
          alt="archive"
          src={crossIcon}
          className="h-5 w-5 hover:cursor-pointer absolute right-0 top-0 mt-3 mr-10"
          onClick={clearSearch}
        />
      )}
      <img
        alt="archive"
        src={searchIcon}
        className="h-5 w-5 absolute right-0 top-0 mt-3 mr-4"
        onClick={() => {}}
      />
    </div>
  );
};

export default SearchInput;
