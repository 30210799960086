import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import ReplyArrowIcon from "assets/images/reply-arrow.png";
import ConfirmationModal from "components/common/ConfirmationModal";
import Skeleton from "components/common/fields/Skeleton";
import SpinnerButton from "components/common/fields/SpinnerButton";
import RatingField from "components/contacts/customer/RatingField";
import {
  getCurrentLocation,
  getUserData,
  getWordCount,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteReplyReview } from "redux/reputation/deleteReviewReplySlice";
import { getAiSuggestion } from "redux/reputation/getAiSuggestionSlice";
import { getStarReview } from "redux/reputation/getStarReviewSlice";
import { replyReview } from "redux/reputation/replyReviewSlice";

const Quarantine = ({
  getReviewsList = () => {},
  reviewTemplate,
  quarantineData,
}) => {
  const [editing, setEditing] = useState([false]);
  const [isEditStart, setEditStart] = useState(false);
  const companyId = getUserData() ? JSON.parse(getUserData()) : "";
  const [reviewId, setReviewId] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const currentLocation = getCurrentLocation();
  const [clickCount, setClickCount] = useState(0);
  const [message, setMessage] = useState({
    editMessage: "",
    editId: "",
    showError: false,
  });
  const getStarReviewData = useSelector(
    (state) => state?.getStarReview?.data ?? []
  );
  const getAiLoader = useSelector(
    (state) => state?.getAiSuggestion?.loading ?? false
  );

  const dispatch = useDispatch();
  // textarea open and close function
  const openTextarea = (index) => {
    setEditing((prevVisibility) => ({
      [index]: !prevVisibility[index],
    }));
    setEditStart(true);
    setClickCount(0);
  };
  // reply button click function handlesubmit
  const handleSubmit = async (data, index) => {
    let replyText = {
      company_id: companyId?.company_id,
      company_location_id: currentLocation?.id,
      review_reply: message?.editMessage,
      id: data?.id,
    };
    if (message?.editMessage?.length > 0 && editing[index]) {
      if (getWordCount(message?.editMessage) >= 5) {
        dispatch(replyReview(replyText)).then((res) => {
          getReviewsList();
          openTextarea(index);
          setMessage({
            ...message,
            showError: false,
            editMessage: "",
            editId: "",
          });
        });
      } else {
        setMessage({ ...message, showError: true });
      }
    } else {
      openTextarea(index);
      setMessage({
        ...message,
        showError: false,
        editMessage: "",
        editId: "",
      });
    }
  };
  // onchange event
  const handleChange = (value, index) => {
    let regex = /\s+/gi;
    let words = value?.trim().replace(regex, " ").split(" ").length;
    words >= 5 || value?.length == 0
      ? setMessage({
          ...message,
          showError: false,
          editMessage: value,
        })
      : setMessage({
          ...message,
          showError: true,
          editMessage: value,
        });
  };

  // get template button function
  const getTamplate = (data, index) => {
    let templateStar = getStarReviewData?.dataObj?.filter(
      (matched, index) =>
        Number(matched?.rating) ===
        reviewTemplate?.starRating[data?.star_rating]
    );
    if (templateStar?.length > 0) {
      let replaceName = templateStar[0]?.rating_template.replace(
        /%%First_Name%%/g,
        `${data?.reviewer_name?.split(" ")[0]}`
      );
      setMessage({
        ...message,
        editMessage: replaceName,
      });
    } else {
      showErrorMsg("Template Is Not Matched At This Location");
    }
  };
  // handleEdit review
  const handleEdit = (index, data) => {
    openTextarea(index);
    setMessage({
      ...message,
      editMessage: data?.review_reply_comment,
      editId: data?.id,
    });
  };

  let replyData = {
    company_id: companyId?.company_id,
    location_id: currentLocation?.id,
    company_location_id: currentLocation?.id,
    id: reviewId,
  };
  // handleDelete review
  const handleDelete = () => {
    dispatch(deleteReplyReview(replyData)).then((data) => {
      getReviewsList();
      setModalOpen(false);
      showSuccessMsg(data?.payload?.dataObj?.message);
    });
  };

  // handleAI review
  const handleAiReview = (star = "", comment = "", review_id = "") => {
    let review_data = {
      star: star,
      review_text: comment,
      company_location_id: currentLocation?.id,
      review_id: review_id,
    };
    dispatch(getAiSuggestion(review_data)).then((res) => {
      setMessage({
        ...message,
        editMessage: res?.payload?.dataObj?.reply_message,
      });
      showSuccessMsg(res?.payload?.message);
    });
    setClickCount(clickCount + 1);
  };

  useEffect(() => {
    dispatch(getStarReview(replyData));
  }, []);

  return (
    <div className="pb-5 ">
      {!reviewTemplate?.getStarTemplateLoading &&
      !reviewTemplate?.getMoreLoading ? (
        quarantineData?.map((data, index) => {
          return (
            <div
              className="outer-div border-2 p-2 border-gray-400 mt-5 bg-white"
              key={index}
            >
              <div className="flex  w-full  ">
                <div className=" w-full ">
                  <div className="mb-2">{data?.reviewer_name}</div>
                </div>
              </div>
              <div className="mb-2 flex">
                <span className="my-1 flex list-none  p-0">
                  <RatingField
                    rating={reviewTemplate?.starRating[data?.star_rating] ?? 0}
                    size={1.5}
                  />
                </span>
                <span className="flex items-center ml-2 text-sm text-gray-400">
                  {data?.google_review_created_at}
                </span>
              </div>
              <p className="mb-1">{data?.comment}</p>
              {!editing[index] && (
                <div className="  w-full  ">
                  <div className="flex   w-full ">
                    {data?.review_reply_comment !== null && (
                      <p className="w-5">
                        <img
                          src={ReplyArrowIcon}
                          alt="reply-arrow"
                          className="w-5 h-5 transform scale-x-[-1]"
                        />
                      </p>
                    )}
                    <p
                      id="message "
                      className="ml-2 transition-all delay-500 duration-300 w-full "
                    >
                      {data?.review_reply_comment ?? ""}{" "}
                    </p>
                  </div>
                  {data?.review_reply_comment !== null && (
                    <div className="flex justify-start items-start ml-7 mt-2  gap-2">
                      <button
                        disabled={message?.editId}
                        title="Edit Reply"
                        onClick={() => {
                          handleEdit(index, data);
                        }}
                      >
                        <PencilSquareIcon className="h-5 w-5" />
                      </button>
                      <button
                        disabled={message?.editId}
                        title="Delete Reply"
                        onClick={() => {
                          setReviewId(data?.id);
                          setModalOpen(true);
                        }}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div
                className={`inner-div delay-500 duration-300  ${
                  editing[index]
                    ? "open "
                    : `${
                        isEditStart &&
                        Number(Object.keys(editing)?.[0]) === index
                          ? `close`
                          : "hidden"
                      }`
                } `}
              >
                <form id="editForm">
                  <div>
                    <div className="row sm:flex">
                      <div className="col sm:w-1/2">
                        <div className={`flex flex-col  `}>
                          <textarea
                            rows="4"
                            id="editMessage"
                            placeholder="Replying publicaly"
                            className={`w-full focus:border-gray-400  `}
                            value={message?.editMessage}
                            onChange={(e) => {
                              handleChange(e.target.value, index);
                            }}
                          />
                          {message?.showError && (
                            <p className="text-red-400">
                              Enter Minimum 5 Words
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col mt-8 sm:ml-8 sm:mt-0">
                        <div className="  rounded flex flex-col  ">
                          <SpinnerButton
                            className="btn-green text-white mb-3 active:scale-95 "
                            title={` ${
                              reviewTemplate?.starRating[data?.star_rating]
                            }-Star Template`}
                            action={() => {
                              getTamplate(data, index);
                            }}
                            type={"button"}
                          />

                          <SpinnerButton
                            className="btn-green text-white mb-3 active:scale-95 "
                            title={`${
                              clickCount >= 1
                                ? `Regenerate with AI `
                                : "AI Generate"
                            } `}
                            action={() => {
                              handleAiReview(
                                reviewTemplate?.starRating[data?.star_rating],
                                data?.comment,
                                data?.id
                              );
                            }}
                            type={"button"}
                            loading={getAiLoader}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="mt-2 text-gray-500 ">
                      * This customer will be notified about your reply, and it
                      will be publicly visible on your Business Profile.
                    </p>
                  </div>
                </form>
              </div>
              {data?.review_reply_comment === null && (
                <div className="flex justify-between items-center mx-2">
                  <button
                    className={`btn active:scale-95  ${
                      getWordCount(message?.editMessage) >= 5 && editing[index]
                        ? "bg-[#12d1ea] hover:bg-cyan-500"
                        : "btn-light-gray"
                    }  text-white mt-1`}
                    onClick={(e) => {
                      handleSubmit(data, index);
                    }}
                  >
                    Reply
                  </button>
                </div>
              )}

              {message?.editId === data?.id && (
                <div className="flex justify-between items-center mx-2">
                  <button
                    className={`btn active:scale-95 ${
                      getWordCount(message?.editMessage) >= 5 && editing[index]
                        ? "bg-[#12d1ea] hover:bg-cyan-500"
                        : "btn-light-gray"
                    }  text-white mt-2`}
                    onClick={(e) => {
                      handleSubmit(data, index);
                    }}
                  >
                    Reply
                  </button>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="w-full">
          <Skeleton counter={3} className="my-2" height={10} />
        </div>
      )}
      {(reviewTemplate?.getStarTemplateData === undefined ||
        quarantineData?.length == 0) && (
        <p className="w-full mt-5 text-center text-gray-600">
          No Review Available
        </p>
      )}
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleDelete}
        />
      )}
    </div>
  );
};

export default Quarantine;
