import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getMenuDetail = createAsyncThunk(
  "gmb/menu-detail",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await getApi(
        `api/gmblocationmenu/${formData?.id}?location_id=${
          formData?.location_id ?? 0
        }`
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getMenuDetailSlice = createSlice({
  name: "getMenuDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenuDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMenuDetail.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getMenuDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getMenuDetailSlice.reducer;
