import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi, postApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const generateServicePostContent = createAsyncThunk(
  "post/service-ai-content",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await postApi(`api/generate-service-post`, formData);
      return response?.data?.dataObj;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const generateServicePostContentSlice = createSlice({
  name: "generateServicePostContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateServicePostContent.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(generateServicePostContent.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(generateServicePostContent.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default generateServicePostContentSlice.reducer;
