import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getServiceDetails = createAsyncThunk(
  "gmb/service-detail",
  async (formData, { rejectWithValue }) => {
    try {
      const { id, location_id } = formData;
      const response = await getApi(
        `api/gmblocationService/${id}?location_id=${location_id}`
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getServiceDetailsSlice = createSlice({
  name: "getServiceDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getServiceDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getServiceDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getServiceDetailsSlice.reducer;
