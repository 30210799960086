import classNames from "classnames";
import CustomTable from "components/common/fields/CustomTable";
import RatingField from "components/contacts/customer/RatingField";
import { debounce } from "lodash";
import {
  formatPhoneNumberAlways,
  getCurrentLocation,
  getShortCodeOfStates,
  isValidArray,
  showErrorMsg,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllContacts } from "redux/contacts/getAllContactsSlice";
import { addCustomerToLocation } from "redux/customer/addCustomerToLocationSlice";

const CustomerSearchForm = (props) => {
  const { setSearchFormOpen = () => {}, currentCompanyId = "" } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [sortIndex, setSortIndex] = useState(1);
  const [sortOrder, setSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const [{ customerListData = [], customerListLoading = false }] = useSelector(
    (state) => [
      {
        customerListData: state?.getAllContacts?.data?.dataObj ?? [],
        customerListLoading: state?.getAllContacts?.loading ?? false,
      },
    ]
  );

  const totalRecords = customerListData?.total;
  const currentPerPage = customerListData?.per_page ?? showPerPage;
  const isEnableButtons =
    !!name ||
    (!!name && !isValidArray(customerListData?.data ?? [])) ||
    !isValidArray(customerListData?.data ?? []);

  const handleCustomerList = (search) => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
      company_id: currentCompanyId,
      type: "customer",
    };
    dispatch(getAllContacts(sortObj));
  };

  const delayedSearch = useCallback(
    debounce((q) => globalSearch(q), 600),
    []
  );

  const clearSearch = () => {
    setName("");
    setSelectedCustomer("");
    globalSearch("");
  };

  const handleChange = (value) => {
    setName(value);
    delayedSearch(value);
    if (!!!value) {
      setSelectedCustomer("");
    }
  };

  const globalSearch = (search) => {
    handleCustomerList(search);
  };

  useEffect(() => {
    handleCustomerList(name);
  }, [sortOrder, currentPage, perPage]);

  const handleAddCustomerToLocation = () => {
    const { id: currentLocationId } = getCurrentLocation();
    const formData = {
      locationId: currentLocationId,
      customerId: selectedCustomer,
    };
    dispatch(addCustomerToLocation(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        showSuccessMsg(data?.payload?.dataObj?.message);
        navigate("/contacts?tab=customers-list");
      } else {
        showErrorMsg("Something went wrong");
      }
    });
  };

  const columns = [
    {
      Header: "",
      width: 50,
      accessor: "id",
      Cell: ({ cell }) => (
        <label className="form-check-label my-1 hover:cursor-pointer">
          <input
            name={`customerId`}
            type="radio"
            className="form-check-input rounded-none hover:cursor-pointer text-pink-600 outline-0"
            value={cell.row.values.id}
            checked={cell.row.values.id === selectedCustomer}
            onChange={(e) => setSelectedCustomer(cell.row.values.id)}
          />
        </label>
      ),
    },
    {
      Header: "Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },

    {
      Header: "Address",
      accessor: "address1",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State",
      accessor: "state",
      Cell: ({ cell }) => (
        <span>{getShortCodeOfStates(cell.row.values?.state)}</span>
      ),
    },
    {
      Header: "Zip",
      accessor: "zip",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ cell }) => {
        return formatPhoneNumberAlways(cell.row.values?.phone);
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Rating",
      accessor: "score",
      Cell: ({ cell }) => <RatingField rating={cell.row.values?.score ?? 0} />,
    },
  ];

  return (
    <div className="gap-x-4 border border-gray-200 p-3 bg-white">
      <div className="">
        <div className="flex flex-wrap items-center -mx-3 mb-3">
          <div className="w-full xl:w-1/3 px-3 mb-6 md:mb-0">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Search Customer
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                placeholder="Please search customer"
                onChange={(e) => handleChange(e.target.value)}
                className="input-field"
              />
            </div>
          </div>

          <div className="flex items-center justify-start md:mt-8 px-4">
            <button
              type="button"
              onClick={() => {
                if (name) {
                  clearSearch();
                }
              }}
              className={classNames("btn btn-gray mr-2", {
                "disabled-btn": !isEnableButtons,
              })}
            >
              Clear Search
            </button>
            <button
              type="button"
              onClick={() => {
                setSelectedCustomer("");
                setSearchFormOpen(false);
              }}
              disabled={!isEnableButtons}
              className={classNames("btn-pink", {
                "disabled-btn": !isEnableButtons,
              })}
            >
              Go To Add Customer Form
            </button>
            <button
              type="button"
              onClick={() => handleAddCustomerToLocation()}
              className={classNames("btn-pink ml-2", {
                "disabled-btn": !!!selectedCustomer,
              })}
              disabled={!!!selectedCustomer}
            >
              Add To Current Location
            </button>
            <button
              type="button"
              className="btn btn-gray ml-2"
              onClick={() => navigate(`/contacts?tab=${location?.state}`)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <CustomTable
          data={
            name && isValidArray(customerListData?.data)
              ? customerListData?.data
              : []
          }
          isLoading={customerListLoading}
          columns={columns}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
        />
      </div>
    </div>
  );
};

export default CustomerSearchForm;
