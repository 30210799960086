import SpinnerButton from "components/common/fields/SpinnerButton";
import GuestWifiLoader from "components/front/GuestWifiLoader";
import {
  convertTimeStampToDate,
  createFirstLetterCapital,
  getCurrentLocation,
  getDataLocalStorage,
  isValidArray,
  isValidObject,
  removeUnderscore,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getCategoryDetails } from "redux/gmbLocation/getCategoryDetailsSlice";
import { getMenuDetail } from "redux/gmbLocation/getMenuDetailSlice";
import { getSectionDetail } from "redux/gmbLocation/getSectionDetailSlice";
import { addCampaignPost } from "redux/post/addCampaignPostSlice";
import { addServiceCampaignPost } from "redux/post/service/addServiceCampaignPostSlice";
import { updateServiceCampaignPost } from "redux/post/service/updateServiceCampaignPostSlice";
import { updateCampaignPost } from "redux/post/updateCampaignPostSlice";

export default function PostLaunchForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const campaignId = searchParams.get("campaignId")
    ? searchParams.get("campaignId")
    : "";
  const isEdit = !!campaignId ? campaignId : false;

  const addCampaignLoading = useSelector(
    (state) => state?.addCampaignPost?.loading ?? false
  );
  const updateCampaignLoading = useSelector(
    (state) => state?.updateCampaignPost?.loading ?? false
  );
  const addServiceCampaignLoading = useSelector(
    (state) => state?.addServiceCampaignPost?.loading ?? false
  );
  const updateServiceCampaignLoading = useSelector(
    (state) => state?.updateServiceCampaignPost?.loading ?? false
  );

  const [menuName, setMenuName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const { id: currentLocationId, google_business_type = "" } =
    getCurrentLocation();
  const campaignDetails = getDataLocalStorage("CAMPAIGN_POST_DATA");

  const handleGetMenuDetails = () => {
    const formData = {
      id: campaignDetails?.menu_id,
    };
    dispatch(getMenuDetail(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const menuDetails = data?.payload?.dataObj;
        setMenuName(menuDetails?.menu_name);
      }
    });
  };

  const handleGetCategoryDetails = () => {
    const formData = {
      id: campaignDetails?.category_id,
      location_id: currentLocationId,
    };
    dispatch(getCategoryDetails(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const categoryDetails = data?.payload?.dataObj?.category_name;
        setMenuName(categoryDetails);
      }
    });
  };

  const handleSectionDetail = () => {
    if (!!campaignDetails?.section_id) {
      const formData = {
        id: campaignDetails?.section_id,
        location_id: currentLocationId,
      };
      dispatch(getSectionDetail(formData)).then((data) => {
        const dataObj = data?.payload?.dataObj?.data;
        if (data?.payload?.code == 200) {
          const name = dataObj?.section_name ?? "";
          setSectionName(name);
        }
      });
    } else {
      isValidObject(campaignDetails)
        ? setSectionName("All Sections")
        : setSectionName("N/A");
    }
  };

  const handleSubmit = () => {
    const { path, ...formData } = campaignDetails;

    const action = isEdit
      ? google_business_type === "Restaurant"
        ? updateCampaignPost({ id: campaignId, formData })
        : updateServiceCampaignPost({ id: campaignId, formData })
      : google_business_type === "Restaurant"
      ? addCampaignPost(formData)
      : addServiceCampaignPost(formData);

    dispatch(action).then((data) => {
      if (data?.payload?.code === 200) {
        navigate(`/marketing?tab=campaigns`);
        showSuccessMsg(
          data?.payload?.dataObj?.message ?? data?.payload?.message ?? isEdit
            ? "Campaign post updated successfully."
            : "Campaign post added successfully."
        );
      } else {
        handleServerValidation(data);
      }
    });
  };

  useEffect(() => {
    google_business_type === "Restaurant"
      ? (handleGetMenuDetails(), handleSectionDetail())
      : handleGetCategoryDetails();
    if (!isValidObject(campaignDetails) || !campaignDetails?.campaign_name) {
      const basePath = `/marketing?tab=posts&type=multi&form=post-details`;
      const navigatePath = isEdit
        ? `${basePath}&campaignId=${campaignId}`
        : basePath;
      navigate(navigatePath);
    }
  }, []);

  return (
    <div className="w-full h-[40rem] flex justify-center relative">
      <div className="w-full h-full p-4">
        <div className="w-3/4 px-3 -mx-3 mb-6 flex gap-2">
          <span className="text-[15px] font-semibold w-40 pl-4">
            Campaign Name :
          </span>
          <span className="font-light ml-2">
            {campaignDetails?.campaign_name ?? "N/A"}
          </span>
        </div>

        <div className="w-3/4 px-3 -mx-3 mb-6 flex gap-2">
          <span className="text-[15px] font-semibold w-40 pl-4">Post to :</span>
          <span className="font-light ml-2">
            {removeUnderscore(campaignDetails?.post_to) ?? "N/A"}
          </span>
        </div>

        <div className="w-3/4 px-3 -mx-3 mb-6 flex gap-2">
          <span className="text-[15px] font-semibold w-40 pl-4">
            Total Post :
          </span>
          <span className="font-light ml-2">
            {campaignDetails?.post_count ?? "N/A"}
          </span>
        </div>

        <div className="w-3/4 px-3 -mx-3 mb-6 flex gap-2">
          <span className="text-[15px] font-semibold w-40 pl-4">
            {google_business_type === "Restaurant"
              ? `Menu :`
              : `Service Category :`}
          </span>
          <span className="font-light ml-2">{menuName ?? "N/A"}</span>
        </div>

        {google_business_type === "Restaurant" && (
          <div className="w-3/4 px-3 -mx-3 mb-6 flex gap-2">
            <span className="text-[15px] font-semibold w-40 pl-4">
              Section :
            </span>
            <span className="font-light ml-2">{sectionName ?? "N/A"}</span>
          </div>
        )}

        <div className="w-3/4 px-3 -mx-3 mb-6 flex gap-2">
          <span className="text-[15px] font-semibold w-40 pl-4">
            Post Date :
          </span>
          <span className="font-light ml-2">
            {convertTimeStampToDate(campaignDetails?.post_launch_date) ?? "N/A"}
          </span>
        </div>

        <div className="w-3/4 px-3 -mx-3 mb-6 flex gap-2">
          <span className="text-[15px] font-semibold w-40 pl-4">
            Post Schedule :
          </span>
          <span className="font-light ml-2">
            {createFirstLetterCapital(campaignDetails?.postSchedule) || "N/A"}
          </span>
        </div>

        {isValidArray(campaignDetails?.weekdays) && (
          <div className="w-full px-3 -mx-3 mb-6 flex gap-2">
            <span className="text-[15px] font-semibold w-40 pl-4">
              Week days :
            </span>
            {campaignDetails?.weekdays?.length == 7 ? (
              <span className="font-light ml-2">Whole Week</span>
            ) : (
              <span className="font-light ml-2">
                {campaignDetails?.weekdays?.map((days, daysIndex) => {
                  return (
                    <span key={daysIndex}>
                      {days}
                      {campaignDetails?.weekdays?.length !== daysIndex && `, `}
                    </span>
                  );
                })}
              </span>
            )}
          </div>
        )}

        <div className="w-3/4 px-3 -mx-3 mb-6 flex gap-2">
          <span className="text-[15px] font-semibold w-40 pl-4">
            Post Timing :
          </span>
          <span className="font-light ml-2">
            {`${campaignDetails?.post_start_time ?? "N/A"} - ${
              campaignDetails?.post_end_time ?? "N/A"
            }`}
          </span>
        </div>

        <div className="w-full flex justify-between items-end absolute bottom-0 right-0 p-4">
          <button
            type="button"
            onClick={() => {
              const basePath = `/marketing?tab=posts&type=multi&form=post-schedule`;
              const navigatePath = isEdit
                ? `${basePath}&campaignId=${campaignId}`
                : basePath;
              // location?.state
              navigate(navigatePath, { state: location?.state });
              // if (isEdit) {
              //   navigate(
              //     `/marketing?tab=posts&type=multi&form=post-schedule&campaignId=${campaignId}`
              //   );
              // } else {
              //   navigate(`/marketing?tab=posts&type=multi&form=post-schedule`);
              // }
            }}
            className="btn btn-gray"
          >
            Back
          </button>
          <div className="flex gap-2">
            <SpinnerButton
              className="btn-green"
              title={"Launch"}
              type={"button"}
              action={() => {
                handleSubmit();
              }}
              // loading={addCampaignLoading || updateCampaignLoading}
            />
          </div>
        </div>
      </div>
      <GuestWifiLoader
        isModalOpen={
          addCampaignLoading ||
          updateCampaignLoading ||
          addServiceCampaignLoading ||
          updateServiceCampaignLoading
        }
        message={"Building Campaign..."}
      />
    </div>
  );
}
