import CommonProcessingModal from "components/common/CommonProcessingModal";
import SpinnerButton from "components/common/fields/SpinnerButton";
import ColumnMappingForm from "components/leadList/ColumnMappingForm";
import ShowLeadCountsModal from "components/leadList/ShowLeadCountsModal";
import { Field, Form, Formik } from "formik";
import { postApi } from "module/api";
import { leadsColumnArray, leadsRequiredColumnArray } from "module/data";
import {
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import {
  handleServerValidation,
  leadListForm,
  showError,
} from "module/validation";
import { useEffect, useState } from "react";
import {
  formatFileSize,
  lightenDarkenColor,
  readString,
  useCSVReader,
} from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { companyList } from "redux/company/companyListSlice";
import { getLeadSources } from "redux/leadList/getLeadSourcesSlice";
import { getLocationsByCompany } from "redux/leadList/getLocationsByCompanySlice";

const LeadListForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { CSVReader } = useCSVReader();

  const GREY = "#CCC";
  const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
  const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
  const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
    DEFAULT_REMOVE_HOVER_COLOR,
    40
  );
  const GREY_DIM = "#686868";

  const styles = {
    zone: {
      alignItems: "center",
      border: `2px dashed`,
      borderColor: GREY,
      borderRadius: 20,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      padding: 20,
    },
    file: {
      background: "linear-gradient(to bottom, #EEE, #DDD)",
      borderRadius: 20,
      display: "flex",
      height: 120,
      minWidth: 150,
      maxWidth: 350,
      position: "relative",
      zIndex: 0,
      flexDirection: "column",
      justifyContent: "center",
    },
    info: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 10,
      paddingRight: 10,
    },
    size: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      marginBottom: "0.5em",
      justifyContent: "center",
      display: "flex",
      maxWidth: "200px",
    },
    name: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      fontSize: 12,
      marginBottom: "0.5em",
      maxWidth: "200px",
    },
    progressBar: {
      bottom: 14,
      position: "absolute",
      width: "100%",
      paddingLeft: 10,
      paddingRight: 10,
    },
    zoneHover: {
      borderColor: GREY_DIM,
    },
    default: {
      borderColor: GREY,
    },
    remove: {
      height: 23,
      position: "absolute",
      right: 6,
      top: 6,
      width: 23,
    },
  };

  const [totalCompanyList, setTotalCompanyList] = useState([]);
  const [csvData, setCsvData] = useState({});
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [sourceArray, setSourceArray] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [importedLeadData, setImportedLeadData] = useState({});
  const [zoneHover, setZoneHover] = useState(false);
  const [isSaveClicked, setSaveClicked] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  useEffect(() => {
    dispatch(
      companyList({ companyStatus: "active", with_location: true })
    ).then((data) => {
      if (data?.payload?.data) {
        setTotalCompanyList(data?.payload?.data);
      }
    });

    dispatch(getLeadSources()).then((data) => {
      if (data?.payload) {
        const finalOptions = data?.payload?.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        setSourceArray(finalOptions);
      }
    });

    dispatch(
      setBreadCrumb([
        {
          name: "Lead List",
          href: "/lead-list",
          current: true,
        },
        {
          name: "Add Lead List",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  const initialValues = {
    company: "",
    location: "",
    name: "",
    source: "",
  };

  const handleLeadDataModal = (data) => {
    setImportedLeadData(data);
    setModalOpen(true);
  };

  const handleSubmit = (values) => {
    if (isValidArray(csvData)) {
      const formData = {
        list_name: values?.name,
        location_id: values?.location,
        user_id: values?.company,
        source: values?.source,
      };
      handleReadString(formData);
    } else {
      setSaveClicked(true);
    }
  };

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => {
      return (
        String(object[key]) !== "" &&
        String(value) !== "" &&
        String(object[key]) === String(value)
      );
    });
  };

  const isValueBlank = (value) => {
    return value === null || value === undefined || value === "";
  };

  const checkIsObjectValid = () => {
    if (isValidObject(selectedData)) {
      for (const key in selectedData) {
        if (selectedData.hasOwnProperty(key)) {
          const value = selectedData[key];
          if (isValueBlank(value) && leadsRequiredColumnArray.includes(key)) {
            showErrorMsg(`Please select ${key} field`);
            return false;
          }
        }
      }
      return true;
    }
  };

  const createArrayToObject = (headerArray, data) => {
    const dataArray = isValidArray(data) ? data : [];

    if (isValidArray(dataArray)) {
      const headerRow = headerArray;

      let rawData = [];
      if (isValidArray(dataArray)) {
        rawData = dataArray.map((row) => {
          const obj = {};
          headerRow.forEach((key, index) => {
            const finalKey = getKeyByValue(selectedData, index);
            if (finalKey !== "" && finalKey !== undefined) {
              obj[finalKey] = row[index];
            }
          });
          return obj;
        });
      }

      return rawData;
    }
    return [];
  };

  const handleReadString = (dataObj) => {
    setSaveClicked(false);
    const dataArray = isValidArray(csvData) ? csvData : [];
    const headerArray = dataArray[0];

    if (isValidArray(dataArray.slice(1)) && checkIsObjectValid()) {
      let latestString = "";
      let leads_id = "";
      let leadsObj = {};
      let existing_count = "";
      let inserted_count = 0;
      let total_count = 0;
      let updated_count = 0;

      dataArray.slice(1).map((item) => {
        latestString += item.join("^") + "\n";
      });
      setLoading(true);
      readString(latestString, {
        chunkSize: 200000,
        linebreak: "\n",
        skipEmptyLines: true,
        delimiter: "^",
        chunk: async (results, parser) => {
          if (isValidArray(results?.data)) {
            const rowData = createArrayToObject(headerArray, results?.data);
            parser.pause();

            const finalObj = {
              ...dataObj,
              json_data: rowData,
              lead_list_id: leads_id,
            };
            await postApi(`api/bulk-lead-import `, finalObj)
              .then((data) => {
                if (data.status === 200) {
                  leadsObj = data?.data?.dataObj ?? {};
                  existing_count = data?.data?.dataObj?.existing_count ?? 0;
                  inserted_count += data?.data?.dataObj?.inserted_count ?? 0;
                  total_count += data?.data?.dataObj?.total_count ?? 0;
                  updated_count += data?.data?.dataObj?.updated_count ?? 0;
                  leads_id = data?.data?.dataObj?.lead_list_id ?? 0;
                  parser.resume();
                } else {
                  handleServerValidation(data);
                  setLoading(false);
                }
              })
              .catch((error) => {
                setLoading(false);
                handleServerValidation(error);
              });
          }
        },
        complete: (results) => {
          setLoading(false);
          if (results) {
            showSuccessMsg("Leads imported successfully");
          }
          if (isValidObject(leadsObj)) {
            const dataObj = {
              existing_count: existing_count,
              inserted_count: inserted_count,
              total_count: total_count,
              updated_count: updated_count,
            };
            handleLeadDataModal(dataObj);
          }
        },
      });
    }
  };

  const handleCompanyLocation = (companyId) => {
    if (companyId) {
      dispatch(getLocationsByCompany(companyId)).then((data) => {
        if (isValidObject(data?.payload)) {
          setLocations(data?.payload);
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      setLocations([]);
    }
  };

  useEffect(() => {
    isValidArray(csvData) && setSaveClicked(false);
  }, [csvData]);

  return (
    <div className="flex flex-col xl:flex-row gap-4 gap-x-4 w-full border border-gray-200  p-3 bg-white mt-4">
      <div className="flex-1 min-w-[40%]">
        {/*  border shadow-md */}
        <Formik
          initialValues={initialValues}
          validationSchema={() => {
            return leadListForm(validationMsgObj?.leadList ?? {});
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            touched,
            handleChange,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                e.key == "Enter" && e.preventDefault();
              }}
            >
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label htmlFor="company" className="input-field-label">
                    Company
                  </label>
                  <div className="mt-2">
                    <Field
                      as="select"
                      className="input-field"
                      name="company"
                      onChange={(e) => {
                        setFieldValue("company", e.target.value);
                        handleCompanyLocation(e.target.value);
                        if (!e.target.value) {
                          setFieldValue("location", "");
                        }
                      }}
                    >
                      <option key={1} value={""}>
                        Select Company
                      </option>
                      {isValidArray(totalCompanyList) &&
                        totalCompanyList.map((item) => (
                          <option
                            key={item?.id}
                            value={item?.id ?? ""}
                            defaultValue={values?.company ?? ""}
                          >
                            {item?.company ?? item?.name}
                          </option>
                        ))}
                    </Field>
                  </div>
                  {touched?.company && showError(errors?.company)}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full  px-3 mb-6 md:mb-0">
                  <label htmlFor="location" className="input-field-label">
                    Location
                  </label>
                  <div className="mt-2">
                    <Field as="select" className="input-field" name="location">
                      <option key={1} value={""}>
                        Select Location
                      </option>
                      {isValidArray(locations) &&
                        locations.map((item) => (
                          <option
                            key={item?.id}
                            value={item?.id ?? ""}
                            defaultValue={values?.location ?? ""}
                          >
                            {item?.name}
                          </option>
                        ))}
                    </Field>
                  </div>
                  {touched?.location && showError(errors?.location)}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label htmlFor="name" className="input-field-label">
                    Name
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      value={values?.name ?? ""}
                      onChange={handleChange}
                      placeholder="Enter Name"
                      className="input-field"
                    />
                  </div>
                  {touched?.name && showError(errors?.name)}
                </div>
              </div>
              <div className="flex  flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label htmlFor="source" className="input-field-label">
                    Source
                  </label>
                  <div className="mt-2">
                    <CreatableSelect
                      isClearable={true}
                      name="source"
                      value={sourceArray?.filter(function (option) {
                        return option?.value === values?.source;
                      })}
                      onChange={(e) => {
                        const isExist = sourceArray?.find(
                          (item) => item.value === e?.value
                        );
                        !isExist &&
                          !!e?.value &&
                          setSourceArray([
                            ...sourceArray,
                            { label: e?.value, value: e?.value },
                          ]);
                        setFieldValue("source", e?.value ?? "");
                      }}
                      onBlur={(e) => {
                        setFieldTouched("source", true);
                      }}
                      options={sourceArray}
                      styles={{
                        control: (baseStyles, { isFocused }) => ({
                          ...baseStyles,
                          borderColor: isFocused ? "#64656680" : "#abadb180",
                          borderRadius: 0,
                        }),
                        option: (baseStyles, { isSelected }) => ({
                          ...baseStyles,
                          backgroundColor: isSelected ? "#abadb180" : "#fff",
                          color: "black",
                          ":hover": {
                            backgroundColor: "#abadb180",
                          },
                        }),
                        input: (baseStyles, { isFocused, isSelected }) => ({
                          ...baseStyles,
                          borderColor: isFocused ? "#64656680" : "#abadb180",
                          ":hover": {
                            borderColor: "#64656680",
                            border: "1px",
                          },
                        }),
                      }}
                    />
                    {touched?.source && showError(errors?.source)}
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full  px-3">
                  <label htmlFor="upload-file" className="input-field-label">
                    Upload File{" "}
                    <span className="text-xs font-light">
                      (Upload Only CSV File.)
                    </span>
                  </label>
                  <div className="mt-2">
                    <CSVReader
                      onUploadAccepted={(results) => {
                        if (isValidArray(results.data)) {
                          setCsvData(results.data);
                        } else {
                          if (isValidArray(results.error)) {
                            showErrorMsg("Something went wrong.");
                          }
                        }
                        setZoneHover(false);
                      }}
                      config={{
                        delimiter: ",",
                      }}
                      onDragOver={(event) => {
                        event.preventDefault();
                        setZoneHover(true);
                      }}
                      onDragLeave={(event) => {
                        event.preventDefault();
                        setZoneHover(false);
                      }}
                    >
                      {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                        Remove,
                      }) => (
                        <>
                          <div
                            className="cursor-pointer"
                            {...getRootProps()}
                            style={Object.assign(
                              {},
                              styles.zone,
                              zoneHover && styles.zoneHover
                            )}
                          >
                            {acceptedFile ? (
                              <>
                                <div style={styles.file}>
                                  <div style={styles.info}>
                                    <span style={styles.size}>
                                      {formatFileSize(acceptedFile.size)}
                                    </span>
                                    <span style={styles.name}>
                                      {acceptedFile.name}
                                    </span>
                                  </div>
                                  <div style={styles.progressBar}>
                                    <ProgressBar />
                                  </div>
                                  <div
                                    {...getRemoveFileProps()}
                                    onClick={(event) => {
                                      getRemoveFileProps().onClick(event);
                                      setCsvData([]);
                                      setSaveClicked(true);
                                    }}
                                    style={styles.remove}
                                    onMouseOver={(event) => {
                                      event.preventDefault();
                                      setRemoveHoverColor(
                                        REMOVE_HOVER_COLOR_LIGHT
                                      );
                                    }}
                                    onMouseOut={(event) => {
                                      event.preventDefault();
                                      setRemoveHoverColor(
                                        DEFAULT_REMOVE_HOVER_COLOR
                                      );
                                    }}
                                  >
                                    <Remove color={removeHoverColor} />
                                  </div>
                                </div>
                              </>
                            ) : (
                              "Drop CSV file here or click to upload"
                            )}
                          </div>

                          {isValidArray(csvData) && (
                            <span className="font-light text-sm ml-2">
                              Total{" "}
                              <span className="font-semibold">
                                {csvData?.length - 2}
                              </span>{" "}
                              Contacts.
                            </span>
                          )}
                          {isValidArray(csvData) && csvData?.length == 2 && (
                            <span className="text-red-500 ml-2">
                              File should not empty.
                            </span>
                          )}
                          {isSaveClicked && !isValidArray(csvData) && (
                            <span className="text-red-500 ml-2">
                              File is required.
                            </span>
                          )}
                        </>
                      )}
                    </CSVReader>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-start">
                <SpinnerButton
                  className="btn-pink"
                  action={() => setSaveClicked(true)}
                  title={"Save"}
                  type={"submit"}
                  loading={loading}
                />

                <button
                  onClick={() => navigate("/lead-list")}
                  disabled={loading}
                  type="button"
                  className="btn btn-gray ml-2"
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="block xl:hidden w-full h-[1px] bg-gray-300 my-4"></div>
      <div className="flex-2 w-full xl:max-w-[60%] bg-gray-100 shadow-lg">
        <ColumnMappingForm
          csvData={csvData}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          mappingColumnsArray={leadsColumnArray}
        />
      </div>
      {isModalOpen && (
        <ShowLeadCountsModal
          setModalOpen={setModalOpen}
          isModalOpen={isModalOpen}
          importedLeadData={importedLeadData}
        />
      )}
      {loading && (
        <CommonProcessingModal
          processingModal={true}
          message="Please wait a while, Processing Leads..."
        />
      )}
    </div>
  );
};

export default LeadListForm;
