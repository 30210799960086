import { ClockIcon, TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";
import {
  convertPacificTime,
  createFirstLetterCapital,
  getItemImage,
  isValidArray,
} from "module/util";
import { useState } from "react";

export default function FailedPostList({
  postList = [],
  postListLoading = false,
  setModalOpen = () => {},
  setIdForDelete = () => {},
}) {
  const [isViewMore, setIsViewMore] = useState(false);
  const [isViewMoreForFailed, setIsViewMoreForFailed] = useState(false);

  return (
    <div className="w-full border border-gray-300 bg-white p-4 overflow-auto shadow-inner">
      <div className="w-3/4 transition-all ease-in-out delay-700 "></div>
      {postListLoading ? (
        <div role="status" className={`w-full space-y-4 rounded animate-pulse`}>
          <div className="w-full">
            {[...Array(3)]?.map((_, menuIndex) => {
              return (
                <div key={menuIndex} className="h-28">
                  <div className="h-full bg-gray-300 m-2"></div>
                </div>
              );
            })}
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      ) : isValidArray(postList) ? (
        <div className={"h-[calc(100vh-265px)]"}>
          {postList?.map((post, postIndex) => {
            const postDescription = post?.descriptions[0];
            const postSchedule = post?.schedule[0];
            const isEditorValueValid = /^(?!$|<p><br><\/p>).+/.test(
              postDescription?.description
            );
            const zone = convertPacificTime(
              postSchedule?.post_launch_date ?? "",
              postSchedule?.post_launch_time ?? "",
              postSchedule?.post_launch_time_type ?? ""
            );
            return (
              <div key={post?.id}>
                <div className="w-full flex h-28">
                  <div className="w-[100rem] max-h-40 overflow-y-auto break-words">
                    {!!postDescription?.description && isEditorValueValid ? (
                      <div className="font-normal text-sm">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              isViewMore == post?.id
                                ? postDescription?.description
                                : `${postDescription?.description?.slice(
                                    0,
                                    280
                                  )}`,
                          }}
                        />
                        {postDescription?.description?.length > 280 && (
                          <button
                            type="button"
                            onClick={() => {
                              isViewMore == post?.id
                                ? setIsViewMore(0)
                                : setIsViewMore(post?.id);
                            }}
                            className={`text-sm p-1 rounded-md text-blue-500`}
                          >
                            {isViewMore == post?.id
                              ? "View Less"
                              : "...View More"}
                          </button>
                        )}
                      </div>
                    ) : (
                      `Content Not Avaialble`
                    )}
                  </div>

                  <div className="w-[40%] flex justify-center items-center">
                    <img
                      src={getItemImage(postDescription?.post_image)}
                      alt={postDescription?.post_image}
                      className="w-20 h-20 rounded"
                    />
                  </div>

                  <div className="w-[40%] flex flex-col justify-center items-start">
                    <div className="flex justify-center items-center">
                      <span className="w-5 h-5 flex justify-center items-center border mr-2 border-gray-200 bg-white text-yellow-400 text-sm font-medium rounded-xl ">
                        <ClockIcon className="w-4 h-4" strokeWidth={2.5} />
                      </span>
                      <span className="text-sm font-semibold">
                        {zone?.date}
                      </span>
                    </div>
                    <p className="text-sm text-gray-500 ml-8">
                      {zone?.pacificTime ?? ""}
                    </p>
                  </div>

                  <div className="w-[30rem] flex flex-col justify-start items-center h-[115px]">
                    <div className="w-full flex justify-start items-center">
                      <span className="w-5 h-5 flex justify-center items-center mr-1 text-yellow-700 text-sm font-medium rounded-xl ">
                        <XCircleIcon className="w-4 h-4" strokeWidth={2.5} />
                      </span>

                      <span className="input-field-label">Failed</span>
                    </div>
                    <div className="w-[30rem] break-words max-h-full overflow-y-auto">
                      {!!postSchedule?.failed_post ? (
                        <div className="font-normal text-sm">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                isViewMoreForFailed == postSchedule.id
                                  ? postSchedule?.failed_post
                                  : `${postSchedule?.failed_post?.slice(
                                      0,
                                      150
                                    )}`,
                            }}
                          />
                          {postSchedule?.failed_post?.length > 150 && (
                            <button
                              type="button"
                              onClick={() => {
                                isViewMoreForFailed == postSchedule.id
                                  ? setIsViewMoreForFailed(0)
                                  : setIsViewMoreForFailed(postSchedule.id);
                              }}
                              className={`text-sm p-1 rounded-md text-blue-500`}
                            >
                              {isViewMoreForFailed == postSchedule.id
                                ? "View Less"
                                : "...View More"}
                            </button>
                          )}
                        </div>
                      ) : (
                        `Information not available`
                      )}
                    </div>
                  </div>

                  <div className="w-[50rem] flex justify-center items-center">
                    {post?.campaign_type == "single" ? (
                      <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-md dark:text-green-500">
                        {createFirstLetterCapital(post?.campaign_type)}
                      </span>
                    ) : (
                      <div className="flex flex-col items-center">
                        <div className=" bg-orange-100 text-orange-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-md dark:text-orange-500">
                          {`Campaign`}
                        </div>
                        <div className="">{post?.campaign_name}</div>
                      </div>
                    )}
                  </div>

                  <div className="w-[15rem] flex justify-center items-center">
                    <button
                      type="button"
                      onClick={() => {
                        setIdForDelete(post?.id);
                        setModalOpen(true);
                      }}
                    >
                      <TrashIcon className="w-5 h-5" title="Delete" />
                    </button>
                  </div>

                  {/* <div className="w-[30rem] flex justify-center items-center gap-3">
                  <img className="w-4 h-4" src={facebookIcon} alt="face-book" />
                  <img
                    className="w-4 h-4"
                    src={instagramIcon}
                    alt="face-book"
                  />
                </div> */}
                </div>
                {postIndex + 1 !== postList?.length && (
                  <div className="block w-full h-[1px] bg-gray-300 my-2"></div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="w-full h-40 flex justify-center items-center text-lg font-light ">
          No Post Available
        </div>
      )}
    </div>
  );
}
