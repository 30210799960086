import classNames from "classnames";
import { getCurrentLocation, showErrorMsg, systemTimeZone } from "module/util";
import { handleServerValidation } from "module/validation";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCalenderPost } from "redux/post/getCalenderPostSlice";
import { postUpdateFromCalender } from "redux/post/postUpdateFromCalenderSlice";
import { getServiceCalenderPost } from "redux/post/service/getServiceCalenderPostSlice";

export default function Calender() {
  const [calenderView, setCalenderView] = useState("month");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: currentLocationId, google_business_type = "" } =
    getCurrentLocation();
  moment.tz.setDefault(systemTimeZone);
  const localizer = momentLocalizer(moment);
  const [myEvents, setMyEvents] = useState([]);

  const DragAndDropCalendar = withDragAndDrop(Calendar);

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const getPostLoading = useSelector(
    (state) => state?.getCalenderPost?.loading ?? false
  );

  const eventPropGetter = useCallback(
    (event) => ({
      ...(event.isDraggable
        ? { className: "isDraggable" }
        : { className: "nonDraggable" }),
    }),
    []
  );

  const handlePostDateChange = async (date, id) => {
    const formData = {
      id: id,
      date: date,
    };
    await dispatch(postUpdateFromCalender(formData)).then((data) => {});
  };

  const moveEvent = useCallback(
    async ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay, id } = event;
      // if (!allDay && droppedOnAllDaySlot) {
      //   event?.allDay = true;
      // }
      // if (allDay && !droppedOnAllDaySlot) {
      //   event?.allDay = false;
      // }

      await handlePostDateChange(start, id);
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        const result = [...filtered, { ...existing, start, end, allDay }];

        return [...filtered, { ...existing, start, end, allDay }];
      });
    },
    [setMyEvents]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setMyEvents]
  );

  const getPostList = () => {
    const formData = {
      location_id: currentLocationId,
    };

    const action =
      google_business_type === "Restaurant"
        ? getCalenderPost
        : getServiceCalenderPost;
    dispatch(action(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const events = data?.payload?.dataObj?.data;
        setMyEvents(events);
      } else {
        handleServerValidation(data);
      }
    });
  };

  useEffect(() => {
    getPostList();
  }, [currentLocationId]);

  return (
    <>
      {" "}
      <div
        className={classNames("border border-gray-200 bg-white p-4", {
          "opacity-55 grayscale": getPostLoading,
        })}
      >
        <DragAndDropCalendar
          defaultView={calenderView}
          onView={(e) => {
            setCalenderView(e);
          }}
          localizer={localizer}
          events={myEvents}
          style={{ height: "calc(100vh - 16.5rem)" }}
          eventPropGetter={eventPropGetter}
          min={moment()?.date()}
          onEventDrop={(e) => {
            if (e?.event?.status !== "launch") {
              moveEvent(e);
            } else {
              showErrorMsg("You can not move this post as post is launched.");
            }
          }}
          onSelectSlot={(e) => {}}
          onDoubleClickEvent={(e) => {
            if (e?.status !== "launch") {
              navigate(
                `/marketing?tab=posts&type=single&form=post-details&postId=${e?.id}`,
                { state: "calender" }
              );
            } else {
              showErrorMsg("You can not edit this post as post is launched.");
            }
          }}
          // popup
          resizable={false}
          selectable
        />
      </div>
    </>
  );
}
