import eyeIcon from "assets/images/eyeIcon.svg";
import eyeSlashIcon from "assets/images/eyeSlashIcon.svg";
import Logo from "assets/images/logo-pendio.png";
import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import FrontModal from "components/front/FrontModal";
import { Field, Form, Formik } from "formik";
import { showErrorMsg, showSuccessMsg } from "module/util";
import {
  handleServerValidation,
  resetPasswordForm,
  setNewPasswordForm,
  showError,
} from "module/validation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "redux/front/resetPasswordSlice";
import { setNewPassword } from "redux/front/setNewPasswordSlice";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const type = searchParams.get("type");

  const [isPasswordShow, setPasswordShow] = useState(false);
  const [isResetPasswordShow, setResetPasswordShow] = useState(false);

  const [{ isLoading = false }] = useSelector((state) => [
    {
      isLoading: state?.resetPassword?.loading,
    },
  ]);

  const togglePasswordShow = () => setPasswordShow(!isPasswordShow);
  const toggleResetPasswordShow = () =>
    setResetPasswordShow(!isResetPasswordShow);

  const handleSubmit = (values) => {
    const formData = {
      email: values?.email ?? "",
    };
    dispatch(resetPassword(formData))
      .then((data) => {
        if (data?.payload?.code === 200) {
          showSuccessMsg(
            data?.payload?.dataObj?.message ??
              "Reset password link sent to your email address"
          );
        } else {
          const errorMsg =
            data?.payload?.dataObj?.message ?? "Something went wrong";
          showErrorMsg(errorMsg);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="flex flex-col justify-center py-12 px-6 lg:px-8">
      <ShowPageTitle title={"resetPassword"} />
      {!!token ? (
        <VerifyCode
          token={token}
          email={email}
          type={type}
          togglePasswordShow={togglePasswordShow}
          isPasswordShow={isPasswordShow}
          toggleResetPasswordShow={toggleResetPasswordShow}
          isResetPasswordShow={isResetPasswordShow}
        />
      ) : (
        <>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-30 w-auto"
              src={Logo}
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Reset Password
            </h2>
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow shadow-gray-400 sm:px-12">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={resetPasswordForm}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  errors,
                  touched,
                  validateField,
                  validateForm,
                  handleChange,
                  values,
                  handleSubmit,
                  handleBlur,
                }) => (
                  <Form
                    className="space-y-6"
                    onSubmit={handleSubmit}
                    // onKeyDown={(e) => {
                    //   e.key == "Enter" && e.preventDefault();
                    // }}
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <Field
                          id="email"
                          name="email"
                          type="text"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Enter Email"
                          className="input-field"
                        />
                      </div>
                      {touched.email && showError(errors.email)}
                    </div>

                    <div>
                      <SpinnerButton
                        className="flex w-full justify-center btn-pink"
                        title={"Reset Password"}
                        action={() => {}}
                        type={"submit"}
                        loading={isLoading}
                      />
                    </div>
                  </Form>
                )}
              </Formik>

              <div></div>
            </div>

            <p className="mt-1 text-center text-sm text-gray-500">
              <Link
                to="/"
                className="font-semibold leading-6 text-pink-600 hover:text-pink-500 underline"
              >
                Go to Login
              </Link>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

const VerifyCode = (props) => {
  const {
    token = "",
    email = "",
    togglePasswordShow,
    isPasswordShow,
    isResetPasswordShow,
    toggleResetPasswordShow,
    type = "",
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");

  const isLoading = useSelector(
    (state) => state?.setNewPassword?.loading ?? false
  );
  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  const handleSubmit = (values) => {
    const formData = {
      password: values.password,
      password_confirmation: values.confirmPassword,
      email: email?.trim()?.replace(" ", "+") ?? "",
      token: token,
    };

    dispatch(setNewPassword(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        showSuccessMsg(data?.payload?.dataObj?.message);
        navigate("/login");
      } else if (data?.payload?.code === 400) {
        const msg =
          data?.payload?.dataObj.error ??
          data?.payload?.message ??
          "Something went wrong!";
        setConfirmationModal(true);
        setMessage(msg);
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleRedirect = () => {
    navigate("/reset-password");
    setConfirmationModal(false);
  };

  return (
    <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8 ">
      <ShowPageTitle title="login" />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-30 w-auto" src={Logo} alt="Your Company" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {type === "generate" ? "Set New Password" : "Reset Password"}
        </h2>
      </div>

      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow shadow-gray-400 sm:px-12">
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={() =>
              setNewPasswordForm(validationMsgObj?.updatePassword ?? {})
            }
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              errors,
              touched,
              validateField,
              validateForm,
              handleChange,
              values,
              handleSubmit,
              handleBlur,
            }) => (
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="relative mt-2 shadow-sm">
                    <input
                      type={isPasswordShow ? "text" : "password"}
                      name="password"
                      id="password"
                      value={values?.password ?? ""}
                      onChange={handleChange}
                      className="block w-full border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter Password"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <img
                        alt="eye"
                        src={isPasswordShow ? eyeIcon : eyeSlashIcon}
                        className="hover:cursor-pointer"
                        onClick={() => togglePasswordShow()}
                      />
                    </div>
                  </div>
                  {touched.password && showError(errors.password)}
                </div>

                <div>
                  <label
                    htmlFor="re-type-password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirm password
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="re-type-password"
                      name="confirmPassword"
                      type={isResetPasswordShow ? "text" : "password"}
                      value={values?.confirmPassword ?? ""}
                      onChange={handleChange}
                      autoComplete="current-password"
                      placeholder="Confirm Password"
                      className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <img
                        alt="eye"
                        src={isResetPasswordShow ? eyeIcon : eyeSlashIcon}
                        className="hover:cursor-pointer"
                        onClick={() => toggleResetPasswordShow()}
                      />
                    </div>
                  </div>
                  {touched.confirmPassword && showError(errors.confirmPassword)}
                </div>

                <div>
                  <SpinnerButton
                    className="flex w-full justify-center btn-pink"
                    title={
                      type === "generate"
                        ? "Set New Password"
                        : "Reset Password"
                    }
                    action={() => {}}
                    type={"submit"}
                    loading={isLoading}
                  />
                </div>
              </form>
            )}
          </Formik>

          <div></div>
        </div>

        <p className="mt-1 text-center underline text-sm text-gray-500">
          <Link
            to="/"
            className="font-semibold leading-6 text-pink-600 hover:text-pink-500"
          >
            Go to Login
          </Link>
        </p>
      </div>

      {confirmationModal && (
        <FrontModal
          isModalOpen={confirmationModal}
          setModalOpen={setConfirmationModal}
          message={message}
        />
      )}
    </div>
  );
};

export default ResetPassword;
