import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";
import { showErrorMsg, showSuccessMsg } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const addCampaign = createAsyncThunk(
  "campaign/add",
  async (formDataObj, { rejectWithValue }) => {
    const { formData } = formDataObj;
    try {
      const response = await postApi(`api/campaign`, formData, true);
      // if (response?.data?.code === 200) {
      //   showSuccessMsg(response?.data?.message);
      // } else {
      //   showErrorMsg(response?.data?.message);
      // }
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const addCampaignSlice = createSlice({
  name: "addCampaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addCampaign.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addCampaign.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(addCampaign.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default addCampaignSlice.reducer;
