import { PlusIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import Category from "components/location/services/Category";
import { isValidArray, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { reOrderCategory } from "redux/gmbLocation/reOrderCategorySlice";

export const ItemTypes = {
  CARD: "card",
};

export default function CategoryList({
  categoryList = [],
  setCategoryList = () => {},
  categoryListLoading = false,
  categoryId = 0,
  setCategoryId = () => {},
  isCategoryModalOpen = false,
  setIsCategoryModalOpen = () => {},
  handleGetCategoryList = () => {},
  currentLocationId = 0,
}) {
  const dispatch = useDispatch();

  const handleReOrderCategory = (data) => {
    const formData = {
      data: data,
    };

    dispatch(reOrderCategory(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            "Categories re-ordered successfully."
        );
        const finalData = data?.payload?.dataObj?.data ?? [];
        setCategoryList(finalData);
      } else {
        handleServerValidation(data);
      }
    });
  };

  // Function for re-order item list
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    if (dragIndex === 0 || hoverIndex === 0) {
      // made condition for primary category -> will be alwasy first in order can't move
      return;
    }

    setCategoryList((prevItems) => {
      const updatedItems = [...prevItems];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);

      return updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));
    });
  }, []);

  const dropCard = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragIndex === 0 || hoverIndex === 0) {
        // made condition for primary category -> will be alwasy first in order can't drop here any other service
        return;
      }

      const updatedItems = [...categoryList];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);

      const finalData = updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      handleReOrderCategory(finalData);
    },
    [categoryList]
  );
  //

  return (
    <div className="w-full min-w-xl border border-gray-400 p-3 bg-white min-h-96">
      <DndProvider backend={HTML5Backend}>
        <div
          className={classNames("flex flex-wrap py-2 gap-8 my-10", {
            "grayscale opacity-60": categoryListLoading,
          })}
        >
          {categoryListLoading ? (
            <div
              role="status"
              className="w-full space-y-4 rounded animate-pulse mb-4"
            >
              <div className="flex flex-wrap gap-4">
                {[...Array(7)].map((_, index) => (
                  <div key={index} className="h-44 w-44 bg-gray-300"></div>
                ))}
              </div>
              <span className="sr-only">Loading...</span>
            </div>
          ) : isValidArray(categoryList) ? (
            categoryList?.map((category, index) => (
              <Category
                key={category?.id}
                index={index}
                moveCard={moveCard}
                dropCard={dropCard}
                categoryData={category}
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                isCategoryModalOpen={isCategoryModalOpen}
                setIsCategoryModalOpen={setIsCategoryModalOpen}
                handleGetCategoryList={handleGetCategoryList}
                currentLocationId={currentLocationId}
              />
            ))
          ) : (
            <div className="flex justify-center items-center w-full py-10">
              <button
                className="text-pink-600 hover:text-pink-700 font-semibold mt-5 w-15 flex justify-center items-center gap-1"
                onClick={() => {
                  setCategoryId("");
                  setIsCategoryModalOpen(true);
                }}
              >
                <PlusIcon className="w-5 h-5" />
                <span className="text-sm font-bold">Add Category</span>
              </button>
            </div>
          )}
        </div>
      </DndProvider>
    </div>
  );
}
