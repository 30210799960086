import ShowPageTitle from "components/common/ShowPageTitle";
import CampaignPostPreview from "components/marketing/posts/CampaignPostPreview";
import PostLaunchForm from "components/marketing/posts/PostLaunchForm";
import PostScheduleForm from "components/marketing/posts/PostScheduleForm";
import SinglePostPreview from "components/marketing/posts/SinglePostPreview";
import ServiceCampaignPostForm from "components/marketing/posts/servicePosts/ServiceCampaignPostForm";
import ServiceSinglePostForm from "components/marketing/posts/servicePosts/ServiceSinglePostForm";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function ServicePost({ isGoogleConnected = false }) {
  const [searchParams] = useSearchParams();
  const [generatePostLoading, setGeneratePostLoading] = useState(false);
  const [generatedPost, setGeneratedPost] = useState();
  const [currentPostContentIndex, setCurrentPostContentIndex] = useState(0);

  const type = searchParams.get("type") ? searchParams.get("type") : "";
  const form = searchParams.get("form") ? searchParams.get("form") : "";
  const postId = searchParams.get("postId") || "";
  const campaignId = searchParams.get("campaignId") || "";
  const isEdit = !!postId || !!campaignId;

  useEffect(() => {
    return () => {
      removeDataLocalStorage("CAMPAIGN_POST_DATA"); //clear campaign data when component unmount
    };
  }, []);

  return (
    <div className="w-full flex flex-col xl:flex-row gap-4 mt-4 !min-h-[calc(100vh-210px)]">
      <ShowPageTitle title={type == "single" ? "singlePost" : "campaignPost"} />
      <div className="w-full max-h-full xl:max-w-[49%] border-2 border-gray-300 bg-white">
        <div className="header w-full bg-gray-200 p-2">
          <span className="font-semibold text-gray-800">
            {type == "single"
              ? "Single Post"
              : type == "multi"
              ? "Campaign Post"
              : ""}{" "}
            {isEdit ? "Edit" : "Builder"}
          </span>
        </div>
        {form == "post-details" ? (
          type == "single" ? (
            <ServiceSinglePostForm
              isGoogleConnected={isGoogleConnected}
              generatedPost={generatedPost}
              setGeneratedPost={setGeneratedPost}
              setGeneratePostLoading={setGeneratePostLoading}
            />
          ) : (
            type == "multi" && (
              <ServiceCampaignPostForm
                isGoogleConnected={isGoogleConnected}
                generatedPost={generatedPost}
                setGeneratedPost={setGeneratedPost}
                setGeneratePostLoading={setGeneratePostLoading}
                currentPostContentIndex={currentPostContentIndex}
                generatePostLoading={generatePostLoading}
              />
            )
          )
        ) : form == "post-schedule" ? (
          <PostScheduleForm />
        ) : (
          form == "post-launch" && (
            <PostLaunchForm isGoogleConnected={isGoogleConnected} />
          )
        )}
      </div>
      <div className="w-full xl:max-w-[50%] border-2 border-blue-300 bg-white">
        <div className="header bg-blue-300 p-2">
          <span className="font-semibold text-gray-800">Post Preview</span>
        </div>

        <div className="w-full p-2 relative">
          {generatePostLoading && <PostLoading />}
          <div className="w-full flex justify-center">
            {type == "multi" ? (
              <CampaignPostPreview
                generatedPost={generatedPost}
                setGeneratedPost={setGeneratedPost}
                setCurrentPostContentIndex={setCurrentPostContentIndex}
              />
            ) : (
              type == "single" && (
                <SinglePostPreview
                  generatedPost={generatedPost}
                  setGeneratedPost={setGeneratedPost}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export const PostLoading = () => {
  return (
    <div className="w-full h-[99.5%] z-10 absolute bottom-1 right-0 overflow-y-auto bg-slate-300 bg-opacity-40 transition-opacity">
      <div className="flex flex-col min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
        <svg
          width="90"
          height="90"
          className="animate-spin"
          viewBox="0 0 256 256"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M88.402 77.088a8 8 0 0 1-11.314 11.314L54.46 65.775a8 8 0 0 1 11.314-11.314Zm-11.314 90.51L54.46 190.225a8 8 0 0 0 11.314 11.314l22.627-22.627a8 8 0 0 0-11.314-11.314ZM72 128a8 8 0 0 0-8-8H32a8 8 0 0 0 0 16h32a8 8 0 0 0 8-8Zm56-104a8 8 0 0 0-8 8v32a8 8 0 0 0 16 0V32a8 8 0 0 0-8-8Zm96 96h-32a8 8 0 0 0 0 16h32a8 8 0 0 0 0-16Zm-45.088 47.598a8 8 0 0 0-11.314 11.314l22.627 22.627a8 8 0 1 0 11.314-11.314ZM128 184a8 8 0 0 0-8 8v32a8 8 0 0 0 16 0v-32a8 8 0 0 0-8-8Z" />
        </svg>
      </div>
    </div>
  );
};
