import CustomPhoneInput from "components/common/fields/CustomPhoneInput";
import DropdownField from "components/common/fields/DropdownField";
import GoogleAddressField from "components/common/fields/GoogleAddressField";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, Formik, Form } from "formik";
import {
  getUserDataByParam,
  isCompanyUser,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { accountForm, showError } from "module/validation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyAccountDetail } from "redux/account/getCompanyAccountDetailSlice";
import { updateCompanyAccount } from "redux/account/updateCompanyAccountSlice";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const AccountForm = () => {
  const dispatch = useDispatch();

  const getCompanyAccountData = useSelector(
    (state) => state?.getCompanyAccountDetail?.data ?? []
  );
  const updateCompanyLoading = useSelector(
    (state) => state?.updateCompanyAccount?.loading ?? false
  );
  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  const companyId = getUserDataByParam("COMPANY_ID");
  const isCompanyActive = getCompanyAccountData?.status === "active" ?? false;

  useEffect(() => {
    if (isCompanyUser()) {
      dispatch(getCompanyAccountDetail(companyId));
    }
  }, []);

  const handleSubmit = (values) => {
    const formData = {
      company: values?.company ?? "",
      name: values?.name ?? "",
      address1: values?.address1 ?? "",
      address2: values?.address2 ?? "",
      city: values?.city ?? "",
      state: values?.state ?? "",
      zip: values?.zip ?? "",
      phone: values?.phone ?? "",
      email: values?.email ?? "",
    };

    dispatch(
      updateCompanyAccount({
        companyId: getCompanyAccountData?.id,
        formData,
      })
    ).then((data) => {
      if (data?.payload?.code === 200) {
        showSuccessMsg(
          data?.payload?.message ?? "Account details updated successfully"
        );
      } else {
        if (data?.payload?.code === 422) {
          const errorMsg =
            data?.payload?.exception?.errors.email?.[0] ??
            data?.payload?.exception?.message;
          showErrorMsg(errorMsg);
        } else {
          showErrorMsg("Something went wrong");
        }
      }
    });
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Account",
          href: "",
          current: false,
        },
        {
          name: "Account Details",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  const initialValues = {
    company: getCompanyAccountData?.company ?? "",
    name: getCompanyAccountData?.name ?? "",
    address1: getCompanyAccountData?.address1 ?? "",
    address2: getCompanyAccountData?.address2 ?? "",
    city: getCompanyAccountData?.city ?? "",
    state: getCompanyAccountData?.state ?? "",
    zip: getCompanyAccountData?.zip ?? "",
    phone: getCompanyAccountData?.phone ?? "",
    email: getCompanyAccountData?.email ?? "",
  };

  return (
    <div className="mt-4">
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={() => accountForm(validationMsgObj?.account ?? {})}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {({
            errors,
            touched,
            validateField,
            validateForm,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form
              className="w-full min-w-xl border border-gray-200 p-3 bg-white"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                e.key == "Enter" && e.preventDefault();
              }}
            >
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="company" className="input-field-label">
                    Company
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="company"
                      id="company"
                      value={values?.company ?? ""}
                      onChange={handleChange}
                      placeholder="Enter Company"
                      className="input-field"
                      readOnly={!isCompanyActive}
                    />
                  </div>
                  {touched?.company && showError(errors?.company)}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="name" className="input-field-label">
                    Name
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      value={values?.name ?? ""}
                      onChange={handleChange}
                      placeholder="Enter Name"
                      className="input-field"
                      readOnly={!isCompanyActive}
                    />
                  </div>
                  {touched?.name && showError(errors?.name)}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="address" className="input-field-label">
                    Address
                  </label>
                  <div className="mt-2">
                    <GoogleAddressField
                      name="address1"
                      setFieldValue={setFieldValue}
                      value={values?.address1}
                      onChange={handleChange}
                      placeholder={"Enter Address"}
                      isReadOnly={!isCompanyActive}
                      setFieldTouched={setFieldTouched}
                      FieldValues={values}
                    />
                  </div>
                  {touched?.address1 && showError(errors?.address1)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="Address2" className="input-field-label">
                    Address2
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="address2"
                      id="Address2"
                      value={values?.address2 ?? ""}
                      onChange={handleChange}
                      autoComplete="Address2"
                      placeholder="Enter Address2"
                      className="input-field"
                      readOnly={!isCompanyActive}
                    />
                  </div>
                  {touched?.address2 && showError(errors?.address2)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label htmlFor="city" className="input-field-label">
                    City
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="city"
                      id="city"
                      value={values?.city ?? ""}
                      onChange={handleChange}
                      autoComplete="address-level2"
                      placeholder="Enter City"
                      className="input-field"
                      readOnly={!isCompanyActive}
                    />
                  </div>
                  {touched?.city && showError(errors?.city)}
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label htmlFor="state" className="input-field-label">
                    State
                  </label>
                  <div className="mt-2">
                    <DropdownField
                      setFieldValue={setFieldValue}
                      values={values}
                      name="state"
                      isReadOnly={!isCompanyActive}
                    />
                  </div>
                  {touched?.state && showError(errors?.state)}
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label htmlFor="zip" className="input-field-label">
                    ZIP / Postal code
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="zip"
                      id="zip"
                      value={values?.zip ?? ""}
                      onChange={handleChange}
                      autoComplete="zip"
                      placeholder="Enter Zip/Postal Code"
                      className="input-field"
                      readOnly={!isCompanyActive}
                    />
                  </div>
                  {touched?.zip && showError(errors?.zip)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="phone" className="input-field-label">
                    Phone
                  </label>
                  <CustomPhoneInput
                    values={values}
                    name="phone"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    isReadOnly={!isCompanyActive}
                  />
                  {touched?.phone && showError(errors?.phone)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label htmlFor="email" className="input-field-label">
                    Email
                  </label>
                  <div className="mt-2">
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      value={values?.email ?? ""}
                      onChange={handleChange}
                      autoComplete="email"
                      placeholder="Enter Email"
                      className="input-field"
                      readOnly={!isCompanyActive}
                    />
                  </div>
                  {touched?.email && showError(errors?.email)}
                </div>
              </div>
              {isCompanyActive && (
                <div className="flex flex-wrap justify-start">
                  <SpinnerButton
                    className="btn-pink"
                    title={"Save"}
                    action={() => {}}
                    type={"submit"}
                    loading={updateCompanyLoading}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AccountForm;
