import { PlusIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { isValidArray, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { reorderSection } from "redux/gmbLocation/reorderSectionSlice";
import SectionItem from "./SectionItem";

export const ItemTypes = {
  CARD: "card",
};

export default function SectionList({
  sectionData = {},
  itemsList = [],
  setItemsList = () => {},
  handleSectionDetail = () => {},
  menuId = 0,
  idForDelete = 0,
  setIdForDelete = () => {},
  setSectionData = () => {},
  handleGetSectionList = () => {},
  setItemList = () => {},
  sectionDeleteLoading = false,
  setIsSectionModalOpen = () => {},
  sectionListLoading = false,
  setSectionIdForEdit = () => {},
}) {
  const dispatch = useDispatch();
  const [onHover, setOnHover] = useState(false);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      setItemsList((prevItems) => {
        const updatedItems = [...prevItems];
        const [draggedItem] = updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);

        // Reorder items based on their new position
        return updatedItems.map((item, index) => ({
          ...item,
          order: index,
        }));
      });
    },
    [itemsList]
  );

  const dropCard = useCallback(
    (dragIndex, hoverIndex) => {
      const updatedItems = [...itemsList];

      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);

      const finalData = updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      handleReOrderItem(finalData);
    },
    [itemsList]
  );

  const handleReOrderItem = async (data) => {
    const formData = {
      data: data,
    };
    await dispatch(reorderSection(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Items re-ordered successfully."
        );
        setItemsList(data?.payload?.dataObj?.data ?? []);
      } else {
        handleServerValidation(data);
      }
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={classNames(
          "section-list w-1/4 border-r-2 border-gray-400 p-2",
          {
            "grayscale opacity-60": sectionListLoading,
          }
        )}
      >
        <p className="text-md font-semibold text-gray-800">Sections</p>
        <div className="mx-1 mt-2">
          {isValidArray(itemsList) &&
            itemsList.map((section, itemIndex) => {
              return (
                <SectionItem
                  key={section?.id}
                  index={itemIndex}
                  itemData={section}
                  sectionData={sectionData}
                  moveCard={moveCard}
                  dropCard={dropCard}
                  onHover={onHover}
                  setOnHover={setOnHover}
                  handleSectionDetail={handleSectionDetail}
                  menuId={menuId}
                  idForDelete={idForDelete}
                  setIdForDelete={setIdForDelete}
                  setSectionData={setSectionData}
                  handleGetSectionList={handleGetSectionList}
                  setItemList={setItemList}
                  sectionDeleteLoading={sectionDeleteLoading}
                  setSectionIdForEdit={setSectionIdForEdit}
                  setIsSectionModalOpen={setIsSectionModalOpen}
                />
              );
            })}
          <button
            className="text-pink-600 hover:text-pink-700 font-semibold mt-5 w-full flex items-center gap-1"
            onClick={() => {
              setSectionIdForEdit(0);
              setIsSectionModalOpen(true);
            }}
          >
            <PlusIcon className="w-5 h-5" />

            <span className="text-sm font-bold">Add Section</span>
          </button>
        </div>
      </div>
    </DndProvider>
  );
}
