import deleteIcon from "assets/images/deleteIcon.svg";
import editIcon from "assets/images/editIcon.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import {
  formatPhoneNumberAlways,
  getCompanyStatus,
  getCurrentLocation,
  removeCurrentLocation,
  showErrorMsg,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { changeLocationStatus } from "redux/location/changeLocationStatusSlice";
import { locationList } from "redux/location/locationListSlice";

const LocationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalLocations, setTotalLocation] = useState([]);
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const { id: currentLocationId } = getCurrentLocation();

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const locationListData = useSelector(
    (state) => state?.locationList?.data ?? []
  );
  const locationListLoading = useSelector(
    (state) => state?.locationList?.loading ?? false
  );
  const changeLocationStatusLoading = useSelector(
    (state) => state?.changeLocationStatus?.loading ?? []
  );

  const totalRecords = locationListData?.total ?? 0;
  const currentPerPage = locationListData?.per_page ?? showPerPage;

  const handleLocationList = (search) => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
    };
    dispatch(locationList(sortObj)).then((data) => {
      if (data?.payload?.data) {
        setTotalLocation(data?.payload?.data);
      }
    });
  };

  const handleLocationStatusChange = (locationId, status) => {
    dispatch(
      changeLocationStatus({ locationId: selectedId, status: "archived" })
    ).then((data) => {
      if (data?.payload?.data?.code === 200) {
        setModalOpen(false);
        showSuccessMsg(
          data?.payload?.data?.message ??
            "Company location deleted successfully."
        );
        totalLocations?.length == 1 &&
          (removeCurrentLocation(), window.location.reload(false));

        handleLocationList(searchInput);
      } else {
        setModalOpen(false);
        showErrorMsg(
          data?.payload?.dataObj?.message ??
            "Location currently has Assigned users."
        );
      }
    });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell }) => (
        <span
          className="hover:underline hover:cursor-pointer "
          onClick={() =>
            navigate(`/account/locations/edit/${cell.row.values.id}`)
          }
        >
          {cell.row.values.name}
        </span>
      ),
    },
    {
      Header: "Address",
      accessor: "address1",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ cell }) => {
        return formatPhoneNumberAlways(cell?.row?.values?.phone);
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    ...(isCompanyActive
      ? [
          {
            width: 100,
            Header: "Actions",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className="flex items-center gap-x-2 ">
                <button
                  title="Edit"
                  className="h-5 w-5 hover:cursor-pointer"
                  onClick={() =>
                    navigate(`/account/locations/edit/${cell.row.values.id}`)
                  }
                >
                  <img alt="Edit" src={editIcon} />
                </button>
                <button
                  className={`h-5 w-5 ${
                    cell?.row?.values?.id == getCurrentLocation()?.id &&
                    totalLocations?.length > 1
                      ? `disabled-btn`
                      : `hover:cursor-pointer`
                  } `}
                  onClick={() => {
                    setModalOpen(true);
                    setSelectedId(cell.row.values.id);
                  }}
                  disabled={
                    cell?.row?.values?.id == getCurrentLocation()?.id &&
                    totalLocations?.length > 1
                  }
                >
                  <img alt="archive" src={deleteIcon} />
                </button>
              </div>
            ),
          },
        ]
      : []),
  ];

  const globalSearch = (search) => {
    handleLocationList(search);
  };

  useEffect(() => {
    handleLocationList(searchInput);
  }, [sortOrder, currentPage, perPage, currentLocationId]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Account",
          href: "",
          current: false,
        },
        {
          name: "Locations List",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  return (
    <>
      <div className="">
        <ShowPageTitle title="locations" />
        <div className="flex justify-end items-center gap-x-2 mt-2">
          {isCompanyActive && (
            <button
              type="button"
              onClick={() => navigate("/account/locations/add")}
              className="btn-pink"
            >
              New Location
            </button>
          )}
          <div className="flex rounded-md shadow-sm">
            <SearchInput
              globalSearch={globalSearch}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
            />
          </div>
        </div>
        <div className="mt-2 flex flex-col">
          <CustomTable
            data={totalLocations}
            columns={columns}
            isLoading={locationListLoading}
            defaultSortIndexColumn={sortIndex}
            defaultOrder={sortOrder}
            setSortIndex={setSortIndex}
            setSortOrder={setSortOrder}
            totalRecords={totalRecords}
            perPage={perPage ? perPage : currentPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPerPage={setPerPage}
          />
        </div>
        {isModalOpen && (
          <ConfirmationModal
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            handleAction={handleLocationStatusChange}
            isLoading={changeLocationStatusLoading}
          />
        )}
      </div>
    </>
  );
};

export default LocationList;
