import classNames from "classnames";
import SpinnerButton from "components/common/fields/SpinnerButton";
import GuestWifiAuthForm from "components/front/GuestWifiAuthForm";
import { Field, Form, Formik } from "formik";
import { manipulateOTP, showSuccessMsg } from "module/util";
import {
  handleServerValidation,
  showError,
  splashPageForm,
} from "module/validation";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addWifiData } from "redux/front/addWifiDataSlice";

const GuestWifiForm = ({
  companyId = {},
  currentLocationId = 0,
  companyName = "",
  locationName = "",
  isPreview = true,
  guestId = "",
  queryParams = {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sessionTimeoutState, setSessionTimeoutState] = useState(3600);
  const currentCampaignsType = searchParams.get("");
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
  };
  const addWifiDataLoading = useSelector(
    (state) => state?.addWifiData?.loading ?? false
  );

  const handleSubmit = (values) => {
    const newQueryParam = {};
    for (const key in queryParams) {
      newQueryParam[key] = decodeURIComponent(queryParams[key]);
    }
    const phoneNumber = values?.phone?.replace(/\D/g, "")?.slice(1);
    const formData = {
      company_id: companyId ?? "",
      location_id: currentLocationId ?? "",
      name: values?.name ?? "",
      email: values?.email ?? "",
      phone: phoneNumber ?? "",
      ...newQueryParam,
    };
    dispatch(addWifiData(formData)).then((data) => {
      if (data?.payload.code === 200) {
        const guestExist = data?.payload?.dataObj?.guest_exists ?? false;
        const sessionTimeout = data?.payload?.dataObj?.session_timeout ?? 3600;
        localStorage?.setItem("SESSION_TIMEOUT", sessionTimeout);
        const sessionDelay = data?.payload?.dataObj?.session_delay ?? 5000;
        // setSessionTimeoutState(sessionTimeout);

        if (guestExist) {
          setIsLoading(true);
          setTimeout(() => {
            formRef.current["session_timeout"].value = sessionTimeout;
            formRef.current.submit();
            setIsLoading(false);
          }, sessionDelay);
        } else {
          showSuccessMsg(data?.payload?.message ?? "Guest stored successfully");
          const guestId = data?.payload?.dataObj?.guest_id ?? 0;
          const vCode = manipulateOTP(
            data?.payload?.dataObj?.security_code ?? 0
          );

          const redirectURL = `/wifi/${currentLocationId}/${locationName}/${companyId}/${companyName}/verify-otp/${guestId}?cmd=${queryParams?.cmd}&mac=${queryParams?.mac}&network=${queryParams?.network}&ip=${queryParams?.ip}&apmac=${queryParams?.apmac}&site=${queryParams?.site}&post=${queryParams?.post}&url=${queryParams?.url}&vcode=${vCode}&sessionTimeout=${sessionTimeout}&sessionDelay=${sessionDelay}`;
          navigate(redirectURL);
        }
      } else {
        handleServerValidation(data);
      }
    });
  };

  const isInSessionTime = (sessionTimeoutInSeconds) => {
    const setTime = moment(localStorage?.getItem("SESSION_START"));
    const currentTimezone = moment.tz.guess();
    const currentDateTime = moment().tz(currentTimezone);

    // Calculate the session end time based on the session timeout
    const sessionEndTime = setTime
      .clone()
      .add(sessionTimeoutInSeconds, "seconds");

    // Check if the current time is before the session end time
    const isInSession = currentDateTime.isBefore(sessionEndTime);

    return isInSession;
  };

  useEffect(() => {
    const sessionTimeout = localStorage.getItem("SESSION_TIMEOUT");
    const inSessionTime = isInSessionTime(sessionTimeout);
    if (inSessionTime) {
      formRef.current["session_timeout"].value = sessionTimeoutState;
      formRef.current.submit();
    } else {
      localStorage.removeItem("SESSION_TIMEOUT");
      localStorage.removeItem("SESSION_START");
    }
    // if (localStorage.getItem("USER_REGISTERED") === "registered") {
    //   localStorage.setItem("USER_REGISTERED", "not registered");
    //     formRef.current["session_timeout"].value = sessionTimeoutState;
    //     formRef.current.submit();
    // }
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={splashPageForm}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        // validateOnBlur={false}
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          handleBlur,
          values,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form
            className="w-full min-w-xl"
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              e.key == "Enter" && e.preventDefault();
            }}
          >
            <div className="mb-4">
              <label htmlFor="name" className="input-field-label">
                Name
              </label>
              <div className="relative mt-2">
                <Field
                  type="text"
                  name="name"
                  id="name"
                  disabled={isPreview}
                  value={values?.name ?? ""}
                  onChange={handleChange}
                  autoComplete="name"
                  placeholder="Enter Name"
                  className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <div
                  className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-400"
                  aria-hidden="true"
                />
              </div>
              {touched?.name && showError(errors?.name)}
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="input-field-label">
                Email
              </label>
              <div className="relative mt-2">
                <Field
                  type="email"
                  name="email"
                  id="email"
                  disabled={isPreview}
                  value={values?.email ?? ""}
                  onChange={handleChange}
                  autoComplete="email"
                  placeholder="Enter Email"
                  className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <div
                  className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-400"
                  aria-hidden="true"
                />
              </div>
              {touched?.email && showError(errors?.email)}
            </div>
            <div className="mb-8">
              <label htmlFor="name" className="input-field-label">
                Phone number
              </label>
              <div className="relative mt-2">
                <PatternFormat
                  name={"phone"}
                  disabled={isPreview}
                  value={values?.phone ?? ""}
                  onValueChange={(values) => {
                    // const finalPhone =
                    //   values?.value && values?.value?.startsWith(1)
                    //     ? values?.formattedValue
                    //     : `1${values?.value}`;
                    const finalPhone = values?.value && values?.formattedValue;
                    setFieldValue("phone", finalPhone);
                  }}
                  format="+1 (%%%) %%%-%%%%"
                  patternChar="%"
                  placeholder="Enter Phone"
                  className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                  onBlur={handleBlur}
                />

                <div
                  className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-400"
                  aria-hidden="true"
                />
              </div>
              {touched?.phone && showError(errors?.phone)}
            </div>
            <div className="flex justify-center relative">
              <SpinnerButton
                className={classNames(
                  "text-2xl font-bold py-3 px-4 border-2 border-white bg-blue-400 text-white shadow-sm shadow-gray-300  absolute -top-6  rounded-full",
                  { "hover:bg-blue-300": !isPreview }
                )}
                title={">"}
                action={() => {}}
                isDisable={isPreview}
                type={"submit"}
                loading={addWifiDataLoading || isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
      <GuestWifiAuthForm queryParams={queryParams} formRef={formRef} />
    </>
  );
};

export default GuestWifiForm;
