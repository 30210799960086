import {
  Bars3Icon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import dummyImage from "assets/images/dummyImage.svg";
import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import ImageGalleryModal from "components/location/menu/ImageGalleryModal";
import ItemModal from "components/location/menu/ItemModal";
import {
  getCurrentLocation,
  getItemImage,
  isValidArray,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { deleteMenuItem } from "redux/gmbLocation/deleteMenuItemSlice";

// ItemTypes definition
const ItemTypes = {
  CARD: "card",
};

export default function Items({
  itemData = {},
  index = 0,
  moveCard = () => {},
  setionList = [],
  handleItemList = () => {},
  dropCard = () => {},
  handleGetSectionList = () => {},
  menuPriceShow = false,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id: currentLocationId } = getCurrentLocation();
  const menu = !!searchParams?.get("menu") ? searchParams?.get("menu") : 0;
  const section = !!searchParams?.get("sectionId")
    ? searchParams?.get("sectionId")
    : 0;

  const itemId = !!itemData?.id ? itemData?.id : 0;
  const ref = useRef(null);
  const handleRef = useRef(null);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [imgGalleryModal, setImgGalleryModal] = useState(false);

  const deleteItemLoading = useSelector(
    (state) => state?.deleteMenuItem?.loading ?? false,
    shallowEqual
  );

  // Function for re-order item list
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // if (dragIndex === hoverIndex) return;

      dropCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ itemId, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(handleRef);
  drop(ref);
  preview(ref);

  const handleEditMenuItem = () => {
    setItemModalOpen(true);
  };

  const handleDeleteItems = () => {
    const formData = {
      location_id: currentLocationId,
      menu_id: menu,
      item_id: itemId,
    };
    dispatch(deleteMenuItem(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Item deleted successfully."
        );
        handleGetSectionList();
      } else {
        handleServerValidation(data);
      }
      setIsConfirmationModalOpen(false);
    });
  };

  const getImageArray = () => {
    const secondaryImg = isValidArray(itemData?.secondary_image)
      ? itemData?.secondary_image?.map((item) => item?.item_image ?? "")
      : [];
    const imageArray =
      itemData?.primary_image && isValidArray(itemData?.secondary_image)
        ? [itemData?.primary_image, ...(secondaryImg ?? [])]
        : [itemData?.primary_image];

    return imageArray;
  };

  const imageArray = getImageArray();

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          "p-4 flex gap-4 shadow-md shadow-gray-200 my-0.5 items-center justify-between mb-2",
          {
            "opacity-0": isDragging,
          }
        )}
      >
        <div
          className={`w-full flex gap-4 items-center`}
          data-handler-id={handlerId}
        >
          <button
            ref={handleRef}
            className={`h-6 w-6 cursor-grab font-semibold`}
          >
            <Bars3Icon strokeWidth={1.8} />
          </button>
          <div className="flex gap-2 w-[95%]">
            <div className="relative h-24 w-24">
              <div
                className={classNames(
                  "h-24 w-24 !z-99 bg-white absolute shadow-md shadow-gray-200 top-0 right-1"
                )}
                onClick={() => {
                  if (isValidArray(imageArray)) {
                    // setImgGalleryModal(true);
                  }
                }}
                title="View Image"
              >
                <img
                  src={
                    isValidArray(imageArray)
                      ? getItemImage(imageArray[0])
                      : dummyImage
                  }
                  alt="img"
                  className={classNames("w-full h-full object-contain", {
                    "p-2": !isValidArray(imageArray) || !imageArray[0],
                  })}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="text-md font-semibold text-gray-700 flex justify-between items-center">
                <span className="w-[80%]">{itemData?.item_name ?? ""}</span>
                <div className="flex flex-wrap justify-end gap-10 max-w-[20%] w-full ">
                  <span className="text-sm text-gray-700 ">
                    {itemData?.item_calories ?? 0} Cals
                  </span>
                  {menuPriceShow && (
                    <span className="text-sm text-gray-700 ">
                      {itemData?.item_price
                        ? `$${itemData?.item_price}`
                        : "$0.00"}
                    </span>
                  )}
                </div>
              </div>
              <p className="w-full text-md text-gray-700 flex justify-between mb-4">
                <span className="text-sm">{itemData?.item_description}</span>
              </p>
              <div className="overflow-y-auto max-h-[80px] h-full">
                {isValidArray(itemData?.food_options) &&
                  itemData?.food_options?.map((option) => {
                    return (
                      <p
                        key={option?.id}
                        className="w-full px-4 py-1 text-sm text-gray-700  flex justify-between border-t border-gary-300"
                      >
                        <span>{option?.option_name}</span>
                        {menuPriceShow && (
                          <span>
                            {option?.option_price
                              ? `$${
                                  parseFloat(
                                    option?.option_price ?? 0
                                  )?.toFixed(2) ?? 0.0
                                }`
                              : "$0.00"}
                          </span>
                        )}
                      </p>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <button
            title="Edit"
            className={`h-5 w-5 cursor-pointer`}
            onClick={() => {
              handleEditMenuItem(); // Ensure this function is defined
            }}
          >
            <PencilSquareIcon />
          </button>
          <button
            title="Delete"
            className={`h-5 w-5 cursor-pointer`}
            onClick={(e) => {
              e.stopPropagation();
              setIsConfirmationModalOpen(true); // Ensure this function is defined
            }}
          >
            <TrashIcon />
          </button>
        </div>
      </div>
      {itemModalOpen && (
        <ItemModal
          isModalOpen={itemModalOpen}
          setModalOpen={setItemModalOpen}
          sectionList={setionList}
          itemId={itemId}
          sectionId={section}
          handleItemList={handleItemList}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={() => {
            handleDeleteItems();
          }}
          isLoading={deleteItemLoading}
          message="Are you sure you want to delete this item ? This will also remove the item from your GMB account "
        />
      )}
      {imgGalleryModal && (
        <ImageGalleryModal
          isModalOpen={imgGalleryModal}
          setModalOpen={setImgGalleryModal}
          handleAction={() => {}}
          data={imageArray ?? []}
          isLoading={false}
          message=""
        />
      )}
    </>
  );
}
