import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getServiceCampaignPostDetails = createAsyncThunk(
  "post/service-campaign-post-detail",
  async (formData, { rejectWithValue }) => {
    const { campaign_id } = formData;
    try {
      const response = await getApi(
        `api/show-multiple-service-post/${campaign_id}`
      );  
      return response?.data?.dataObj;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getServiceCampaignPostDetailsSlice = createSlice({
  name: "getServiceCampaignPostDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceCampaignPostDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getServiceCampaignPostDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(
      getServiceCampaignPostDetails.fulfilled,
      (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      }
    );
  },
});

export default getServiceCampaignPostDetailsSlice.reducer;
