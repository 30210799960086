import {
  BanknotesIcon,
  BeakerIcon,
  BuildingStorefrontIcon,
  Cog6ToothIcon,
  HomeIcon,
  KeyIcon,
  PaperAirplaneIcon,
  RocketLaunchIcon,
  StarIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import SidebarMenu from "components/common/SidebarMenu";
import {
  doEmptyLocalStorage,
  getUserCode,
  getUserData,
  getUserDataByParam,
  isAdmin,
  isCompanyUser,
  isCompanyUserSwitched,
  isSuperAdmin,
  removeCurrentLocation,
  setCompanyStatus,
  setUserData,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyAccountDetail } from "redux/account/getCompanyAccountDetailSlice";
import { backToAdmin } from "redux/company/backToAdminSlice";
import { logoutUser } from "redux/front/logoutSlice";

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedMenuItem, setMenuItem] = useState("Dashboard");
  const [currentCompanyStatus, setCurrentCompanyStatus] = useState("");
  const [isLocationAvailable, setLocationAvailable] = useState(true);
  const location = useLocation();

  const switchToCompany = useSelector(
    (state) => state?.switchToCompany?.data ?? {}
  );

  const currentUrl = location?.pathname;
  const isCompanyArchiveOrDenied = ["archived", "denied"].includes(
    currentCompanyStatus
  );
  const isBackToLoginShow = isCompanyUserSwitched();
  const isLogoutButtonShow = !isCompanyUserSwitched();

  const navigation = [
    { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: true },
    ...(isCompanyUser() && !isCompanyArchiveOrDenied && isLocationAvailable
      ? [
          {
            name: "Location",
            href: "/location",
            icon: BuildingStorefrontIcon,
            current: false,
          },
          {
            name: "Contacts",
            href: "/contacts",
            icon: UsersIcon,
            current: false,
          },
        ]
      : []),
    ...(isCompanyUser() && !isCompanyArchiveOrDenied && isLocationAvailable
      ? [
          {
            name: "Campaigns",
            href: "/campaigns/list",
            icon: RocketLaunchIcon,
            current: true,
          },
          {
            name: "Marketing",
            href: "/marketing",
            icon: NewspaperIcon,
            current: true,
          },
          // {
          //   name: "Automation",
          //   href: "/automation",
          //   icon: BeakerIcon,
          //   current: true,
          // },
          {
            name: "Redemptions",
            href: "/redemptions",
            icon: BanknotesIcon,
            current: true,
          },
        ]
      : []),
    ...(isSuperAdmin() || isAdmin()
      ? [
          {
            name: "Companies",
            href: "/company/list",
            icon: KeyIcon,
            current: false,
          },
        ]
      : []),
    ...(isSuperAdmin() || isAdmin()
      ? [
          {
            name: "Lead Lists",
            href: "/lead-list",
            icon: PaperAirplaneIcon,
            current: true,
          },
        ]
      : []),

    ...(isSuperAdmin()
      ? [
          {
            name: "Admin Users",
            href: "/admin-user",
            icon: UsersIcon,
            current: false,
          },
        ]
      : []),
    // ...(isSuperAdmin() ||
    // isAdmin() ||
    // (isCompanyUser() && !isCompanyArchiveOrDenied && isLocationAvailable)
    //   ? [
    //       {
    //         name: "Marketplace",
    //         href: "/marketplace",
    //         icon: BuildingStorefrontIcon,
    //         current: true,
    //       },
    //     ]
    //   : []),
    ...(isSuperAdmin() ||
    isAdmin() ||
    (isCompanyUser() && !isCompanyArchiveOrDenied && isLocationAvailable)
      ? [
          {
            name: "Reputation",
            href: "/reputation",
            icon: StarIcon,
            current: true,
          },
        ]
      : []),
    ...(isCompanyUser() && !isCompanyArchiveOrDenied && isLocationAvailable
      ? [
          {
            id: 1,
            name: "Settings",
            href: "/user-settings",
            icon: WrenchScrewdriverIcon,
            current: false,
          },
        ]
      : []),
  ];
  const teams = [
    ...(isCompanyUser()
      ? [
          {
            id: 1,
            name: "Account",
            href: "/account",
            icon: Cog6ToothIcon,
            current: false,
          },
        ]
      : []),
    ...(isSuperAdmin() || isAdmin()
      ? [
          {
            id: 1,
            name: "Settings",
            href: "/settings",
            icon: WrenchScrewdriverIcon,
            current: false,
          },
        ]
      : []),
  ];

  const getCompanyDetails = () => {
    const companyId = getUserDataByParam("COMPANY_ID");
    const currentUserData = getUserData() ? JSON.parse(getUserData()) : {};

    if (companyId) {
      dispatch(getCompanyAccountDetail(companyId)).then((data) => {
        if (data?.payload) {
          if (["archived", "denied"].includes(data?.payload?.status)) {
            doEmptyLocalStorage();
            navigate("/");
          } else {
            setCompanyStatus(data?.payload?.status);
            setCurrentCompanyStatus(data?.payload?.status);
          }
          const finalUserData = {
            ...currentUserData,
            show_direct_mail: data?.payload?.show_direct_mail ?? "0",
            show_door_hangar: data?.payload?.show_door_hangar ?? "0",
          };
          setUserData(JSON.stringify(finalUserData));

          if (
            data?.payload?.locations_count == 0 &&
            ["active", "pending"].includes(data?.payload?.status)
          ) {
            setLocationAvailable(false);
          } else {
            setLocationAvailable(true);
          }
        } else {
          doEmptyLocalStorage();
          navigate("/");
          showErrorMsg("Something went wrong");
        }
      });
    }
  };

  useEffect(() => {
    if (currentUrl.startsWith("/locations")) {
      setMenuItem("Locations");
    } else if (currentUrl.includes("/dashboard")) {
      setMenuItem("Dashboard");
    } else if (currentUrl.includes("/contacts")) {
      setMenuItem("Contacts");
    } else if (currentUrl.startsWith("/account")) {
      setMenuItem("Account");
    } else if (currentUrl.includes("/admin-user")) {
      setMenuItem("Admin Users");
    } else if (currentUrl.includes("/company")) {
      setMenuItem("Companies");
    } else if (currentUrl.startsWith("/campaigns")) {
      setMenuItem("Campaigns");
    } else if (currentUrl.includes("/redemptions")) {
      setMenuItem("Redemptions");
    } else if (currentUrl.includes("/lead-list")) {
      setMenuItem("Lead Lists");
    } else if (currentUrl.includes("/marketplace")) {
      setMenuItem("Marketplace");
    } else if (currentUrl.includes("/reputation")) {
      setMenuItem("Reputation");
    } else if (currentUrl.includes("/settings")) {
      setMenuItem("Settings");
    } else if (currentUrl.includes("/user-settings")) {
      setMenuItem("Settings");
    } else if (currentUrl.startsWith("/posts")) {
      setMenuItem("Posts");
    } else if (currentUrl.startsWith("/marketing")) {
      setMenuItem("Marketing");
    } else if (currentUrl.includes("/location")) {
      setMenuItem("Location");
    } else if (currentUrl.includes("/automation")) {
      setMenuItem("Automation");
    }
  }, [navigate]);

  useEffect(() => {
    if (isCompanyUser()) {
      getCompanyDetails();
    }
  }, [switchToCompany]);

  const handleLogout = () => {
    dispatch(logoutUser())
      .then((data) => {
        doEmptyLocalStorage();
        navigate("/");
      })
      .catch((error) => {});
  };

  const handleBackToAdmin = () => {
    const userCode = getUserCode();
    dispatch(backToAdmin(userCode)).then((data) => {
      if (data?.payload?.code === 200) {
        showSuccessMsg("Welcome back to admin");
        navigate("/company/list");
        removeCurrentLocation();
      } else {
        const errorMsg =
          data?.payload?.dataObj?.message ?? "Something went wrong";
        showErrorMsg(errorMsg);
      }
    });
  };

  return (
    <div>
      <SidebarMenu
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigation={navigation}
        setMenuItem={setMenuItem}
        selectedMenuItem={selectedMenuItem}
        teams={teams}
        isBackToLoginShow={isBackToLoginShow}
        handleLogout={handleLogout}
        handleBackToAdmin={handleBackToAdmin}
        isLogoutButtonShow={isLogoutButtonShow}
      />
    </div>
  );
};

export default Sidebar;
