import imagePreview from "assets/images/dummyImage.svg";
import classNames from "classnames";
import { callToActionOptions } from "module/data";
import { getItemImage, isValidArray } from "module/util";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function SinglePostPreview({
  generatedPost = [],
  setGeneratedPost = () => {},
}) {
  const getLabelForValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : null;
  };

  useEffect(() => {
    setGeneratedPost([
      {
        post_image: [],
        cta_btn_text: "",
        post_content: "",
      },
    ]);
  }, []);

  return (
    <div className="w-full h-full mt-2">
      {isValidArray(generatedPost) &&
        generatedPost?.map((post, index) => {
          const imgSrc = !!post?.post_image[0]
            ? typeof post?.post_image[0] === "string"
              ? getItemImage(post?.post_image[0])
              : URL?.createObjectURL(post?.post_image[0])
            : imagePreview;
          return (
            <div key={index} className="flex flex-col gap-y-4">
              <div
                className={classNames(
                  "flex justify-center items-center border-2 border-gray-300"
                )}
              >
                <img
                  src={imgSrc}
                  alt="image preview"
                  className={classNames("object-contain w-full p-2", {
                    "h-[26rem]": post?.cta_btn_text,
                    "h-[30rem]": !post?.cta_btn_text,
                  })}
                  onLoad={() =>
                    typeof img !== "string" && URL.revokeObjectURL(imgSrc)
                  }
                />
              </div>
              <div className="editor-container">
                <ReactQuill
                  className="custom-input-field !border"
                  value={post?.post_content}
                  theme="snow"
                  modules={{ toolbar: false }}
                  readOnly
                  placeholder="Write Post Content"
                />
              </div>
              {post?.cta_btn_text && (
                <div className="">
                  <button type="button" className="btn-pink !px-4">
                    {getLabelForValue(post?.cta_btn_text, callToActionOptions)}
                  </button>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
