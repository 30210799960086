import RoutesFile from "RoutesFile";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import store from "../src/store";
import "./App.css";
import { useEffect } from "react";

function App() {
  const removeLoadingScreen = () => {
    const loadingScreen = document.getElementById("loading-screen");
    if (loadingScreen) {
      loadingScreen.style.opacity = "0";
      loadingScreen.remove();
    }
  };

  useEffect(() => {
    removeLoadingScreen();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <RoutesFile />
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="light"
        style={{ minWidth: "350px" }}
      />
    </Provider>
  );
}

export default App;
