import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, Form, Formik } from "formik";
import { showSuccessMsg } from "module/util";
import {
  adminUserForm,
  handleServerValidation,
  showError,
} from "module/validation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addAdminUser } from "redux/adminUser/addAdminUserSlice";
import { getAdminUserDetail } from "redux/adminUser/getAdminUserDetailSlice";
import { updateAdminUser } from "redux/adminUser/updateAdminUserSlice";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const AdminUserForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const isEdit = location.pathname.includes("/admin-user/edit") && id;

  const getAdminUserDetailData = useSelector(
    (state) => state?.getAdminUserDetail?.data ?? []
  );
  const addUserLoading = useSelector(
    (state) => state?.addAdminUser?.loading ?? false
  );
  const updateUserLoading = useSelector(
    (state) => state?.updateAdminUser?.loading ?? false
  );
  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  const initialValues = {
    name: isEdit ? getAdminUserDetailData?.name : "",
    email: isEdit ? getAdminUserDetailData?.email : "",
  };

  useEffect(() => {
    if (isEdit) {
      dispatch(getAdminUserDetail(id));
    }

    dispatch(
      setBreadCrumb([
        {
          name: "Admin Users List",
          href: "/admin-user",
          current: true,
        },
        {
          name: isEdit ? "Edit Admin User" : "Add Admin User",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  const handleSubmit = (values) => {
    const formData = {
      name: values?.name,
      email: values?.email,
      status: "active",
    };

    if (isEdit && id) {
      dispatch(updateAdminUser({ id, formData })).then((data) => {
        if (data?.payload?.code === 200) {
          showSuccessMsg(
            data?.payload?.message ?? "Admin user updated successfully"
          );
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      dispatch(addAdminUser(formData)).then((data) => {
        if (data?.payload?.code === 200) {
          showSuccessMsg(
            data?.payload?.message ?? "Admin user created successfully"
          );
          navigate("/admin-user");
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  return (
    <div className="mt-4 min-w-xl border border-gray-200 bg-white">
      <Formik
        initialValues={initialValues}
        validationSchema={() => adminUserForm(validationMsgObj?.user ?? {})}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
        }) => (
          <Form
            className="w-full xl:w-1/2 p-3"
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              e.key == "Enter" && e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label htmlFor="name" className="input-field-label">
                  Name
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    value={values?.name ?? ""}
                    onChange={handleChange}
                    placeholder="Enter Name"
                    className="input-field"
                  />
                </div>
                {touched?.name && showError(errors?.name)}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label htmlFor="email" className="input-field-label">
                  Email
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    value={values?.email ?? ""}
                    onChange={handleChange}
                    autoComplete="email"
                    placeholder="Enter Email"
                    className="input-field"
                  />
                </div>
                {touched?.email && showError(errors?.email)}
              </div>
            </div>

            <div className="flex flex-wrap justify-start mb-6">
              <SpinnerButton
                className="btn-pink"
                title={"Save"}
                action={() => {}}
                type={"submit"}
                loading={addUserLoading || updateUserLoading}
              />

              <button
                onClick={() => navigate("/admin-user")}
                type="button"
                className="btn btn-gray ml-2"
                disabled={addUserLoading || updateUserLoading}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdminUserForm;
