import {
  checkParagraphWordsLimit,
  convertTimeIntoDateString,
  isDateCurrent,
  isDateTimeLaterThanCurrent,
  isTimeLaterThanCurrent,
  isValidArray,
  isValidObject,
  showErrorMsg,
} from "module/util";
import * as yup from "yup";
export const showError = (value) => (
  <span className="text-red-500">{value}</span>
);

const phoneFormat = /^(|(\+?1-?)) ?((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
const amountFormat = /^\d+\.\d{2,10}$/;
const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const timeFormat = /^(0?[0-9]|1[0-2]):[0-5][0-9]$/;
const URLFormat =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const phoneFormatMsg =
  "Phone number format is invalid. It should be formatted like +1 (xxx) xxx-xxxx.";

// const SUPPORTED_FORMATS = ["jpg", "jpeg", "png"];
const SUPPORTED_IMAGE_VIDEO = ["jpg", "png", "JPG", "PNG", "mp4", "webm"];
const SUPPORTED_FORMATS = ["jpg", "png", "webp", "JPG", "PNG", "WEBP"];
export const SUPPORTED_FORMATS_IMAGE = ["image/jpg", "image/jpeg", "image/png"];
export const FILE_SIZE = 5000000;
export const FILE_SIZE_VIDEO_IMAGE = 25000000;

const sanitizeURL = (url) => {
  if (typeof url !== "string") return url;
  return url.trim().replace(/\s+/g, "");
};

export const loginForm = (data = {}) => {
  return yup.object({
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    password: yup
      .string()
      .trim()
      .matches(/\S+/, "Password can't be blank.")
      .required(data?.password?.required ?? "Password is required."),
  });
};

export const resetPasswordForm = yup.object({
  email: yup
    .string()
    .matches(emailFormat, "Invalid email address.")
    .required("Email is required."),
});

export const setNewPasswordForm = (data = {}) => {
  return yup.object({
    password: yup
      .string()
      .trim()
      .matches(/\S+/, "Password can't be blank.")
      .required(data?.password?.required ?? "Password is required.")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        data?.password?.regex ??
          "Must Contain 8 Characters, One Uppercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .trim()
      .matches(/\S+/, "Password can't be blank.")
      .required(
        data?.password_confirmation?.required ?? "Confirm password required"
      )
      .oneOf(
        [yup.ref("password"), null],
        data?.password_confirmation?.same ?? "Passwords must match"
      ),
  });
};

export const registerForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    company: yup
      .string()
      .required(data?.company?.required ?? "Company is required.")
      .max(
        100,
        data?.company?.max ??
          "Company name may not be greater than 100 characters."
      )
      .min(
        3,
        data?.company?.min ??
          "Company Name should be minimum 3 characters long."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    password: yup
      .string()
      .required(data?.password?.required ?? "Password is required.")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .required(
        data?.password_confirmation?.required ?? "Confirm password required"
      )
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    rememberMe: yup.bool().oneOf([true], "Accept the terms and conditions"),
  });
};

export const accountForm = (data = {}) => {
  return yup.object({
    company: yup
      .string()
      .trim()
      .matches(/\S+/, "Company name can't be blank.")
      .required(data?.company?.required ?? "Company is required.")
      .max(
        100,
        data?.company?.max ??
          "The company name may not be greater than 100 characters."
      ),
    name: yup
      .string()
      .trim()
      .matches(/\S+/, "Name can't be blank.")
      .required(data?.name?.required ?? "Name is required.")
      .max(
        100,
        data?.name?.max ?? "The name may not be greater than 100 characters."
      ),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply no required validation
          return true;
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    zip: yup
      .string()
      .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
      .min(5, "Zip must be 5 or 6 digits long.")
      .max(6, "Zip must be 5 or 6 digits long."),
  });
};

export const companyLocationForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .max(
        50,
        data?.name?.max ?? "Name may not be greater than 100 characters."
      )
      .min(2, data?.name?.min ?? "Name must be at least 2 characters."),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply required validation
          return this.createError({
            message: data?.phone?.required ?? "Phone is required.",
          });
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    address1: yup
      .string()
      .trim()
      .matches(/\S+/, "Address can't be blank.")
      .required(data?.address1?.required ?? "Address is required."),
    zip: yup
      .string()
      .trim()
      .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
      .min(5, "Zip must be 5 or 6 digits long.")
      .max(6, "Zip must be 5 or 6 digits long."),
    city: yup.string().trim().matches(/\S+/, "City can't be blank."),
    location_url: yup
      .string()
      .transform((value) => sanitizeURL(value))
      .url("Enter valid URL")
      .min(3, "URL must be at least 3 characters."),
  });
};

export const accountUserForm = (data = {}) => {
  return yup.object().shape(
    {
      name: yup
        .string()
        .required(data?.name?.required ?? "Name is required.")
        .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
        .max(
          50,
          data?.name?.max ?? "The name may not be greater than 50 characters."
        ),
      email: yup
        .string()
        .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
        .required(data?.email?.required ?? "Email is required."),
      phone: yup
        .string()
        .test("phone-validation", phoneFormatMsg, function (value) {
          if (!value || value === "1" || value === "+1 (   )    -    ") {
            // If phone number is empty or equals "1", apply no required validation
            return true;
          } else {
            // Apply format validation for non-empty phone number
            return yup
              .string()
              .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
              .isValidSync(value);
          }
        }),
      locations: yup
        .array()
        .min(
          1,
          data?.location_ids?.required ?? "Please check at least 1 location."
        )
        .of(
          yup
            .string()
            .required(
              data?.location_ids?.required ??
                "Please check at least 1 location."
            )
        )
        .required(
          data?.location_ids?.required ?? "Please check at least 1 location."
        ),
      zip: yup
        .string()
        .trim()
        .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
        .min(5, "Zip must be 5 or 6 digits long.")
        .max(6, "Zip must be 5 or 6 digits long."),
      city: yup.string().trim().matches(/\S+/, "City can't be blank."),
      address1: yup.string().trim().matches(/\S+/, "Address can't be blank."),
    },
    [["phone"]]
  );
};

export const customerForm = (data = {}) => {
  return yup.object({
    firstName: yup
      .string()
      .trim()
      .min(3, "The name must be at least 3 characters.")
      .required(data?.first_name?.required ?? "First name is required.")
      .max(
        50,
        data?.first_name?.max ??
          "First name may not be greater than 50 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    lastName: yup
      .string()
      .trim()
      .matches(/\S+/, "Last name can't be blank.")
      .required(data?.last_name?.required ?? "Last name is required."),
    address1: yup
      .string()
      .trim()
      .matches(/\S+/, "Address can't be blank.")
      .required(data?.address1?.required ?? "Address is required."),
    city: yup
      .string()
      .trim()
      .matches(/\S+/, "City can't be blank.")
      .required(data?.city?.required ?? "City is required"),
    state: yup.string().required(data?.state?.required ?? "State is required"),
    zip: yup
      .string()
      .trim()
      .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
      .min(5, "Zip must be 5 or 6 digits long.")
      .max(6, "Zip must be 5 or 6 digits long.")
      .required(data?.zip?.required ?? "Zip/Postal code is required."),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply required validation
          return this.createError({
            message: data?.phone?.required ?? "Phone is required.",
          });
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
  });
};

export const adminUserForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
  });
};

export const companyForm = (data = {}) => {
  return yup.object({
    company: yup
      .string()
      .trim()
      .matches(/\S+/, "Company name can't be blank.")
      .required(data?.company?.required ?? "Company is required.")
      .max(
        100,
        data?.company?.max ??
          "The company name may not be greater than 100 characters."
      ),
    name: yup
      .string()
      .trim()
      .min(3, "The name must be at least 3 characters.")
      .required(data?.name?.required ?? "Name is required.")
      .max(
        100,
        data?.name?.max ?? "The name may not be greater than 100 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    address1: yup
      .string()
      .trim()
      .matches(/\S+/, "Address can't be blank.")
      .required(data?.address1?.required ?? "Address is required."),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply required validation
          return this.createError({
            message: data?.phone?.required ?? "Phone is required.",
          });
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
    zip: yup
      .string()
      .trim()
      .matches(/^[0-9]+$/, "Zip must contain only numeric values.")
      .min(5, "Zip must be 5 or 6 digits long.")
      .max(6, "Zip must be 5 or 6 digits long."),
    city: yup.string().trim().matches(/\S+/, "City can't be blank."),
  });
};

export const splashPageForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .max(
        100,
        data?.name?.max ?? "The name may not be greater than 100 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),

    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          // If phone number is empty or equals "1", apply required validation
          return this.createError({
            message: data?.phone?.required ?? "Phone is required.",
          });
        } else {
          // Apply format validation for non-empty phone number
          return yup
            .string()
            .matches(phoneFormat, data?.phone?.regex ?? phoneFormatMsg)
            .isValidSync(value);
        }
      }),
  });
};

export const leadListForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .trim()
      .min(3, "The name must be at least 3 characters.")
      .required(data?.name?.required ?? "Name is required.")
      .max(
        100,
        data?.name?.max ?? "The name may not be greater than 100 characters."
      ),
    company: yup.string().required("Company is required."),
    location: yup
      .string()
      .required(data?.location_id?.required ?? "Location is required."),
    source: yup
      .string()
      .required(data?.source?.required ?? "Source is required."),
  });
};

export const directMailForm1 = (data = {}) => {
  return yup.object().shape(
    {
      name: yup
        .string()
        .required(data?.name?.required ?? "Campaign name is required.")
        .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
        .max(
          50,
          data?.name?.max ?? "The name may not be greater than 50 characters."
        ),
      isEdit: "",
      campaign_cost: yup
        .string()
        .matches(amountFormat, "Value should be in $00.00 format")
        .required(data?.campaign_cost?.required ?? "Campaign cost is required"),
      list_id: yup.string().when("sendTo", {
        is: (sendTo) => ["leads", "both"].includes(sendTo),
        then: () => yup.string().required("List is required."),
      }),
      start_date: yup
        .string()
        .required(data?.start_date?.required ?? "Start date is required."),
      expires: yup.string().required("Expires is required."),
      end_date: yup
        .string()
        .typeError("Invalid date")
        .when("expires", {
          is: "yes",
          then: () =>
            yup
              .string()
              .required(data?.end_date?.required ?? "End date is required."),
        }),

      mailer_upload: yup.lazy(() =>
        yup
          .mixed()
          .when("isEdit", {
            is: false,
            then: () =>
              yup
                .mixed()
                .required(
                  data?.mailer_upload?.required ?? "Please upload image"
                )
                .test(
                  "fileFormat",
                  data?.mailer_upload?.mimes ?? "Unsupported Image Format",
                  (value) => {
                    return (
                      SUPPORTED_FORMATS.indexOf(
                        get_url_extension(value?.name)
                      ) !== -1
                    );
                  }
                )
                .test(
                  "fileSize",
                  "File is too large, Please upload less than 5MB",
                  (value) => value.size <= FILE_SIZE
                ),
          })
          .when(["isEdit", "mailer_upload"], {
            is: (isEdit, mailer_upload) => isEdit === true && !!mailer_upload,
            then: () =>
              yup
                .mixed()
                .test(
                  "fileFormat",
                  data?.mailer_upload?.mimes ?? "Unsupported Image Format",
                  (value) => {
                    return (
                      SUPPORTED_FORMATS.indexOf(
                        get_url_extension(value?.name)
                      ) !== -1
                    );
                  }
                )
                .test(
                  "fileSize",
                  "File is too large, Please upload less than 5MB",
                  (value) => value.size <= FILE_SIZE
                ),
          })
      ),
      offers: yup.array().of(
        yup.object({
          offer_name: yup
            .string()
            .trim()
            .matches(/\S+/, "Offer name can't be blank.")
            .required(data?.offer_name?.required ?? "Offer name is required.")
            .max(
              255,
              data?.offer_name?.max ??
                "offer name may not be greater than 255 characters."
            ),
          discount_cost: yup
            .string()
            .matches(amountFormat, "Value should be in $00.00 format")
            .required(
              data?.discount_cost?.required ?? "Offer cost is required"
            ),
        })
      ),
    },
    [["isEdit", "mailer_upload"], "isEdit"]
  );
};
export const printAdForm1 = (data = {}) => {
  return yup.object().shape(
    {
      name: yup
        .string()
        .required(data?.name?.required ?? "Campaign name is required.")
        .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
        .max(
          50,
          data?.name?.max ?? "The name may not be greater than 50 characters."
        ),
      isEdit: "",
      campaign_cost: yup
        .string()
        .matches(amountFormat, "Value should be in $00.00 format")
        .required(data?.campaign_cost?.required ?? "Campaign cost is required"),
      discount_cost: yup
        .string()
        .matches(amountFormat, "Value should be in $00.00 format")
        .required(data?.discount_cost?.required ?? "Offer cost is required"),
      source: yup
        .string()
        .required(data?.source?.required ?? "Source is required."),
      start_date: yup
        .string()
        .required(data?.start_date?.required ?? "Start date is required."),
      expires: yup.string().required("Expires is required."),
      end_date: yup
        .string()
        .typeError("Invalid date")
        .when("expires", {
          is: "yes",
          then: () =>
            yup
              .string()
              .required(data?.end_date?.required ?? "End date is required."),
        }),
      mailer_upload: yup.lazy(() =>
        yup
          .mixed()
          .when("isEdit", {
            is: false,
            then: () =>
              yup
                .mixed()
                .required(
                  data?.mailer_upload?.required ?? "Please upload image"
                )
                .test(
                  "fileFormat",
                  data?.mailer_upload?.mimes ?? "Unsupported Image Format",
                  (value) => {
                    return (
                      SUPPORTED_FORMATS.indexOf(
                        get_url_extension(value?.name)
                      ) !== -1
                    );
                  }
                )
                .test(
                  "fileSize",
                  "File is too large, Please upload less than 5MB",
                  (value) => value.size <= FILE_SIZE
                ),
          })
          .when(["isEdit", "mailer_upload"], {
            is: (isEdit, mailer_upload) => isEdit === true && !!mailer_upload,
            then: () =>
              yup
                .mixed()
                .test(
                  "fileFormat",
                  data?.mailer_upload?.mimes ?? "Unsupported Image Format",
                  (value) => {
                    return (
                      SUPPORTED_FORMATS.indexOf(
                        get_url_extension(value?.name)
                      ) !== -1
                    );
                  }
                )
                .test(
                  "fileSize",
                  "File is too large, Please upload less than 5MB",
                  (value) => value.size <= FILE_SIZE
                ),
          })
      ),
      offer_name: yup
        .string()
        .trim()
        .matches(/\S+/, "Company can't be blank.")
        .required(data?.offer_name?.required ?? "Offer name is required.")
        .max(
          255,
          data?.offer_name?.max ??
            "Offer name may not be greater than 255 characters."
        ),
    },
    [["isEdit", "mailer_upload"], "isEdit"]
  );
};

export const digitalOfferForm1 = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Campaign name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    campaign_cost: yup
      .string()
      .matches(amountFormat, "Value should be in $00.00 format")
      .required(data?.campaign_cost?.required ?? "Campaign cost is required"),
    discount_cost: yup
      .string()
      .matches(amountFormat, "Value should be in $00.00 format")
      .required(data?.discount_cost?.required ?? "Offer cost is required"),

    offer_valid_days: yup
      .number()
      .min(1, "Must be greater then 0")
      .required("Offer Valid Days is required."),
    start_date: yup
      .string()
      .required(data?.start_date?.required ?? "Start date is required."),
    expires: yup.string().required("Expires is required."),
    end_date: yup
      .string()
      .typeError("Invalid date")
      .when("expires", {
        is: "yes",
        then: () =>
          yup
            .string()
            .required(data?.end_date?.required ?? "End date is required."),
      }),
    budget: yup
      .string()
      .typeError("Invalid date")
      .when("expires", {
        is: "no",
        then: () => yup.string().required("Budget is required."),
      }),
    offer_name: yup
      .string()
      .required(data?.offer_name?.required ?? "Offer name is required.")
      .max(
        255,
        data?.offer_name?.max ??
          "Offer name may not be greater than 255 characters."
      ),
    // expire_time_frame: yup.string().required("Expire Time is required."),
  });
};

export const digitalCouponForm2 = (data = {}) => {
  return yup.object({
    fromName: yup
      .string()
      .trim()
      .matches(/\S+/, "Name can't be blank.")
      .required(data?.from_name?.required ?? "From name is required."),
    replyEmail: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Reply email is required."),
    fromEmail: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "From email is required."),
    subject: yup
      .string()
      .trim()
      .matches(/\S+/, "Subject can't be blank.")
      .required("Subject is required."),
    body: yup
      .string()
      .trim()
      .matches(/\S+/, "Body can't be blank.")
      .required(data?.email_body?.required ?? "Email body is required."),
  });
};

export const redemptionForm = yup.object({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required"),
  address1: yup.string().required("Address1 is required"),
});

export const get_url_extension = (url) => {
  return url?.split(/[#?]/)[0].split(".").pop().trim();
};

export const settingsForm = yup.object({
  dateFormat: yup.string().required("Please select date format."),
  mapZoom: yup.number().required("Google map zoom level is required"),
  heatMapZoom: yup.number().required("Google heat map zoom level is required"),
  phone: yup
    .string()
    .required("Phone number is required.")
    .matches(phoneFormat, phoneFormatMsg),
  accessKey: yup
    .string()
    .trim()
    .matches(/\S+/, "Key can't be blank.")
    .required("Please enter Access key."),
});

export const marketPlaceForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    comment: yup
      .string()
      .required(data?.comment?.required ?? "Comment is required."),
  });
};

export const contactUsForm = (data = {}) => {
  return yup.object({
    name: yup
      .string()
      .required(data?.name?.required ?? "Name is required.")
      .min(3, data?.name?.min ?? "The name must be at least 3 characters.")
      .max(
        50,
        data?.name?.max ?? "The name may not be greater than 50 characters."
      ),
    email: yup
      .string()
      .matches(emailFormat, data?.email?.email ?? "Invalid email address.")
      .required(data?.email?.required ?? "Email is required."),
    comment: yup
      .string()
      .trim()
      .matches(/\S+/, "Comment can't be blank.")
      .required(data?.comment?.required ?? "Comment is required."),
  });
};
// const primaryImageSchema = yup
//   .mixed()
//   .required("Please upload at least one image.")
//   .test("fileFormat", "Unsupported Image Format", (value) => {
//     return (
//       SUPPORTED_FORMATS.indexOf(get_url_extension(value?.name ?? value)) !== -1
//     );
//   })
//   .test("fileSize", "File is too large, Please upload less than 5MB", (value) =>
//     !!value?.size ? value?.size <= FILE_SIZE : true
//   );
export const ImageSchema = yup
  .mixed()
  .test("fileFormat", "Image format should be jpg or png.", (value) => {
    return !!value
      ? SUPPORTED_FORMATS.indexOf(get_url_extension(value?.name ?? value)) !==
          -1
      : true;
  })
  .test(
    "fileSize",
    "File is too large, Please upload less than 5MB.",
    (value) => (!!value?.size ? value?.size <= FILE_SIZE : true)
  )
  .test(
    "image - width - height - check",
    "Image dimension should be min. 900x900 and max. 3000x3000.",
    async (value) => {
      if (typeof value === "object") {
        const imgDimensions = await imageWidthAndHeight(value);

        if (imgDimensions.width < 720 || imgDimensions.width > 3000) {
          return false;
        }
        if (imgDimensions.height < 720 || imgDimensions.height > 3000) {
          return false;
        }
        return true;
      }
      return true;
    }
  );

export const imageWidthAndHeight = (provideFile) => {
  // take the given file (which should be an image) and return the width and height
  const imgDimensions = { width: null, height: null };

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(provideFile);
    reader.onload = function () {
      const img = new Image();
      img.src = reader.result;

      img.onload = function () {
        imgDimensions.width = img.width;
        imgDimensions.height = img.height;
        resolve(imgDimensions);
      };
    };
  });
};

export const menuItemsForm = (data = {}) => {
  return yup.object().shape({
    section_id: yup.string().required("Section is required."),
    item_name: yup
      .string()
      .required("Name is required.")
      .min(3, "Name must be at least 3 characters."),
    item_price: yup
      .string()
      .matches(amountFormat, "Value should be in $00.00 format"),
    // .required("Price is required"),
    item_calories: yup
      .string()
      // .required("Calories is required.")
      .test(
        "is-greater-than-zero",
        "Calories must be greater than 0.",
        (value) => (!!value ? parseFloat(value) > 0 : true)
      ),
    item_description: yup
      .string()
      .required("Description is required.")
      .min(3, "Description must be at least 3 characters.")
      .test("wordCount", "Description cannot exceed 300 words.", (value) => {
        if (value) {
          return !checkParagraphWordsLimit(value, 300);
        }
        return true;
      }),
    url: yup
      .string()
      .transform((value) => sanitizeURL(value))
      .url("Enter valid URL")
      .min(3, "URL must be at least 3 characters."),
    featured_menu_item: yup
      .string()
      .min(3, "Featured Menu Item must be at least 3 characters."),
    primary_image: yup.array().of(ImageSchema),
    secondary_image: yup.array().of(ImageSchema),
    options: yup.array().of(
      yup.object({
        option_name: yup
          .string()
          .trim()
          .matches(/\S+/, "Option name can't be blank."),
        option_calories: yup
          .string()
          .test(
            "is-greater-than-zero",
            "Caloris must be greater than 0.",
            (value) => (value ? parseFloat(value) > 0 : true)
          ),
        option_price: yup
          .string()
          .matches(amountFormat, "Value should be in $00.00 format"),
      })
    ),
  });
};

export const postDetailsForm = (data = {}) => {
  return yup.object({
    postCampaignType: yup.string(),
    postTo: yup.string().required("Media is required."),
    // postImage: yup.array().of(
    //   yup.object({
    //     image: yup
    //       .mixed()
    //       .required("Please upload image")
    //       .test("fileFormat", "Unsupported Image Formats", (value) => {
    //         return (
    //           SUPPORTED_IMAGE_VIDEO.indexOf(get_url_extension(value?.name)) !==
    //           -1
    //         );
    //       })
    //       .test(
    //         "fileSize",
    //         "File is too large, Please upload less than 25MB.",
    //         (value) => value.size <= FILE_SIZE_VIDEO_IMAGE
    //       ),
    //   })
    // ),

    writePost: yup.array().of(
      yup.object({
        description: yup
          .string()
          .test("remove-space", "Post Content is required.", (val) => {
            return val.replace(/<p>\s*<\/p>/g, "<p></p>").trim() === "<p></p>"
              ? false
              : true;
          })
          .required("Post Content is required."),
        // post_image: yup
        //   .mixed()
        //   .required("Please upload image")
        //   .test("fileFormat", "Image format should be jpg or png.", (value) => {
        //     return (
        //       SUPPORTED_IMAGE_VIDEO.indexOf(
        //         get_url_extension(value?.name ?? value)
        //       ) !== -1
        //     );
        //   })
        //   .test(
        //     "fileSize",
        //     "File is too large, Please upload less than 25MB.",
        //     (value) =>
        //       !!value?.size ? value?.size <= FILE_SIZE_VIDEO_IMAGE : true
        //   )
        //   .test(
        //     "image - width - height - check",
        //     "Image dimension should be min. 900x900 and max. 3000x3000.",
        //     async (value) => {
        //       if (typeof value === "object") {
        //         const imgDimensions = await imageWidthAndHeight(value);

        //         if (imgDimensions.width < 900 || imgDimensions.width > 3000) {
        //           return false;
        //         }
        //         if (imgDimensions.height < 900 || imgDimensions.height > 3000) {
        //           return false;
        //         }
        //         return true;
        //       }
        //       return true;
        //     }
        //   ),
      })
    ),
    callToAction: yup.array().of(
      yup.object({
        cta_btn_text: yup.string(),
        cta_btn_url: yup.string().when(["cta_btn_text"], {
          is: (cta_btn_text) => !!cta_btn_text,
          then: (schema) =>
            schema
              .transform((value) => sanitizeURL(value))
              .url("Enter valid URL")
              .required("Action URL is required."),
        }),
      })
    ),
    // postLaunchDate: yup
    //   .string()
    //   .typeError("Invalid date")
    //   .when(["postNowOrDate"], {
    //     is: (postNowOrDate) => postNowOrDate === "date",
    //     then: (schema) =>
    //       schema
    //         .required("Post date is required.")
    //         .test(
    //           "is-later-than-current",
    //           "Date and time must be later than current.",
    //           function (value) {
    //             return isDateTimeLater(value);
    //           }
    //         )
    //         .required("Post date is required."),
    //   }),
    postLaunchDate: yup
      .string()
      .typeError("Invalid date")
      .when(["postNowOrDate", "postLaunchTime", "postLaunchTimeType"], {
        is: (postNowOrDate) => postNowOrDate === "date",
        then: (schema) =>
          schema
            .required("Post date is required.")
            .test(
              "is-later-than-current",
              "Date and time must be later than current.",
              function (value) {
                const { postLaunchTime, postLaunchTimeType } = this.parent;

                if (!value || !postLaunchTime || !postLaunchTimeType) {
                  return false;
                }

                return isDateTimeLaterThanCurrent(
                  value,
                  postLaunchTime,
                  postLaunchTimeType
                );
              }
            ),
      }),
    menuId: yup.string().when(["isStructureData", "postCampaignType"], {
      is: (isStructureData, postCampaignType) =>
        isStructureData == true && postCampaignType == "single",
      then: () => yup.string().required("Menu is required."),
    }),
    sectionId: yup.string().when(["isStructureData", "postCampaignType"], {
      is: (isStructureData, postCampaignType) =>
        isStructureData == true && postCampaignType == "single",
      then: () => yup.string().required("Menu is required."),
    }),
    foodItemId: yup.string().when(["isStructureData", "postCampaignType"], {
      is: (isStructureData, postCampaignType) =>
        isStructureData == true && postCampaignType == "single",
      then: () => yup.string().required("Item is required."),
    }),
  });
};

export const campaignPostDetailsForm = (data = {}) => {
  return yup.object({
    post_to: yup.string().required("Media is required."),
    menu_id: yup.string().required("Menu is required."),
    callToAction: yup.array().of(
      yup.object({
        cta_btn_text: yup
          .string()
          .required("Button is required.")
          .test("uniqueCTA", "Button must be unique.", function (value) {
            const { context } = this.options;
            if (!context || !context.callToAction) {
              return true; // No context, can't validate, assume valid
            }
            const { callToAction } = context;
            const duplicate = callToAction.filter(
              (item) => item.cta_btn_text === value
            ).length;
            return duplicate === 1;
          }),
        cta_btn_url: yup
          .string()
          .transform((value) => sanitizeURL(value))
          .url("Enter valid URL")
          .required("Action URL is required."),
      })
    ),
  });
};

export const postScheduleForm = (data = {}) => {
  return yup.object().shape(
    {
      campaign_name: yup
        .string()
        .trim()
        .matches(/\S+/, "Campaign name can't be blank.")
        .required("Campaign name is required.")
        .min(
          3,
          data?.name?.min ?? "Campaign name must be at least 3 characters."
        )
        .max(
          50,
          data?.name?.max ??
            "campaign name may not be greater than 50 characters."
        ),
      post_launch_date: yup.string().required("Post launch date is required."),
      isEdit: "",
      post_start_time: yup.string().when(["post_launch_date", "isEdit"], {
        is: (post_launch_date, isEdit) => {
          return isEdit == false && isDateCurrent(post_launch_date);
        },
        then: (schema) =>
          schema
            .required("Post start time is required.")
            .test(
              "is-later-than-current",
              "Time must be later than current.",
              function (value) {
                const [postLaunchTime, postLaunchTimeType] =
                  value?.split(" ") ?? [];

                if (!postLaunchTime || !postLaunchTimeType) {
                  return false;
                }

                return isTimeLaterThanCurrent(
                  postLaunchTime,
                  postLaunchTimeType
                );
              }
            ),
      }),
      // .required("Post start time is required."),
      dailyPost: yup.string().when("postSchedule", {
        is: "daily",
        then: () =>
          yup
            .string()
            .required("daily post limit is required.")
            .test(
              "is-greater-than-two",
              "Post max limit is 2.",
              (value) => parseFloat(value) <= 2
            )
            .test(
              "is-greater-than-zero",
              "Post min limit is 1.",
              (value) => parseFloat(value) > 0
            ),
      }),

      post_end_time: yup
        .string()
        .required("Post end time is required.")
        .test("is-after-start", "End time must be later.", function (value) {
          const { post_start_time } = this.parent;
          if (!post_start_time || !value) return true;
          const startTime = convertTimeIntoDateString(post_start_time);
          const endTime = convertTimeIntoDateString(value);
          return endTime > startTime;
        }),
      weekdays: yup.array().when("postSchedule", {
        is: "weekly",
        then: () =>
          yup
            .array()
            .required("Select atleast one day.")
            .min(1, "Select atleast one day."),
      }),
    },
    ["postSchedule", "isEdit"]
  );
};

export const ServicePostDetailsForm = (data = {}) => {
  return yup.object({
    postCampaignType: yup.string(),
    postTo: yup.string().required("Media is required."),
    writePost: yup.array().of(
      yup.object({
        description: yup
          .string()
          .test("remove-space", "Post Content is required.", (val) => {
            return val.replace(/<p>\s*<\/p>/g, "<p></p>").trim() === "<p></p>"
              ? false
              : true;
          })
          .required("Post Content is required."),
      })
    ),
    callToAction: yup.array().of(
      yup.object({
        cta_btn_text: yup.string(),
        cta_btn_url: yup.string().when(["cta_btn_text"], {
          is: (cta_btn_text) => !!cta_btn_text,
          then: (schema) =>
            schema
              .transform((value) => sanitizeURL(value))
              .url("Enter valid URL")
              .required("Action URL is required."),
        }),
      })
    ),
    postLaunchDate: yup
      .string()
      .typeError("Invalid date")
      .when(["postNowOrDate", "postLaunchTime", "postLaunchTimeType"], {
        is: (postNowOrDate) => postNowOrDate === "date",
        then: (schema) =>
          schema
            .required("Post date is required.")
            .test(
              "is-later-than-current",
              "Date and time must be later than current.",
              function (value) {
                const { postLaunchTime, postLaunchTimeType } = this.parent;

                if (!value || !postLaunchTime || !postLaunchTimeType) {
                  return false;
                }

                return isDateTimeLaterThanCurrent(
                  value,
                  postLaunchTime,
                  postLaunchTimeType
                );
              }
            ),
      }),
    categoryId: yup.string().when(["isStructureData", "postCampaignType"], {
      is: (isStructureData, postCampaignType) =>
        isStructureData == true && postCampaignType == "single",
      then: () => yup.string().required("Category is required."),
    }),
    serviceId: yup.string().when(["isStructureData", "postCampaignType"], {
      is: (isStructureData, postCampaignType) =>
        isStructureData == true && postCampaignType == "single",
      then: () => yup.string().required("Service is required."),
    }),
  });
};

export const serviceCampaignPostDetailsForm = (data = {}) => {
  return yup.object({
    post_to: yup.string().required("Media is required."),
    categoryId: yup.string().required("Category is required."),
    callToAction: yup.array().of(
      yup.object({
        cta_btn_text: yup
          .string()
          .required("Button is required.")
          .test("uniqueCTA", "Button must be unique.", function (value) {
            const { context } = this.options;
            if (!context || !context.callToAction) {
              return true; // No context, can't validate, assume valid
            }
            const { callToAction } = context;
            const duplicate = callToAction.filter(
              (item) => item.cta_btn_text === value
            ).length;
            return duplicate === 1;
          }),
        cta_btn_url: yup
          .string()
          .transform((value) => sanitizeURL(value))
          .url("Enter valid URL")
          .required("Action URL is required."),
      })
    ),
  });
};

export const handleServerValidation = (data = {}) => {
  if (data?.payload?.code === 422) {
    if (isValidObject(data?.payload?.exception?.errors)) {
      const errorObj = data?.payload?.exception?.errors;

      //TODO: Static Error Key Condition */
      // if (isValidArray(errorObj?.email)) {
      //   showErrorMsg(errorObj?.email?.[0]);
      // } else if (isValidArray(errorObj?.name)) {
      //   showErrorMsg(errorObj?.name?.[0]);
      // } else if (isValidArray(errorObj?.phone)) {
      //   showErrorMsg(errorObj?.phone?.[0]);
      // } else if (isValidArray(errorObj?.zip)) {
      //   showErrorMsg(errorObj?.zip?.[0]);
      // } else if (isValidArray(errorObj?.list_id)) {
      //   showErrorMsg(errorObj?.list_id?.[0]);
      // } else if (isValidArray(errorObj?.tag)) {
      //   showErrorMsg(errorObj?.tag?.[0]);
      // } else if (isValidArray(errorObj?.item_image)) {
      //   showErrorMsg(errorObj?.item_image[0]);
      // } else if (isValidArray(errorObj?.item_name)) {
      //   showErrorMsg(errorObj?.item_name[0]);
      // } else if (isValidArray(errorObj?.section_name)) {
      //   showErrorMsg(errorObj?.section_name[0]);
      // } else if (isValidArray(errorObj?.category_name)) {
      //   showErrorMsg(errorObj?.category_name[0]);
      // } else {
      //   showErrorMsg("Something went wrong!");
      // }
      //TODO: ----------- */

      //TODO: Optimized with Static Error Key Condition  */
      // const errorKeys = [
      //   "email",
      //   "name",
      //   "phone",
      //   "zip",
      //   "list_id",
      //   "tag",
      //   "item_image",
      //   "item_name",
      //   "section_name",
      //   "category_name",
      //   "service_name",
      // ];

      // Find the first error message that exists and show it
      // for (const key of errorKeys) {
      //   if (isValidArray(errorObj[key])) {
      //     showErrorMsg(errorObj[key][0]);
      //     return; // Exit once we've shown the first error
      //   }
      // }
      // showErrorMsg("Something went wrong!");
      //TODO: ----------- */

      //TODO: Optomize with Dynamic Error Key Condition */
      // Loop through each key in the error object
      for (const key of Object.keys(errorObj)) {
        if (isValidArray(errorObj[key])) {
          showErrorMsg(errorObj[key][0]);
          return;
        }
      }
      // If no specific error is found, fallback to a generic error message
      showErrorMsg("Something went wrong!");
    } else if (data?.payload?.dataObj) {
      showErrorMsg(data?.payload?.dataObj?.message ?? "Something went wrong!");
    } else {
      showErrorMsg(
        data?.payload?.exception?.message ??
          data?.payload?.message ??
          "Something went wrong!"
      );
    }
  } else if (data?.payload?.code === 500 || data?.code === 500) {
    showErrorMsg(
      data?.payload?.exception ?? data?.exception ?? "Something went wrong!"
    );
  } else if (data?.payload?.code === 400) {
    showErrorMsg(
      data?.payload?.dataObj.error ??
        data?.payload?.dataObj.message ??
        data?.payload?.message ??
        "Something went wrong!"
    );
  } else if (data?.payload?.code === 404) {
    showErrorMsg(data?.payload?.dataObj?.message ?? "Something went wrong!");
  } else if (data?.payload?.code === 503) {
    showErrorMsg(data?.payload?.dataObj?.error ?? "Service is not available!");
  } else {
    showErrorMsg(
      !!data?.payload?.message
        ? data?.payload?.message
        : "Something went wrong!"
    );
  }
};
