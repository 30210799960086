import { Dialog, Transition } from "@headlessui/react";
import { ArrowUpOnSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import plusIcon from "assets/images/plusIcon.svg";
import classNames from "classnames";
import { getCurrentLocation, getItemImage, isValidArray } from "module/util";
import { ImageSchema } from "module/validation";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function PostImageModal({
  isModalOpen = false,
  setModalOpen = () => {},
  itemImageArray = [],
  setItemImageArray = () => {},
  handleImageChange = () => {},
  postImages = [],
  postDetails = {},
}) {
  const cancelButtonRef = useRef(null);
  const uploadImageRef = useRef();
  const [searchParams] = useSearchParams();
  const postId = searchParams.get("postId") || "";
  const isEdit = !!postId;
  const [changeButtonLabel, setChangeButtonLabel] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();

  const isMultiPost =
    isEdit &&
    postDetails?.campaign_type == "multi" &&
    google_business_type == "Restaurant"
      ? true
      : false;

  const defaultImage = isValidArray(postDetails?.descriptions)
    ? postDetails?.descriptions[0]?.post_image ?? itemImageArray[0]
    : itemImageArray[0];

  const handleSubmit = () => {
    handleImageChange(selectedImage);
    setModalOpen(false);
  };

  useEffect(() => {
    isValidArray(itemImageArray) &&
      setSelectedImage(isValidArray(postImages) ? postImages[0] : defaultImage);
  }, []);

  const handleUploadChange = async (e) => {
    let filesData = e.target.files[0];
    try {
      await ImageSchema.validate(filesData);

      !!filesData
        ? isValidArray(itemImageArray)
          ? setItemImageArray([...itemImageArray, filesData])
          : setItemImageArray([filesData])
        : "";
      setSelectedImage(filesData);
      setErrorMessage("");
      setChangeButtonLabel(true);
    } catch (err) {
      setSelectedImage("");
      setErrorMessage(err.message);
    }
  };

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-visible rounded-sm bg-white text-left align-middle shadow-xl transition-all p-5">
                <div className="flex justify-between">
                  <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
                    <span className="font-bold text-lg">Select Post Image</span>
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center outline-0"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="w-full h-[1px] bg-gray-300 mt-1"></div>
                <div>
                  <div className="my-4 flex flex-wrap gap-x-2 gap-y-9 justify-start">
                    {isValidArray(itemImageArray) ? (
                      <>
                        {itemImageArray?.map((images, index) => {
                          const imageSrc =
                            typeof images == "string"
                              ? getItemImage(images)
                              : images
                              ? images
                                ? URL.createObjectURL(images ?? null)
                                : ""
                              : "";
                          return (
                            !!imageSrc && (
                              <div
                                key={images}
                                className={classNames(
                                  "relative flex justify-center h-[150px] w-[150px] border-2 border-gray-400",
                                  {
                                    "border-pink-500 cursor-default":
                                      selectedImage == images,
                                    " cursor-pointer": selectedImage !== images,
                                  }
                                )}
                                onClick={() => {
                                  if (selectedImage !== images) {
                                    setSelectedImage(images);
                                    setErrorMessage("");
                                    setChangeButtonLabel(true);
                                  }
                                }}
                              >
                                <img
                                  src={imageSrc}
                                  alt="item-image-png"
                                  className="!object-contain"
                                />

                                <button
                                  type="button"
                                  className="absolute -bottom-7"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const updatedImages = itemImageArray.filter(
                                      (_, i) => i !== index
                                    );
                                    setChangeButtonLabel(true);

                                    setItemImageArray(updatedImages);
                                    !!updatedImages[0]
                                      ? setSelectedImage(updatedImages[0])
                                      : setSelectedImage("");
                                  }}
                                >
                                  <TrashIcon className="w-5 h-5" />
                                </button>
                              </div>
                            )
                          );
                        })}
                        {!isMultiPost && (
                          <div className="flex justify-center items-center">
                            <button
                              type="button"
                              title={
                                isMultiPost
                                  ? "You can not upload new image"
                                  : "Upload new image"
                              }
                              className="text-pink-600 text-sm font-semibold btn h-10"
                              disabled={isMultiPost}
                              onClick={() => {
                                if (!isMultiPost) {
                                  uploadImageRef?.current?.click();
                                }
                              }}
                            >
                              <span className="flex justify-center items-center gap-x-1 text-sm text-gray-700 font-semibold">
                                Add More
                                <img
                                  src={plusIcon}
                                  alt="plus icon"
                                  className="w-4 h-4"
                                />
                              </span>
                            </button>{" "}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="w-full flex gap-x-2 justify-center items-center min-h-28 text-sm font-semibold">
                        <button
                          type="button"
                          title={"Upload image"}
                          className=""
                          disabled={isMultiPost}
                          onClick={() => {
                            if (!isMultiPost) {
                              uploadImageRef?.current?.click();
                            }
                          }}
                        >
                          <span className="flex justify-center items-center gap-x-1 text-sm text-gray-700 font-semibold">
                            Upload Image
                            <ArrowUpOnSquareIcon
                              className="w-5 h-5 text-pink-600"
                              strokeWidth={2}
                            />
                          </span>
                          <span className="text-[13px] font-light mr-2">
                            (Upload: JPEG or PNG, 3000x3000, max 5MB)
                          </span>
                          {errorMessage && (
                            <div className="text-red-500 !font-normal">
                              {errorMessage}
                            </div>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  {errorMessage && isValidArray(itemImageArray) && (
                    <div className="text-red-500 mt-7">{errorMessage}</div>
                  )}
                  <label htmlFor={`upload_image`} className="hidden">
                    <input
                      ref={uploadImageRef}
                      type="file"
                      id={`upload_image`}
                      name={`upload_image`}
                      accept=".png, .jpg, .jpeg"
                      onChange={handleUploadChange}
                      autoComplete="image"
                      placeholder="Enter Image"
                      className="file-field"
                    />
                    <p className="min-w-28 absolute top-[6px] left-[127px] bg-white text-neutral-700 truncate"></p>
                    <span className="text-[13px] font-light mr-2">
                      (Upload: JPEG or PNG, 1000x1000, max 500k)
                    </span>
                  </label>
                  <div className="w-full flex justify-end gap-2">
                    <button
                      type="button"
                      className={classNames("btn-pink", {
                        "w-20": !changeButtonLabel,
                        "w-32": changeButtonLabel,
                      })}
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={
                        (!selectedImage && !changeButtonLabel) || errorMessage
                      }
                    >
                      {changeButtonLabel ? `Save & Next` : `Next`}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
