import { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // Import ReactQuill
import "react-quill/dist/quill.snow.css"; // Import styles
import "react-quill-emoji/dist/quill-emoji.css"; // Import emoji styles
import { EmojiBlot, emojiModule } from "react-quill-emoji";

const DraftEditor = (props) => {
  const {
    setFieldValue,
    value = "",
    setFieldTouched,
    emailTemplateData,
  } = props;
  const [editorValue, setEditorValue] = useState(""); // Use state for editor value

  useEffect(() => {
    // Convert HTML to editor value when emailTemplateData changes
    convertHtmlToDraft(value);
    setFieldTouched("body", true);
  }, [emailTemplateData]);

  const convertHtmlToDraft = (htmlData = "") => {
    setEditorValue(htmlData); // Set editor value directly
  };

  const handleEditorChange = (value) => {
    setEditorValue(value); // Update editor value
    setFieldValue("body", value); // Set form field value
  };

  // Define the modules for ReactQuill, including the emoji module
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image", "video"],
        ["emoji"], // Add emoji button
        ["clean"],
      ],
    },
    "emoji-toolbar": true,
    "emoji-shortname": true,
  };

  return (
    <>
      <style>
        {`
            .ql-editor {
              font-size: 16px; /* Set default text size */
            }
          `}
      </style>
      <ReactQuill
        className="h-72"
        value={editorValue}
        onChange={handleEditorChange}
        theme="snow" // Set theme to snow
        modules={modules}
      />
    </>
  );
};

export default DraftEditor;
