import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getMenuItemImageList = createAsyncThunk(
  "gmb/item-image-list",
  async (formData, { rejectWithValue }) => {
    const { location_id, menu_id, section_id, image_required } = formData;
    try {
      const response = await getApi(
        `api/get-food-items-images?location_id=${location_id}&${
          !!menu_id ? `menu_id=${menu_id}` : `section_id=${section_id}`
        }&image_required=${image_required}`
      );
      return response?.data?.dataObj;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getMenuItemImageListSlice = createSlice({
  name: "getMenuItemImageList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenuItemImageList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMenuItemImageList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getMenuItemImageList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getMenuItemImageListSlice.reducer;
