import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getMenuItemList = createAsyncThunk(
  "gmb/get-item-list",
  async (formData, { rejectWithValue }) => {
    try {
      const { id, location_id } = formData;
      const response = await getApi(
        `api/gmblocationmenuitem?section_id=${id}&location_id=${location_id}`
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getMenuItemListSlice = createSlice({
  name: "getMenuItemList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenuItemList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMenuItemList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getMenuItemList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getMenuItemListSlice.reducer;
