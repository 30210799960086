import ConfirmationModal from "components/common/ConfirmationModal";
import CompletedPostList from "components/marketing/posts/CompletedPostList";
import ErrorPostList from "components/marketing/posts/ErrorPostListSlice";
import FailedPostList from "components/marketing/posts/FailedPostList";
import PendingPostList from "components/marketing/posts/PendingPostList";
import ScheduledPostList from "components/marketing/posts/ScheduledPostList";
import { callToActionOptions } from "module/data";
import { getCurrentLocation, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { deletePost } from "redux/post/deletePostSlice";
import { getPostList } from "redux/post/getPostListSlice";
import { deleteServiceSinglePost } from "redux/post/service/deleteServiceSinglePostSlice";
import { getServicePostList } from "redux/post/service/getServicePostListSlice";

export default function PostList() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setModalOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(0);
  const postStatus = searchParams.get("tab")
    ? searchParams.get("tab")
    : "pending";

  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );
  const postList = useSelector(
    (state) => state?.getPostList?.data?.dataObj?.data ?? []
  );
  const servicePostList = useSelector(
    (state) => state?.getServicePostList?.data?.dataObj?.data ?? []
  );
  const postListLoading = useSelector(
    (state) => state?.getPostList?.loading ?? false
  );
  const servicePostListLoading = useSelector(
    (state) => state?.getServicePostList?.loading ?? false
  );
  const deletePostLoading = useSelector(
    (state) => state?.deletePost?.loading ?? false
  );
  const deleteServicePostLoading = useSelector(
    (state) => state?.deleteServiceSinglePost?.loading ?? false
  );

  const statusForAPI = {
    pending: "pending",
    scheduled: "scheduled",
    completed: "completed",
    error: "error",
    failed: "failed",
  };
  const handleGetPostList = () => {
    const formData = {
      location_id: currentLocationId,
      post_status: statusForAPI[postStatus],
    };
    const action =
      google_business_type === "Restaurant" ? getPostList : getServicePostList;
    dispatch(action(formData));
  };

  const handleDeletePost = () => {
    // deleteServiceSinglePost()

    const action =
      google_business_type === "Restaurant"
        ? deletePost
        : deleteServiceSinglePost;

    dispatch(action(idForDelete)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Post deleted successfully."
        );
        setModalOpen(false);
        handleGetPostList();
      } else {
        handleServerValidation(data);
        setModalOpen(false);
      }
    });
  };

  const getCtaBtnLabel = (value) => {
    const result =
      callToActionOptions?.find((item) => item?.value === value) ?? value;
    return result;
  };

  useEffect(() => {
    handleGetPostList();
  }, [currentLocationId, postStatus]);

  return (
    <div className="w-full">
      {postStatus == "scheduled" ? (
        <ScheduledPostList
          postList={
            google_business_type === "Restaurant" ? postList : servicePostList
          }
          postListLoading={postListLoading || servicePostListLoading}
          setModalOpen={setModalOpen}
          setIdForDelete={setIdForDelete}
        />
      ) : postStatus == "failed" ? (
        <FailedPostList
          postList={
            google_business_type === "Restaurant" ? postList : servicePostList
          }
          postListLoading={postListLoading || servicePostListLoading}
          setModalOpen={setModalOpen}
          setIdForDelete={setIdForDelete}
        />
      ) : postStatus == "error" ? (
        <ErrorPostList
          postList={
            google_business_type === "Restaurant" ? postList : servicePostList
          }
          postListLoading={postListLoading || servicePostListLoading}
          setModalOpen={setModalOpen}
          setIdForDelete={setIdForDelete}
        />
      ) : postStatus == "pending" ? (
        <PendingPostList
          postList={
            google_business_type === "Restaurant" ? postList : servicePostList
          }
          postListLoading={postListLoading || servicePostListLoading}
          setModalOpen={setModalOpen}
          setIdForDelete={setIdForDelete}
          getCtaBtnLabel={getCtaBtnLabel}
        />
      ) : (
        postStatus == "completed" && (
          <CompletedPostList
            postList={
              google_business_type === "Restaurant" ? postList : servicePostList
            }
            postListLoading={postListLoading || servicePostListLoading}
            setModalOpen={setModalOpen}
            setIdForDelete={setIdForDelete}
            getCtaBtnLabel={getCtaBtnLabel}
          />
        )
      )}
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleDeletePost}
          isLoading={deletePostLoading || deleteServicePostLoading}
          message="You want to delete this post?"
        />
      )}
    </div>
  );
}
