import CustomPhoneInput from "components/common/fields/CustomPhoneInput";
import DropdownField from "components/common/fields/DropdownField";
import GoogleAddressField from "components/common/fields/GoogleAddressField";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, FieldArray, Form, Formik } from "formik";
import {
  getUserDataByParam,
  isValidArray,
  isValidObject,
  showSuccessMsg,
  statusArray,
} from "module/util";
import {
  accountUserForm,
  handleServerValidation,
  showError,
} from "module/validation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addCompanyUser } from "redux/account/addCompanyUserSlice";
import { updateCompanyUser } from "redux/account/updateCompanyUserSlice";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { getLocationUserDetail } from "redux/location/getLocationUserDetailSlice";
import { locationList } from "redux/location/locationListSlice";

const UserForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const isEdit = location.pathname.includes("/users/edit") && id;
  const companyName = getUserDataByParam("COMPANY_NAME") ?? "";

  const getLocationUserDetailData = useSelector(
    (state) => state?.getLocationUserDetail?.data ?? []
  );
  const addCompanyUserLoading = useSelector(
    (state) => state?.addCompanyUser?.loading ?? false
  );
  const updateCompanyUserLoading = useSelector(
    (state) => state?.updateCompanyUser?.loading ?? false
  );
  const totalLocationListData = useSelector(
    (state) => state?.locationList?.data?.data ?? []
  );
  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  const locationUserData = isValidObject(
    getLocationUserDetailData?.dataObj?.user
  )
    ? getLocationUserDetailData?.dataObj?.user
    : {};

  const locationData = isValidObject(
    getLocationUserDetailData?.dataObj?.locations
  )
    ? getLocationUserDetailData?.dataObj?.locations
    : [];

  const personalData = isValidObject(
    getLocationUserDetailData?.dataObj?.user?.details
  )
    ? getLocationUserDetailData?.dataObj?.user?.details
    : {};

  const locationsArray = locationData?.reduce((old, newArray) => {
    old.push(newArray?.id);
    return old;
  }, []);

  useEffect(() => {
    if (isEdit) {
      dispatch(getLocationUserDetail(id));
    }
    dispatch(locationList());
  }, [dispatch, isEdit, id]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Account",
          href: "/account",
          current: true,
        },
        {
          name: "Users List",
          href: "/account/users",
          current: true,
        },
        {
          name: isEdit ? "Edit User" : "Add User",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  const initialValues = {
    name: isEdit ? locationUserData?.name : "",
    company: companyName,
    address1: isEdit ? personalData?.address1 : "",
    address2: isEdit ? personalData?.address2 : "",
    city: isEdit ? personalData?.city : "",
    state: isEdit ? personalData?.state : "",
    zip: isEdit ? personalData?.zip : "",
    phone: isEdit ? locationUserData?.phone ?? "" : "",
    email: isEdit ? locationUserData?.email : "",
    status: isEdit ? locationUserData?.status : "active",
    locations: isEdit ? locationsArray : [],
  };

  const handleSubmit = (values) => {
    const companyId = getUserDataByParam("COMPANY_ID");

    const formData = {
      name: values?.name ?? "",
      address1: values?.address1 ?? "",
      address2: values?.address2 ?? "",
      city: values?.city ?? "",
      state: values?.state ?? "",
      zip: values?.zip ?? "",
      phone: values?.phone == "1" ? "" : values?.phone ?? "",
      email: values?.email ?? "",
      status: values?.status ?? "",
      location_ids: values?.locations.toString(),
      company_id: companyId,
    };

    const id = locationUserData?.id;
    if (isEdit) {
      dispatch(updateCompanyUser({ id, formData })).then((data) => {
        if (data?.payload?.code === 200) {
          showSuccessMsg(
            data?.payload?.message ?? "Location user updated successfully"
          );
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      dispatch(addCompanyUser(formData)).then((data) => {
        if (data?.payload?.code === 200) {
          showSuccessMsg(data?.payload?.message);
          navigate("/account/users");
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  return (
    <div className="mt-4">
      <Formik
        initialValues={initialValues}
        validationSchema={() => accountUserForm(validationMsgObj?.user ?? {})}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        // validateOnBlur={false}
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          handleBlur,
          values,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            className="w-full min-w-xl border border-gray-200 p-3 bg-white"
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              e.key == "Enter" && e.preventDefault();
            }}
          >
            <div className="main-div xl:flex w-full">
              <div className="section-1 w-full xl:w-[50%]">
                <div className="block sm:flex xl:block gap-4">
                  <div className="w-full">
                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full px-3">
                        <label htmlFor="name" className="input-field-label">
                          Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            value={values?.name ?? ""}
                            onChange={handleChange}
                            placeholder="Enter Name"
                            className="input-field"
                          />
                        </div>
                        {touched?.name && showError(errors?.name)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full px-3">
                        <label htmlFor="company" className="input-field-label">
                          Company
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="company"
                            id="company"
                            value={values?.company ?? ""}
                            onChange={handleChange}
                            autoComplete="family-name"
                            placeholder="Enter Company"
                            readOnly={true}
                            disabled={true}
                            className="input-field"
                          />
                        </div>
                        {touched?.company && showError(errors?.company)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full px-3">
                        <label htmlFor="address" className="input-field-label">
                          Address
                        </label>
                        <div className="mt-2">
                          <GoogleAddressField
                            name="address1"
                            setFieldValue={setFieldValue}
                            value={values?.address1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={"Enter Address"}
                            setFieldTouched={setFieldTouched}
                            FieldValues={values}
                          />
                        </div>
                        {touched?.address1 && showError(errors?.address1)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full px-3">
                        <label htmlFor="Address2" className="input-field-label">
                          Address2
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="address2"
                            id="Address2"
                            value={values?.address2 ?? ""}
                            onChange={handleChange}
                            autoComplete="Address2"
                            placeholder="Enter Address2"
                            className="input-field"
                          />
                        </div>
                        {touched?.address2 && showError(errors?.address2)}
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="flex flex-wrap -mx-3 mb-2">
                      <div className="w-full xl:w-1/3 px-3 md:mb-0">
                        <label htmlFor="city" className="input-field-label">
                          City
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            value={values?.city ?? ""}
                            onChange={handleChange}
                            autoComplete="address-level2"
                            placeholder="Enter City"
                            className="input-field"
                          />
                        </div>
                        {touched?.city && showError(errors?.city)}
                      </div>
                      <div className="w-full xl:w-1/3 px-3 md:mb-0 mt-[10px] xl:mt-0">
                        <label htmlFor="state" className="input-field-label">
                          State
                        </label>
                        <div className="mt-2">
                          <DropdownField
                            setFieldValue={setFieldValue}
                            values={values}
                            name="state"
                          />
                        </div>
                        {touched?.state && showError(errors?.state)}
                      </div>
                      <div className="w-full xl:w-1/3 px-3 md:mb-0 mt-[12px] xl:mt-0">
                        <label htmlFor="zip" className="input-field-label">
                          ZIP / Postal code
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="zip"
                            id="zip"
                            value={values?.zip ?? ""}
                            onChange={handleChange}
                            autoComplete="zip"
                            placeholder="Enter Zip/Postal Code"
                            className="input-field"
                          />
                        </div>
                        {touched?.zip && showError(errors?.zip)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-3 mt-[12px] xl:mt-0">
                      <div className="w-full px-3">
                        <label htmlFor="phone" className="input-field-label">
                          Phone
                        </label>
                        <CustomPhoneInput
                          values={values}
                          name="phone"
                          setFieldValue={setFieldValue}
                          onBlur={handleBlur}
                        />
                        {touched?.phone && showError(errors?.phone)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:flex gap-4">
                  <div className="w-full">
                    <div className="flex flex-wrap -mx-3 mb-3">
                      <div className="w-full px-3">
                        <label htmlFor="email" className="input-field-label">
                          Email
                        </label>
                        <div className="mt-2">
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            value={values?.email ?? ""}
                            onChange={handleChange}
                            autoComplete="email"
                            placeholder="Enter Email"
                            className="input-field"
                          />
                        </div>
                        {touched?.email && showError(errors?.email)}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label htmlFor="status" className="input-field-label">
                          Status
                        </label>
                        <div className="mt-2">
                          <Field
                            as="select"
                            className="input-field"
                            name="status"
                          >
                            {isValidArray(statusArray()) &&
                              statusArray().map((item) => (
                                <option
                                  key={item?.name}
                                  value={item?.value}
                                  defaultValue={values?.status}
                                >
                                  {item?.name}
                                </option>
                              ))}
                          </Field>
                        </div>
                        {touched?.status && showError(errors?.status)}
                      </div>
                    </div>
                  </div>
                  <div className="section-2 block xl:hidden w-full xl:w-[50%]">
                    <div className="border-b border-gray-200">
                      <label
                        htmlFor="locations"
                        className="block text-bold font-medium leading-6 text-gray-900"
                      >
                        Locations
                      </label>
                    </div>
                    <ul className="mt-2">
                      {isValidArray(totalLocationListData) && (
                        <FieldArray
                          name="locations"
                          render={(arrayHelpers) => (
                            <>
                              {isValidArray(totalLocationListData) ? (
                                totalLocationListData?.map((item, idx) => (
                                  <li key={idx}>
                                    <label className="form-check-label my-1 hover:cursor-pointer">
                                      <input
                                        name={`items.${idx}`}
                                        type="checkbox"
                                        className="form-check-input hover:cursor-pointer text-pink-600 focus:ring-0"
                                        value={item.id}
                                        checked={values?.locations.includes(
                                          item.id
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            arrayHelpers.push(item.id);
                                          } else {
                                            const index =
                                              values.locations.indexOf(item.id);
                                            arrayHelpers.remove(index);
                                          }
                                        }}
                                      />
                                      <span className="mx-2">{item.name}</span>
                                    </label>
                                  </li>
                                ))
                              ) : (
                                <>No Locations..</>
                              )}
                            </>
                          )}
                        />
                      )}
                    </ul>
                    <div>
                      {touched?.locations && showError(errors?.locations)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="section-2 hidden xl:block w-full xl:w-[50%] ml-6">
                <div className="border-b border-gray-200">
                  <label
                    htmlFor="locations"
                    className="block text-bold font-medium leading-6 text-gray-900"
                  >
                    Locations
                  </label>
                </div>
                <ul className="mt-2">
                  {isValidArray(totalLocationListData) && (
                    <FieldArray
                      name="locations"
                      render={(arrayHelpers) => (
                        <>
                          {isValidArray(totalLocationListData) ? (
                            totalLocationListData?.map((item, idx) => (
                              <li key={idx}>
                                <label className="form-check-label my-1 hover:cursor-pointer">
                                  <input
                                    name={`items.${idx}`}
                                    type="checkbox"
                                    className="form-check-input hover:cursor-pointer text-pink-600 focus:ring-0"
                                    value={item.id}
                                    checked={values?.locations.includes(
                                      item.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        arrayHelpers.push(item.id);
                                      } else {
                                        const index = values.locations.indexOf(
                                          item.id
                                        );
                                        arrayHelpers.remove(index);
                                      }
                                    }}
                                  />
                                  <span className="mx-2">{item.name}</span>
                                </label>
                              </li>
                            ))
                          ) : (
                            <>No Locations..</>
                          )}
                        </>
                      )}
                    />
                  )}
                </ul>
                <div>{touched?.locations && showError(errors?.locations)}</div>
              </div>
            </div>
            <div className="flex flex-wrap justify-start mt-4 sm:mt-0">
              <SpinnerButton
                className="btn-pink"
                title={"Save"}
                action={() => {}}
                type={"submit"}
                loading={addCompanyUserLoading || updateCompanyUserLoading}
              />
              <button
                onClick={() => {
                  navigate("/account/users");
                }}
                type="button"
                className="btn btn-gray ml-2"
                disabled={addCompanyUserLoading || updateCompanyUserLoading}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
