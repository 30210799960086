import { addDecimalToNumber, isValidObject } from "module/util";
import ReactApexChart from "react-apexcharts";

const HeatMapMaritalStatus = (props) => {
  const { getCampaignReportData } = props;
  const graphDetails = getCampaignReportData?.redemptionGraph ?? [];
  const hasData = graphDetails.some((value) => value > 0);

  const initValue = {
    options: {
      chart: {
        height: 350,
        type: "heatmap",
      },
      colors: ["#0000"],
      dataLabels: {
        enabled: true,
        formatter: function (
          value,
          { series, dataPointIndex, seriesIndex, w }
        ) {
          const finalValue = w.globals.series[seriesIndex][dataPointIndex];
          return dataPointIndex === 3
            ? `$${addDecimalToNumber(finalValue)}`
            : finalValue;
        },
        style: { colors: ["#FF0000"] },
      },
      xaxis: {
        position: "top",
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex }) {
          const xLabels = graphDetails?.[seriesIndex]?.name ?? "";
          const finalValues =
            graphDetails?.[seriesIndex]?.data?.[dataPointIndex] ?? {};
          if (dataPointIndex === 3) {
            return isValidObject(finalValues)
              ? `${xLabels} - $${
                  dataPointIndex === 3
                    ? addDecimalToNumber(finalValues?.y ?? 0)
                    : finalValues?.y ?? 0
                }`
              : xLabels;
          }
          return isValidObject(finalValues)
            ? `${xLabels} - ${finalValues?.y ?? 0}`
            : xLabels;
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: Infinity,
                color: "#b5b4b1",
                foreColor: "#1c1b1b",
              },
            ],
          },
        },
      },
      stroke: {
        width: 1,
      },
      title: {
        text: "Marital Status Vs Redemption",
      },
    },
  };

  return (
    <div id="chart">
      {hasData ? (
        <ReactApexChart
          options={initValue.options}
          series={graphDetails}
          type="heatmap"
          height={350}
        />
      ) : (
        <div className="h-72 w-full flex justify-center items-center text-gray-600">
          No Data
        </div>
      )}
    </div>
  );
};

export default HeatMapMaritalStatus;
