import { PlusIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import ServiceModal from "components/location/services/ServiceModal";
import Services from "components/location/services/Services";
import { getCompanyStatus, isValidArray, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getCategoryDetails } from "redux/gmbLocation/getCategoryDetailsSlice";
import { getServiceList } from "redux/gmbLocation/getServiceListSlice";
import { reorderService } from "redux/gmbLocation/reOrderServiceSlice";
import { setCategoryPrimary } from "redux/gmbLocation/setCategoryPrimarySlice";

export const ItemTypes = {
  CARD: "card",
};

export default function ServiceManagement({
  currentCompanyId = 0,
  currentLocationId = 0,
  setSelectedServices = () => {},
  selectedServices = [],
  setAreAllServicesSelected = () => {},
  areAllServicesSelected = false,
  handleSelectAllServicePost = () => {},
  setIsSelecteAllServiceModalOpen = () => {},
}) {
  const allSelectRef = useRef(null);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams?.get("category") || "";
  const [serviceList, setServiceList] = useState([]);
  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const [serviceId, setServiceId] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [sectionBasicDetails, setSectionBasicDetails] = useState({
    section_name: "",
    section_description: "",
  });

  const [sectionIdForEdit, setSectionIdForEdit] = useState(0);
  const [isSelectedServicesChanges, setIsSelectedServicesChanged] =
    useState(false);

  const serviceListLoading = useSelector(
    (state) => state?.getServiceList?.loading ?? false,
    shallowEqual
  );
  const categoryDetails = useSelector(
    (state) => state?.getCategoryDetails?.data?.dataObj ?? {}
  );
  const setCategoryPrimaryloading = useSelector(
    (state) => state?.setCategoryPrimary?.loading ?? false
  );

  // Function for re-order service list
  const handleReOrderService = async (data) => {
    const formData = {
      data: data,
    };
    await dispatch(reorderService(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Items re-ordered successfully."
        );
        handleServiceList();
      } else {
        handleServerValidation(data);
      }
    });
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setServiceList((prevItems) => {
      const updatedItems = [...prevItems];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);

      // Reorder items based on their new position
      return updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));
    });
  }, []);

  const dropCard = useCallback(
    (dragIndex, hoverIndex) => {
      const updatedItems = [...serviceList];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);

      const finalData = updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      handleReOrderService(finalData);
    },
    [serviceList]
  );
  const handleServiceList = () => {
    const formData = {
      category_id: categoryId,
      location_id: currentLocationId,
    };
    dispatch(getServiceList(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const serviceData = data?.payload?.dataObj?.data;
        const sortedItemList = isValidArray(serviceData)
          ? [...serviceData].sort((a, b) => a.order - b.order)
          : [];
        setServiceList(sortedItemList);

        const allSelected = serviceData.every(
          (service) => service.is_selected === "yes"
        );
        setAreAllServicesSelected(allSelected);

        const selectedService = sortedItemList.reduce((acc, service) => {
          if (service.is_selected === "yes" && acc.length < 100) {
            acc.push(service.id);
          }
          return acc;
        }, []);

        setSelectedServices(selectedService);
      }
    });
  };

  const handleGetCategoryDetails = () => {
    const formData = {
      id: categoryId,
      location_id: currentLocationId,
    };
    dispatch(getCategoryDetails(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const category = data?.payload?.dataObj || {};
        setSearchParams({ tab: "services", category: category?.id });
        handleServiceList();
      }
    });
  };

  const handleSetCategoryPrimary = () => {
    const formData = {
      id: categoryId,
      location_id: currentLocationId,
    };
    dispatch(setCategoryPrimary(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        setIsConfirmationModalOpen(false);
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Category set to primary successfully."
        );
        handleGetCategoryDetails();
      } else {
        setIsConfirmationModalOpen(false);
        handleServerValidation(data);
      }
    });
  };

  useEffect(() => {
    handleGetCategoryDetails();
  }, []);

  const updateSelectedServices = (serviceList, selectedServices) => {
    const targetCount = 100;
    const selectedSet = new Set(selectedServices);
    const updatedServices = [...selectedServices];

    for (const service of serviceList) {
      const { id } = service;
      if (!selectedSet.has(id)) {
        updatedServices.push(id);
      }
      if (updatedServices.length >= targetCount) {
        break;
      }
    }
    return updatedServices.slice(0, targetCount);
  };

  return (
    <>
      <div className="my-2 w-full min-w-xl border-2 border-gray-400 p-3 bg-white flex justify-between">
        <div className="w-full flex gap-2 justify-between items-center">
          <div className="font-semibold text-gray-700">
            <span className="text-[17px]">
              {categoryDetails?.category_name}
            </span>{" "}
            <span className="text-sm">
              (
              {categoryDetails?.is_primary === "yes" ? "Primary" : "Additional"}
              )
            </span>
            <button
              type="button"
              className="ml-2 text-[11px] font-medium text-blue-500 underline cursor-pointer"
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              {categoryDetails?.is_primary === "no" && "Make it Primary"}
            </button>
          </div>
          <div>
            <button
              type={"button"}
              className="btn-pink"
              onClick={() => setIsSectionModalOpen(true)}
            >{`Add Service`}</button>
          </div>
        </div>
      </div>
      <div className="">
        {isValidArray(serviceList) && (
          <div className="w-full flex gap-4">
            <div className="flex items-center gap-3 my-1">
              <span className="font-light text-sm lg:text-base">
                Selected services:{" "}
                <span className="font-normal">
                  {selectedServices?.length ?? 0}
                </span>
              </span>{" "}
              <span
                className="ml-2 text-sm lg:text-base text-blue-500 underline cursor-pointer"
                onClick={() => {
                  allSelectRef?.current?.click();
                }}
              >
                {``}
                {`${
                  areAllServicesSelected || selectedServices?.length === 100
                    ? `Deselect All`
                    : serviceList?.length > 100
                    ? `Select 100`
                    : `Select All ${serviceList?.length}`
                }  services ?`}
              </span>
              <input
                ref={allSelectRef}
                id="select-service"
                name="select-service"
                type="checkbox"
                className="hidden h-4 w-4 border-gray-300 text-pink-600 focus:ring-0 cursor-pointer"
                checked={areAllServicesSelected}
                onChange={(e) => {
                  const newValue = e.target.checked ? "yes" : "no";
                  setAreAllServicesSelected(e.target.checked);
                  if (serviceList?.length > 100) {
                    setSelectedServices([
                      ...updateSelectedServices(serviceList, selectedServices),
                    ]);
                    handleSelectAllServicePost(
                      categoryId,
                      areAllServicesSelected || selectedServices?.length === 100
                        ? false
                        : e.target.checked,
                      false,
                      () => {
                        handleServiceList();
                      },
                      [...updateSelectedServices(serviceList, selectedServices)]
                    );
                  } else {
                    setSelectedServices([]);
                    handleSelectAllServicePost(
                      categoryId,
                      e.target.checked,
                      true,
                      () => {
                        handleServiceList();
                      },
                      selectedServices
                    );
                  }
                }}
              />
            </div>
            {isSelectedServicesChanges && (
              <button
                type="button"
                className="ml-2 text-sm lg:text-base text-blue-500 underline cursor-pointer"
                onClick={() => {
                  handleSelectAllServicePost(
                    categoryId,
                    isValidArray(selectedServices) ? true : false,
                    isValidArray(selectedServices) ? false : true,
                    () => {
                      handleServiceList(), setIsSelectedServicesChanged(false);
                    },
                    selectedServices
                  );
                }}
              >
                {`Save Selection`}
              </button>
            )}
          </div>
        )}

        <div className="w-full min-w-xl border-2 border-gray-400 bg-white flex">
          <div className="w-full">
            <div className="p-0.5">
              <DndProvider backend={HTML5Backend}>
                <div
                  className={classNames(
                    "h-[65vh] overflow-y-auto flex flex-col gap-1 scroll-smooth",
                    {
                      "opacity-60": serviceListLoading,
                    }
                  )}
                >
                  {serviceListLoading ? (
                    <div
                      role="status"
                      className="h-[calc(100vh-365px)] w-full space-y-4 rounded animate-pulse"
                    >
                      <div className="w-full">
                        {[...Array(4)].map((_, index) => (
                          <div key={index} className="h-[122px]">
                            <div className="h-full bg-gray-300 m-2"></div>
                          </div>
                        ))}
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : isValidArray(serviceList) ? (
                    <div className="w-full h-[calc(100vh-365px)]">
                      {serviceList?.map((service, itemIndex) => (
                        <Services
                          key={service?.id}
                          index={itemIndex}
                          serviceData={service}
                          moveCard={moveCard}
                          dropCard={dropCard}
                          handleServiceList={handleServiceList}
                          setIsSectionModalOpen={setIsSectionModalOpen}
                          setServiceId={setServiceId}
                          setSelectedServices={setSelectedServices}
                          selectedServices={selectedServices}
                          areAllServicesSelected={areAllServicesSelected}
                          setAreAllServicesSelected={setAreAllServicesSelected}
                          setIsSelectedServicesChanged={
                            setIsSelectedServicesChanged
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="h-full bg-gray-100 flex justify-center items-center font-bold text-gray-800">
                      <button
                        className="text-pink-600 hover:text-pink-700 font-semibold mt-5 w-15 flex justify-center items-center gap-1"
                        onClick={() => setIsSectionModalOpen(true)}
                      >
                        <PlusIcon className="w-5 h-5" />

                        <span className="text-sm font-bold">Add Services</span>
                      </button>
                    </div>
                  )}
                </div>
              </DndProvider>
            </div>
          </div>
        </div>
        {isSectionModalOpen && (
          <ServiceModal
            isModalOpen={isSectionModalOpen}
            setModalOpen={setIsSectionModalOpen}
            categoryId={categoryId}
            serviceId={serviceId}
            setServiceId={setServiceId}
            handleAction={handleServiceList}
            currentCompanyId={currentCompanyId}
            currentLocationId={currentLocationId}
            setSectionIdForEdit={setSectionIdForEdit}
            sectionBasicDetails={sectionBasicDetails}
            setSectionBasicDetails={setSectionBasicDetails}
            isCompanyActive={isCompanyActive}
            sectionIdForEdit={sectionIdForEdit}
          />
        )}
        {isConfirmationModalOpen && (
          <ConfirmationModal
            isModalOpen={isConfirmationModalOpen}
            setModalOpen={setIsConfirmationModalOpen}
            handleAction={handleSetCategoryPrimary}
            isLoading={setCategoryPrimaryloading}
            message={"Do you want to set this category to primary ?"}
          />
        )}
      </div>
    </>
  );
}
