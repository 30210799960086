import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { isValidArray } from "module/util";
import { Fragment } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CustomDropDown = ({
  filterDropDown = [],
  selectedLocation = {},
  setSelectedLocation = () => {},
  // createLoactionStatusBadge = () => {},
  setButtonDisable = () => {},
  setSelectLoader = () => {},
  locationListData = {},
}) => {
  const createLoactionStatusBadge = (text, address) => {
    const parts = text?.split("|")?.map((part) => part.trim());
    let statusBadge = "";
    if (parts.length === 2) {
      switch (parts[1]) {
        case "Complete Ownership":
          statusBadge = `bg-green-500`;
          break;
        case "No Ownership":
          statusBadge = `bg-red-800`;
          break;
        case "Need Verification":
          statusBadge = `bg-amber-400`;
          break;
        case "Location Under Review":
          statusBadge = `bg-yellow-400`;
          break;
        case "Unspecified":
          statusBadge = `bg-orange-400`;
          break;
        case "Suspended":
          statusBadge = `bg-red-500`;
          break;
        case "Disabled":
          statusBadge = `bg-gray-400`;
          break;
      }
      return (
        <div className="flex flex-col justify-start items-start">
          <div className="w-full flex justify-start items-center gap-2">
            <span className="font-semibold">{parts[0]}</span>
            <p
              className={`text-white ${statusBadge} text-[12px] font-semibold me-2 px-2 py-0.5 rounded-md`}
            >
              {parts[1]}
            </p>
          </div>
          <div className="text-[12px] font-normal">{address}</div>
        </div>
      );
    } else if (parts.length === 1) {
      return parts[0];
    } else {
      return "Select Locations";
    }
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left w-full">
        <Menu.Button
          className={classNames(
            "inline-flex w-full justify-between items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
            {
              "shadow-lg hover:cursor-pointer":
                isValidArray(filterDropDown) && filterDropDown?.length > 1,
              "hover:cursor-default":
                !isValidArray(filterDropDown) || filterDropDown?.length <= 1,
            }
          )}
        >
          {createLoactionStatusBadge(
            selectedLocation?.name,
            selectedLocation?.address
          )
            ? createLoactionStatusBadge(
                selectedLocation?.name,
                selectedLocation?.address
              )
            : selectedLocation?.name ?? ""}
          <ChevronDownIcon
            className="-mr-1 h-6 w-6 text-gray-700"
            aria-hidden="true"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10  mt-1 max-h-[300px] w-full flex flex-col overflow-auto  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
            <div className="py-1">
              {isValidArray(filterDropDown) &&
                filterDropDown?.map((selectData, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <span
                        onClick={() => {
                          const data = {
                            name: selectData?.name,
                            id: selectData?.id,
                            category: selectData?.category,
                            type: selectData?.type,
                            can_add_menu: selectData?.can_add_menu,
                            can_modify_service_list:
                              selectData?.can_modify_service_list,
                            address: selectData?.address,
                            region_code: selectData?.region_code,
                            language_code: selectData?.language_code,
                          };
                          setSelectedLocation(data);
                          if (
                            selectData?.name == locationListData?.location?.name
                          ) {
                            setButtonDisable(true);
                          } else {
                            setButtonDisable(false);
                          }
                          setSelectLoader && setSelectLoader(true);
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm hover:cursor-pointer"
                        )}
                      >
                        {createLoactionStatusBadge(
                          selectData?.name,
                          selectData?.address
                        )
                          ? createLoactionStatusBadge(
                              selectData?.name,
                              selectData?.address
                            )
                          : selectData?.name}
                        <span></span>
                      </span>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default CustomDropDown;
