import {
  BoldIcon,
  FaceSmileIcon,
  ItalicIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import SpinnerButton from "components/common/fields/SpinnerButton";
import PostImageModal from "components/marketing/posts/PostImageModal";
import { ErrorMessage } from "formik";
import { getCurrentLocation, isValidArray } from "module/util";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill-emoji/dist/quill-emoji.css";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { generateAIContent } from "redux/post/generateAIContentSlice";
import { generateServicePostContent } from "redux/post/service/generateServicePostContentSlice";

export default function PostContentEditor({
  value = "",
  handleOnChange = () => {},
  handleImageChange = () => {},
  handleBlur = () => {},
  itemIdArray = [],
  postImages = [],
  itemImageArray = [],
  setItemImageArray = () => {},
  postDetails = {},
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const postCampaignType = searchParams.get("type")
    ? searchParams.get("type")
    : "";
  const [isApiResponse, setIsApiResponse] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [editorValue, setEditorValue] = useState(value);
  // const isEditorValueValid = /^(?!$|<p><br><\/p>).+/.test(editorValue);
  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();
  const [activeButton, setActiveButton] = useState({
    bold: false,
    italic: false,
  });
  const boldRef = useRef();
  const italicRef = useRef();
  const emojiRef = useRef();

  const aiGeneratedPostLoading = useSelector(
    (state) => state?.generateAIContent?.loading ?? false
  );
  const aiGeneratedServicePostLoading = useSelector(
    (state) => state?.generateServicePostContent?.loading ?? false
  );

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  const handleEditorChange = (value) => {
    const isEditorValueValid = /^(?!$|<p><br><\/p>).+/.test(value);
    if (!isApiResponse) {
      setEditorValue(value);
      if (isEditorValueValid) {
        handleOnChange(value);
      } else {
        handleOnChange("");
      }
    }
  };

  const handleGetAIResponse = () => {
    const formData = {
      location_id: currentLocationId,
      ...(google_business_type === "Restaurant"
        ? { item_id: itemIdArray }
        : { service_id: itemIdArray }),
    };
    setIsApiResponse(true);
    const action =
      google_business_type === "Restaurant"
        ? generateAIContent
        : generateServicePostContent;

    dispatch(action(formData)).then((data) => {
      const response = data?.payload?.generatedContent ?? [];
      const aiPostContent = response[0]?.generatedContent || "";
      setEditorValue(aiPostContent);
      handleOnChange(aiPostContent);
      setIsApiResponse(false);
    });
  };

  const EditorButton = ({ icon, active, onClick }) => {
    return (
      <button
        type="button"
        className={classNames("post-editor-btn font-bold ", {
          "!border-2 ": active,
        })}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {icon}
      </button>
    );
  };

  const toggleActiveButton = (type) => {
    setActiveButton((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  return (
    <>
      <style>
        {`
          .ql-editor {
            font-size: 15px; 
            line-height: 1.3rem; 
            color: rgb(17 24 39 / var(--tw-text-opacity));
            min-height: 5rem;
          }
          .ql-container {
            display: flex; 
            flex-direction: column-reverse;
          }
          #tab-panel{
            gap: 8px;
            justify-content: start !important;
          }
          .bem{
            font-size: 20px;
          }
        `}
      </style>

      <div className="editor-container">
        <ReactQuill
          className="custom-input-field"
          value={editorValue}
          onChange={handleEditorChange}
          onBlur={handleBlur}
          theme="snow"
          modules={{
            toolbar: { container: "#toolbar" },
            "emoji-toolbar": true,
            "emoji-shortname": true,
          }}
          placeholder={
            aiGeneratedPostLoading
              ? "Generating Content..."
              : "Write Post Content"
          }
        />
        <div id="toolbar" hidden>
          <button ref={boldRef} type="button" className="ql-bold">
            Bold
          </button>
          <button ref={italicRef} type="button" className="mx-2 ql-italic">
            Italic
          </button>
          <button ref={emojiRef} type="button" className="mx-2 ql-emoji">
            Emoji
          </button>
        </div>
      </div>
      <ErrorMessage
        name={`writePost.0.description`}
        component="div"
        className="field-error text-red-500"
      />
      <div className="w-full flex gap-2 mt-2">
        <EditorButton
          icon={<BoldIcon className="w-5 h-5" />}
          onClick={() => {
            boldRef?.current?.click();
            toggleActiveButton("bold");
          }}
          active={activeButton?.bold}
        />

        <EditorButton
          icon={<ItalicIcon className="w-5 h-5" />}
          onClick={() => {
            italicRef?.current?.click();
            toggleActiveButton("italic");
          }}
          active={activeButton?.italic}
        />

        <EditorButton
          icon={<FaceSmileIcon className="w-6 h-6" />}
          onClick={() => {
            emojiRef?.current?.click();
          }}
        />

        {postCampaignType == "single" && (
          <>
            <button
              type="button"
              className={`post-editor-btn relative`}
              onClick={(e) => {
                e.preventDefault();
                setIsImageModalOpen(true);
              }}
            >
              <PhotoIcon className="w-6 h-6" />
              {isValidArray(itemIdArray) && (
                <div className="h-3 w-3 rounded-xl absolute -top-[5px] -right-[5px] border-green-600 bg-green-600 bg-opacity-100"></div>
              )}
            </button>
            {isImageModalOpen && (
              <PostImageModal
                isModalOpen={isImageModalOpen}
                setModalOpen={setIsImageModalOpen}
                itemImageArray={itemImageArray}
                setItemImageArray={setItemImageArray}
                handleImageChange={handleImageChange}
                postImages={postImages}
                postDetails={postDetails}
              />
            )}
          </>
        )}
        <div className="relative">
          {aiGeneratedPostLoading || aiGeneratedServicePostLoading ? (
            <>
              <button type="button" className="post-editor-btn !px-3" disabled>
                <PulseLoader
                  color="#ec4899"
                  size={8}
                  className="h-[45px] flex justify-center items-center"
                />
              </button>
            </>
          ) : (
            <>
              <SpinnerButton
                title="AI"
                className="post-editor-btn"
                action={() => {
                  handleGetAIResponse();
                }}
                isDisable={!isValidArray(itemIdArray)}
              />
              {isValidArray(itemIdArray) && (
                <div className="h-3 w-3 rounded-xl absolute -top-[5px] -right-[5px] border-green-600 bg-green-600 bg-opacity-100"></div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
