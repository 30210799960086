import deleteIcon from "assets/images/deleteIcon.svg";
import editIcon from "assets/images/editIcon.svg";
import eyeIcon from "assets/images/eyeIcon.svg";
import classNames from "classnames";
import ContactUsForm from "components/campaigns/ContactUsForm";
import ZapCodeModal from "components/campaigns/ZapCodeModal";
import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import PostMain from "components/posts/PostMain";
import { campaignTypeForList } from "module/data";
import {
  changeDateFormat,
  convertStringToObj,
  createFirstLetterCapital,
  getCompanyStatus,
  getCurrentLocation,
  getUserData,
  isCompanyUser,
  isCompanyUserLoggedIn,
  isValidObject,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCompanyAccountDetail } from "redux/account/getCompanyAccountDetailSlice";
import { changeCampaignStatus } from "redux/campaign/changeCampaignStatusSlice";
import { getCampaignList } from "redux/campaign/getCampaignListSlice";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const CampaingsSub = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentCampaignsType = searchParams.get("type");
  const isCompanyActive = getCompanyStatus() === "active";
  const currentUserData = getUserData() ? JSON.parse(getUserData()) : "";

  const campaignsType = [
    "direct_mail",
    "print_ad",
    "digital_offer",
    "door_hanger",
    "email",
    "sms",
    // "post",
  ].includes(currentCampaignsType)
    ? currentCampaignsType
    : currentUserData?.show_direct_mail == "1"
    ? "direct_mail"
    : "digital_offer";

  const campaignsTabsArray = [
    ...(currentUserData?.show_direct_mail == "1"
      ? [
          {
            name: "Direct Mail",
            value: "direct_mail",
            href: "#",
            current: false,
          },
        ]
      : []),
    { name: "Digital", value: "digital_offer", href: "#", current: false },
    { name: "Print Ad", value: "print_ad", href: "#", current: true },
    ...(currentUserData?.show_door_hangar == "1"
      ? [
          {
            name: "Door Hanger",
            value: "door_hanger",
            href: "#",
            current: true,
          },
        ]
      : []),

    { name: "SMS", value: "sms", href: "#", current: false },
    { name: "Email", value: "email", href: "#", current: false },
    // { name: "Post", value: "post", href: "#", current: false },
  ];

  const [selectedCampaignsType, setCampaignsType] = useState(campaignsType);
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isZapCodeModalOpen, setZapCodeModalOpen] = useState(false);
  const [zapCode, setZapCode] = useState("");
  const [campaignsTabs, setCampaignsTabs] = useState(campaignsTabsArray);

  const campaignListLoading = useSelector(
    (state) => state?.getCampaignList?.loading ?? false,
    shallowEqual
  );
  const getCampaignListData = useSelector(
    (state) => state?.getCampaignList?.data ?? [],
    shallowEqual
  );
  const changeCampaignStatusLoading = useSelector(
    (state) => state?.changeCampaignStatus?.loading ?? false,
    shallowEqual
  );
  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const { id: currentLocationId } = getCurrentLocation();

  const totalRecords = getCampaignListData?.total;
  const currentPerPage = getCampaignListData?.per_page ?? showPerPage;

  const handleStatusChange = () => {
    dispatch(
      changeCampaignStatus({ campaignId: selectedId, status: selectedStatus })
    ).then((data) => {
      if (data?.payload?.code == 200) {
        const message =
          selectedStatus === "archived"
            ? "Campaign deleted successfully"
            : "Campaign activated successfully";
        setModalOpen(false);
        showSuccessMsg(message);
        handleCampaignList(searchInput);
      } else {
        setModalOpen(false);
        handleServerValidation(data);
      }
    });
  };

  const handleCampaignList = (search) => {
    const { id: currentLocationId } = getCurrentLocation();
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
      location_id: currentLocationId,
      type: selectedCampaignsType,
    };
    if (currentLocationId) {
      dispatch(getCampaignList(sortObj));
    }
  };

  const columns = [
    {
      Header: "Name",
      accessor: "campaign_name",
      Cell: ({ cell }) =>
        cell.row.values?.campaign_status === "pending" &&
        !isCompanyUserLoggedIn() &&
        selectedCampaignsType !== "direct_mail" ? (
          <span
            className={`hover:underline hover:cursor-pointer text-gray-900 ${
              !isCompanyActive && `pointer-events-none`
            }`}
            onClick={() =>
              navigate(
                `/campaigns/edit/${cell.row.values?.id}/${
                  cell.row.values?.campaign_type ?? "direct_mail"
                }`
              )
            }
          >
            {cell.row.values.campaign_name}
          </span>
        ) : (
          <span className="text-gray-900">{cell.row.values.campaign_name}</span>
        ),
    },
    {
      Header: "Start",
      accessor: "campaign_start_date",
      Cell: ({ cell }) => (
        <span>
          {changeDateFormat(cell?.row?.values?.campaign_start_date ?? "")}
        </span>
      ),
    },
    {
      Header: "End",
      accessor: "campaign_end_date",
      Cell: ({ cell }) => {
        const endDate = cell?.row?.values?.campaign_end_date ?? "";

        return (
          <span>{!!endDate ? changeDateFormat(endDate) : `No Expiration`}</span>
        );
      },
    },
    {
      Header: "Type",
      accessor: "campaign_type",
      Cell: ({ cell }) => (
        <span className="">
          {campaignTypeForList[cell?.row?.values?.campaign_type]}
        </span>
      ),
    },
    {
      Header: "Status",
      accessor: "campaign_status",
      Cell: ({ cell }) => (
        <span
          className={classNames("p-1 rounded font-semibold text-white", {
            "bg-green-500 ": cell?.row?.values?.campaign_status === "active",
            "bg-red-500 -black":
              cell?.row?.values?.campaign_status !== "active",
          })}
        >
          {createFirstLetterCapital(cell?.row?.values?.campaign_status)}
        </span>
      ),
    },
    ...(selectedCampaignsType === "direct_mail"
      ? [
          {
            Header: "List",
            accessor: "lead_list",
          },
        ]
      : []),
    ...(selectedCampaignsType !== "print_ad"
      ? [
          {
            Header: "Total",
            accessor: "campaign_metrics",
            Cell: ({ cell }) => (
              <span>
                {cell?.row?.values?.campaign_metrics
                  ? convertStringToObj(cell?.row?.values?.campaign_metrics)
                      ?.list_size
                  : 0}
              </span>
            ),
          },
        ]
      : []),

    ...(selectedCampaignsType === "digital_offer"
      ? [
          {
            Header: "Zap Code",
            accessor: "zap_code",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <>
                {isValidObject(cell?.row?.original?.digital_email_templates) &&
                  isValidObject(cell?.row?.original?.coupon_details) && (
                    <span
                      className={`w-5 cursor-pointer hover:text-gray-900 ${
                        !isCompanyActive ? `pointer-events-none` : `underline`
                      }`}
                      title="Show Full Zap Code"
                      onClick={() => {
                        setZapCode(cell?.row?.values?.zap_code ?? "");
                        setZapCodeModalOpen(true);
                      }}
                    >
                      Show Zap Code
                    </span>
                  )}
              </>
            ),
          },
        ]
      : []),
    ...(isCompanyUser() && isCompanyActive
      ? [
          {
            width: 100,
            Header: "Actions",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell }) => {
              const isShowActivate =
                (isValidObject(cell?.row?.original?.digital_email_templates) &&
                  isValidObject(cell?.row?.original?.coupon_details)) ||
                ["direct_mail", "print_ad"]?.includes(
                  cell.row.original?.campaign_type
                );
              return (
                <div className="flex items-center gap-x-2 ">
                  {cell.row.values?.campaign_status === "pending" && (
                    <>
                      {isShowActivate && (
                        <span
                          className="hover:underline hover:cursor-pointer"
                          onClick={() => {
                            setSelectedId(cell.row.values?.id);
                            setSelectedStatus("active");
                            const activateMsg =
                              selectedCampaignsType === "digital_offer" ? (
                                <span>
                                  Are you sure you want to activate the
                                  campaign?
                                </span>
                              ) : (
                                <span>
                                  When you activate the campaign, you can no
                                  longer edit the campaign details. Make sure
                                  you are ready to go live.
                                </span>
                              );
                            setConfirmMessage(activateMsg);
                            setModalOpen(true);
                          }}
                        >
                          Activate
                        </span>
                      )}
                      {selectedCampaignsType !== "direct_mail" && (
                        <img
                          alt="Edit"
                          src={editIcon}
                          className="h-5 w-5 hover:cursor-pointer"
                          title="Edit"
                          onClick={() =>
                            navigate(
                              `/campaigns/edit/${cell.row.values?.id}/${
                                cell.row.values?.campaign_type ?? "direct_mail"
                              }`,
                              {
                                state: currentCampaignsType,
                              }
                            )
                          }
                        />
                      )}
                    </>
                  )}
                  {cell.row.values?.campaign_status === "active" &&
                    selectedCampaignsType === "digital_offer" && (
                      <img
                        alt="Edit"
                        src={editIcon}
                        className="h-5 w-5 hover:cursor-pointer"
                        title="Edit"
                        onClick={() =>
                          navigate(
                            `/campaigns/edit/${cell.row.values?.id}/${
                              cell.row.values?.campaign_type ?? "direct_mail"
                            }`
                          )
                        }
                      />
                    )}
                  {cell.row.values?.campaign_status === "active" && (
                    <>
                      <img
                        alt="view"
                        src={eyeIcon}
                        className="h-6 w-6 hover:cursor-pointer"
                        title="View"
                        onClick={() => {
                          navigate(
                            `/campaigns/details/${cell.row.values?.id}`,
                            { state: selectedCampaignsType }
                          );
                        }}
                      />
                    </>
                  )}
                  <img
                    alt="archive"
                    src={deleteIcon}
                    title="Delete"
                    className="h-5 w-5 hover:cursor-pointer"
                    onClick={() => {
                      setSelectedId(cell.row.values?.id);
                      setSelectedStatus("archived");
                      setConfirmMessage("You want to delete this campaign?");
                      setModalOpen(true);
                    }}
                  />
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const globalSearch = (search) => {
    handleCampaignList(search);
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Campaigns List",
          href: "/campaigns/list",
          current: false,
        },
        {
          name: breadCrumbsTitle[selectedCampaignsType],
          href: "/campaigns/list",
          current: false,
        },
      ])
    );

    handleCampaignList(searchInput);
  }, [
    sortOrder,
    currentPage,
    perPage,
    currentLocationId,
    selectedCampaignsType,
  ]);
  const breadCrumbsTitle = {
    direct_mail: "Direct Mail",
    print_ad: "Print Ad",
    digital_offer: "Digital",
    door_hanger: "Door Hanger",
    email: "Email",
    sms: "SMS",
    // post: "Posts",
  };

  useEffect(() => {
    if (isValidObject(currentUserData)) {
      dispatch(getCompanyAccountDetail(currentUserData?.company_id)).then(
        (data) => {
          if (isValidObject(data?.payload)) {
            const isDirectMailShow =
              data?.payload?.show_direct_mail == "1" ? true : false;
            const isDoorHangerShow =
              data?.payload?.show_door_hangar == "1" ? true : false;

            if (!isDirectMailShow || !isDoorHangerShow) {
              let finalTabsArray = campaignsTabsArray;
              if (!isDirectMailShow) {
                // setCampaignsType("digital_offer");
                // navigate(`/campaigns/list`);
                finalTabsArray = finalTabsArray?.filter(
                  (item) => item.value !== "direct_mail"
                );
              }
              if (!isDoorHangerShow) {
                finalTabsArray = finalTabsArray?.filter(
                  (item) => item.value !== "door_hanger"
                );
              }

              setCampaignsTabs(finalTabsArray);
            }
          }
        }
      );
    }
  }, []);

  return (
    <div>
      <ShowPageTitle title="campaings" />
      <div className="flex ">
        <div className="border-b border-collapse border-gray-300 w-full">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {campaignsTabs.map((tab) => (
              <span
                key={tab.name}
                onClick={() => {
                  setCampaignsType(tab?.value);
                  navigate(`/campaigns/list?type=${tab?.value}`);
                }}
                className={classNames(
                  " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                  {
                    "border-pink-600 hover:border-pink-600 text-gray-800":
                      tab?.value === selectedCampaignsType,
                    "border-transparent": tab?.value !== selectedCampaignsType,
                  }
                )}
                aria-current={
                  tab.value === selectedCampaignsType ? "page" : undefined
                }
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex  justify-end items-center mt-2">
        {isCompanyUser() &&
          isCompanyActive &&
          !["sms", "email", "door_hanger", "post"].includes(
            selectedCampaignsType
          ) && (
            <button
              type="button"
              onClick={() =>
                navigate(`/campaigns/add/${selectedCampaignsType}`, {
                  state: selectedCampaignsType,
                })
              }
              className="btn-pink"
            >
              New Campaign
            </button>
          )}
        {!["sms", "email", "door_hanger", "post"].includes(
          selectedCampaignsType
        ) && (
          <div className="ml-2 flex shadow-sm">
            <SearchInput
              globalSearch={globalSearch}
              setSearchInput={setSearchInput}
              searchInput={searchInput}
              companyStatus={selectedCampaignsType}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col mt-2">
        {["sms", "email", "door_hanger"].includes(selectedCampaignsType) ? (
          <div className=" mt-4">
            <span className="text-red-500 text-xl  flex justify-center">
              {campaignTypeForList[selectedCampaignsType]} campaign is coming
              soon...
            </span>
            <ContactUsForm
              selectedCampaignsType={selectedCampaignsType}
              currentLocationId={currentLocationId}
            />
          </div>
        ) : (
          <CustomTable
            data={getCampaignListData?.data ?? []}
            isLoading={campaignListLoading}
            columns={columns}
            defaultSortIndexColumn={sortIndex}
            defaultOrder={sortOrder}
            setSortIndex={setSortIndex}
            setSortOrder={setSortOrder}
            totalRecords={totalRecords}
            perPage={perPage ? perPage : currentPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPerPage={setPerPage}
            noDataMsg={"Create Campaign."}
          />
        )}
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleStatusChange}
          isLoading={changeCampaignStatusLoading}
          message={confirmMessage}
        />
      )}
      {isZapCodeModalOpen && (
        <ZapCodeModal
          isModalOpen={isZapCodeModalOpen}
          setModalOpen={setZapCodeModalOpen}
          handleAction={() => {}}
          isLoading={false}
          zapCode={zapCode}
        />
      )}
    </div>
  );
};

const CampaingsList = () => {
  return <CampaingsSub />;
};

export default CampaingsList;
