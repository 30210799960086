import datePicker from "assets/images/datePicker.svg";
import classNames from "classnames";
import { convertDateTimeType, getDateFormatLocal } from "module/util";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";

export default function CustomDateTimeInput({
  value = "",
  time = "",
  period = "",
  showTimeInput = false,
  handleChange = () => {},
  handleBlur = () => {},
  placeholder = "Select Start Date",
  isDisabled = false,
}) {
  const [openCalender, setOpenCalender] = useState(false);
  const defaultDateFormat = getDateFormatLocal();
  const datePickerFormat = showTimeInput
    ? `${defaultDateFormat}, h:mm aa`
    : defaultDateFormat;

  const getDateTimeObject = () => {
    if (!value || !showTimeInput) return value;

    const [hours, minutes] = time.split(":");
    const dateObj = new Date(value);

    // Adjust for AM/PM
    let adjustedHours = parseInt(hours, 10);
    if (period.toLowerCase() === "pm" && adjustedHours < 12) {
      adjustedHours += 12;
    } else if (period.toLowerCase() === "am" && adjustedHours === 12) {
      adjustedHours = 0;
    }

    dateObj.setHours(adjustedHours, parseInt(minutes, 10));

    return dateObj;
  };
  const initialDate = getDateTimeObject();

  const handleDateTimeChange = (value) => {
    const timeData = convertDateTimeType(value ?? "");
    const { date, time, period } = timeData;

    handleChange(date, time, period);
  };

  return (
    <div
      className={classNames("relative w-full z-9", {
        "opacity-50": false,
      })}
    >
      <ReactDatePicker
        selected={initialDate ?? ""}
        onChange={handleDateTimeChange}
        minDate={new Date()}
        showTimeInput={showTimeInput}
        timeInputLabel="Time:"
        dateFormat={datePickerFormat}
        open={openCalender}
        onClickOutside={() => {
          openCalender && handleBlur(), setOpenCalender(false);
        }}
        onInputClick={() => setOpenCalender(true)}
        onBlur={handleBlur}
        className={"input-field"}
        placeholderText={placeholder}
        disabled={isDisabled}
      />
      <button
        type="button"
        className="w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-3"
        onClick={() => setOpenCalender(true)}
        disabled={isDisabled}
      >
        <img alt="Date picker" src={datePicker} className="w-full h-full" />
      </button>
    </div>
  );
}
