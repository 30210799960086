import {
  ClipboardDocumentIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ConfirmationModal from "components/common/ConfirmationModal";
import CustomTable from "components/common/fields/CustomTable";
import SpinnerButton from "components/common/fields/SpinnerButton";
import RatingField from "components/contacts/customer/RatingField";
import copy from "copy-to-clipboard";
import { Field, Form, Formik } from "formik";
import {
  checkParagraphWordsLimit,
  getCompanyStatus,
  getCurrentLocation,
  getUserData,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation, showError } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStarTemplate } from "redux/reputation/addStarTemplateSlice";
import { deleteStarTemplate } from "redux/reputation/deleteStarTemplateSlice";
import { getStarReview } from "redux/reputation/getStarReviewSlice";
import { getStarTemplateDetail } from "redux/reputation/getStarTemplateDetailSlice";
import { updateStarTemplate } from "redux/reputation/updateStarTemplateSlice";
import * as yup from "yup";

export default function ReputationSetting() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const selectStar = [
    { name: "5 Star", value: "5" },
    { name: "4 Star", value: "4" },
    { name: "3 Star", value: "3" },
    { name: "2 Star", value: "2" },
    { name: "1 Star", value: "1" },
  ];

  const companyId = getUserData() ? JSON.parse(getUserData()) : "";
  const { id: currentLocationId } = getCurrentLocation();
  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const first_name = "First_Name";

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );
  const getStarReviewTemplatesList = useSelector(
    (state) => state?.getStarReview?.data?.dataObj ?? []
  );
  const getStarReviewLoading = useSelector(
    (state) => state?.getStarReview?.loading ?? false
  );
  const addStarLoading = useSelector(
    (state) => state?.addStarTemplate?.loading ?? false
  );
  const templateData = useSelector(
    (state) => state?.getStarTemplateDetail?.data?.dataObj ?? {}
  );
  const updtaeStarLoading = useSelector(
    (state) => state?.updateStarTemplate?.loading ?? false
  );
  const deleteStarLoading = useSelector(
    (state) => state?.deleteStarTemplate?.loading ?? false
  );

  const initialValues = {
    rating: !!isEdit ? templateData?.rating || "" : "",
    rating_template: !!isEdit ? templateData?.rating_template || "" : "",
  };

  const columns = [
    {
      Header: "Star",
      accessor: "rating",
      disableSortBy: true,
      Cell: ({ cell }) => {
        return (
          <div className={isEdit === cell.row.values?.id && `opacity-50`}>
            <span className="my-1 list-none flex justify-start  p-0">
              <RatingField rating={cell?.row?.values?.rating ?? 0} />
            </span>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "rating_template",
      disableSortBy: true,
      Cell: ({ cell }) => {
        return (
          <div className={isEdit === cell.row.values?.id && `opacity-50`}>
            {cell?.row?.values?.rating_template}
          </div>
        );
      },
    },
    ...(isCompanyActive
      ? [
          {
            Header: "Action",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className={`flex items-center gap-x-[4px]`}>
                <button
                  className={`hover:text-gray-900 hover:underline ${
                    isEdit == cell.row.values?.id && "disabled-btn"
                  }`}
                  onClick={() => {
                    handleGetTemplateDetail(cell.row.values?.id);
                  }}
                  disabled={isEdit == cell.row.values?.id}
                >
                  <PencilSquareIcon title={"Edit"} className="w-[1.3rem]" />
                </button>
                <button
                  className={`hover:text-gray-900 hover:underline ${
                    isEdit == cell.row.values?.id && "disabled-btn"
                  }`}
                  onClick={() => {
                    setIsModalOpen(true);
                    setDeleteId(cell.row.values?.id);
                  }}
                  disabled={isEdit == cell.row.values?.id}
                >
                  <TrashIcon title={"Delete"} className="w-[1.3rem]" />
                </button>
              </div>
            ),
          },
        ]
      : []),
  ];

  const handleTemplateList = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: companyId?.company_id,
    };
    dispatch(getStarReview(formData));
  };

  const match = getStarReviewTemplatesList?.map((template) => template?.rating);

  const handleSubmit = (values, { resetForm }) => {
    const formData = {
      location_id: currentLocationId,
      company_id: companyId?.company_id,
      rating: values?.rating,
      rating_template: values?.rating_template,
    };
    if (!!isEdit) {
      dispatch(updateStarTemplate(formData)).then((data) => {
        if (data?.payload?.code == 200) {
          showSuccessMsg("Review template updated successfully.");
          handleTemplateList();
          setIsEdit("");
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      if (match?.includes(values?.rating)) {
        showErrorMsg(`${values?.rating} star template already taken.`);
      } else {
        dispatch(addStarTemplate(formData)).then((data) => {
          if (data?.payload?.code === 201) {
            resetForm();
            showSuccessMsg("Review template added successfully.");
            handleTemplateList();
          } else {
            handleServerValidation(data);
          }
        });
      }
    }
  };

  const handleGetTemplateDetail = (templateId) => {
    const formData = { id: templateId ?? "" };
    dispatch(getStarTemplateDetail(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        setIsEdit(templateId);
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleDelete = () => {
    const templateId = deleteId ?? "";
    dispatch(deleteStarTemplate(templateId)).then((data) => {
      if (data?.payload?.code == 200) {
        handleTemplateList();
        setIsModalOpen(false);
      } else {
        setIsModalOpen(false);
        handleServerValidation(data);
      }
    });
  };

  useEffect(() => {
    handleTemplateList();
  }, [currentLocationId]);

  return (
    <div className="mt-4 border gap-x-4 border-gray-200 p-4 bg-white">
      <h2 className="font-normal text-gray-900 text-lg">Review Template </h2>
      <div className="block 2xl:flex w-full mt-3 gap-4">
        <div className="w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={yup.object({
              rating: !!isEdit
                ? yup.string().required("Rating is required.")
                : yup
                    .string()
                    .required("Rating is required.")
                    .test(
                      "rating-taken",
                      "Rating already taken.",
                      function (value) {
                        return !match.includes(value);
                      }
                    ),
              rating_template: yup
                .string()
                .trim()
                .matches(/\S+/, "Description cannot be blank.")
                .required("Description is required.")
                .test(
                  "wordCount",
                  "Description cannot exceed 300 words.",
                  (value) => {
                    // Check only if there's a value
                    if (value) {
                      return !checkParagraphWordsLimit(value, 300);
                    }
                    return true; // Pass the test if the field is empty
                  }
                ),
            })}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, { resetForm })
            }
            enableReinitialize={true}
          >
            {({
              errors,
              touched,
              validateField,
              validateForm,
              handleChange,
              handleBlur,
              values,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="flex flex-start w-full gap-1">
                  <label
                    className="font-light leading-6 text-gray-900 w-[30%] md:w-[15.3%] xl:w-[11.5%] 2xl:w-[22.5%]"
                    htmlFor="tag"
                  >
                    Stars :
                  </label>
                  <div className="w-full">
                    <Field
                      as="select"
                      className="input-field"
                      name="rating"
                      value={values?.rating}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!!isEdit}
                    >
                      <option value="">Select Star</option>
                      {selectStar
                        // ?.filter((option) => !match?.includes(option?.value))
                        .map((starOption, index) => (
                          <option key={index} value={starOption?.value}>
                            {starOption?.name}
                          </option>
                        ))}
                    </Field>
                    {touched?.rating && showError(errors?.rating)}
                  </div>
                </div>

                <div className="flex flex-start w-full gap-1">
                  <label
                    className="font-light leading-6 text-gray-900 w-[30%] md:w-[15.3%] xl:w-[11.5%] 2xl:w-[22.5%]"
                    htmlFor="tag"
                  >
                    Review :
                  </label>
                  <div className="w-full">
                    <div className="relative mt-9 top-2 transition-all duration-600 ">
                      {isCompanyActive && (
                        <>
                          <div
                            className="absolute top-[-2.5rem] flex gap-2 border cursor-pointer hover:bg-gray-200 text-[13px] font-light border-gray-300 p-1"
                            title="Click to copy"
                            id="copyName"
                            onClick={() => {
                              const isCopied = copy(`%%${first_name}%%`);
                              if (isCopied) {
                                showSuccessMsg("Copied to clipboard!");
                              }
                            }}
                          >
                            <p className=" ">{`%%${first_name}%%`}</p>
                            <ClipboardDocumentIcon className="h-6 w-6" />
                          </div>

                          <Field
                            as="textarea"
                            rows="5"
                            name="rating_template"
                            className="input-field"
                            value={values?.rating_template}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Write Review"
                          />
                          {touched?.rating_template &&
                            showError(errors?.rating_template)}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {isCompanyActive && (
                  <div className="flex gap-2 mt-6">
                    <SpinnerButton
                      className="btn-pink w-20"
                      title={"Save"}
                      action={() => {}}
                      type={"submit"}
                      loading={addStarLoading || updtaeStarLoading}
                    />

                    <div>
                      <button
                        type="button"
                        className="btn btn-light-gray"
                        onClick={() => {
                          resetForm();
                          setIsEdit("");
                        }}
                        disabled={addStarLoading || updtaeStarLoading}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
        <div className="block 2xl:hidden w-full h-[1px] bg-gray-300 my-6"></div>

        <div className="w-full mt-5 md:mt-0 ">
          <div className="flex flex-col ">
            <CustomTable
              data={
                isValidArray(getStarReviewTemplatesList)
                  ? getStarReviewTemplatesList
                  : []
              }
              isLoading={getStarReviewLoading ?? false}
              columns={columns}
              defaultSortIndexColumn={""}
              colSpan={3}
            />
          </div>
          {isModalOpen && (
            <ConfirmationModal
              isModalOpen={isModalOpen}
              setModalOpen={setIsModalOpen}
              handleAction={handleDelete}
              isLoading={deleteStarLoading}
              message={"You want to delete this review template ?"}
            />
          )}
        </div>
      </div>
    </div>
  );
}
