import { PlusIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { isValidArray, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { reOrderMenu } from "redux/gmbLocation/reOrderMenuSlice";
import MenuItem from "./MenuItem";

export const ItemTypes = {
  CARD: "card",
};

export default function MenuList({
  menuListLoading = false,
  setHoverOnMenu = () => {},
  hoverOnMenu = false,
  setMenuId = () => {},
  setIsMenuModalOpen = () => {},
  handleDeleteMenu = () => {},
  deleteMenuLoading = false,
  google_business_type = "service",
  itemsList = [],
  setItemList = () => {},
}) {
  const dispatch = useDispatch();

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setItemList((prevItems) => {
      const updatedItems = [...prevItems];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);

      return updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));
    });
  }, []);

  const dropCard = useCallback(
    (dragIndex, hoverIndex) => {
      const updatedItems = [...itemsList];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);

      const finalData = updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      handleReOrderItem(finalData);
    },
    [itemsList]
  );

  const handleReOrderItem = (data) => {
    const formData = {
      data: data,
    };

    dispatch(reOrderMenu(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Items re-ordered successfully."
        );
        const finalData = data?.payload?.dataObj?.data ?? [];
        setItemList(finalData);
      } else {
        handleServerValidation(data);
      }
    });
  };

  return (
    <div className="w-full min-w-xl border border-gray-400 p-3 bg-white min-h-96">
      <DndProvider backend={HTML5Backend}>
        <div
          className={classNames("flex flex-wrap py-2 gap-8 my-10", {
            "grayscale opacity-60": menuListLoading,
          })}
        >
          {menuListLoading ? (
            <div
              role="status"
              className={`w-full space-y-4 rounded animate-pulse  mb-4`}
            >
              <div className="flex flex-wrap gap-4 ">
                {[...Array(7)]?.map((_, menuIndex) => {
                  return (
                    <div key={menuIndex} className="h-44 w-44">
                      <div className="h-full bg-gray-300"></div>
                    </div>
                  );
                })}
              </div>
              <span className="sr-only">Loading...</span>
            </div>
          ) : isValidArray(itemsList) ? (
            itemsList?.map((menu, index) => {
              return (
                <MenuItem
                  key={menu?.id}
                  menuData={menu}
                  index={index}
                  setHoverOnMenu={setHoverOnMenu}
                  hoverOnMenu={hoverOnMenu}
                  setMenuId={setMenuId}
                  setIsMenuModalOpen={setIsMenuModalOpen}
                  moveCard={moveCard}
                  dropCard={dropCard}
                  handleDeleteMenu={handleDeleteMenu}
                  deleteMenuLoading={deleteMenuLoading}
                  google_business_type={google_business_type}
                />
              );
            })
          ) : (
            <div className="flex justify-center items-center  w-full py-10">
              <button
                className="text-pink-600 hover:text-pink-700 font-semibold mt-5 w-15 flex  justify-center items-center gap-1"
                onClick={() => {
                  setMenuId("");
                  setIsMenuModalOpen(true);
                }}
              >
                <PlusIcon className="w-5 h-5" />

                <span className="text-sm font-bold">
                  Add{" "}
                  {google_business_type == "Restaurant" ? "Menu" : "Service"}
                </span>
              </button>
            </div>
          )}
        </div>
      </DndProvider>
    </div>
  );
}
