import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteApi, postApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const pauseCampaignPost = createAsyncThunk(
  "post/campaign-post-pause",
  async (id = 0, { rejectWithValue }) => {
    try {
      const response = await postApi(`api/pause-multiple-post/${id}`);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const pauseCampaignPostSlice = createSlice({
  name: "pauseCampaignPost",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(pauseCampaignPost.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(pauseCampaignPost.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(pauseCampaignPost.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default pauseCampaignPostSlice.reducer;
