import { CheckIcon } from "@heroicons/react/24/outline";
import deleteIcon from "assets/images/deleteBoldIcon.svg";
import imagePreview from "assets/images/dummyImage.svg";
import plusIcon from "assets/images/plusIcon.svg";
import classNames from "classnames";
import DropdownCommon from "components/common/fields/DropdownCommon";
import SpinnerButton from "components/common/fields/SpinnerButton";
import SwitchButton from "components/common/fields/SwitchButton";
import PostContentEditor from "components/marketing/posts/PostContentEditor";
import { PostLoading } from "components/marketing/posts/PostFormMain";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { callToActionOptions } from "module/data";
import {
  areArrayObjectValuesValid,
  getCurrentLocation,
  getDataLocalStorage,
  isValidArray,
  isValidObject,
  removeDataLocalStorage,
  setDataLocalStorage,
  showErrorMsg,
} from "module/util";
import {
  campaignPostDetailsForm,
  handleServerValidation,
} from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { getMenuList } from "redux/gmbLocation/getMenuListSlice";
import { getSectionList } from "redux/gmbLocation/getSectionListSlice";
import { addCampaignPost } from "redux/post/addCampaignPostSlice";
import { getCampaignPostDetails } from "redux/post/getCampaignPostDetailsSlice";
import { getMenuItemImageList } from "redux/post/getMenuItemImageListSlice";
import { updateCampaignPost } from "redux/post/updateCampaignPostSlice";

export default function CampaignPostForm({
  generatedPost = [],
  setGeneratedPost = () => {},
  setGeneratePostLoading = () => {},
  currentPostContentIndex = 0,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId") || "";
  const isEdit = !!campaignId ? campaignId : false;
  const { id: currentLocationId, location_url = "" } = getCurrentLocation();

  const [isPostGenerated, setIsPostGenerated] = useState(false);
  const [menuId, setMenuId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [sectionCount, setSectionCount] = useState(0);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [itemIdArray, setItemIdArray] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [ctaArray, setCTAArray] = useState([
    { cta_btn_text: "", cta_btn_url: location_url },
  ]);
  const [isImageRequired, setIsImageRequired] = useState(true);

  const campaignDetails = getDataLocalStorage("CAMPAIGN_POST_DATA") ?? [];
  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );
  const getPostDetailsLoading = useSelector(
    (state) => state?.getCampaignPostDetails?.loading ?? false
  );
  const addCampaignPostLoading = useSelector(
    (state) => state?.addCampaignPost?.loading ?? false
  );
  const menuList = useSelector(
    (state) => state?.getMenuList?.data?.dataObj?.data ?? []
  );
  const sectionList = useSelector(
    (state) => state?.getSectionList?.data?.dataObj?.data ?? []
  );
  const menuItemImageListLoading = useSelector(
    (state) => state?.getMenuItemImageList?.loading ?? false
  );

  const handleGetmenuList = () => {
    const formData = {
      location_id: currentLocationId,
    };
    dispatch(getMenuList(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const menus = data?.payload?.dataObj?.data ?? [];
        let menu_id = "";
        let sections_count = 0;
        if (isEdit || isValidArray(menus)) {
          menu_id = initialValues.menu_id || "";
          if (menu_id) {
            let menuIndex = menus.findIndex((x) => x.id === menu_id);
            sections_count = menus[menuIndex]?.sections_count || 0;
          }
        }
        setMenuId(menu_id);
        handleGetSectionList(menu_id);
        setSectionCount(sections_count);
      }
    });
  };

  const handleGetSectionList = (menu_id) => {
    const formData = {
      menu_id: menu_id,
      location_id: currentLocationId,
    };
    dispatch(getSectionList(formData));
  };

  const createPosts = (imageArray, buttonsArray) => {
    // Calculate the number of images and buttons
    const totalImages = imageArray?.length;
    const totalButtons = buttonsArray?.length;
    // Calculate the maximum times each button should be used
    const maxButtonUsage = Math.ceil(totalImages / totalButtons);

    let result = [];

    // Create a map to track button usage
    let buttonUsageMap = {};
    isValidArray(buttonsArray) &&
      buttonsArray?.forEach((button) => {
        buttonUsageMap[button.cta_btn_text] = 0;
      });

    // Assign images and buttons to the result array
    for (let i = 0; i < totalImages; i++) {
      let availableButtons =
        isValidArray(buttonsArray) &&
        buttonsArray?.filter(
          (button) => buttonUsageMap[button.cta_btn_text] < maxButtonUsage
        );

      // Randomly select a button from the available buttons
      let selectedButton =
        availableButtons[Math.floor(Math.random() * availableButtons.length)];

      // Increment the usage count for the selected button
      buttonUsageMap[selectedButton?.cta_btn_text]++;

      // Create a post object with the image and selected button
      const post = {
        post_id: isValidArray(imageArray) ? imageArray[i]?.post_id : 0,
        food_item_id: isValidArray(imageArray) ? imageArray[i].food_item_id : 0,
        post_image: isValidArray(imageArray)
          ? imageArray[i].post_image
          : imagePreview,
        description: isEdit
          ? generatedPost[i]?.description
          : isValidArray(imageArray)
          ? imageArray[i]?.description
          : "",
        cta_btn_text: !!selectedButton?.cta_btn_text
          ? selectedButton?.cta_btn_text
          : "",
        cta_btn_url: !!selectedButton?.cta_btn_url
          ? selectedButton?.cta_btn_url
          : "",
      };

      // Add the post object to the result array
      result.push(post);
    }
    if (isValidArray(result)) {
      setGeneratedPost(result);
      setIsPostGenerated(true);
    } else {
      setGeneratedPost([
        {
          food_item_id: 0,
          post_image: "",
          cta_btn_text: "",
          cta_btn_url: "",
        },
      ]);
      setIsPostGenerated(false);
    }

    setTimeout(() => {
      setGeneratePostLoading(false);
    }, 400);
    return result;
  };

  const getIdsImageFromMenuItems = (menuItemImageList) => {
    let ids = [];
    let imageArray = [];

    isValidArray(menuItemImageList) &&
      menuItemImageList?.map((item) => {
        const { food_item_id, images } = item;
        if (isValidArray(images)) {
          images.forEach((image) => {
            imageArray.push({ food_item_id: food_item_id, post_image: image });
          });
        }
        if (isValidArray(images)) {
          for (let i = 0; i < images.length; i++) {
            ids = [...ids, food_item_id];
          }
        }
      });
    setImageArray(imageArray);
    setItemIdArray(ids);
    createPosts(imageArray, ctaArray);

    return { ids: ids, images: imageArray };
  };

  const handleGetMenuItemImage = (image) => {
    const formData = {
      location_id: currentLocationId,
      menu_id: sectionId ? null : menuId,
      section_id: sectionId || null,
      image_required: image ?? true,
    };

    setGeneratePostLoading(true);
    dispatch(getMenuItemImageList(formData)).then((data) => {
      const menuList = data?.payload?.data ?? [];
      getIdsImageFromMenuItems(menuList);
      setGeneratePostLoading(false);

      // if (isValidArray(menuList)) {
      //   if (areArrayObjectValuesValid(ctaArray)) {
      //   getIdsImageFromMenuItems(menuList);
      //   } else {
      //     showErrorMsg("Please select button and url.");
      //   setGeneratePostLoading(false);
      //   }
      // } else {
      //   handleServerValidation(data);
      //   getIdsImageFromMenuItems([]);
      //   setGeneratePostLoading(false);
      // }
    });
  };

  const getUniqueCTAs = (arr) => {
    const uniqueCTAs = new Map();

    isValidArray(arr) &&
      arr.forEach((item) => {
        // If the CTA text is not already in the Map, add it
        if (!uniqueCTAs.has(item.cta_btn_text)) {
          uniqueCTAs.set(item.cta_btn_text, {
            cta_btn_text: item.cta_btn_text,
            cta_btn_url: item.cta_btn_url,
          });
        }
      });

    // Convert the Map values to an array
    return Array.from(uniqueCTAs.values());
  };

  const initialValues = {
    image_required: isValidObject(campaignDetails)
      ? campaignDetails?.image_required
      : true,
    post_to: isValidObject(campaignDetails)
      ? campaignDetails?.post_to
      : "google_business_profile",
    menu_id: isValidObject(campaignDetails)
      ? campaignDetails?.menu_id
      : // : isValidArray(menuList)
        // ? menuList[0]?.id
        "",
    section_id: isValidObject(campaignDetails)
      ? campaignDetails?.section_id
      : "",
    writePost: isValidObject(campaignDetails) ? campaignDetails?.writePost : "",
    callToAction: isValidObject(campaignDetails)
      ? getUniqueCTAs(campaignDetails?.writePost)
      : [{ cta_btn_text: "", cta_btn_url: location_url }],
  };

  const resetCampaignPosts = () => {
    setGeneratedPost([
      {
        food_item_id: 0,
        post_image: "",
        cta_btn_text: "",
        cta_btn_url: "",
      },
    ]);
  };

  const handleSubmit = (values) => {
    if (!isPostGenerated) {
      // showErrorMsg("Please generate post.");
      showErrorMsg(
        "Selected items do not contain any images. Please disable the image required toggle."
      );
      return;
    }

    const formData = {
      location_id: currentLocationId,
      campaign_type: "multi",
      is_structure_data: true,
      post_to: values?.post_to ?? "",
      menu_id: values?.menu_id ?? "",
      section_id: values?.section_id === "Whole Menu" ? "" : values?.section_id,
      food_item_id: itemIdArray ?? [],
      writePost: isValidArray(generatedPost) ? generatedPost : [],
      post_count: isValidArray(generatedPost) ? generatedPost?.length ?? 0 : 0,
    };

    const handleSaveAsDraft = () => {
      const action = isEdit
        ? updateCampaignPost({
            id: campaignId,
            formData: { ...formData, post_status: "draft" },
          })
        : addCampaignPost({
            ...formData,
            post_status: "draft",
            image_required: values?.image_required,
          });

      dispatch(action).then((data) => {
        if (data?.payload?.code === 200) {
          // removeDataLocalStorage("CAMPAIGN_POST_DATA");
          navigate(`/marketing?tab=campaigns`);
        } else {
          handleServerValidation(data);
        }
      });
    };

    const handlePost = () => {
      const data = isEdit
        ? { ...campaignDetails, ...formData }
        : { ...formData, image_required: values?.image_required };
      setDataLocalStorage("CAMPAIGN_POST_DATA", data);
      const basePath = `/marketing?tab=posts&type=multi&form=post-schedule`;
      const path = isEdit ? `${basePath}&campaignId=${campaignId}` : basePath;
      navigate(path, { state: location?.state });
    };

    saveAsDraft ? handleSaveAsDraft() : handlePost();
  };

  const handleGetCampaignPostDetails = () => {
    const formData = { campaign_id: campaignId };

    dispatch(getCampaignPostDetails(formData)).then((data) => {
      const postDetails = data?.payload?.data ?? {};

      const formData = {
        post_to: postDetails?.post_to || "",
        menu_id: postDetails?.menu_id || "",
        section_id: postDetails?.section_id || "",
        writePost: postDetails?.writePost || [],
        campaign_name: postDetails?.campaign_name || "",
        post_launch_date: postDetails?.post_launch_date || "",
        postSchedule: postDetails?.postSchedule || "",
        dailyPost: postDetails?.dailyPost || "",
        post_start_time: postDetails?.post_start_time || "",
        post_end_time: postDetails?.post_end_time || "",
        weekdays: postDetails?.weekdays || "",
        post_count: postDetails?.post_count || "",
      };

      handleGetSectionList(postDetails?.menu_id);

      const cta = getUniqueCTAs(postDetails?.writePost);
      setCTAArray(cta);
      setImageArray(postDetails?.writePost);
      if (
        !isValidObject(location?.state) &&
        typeof location?.state === "string"
      ) {
        setGeneratedPost(postDetails?.writePost);
        setDataLocalStorage("CAMPAIGN_POST_DATA", formData);
      }
      setIsPostGenerated(true);
    });
  };

  useEffect(() => {
    // if (isValidArray(imageArray) && areArrayObjectValuesValid(ctaArray) ) {
    const posts = createPosts(imageArray, ctaArray);
    isValidArray(posts) && setGeneratedPost(posts);
    // }
  }, [ctaArray, imageArray]);

  useEffect(() => {
    if (isEdit) {
      handleGetCampaignPostDetails();
    }
    handleGetmenuList();
    // setIsImageRequired(initialValues.image_required);
    areArrayObjectValuesValid(campaignDetails?.writePost) &&
      setCTAArray(getUniqueCTAs(campaignDetails?.writePost)),
      setGeneratedPost(campaignDetails?.writePost),
      setIsPostGenerated(true);
  }, [currentLocationId]);

  return (
    <div className={classNames("w-full min-h-[40rem] flex justify-center")}>
      {getPostDetailsLoading && <PostLoading />}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={campaignPostDetailsForm()}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          handleBlur,
          resetForm,
          setFieldValue,
          setFieldTouched,
          values,
          handleSubmit,
        }) => (
          <Form
            className="w-full gap-8 p-4 flex flex-col justify-between"
            id="form1"
            name="form1"
            onSubmit={handleSubmit}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          >
            <div className="form-fields">
              {/* Post To Field */}
              <div className="w-full px-3 my-2">
                <label htmlFor="post_to" className="input-field-label">
                  Post To
                </label>
                <div className="mt-2 w-full">
                  <Field
                    as="select"
                    className="input-field"
                    name="post_to"
                    id="post_to"
                    value={values?.post_to ?? ""}
                    onChange={(e) => setFieldValue("post_to", e.target.value)}
                    onBlur={() => setFieldTouched("post_to", true)}
                  >
                    <option value="google_business_profile">
                      Google Business Profile
                    </option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className="field-error text-red-500"
                    name="post_to"
                  />
                </div>
              </div>
              <div className="flex items-center px-3">
                <span className="text-md leading-6 font-light text-gray-900 w-32">
                  {"Image Required"}
                </span>
                <SwitchButton
                  value={values.image_required}
                  handleToggleForCampaigns={(values, id) => {
                    setFieldValue("image_required", values);
                    setIsPostGenerated(false);
                    resetCampaignPosts();
                  }}
                  isDisable={isEdit}
                />
              </div>

              {/* Menu and Section Selectors */}
              <div className="w-full px-3 my-3">
                <div className="flex items-center gap-4 h-[25px]">
                  <label
                    htmlFor="isStructureData"
                    className="input-field-label flex items-center"
                  >
                    USE Structure Data
                  </label>
                </div>
                <div className="mt-2 w-full flex gap-4">
                  {/* Menu Selector */}
                  <div className="w-full">
                    <select
                      className="input-field disabled:cursor-not-allowed"
                      name="menu_id"
                      value={values?.menu_id ?? ""}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const { value, selectedOptions } = e.target;
                        const sectionCounts =
                          selectedOptions[0]?.dataset?.sectionsCount;
                        setFieldValue("menu_id", value);
                        handleGetSectionList(value);
                        setIsPostGenerated(false);
                        setMenuId(value);
                        setSectionCount(Number(sectionCounts));
                        resetCampaignPosts();
                      }}
                      disabled={isEdit}
                    >
                      <option value="">Select Menu</option>
                      {isValidArray(menuList) ? (
                        menuList?.map((menu) => (
                          <option
                            key={menu?.id}
                            value={menu?.id}
                            data-sections-count={menu?.sections_count}
                          >
                            {menu?.menu_name} ({menu?.sections_count})
                          </option>
                        ))
                      ) : (
                        <option value="">No Menu</option>
                      )}
                    </select>
                    <ErrorMessage
                      component="div"
                      className="field-error text-red-500"
                      name="menu_id"
                    />
                  </div>

                  {/* Section Selector */}
                  <div className="w-full">
                    <select
                      className="input-field disabled:cursor-not-allowed"
                      name="section_id"
                      value={values?.section_id ?? ""}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("section_id", e?.target?.value);
                        setSectionId(e?.target?.value);
                        setIsPostGenerated(false);
                        resetCampaignPosts();
                      }}
                      disabled={isEdit || !values?.menu_id || sectionCount == 0}
                    >
                      <option value="">Whole Menu</option>
                      {isValidArray(sectionList) &&
                        sectionList?.map((section) => (
                          <option key={section?.id} value={section?.id}>
                            {section?.section_name} ({section?.food_items_count}
                            )
                          </option>
                        ))}
                    </select>
                    <ErrorMessage
                      component="div"
                      className="field-error text-red-500"
                      name="section_id"
                    />
                  </div>

                  {/* Generate Posts Button */}
                  {!isEdit && (
                    <div
                      className="relative"
                      title={isPostGenerated ? "Post Generated" : ""}
                    >
                      <SpinnerButton
                        title={
                          isPostGenerated ? (
                            <CheckIcon className="w-5 h-5" strokeWidth={1.7} />
                          ) : (
                            "Generate Posts"
                          )
                        }
                        className={classNames("btn-pink rounded-xs w-32", {
                          "w-auto cursor-default": isPostGenerated,
                          "w-32": !isPostGenerated,
                        })}
                        action={() => {
                          !isPostGenerated &&
                            handleGetMenuItemImage(values.image_required);
                        }}
                        loading={menuItemImageListLoading}
                        isDisable={!values?.menu_id || sectionCount === 0}
                      />
                      {!isPostGenerated && sectionCount !== 0 && (
                        <>
                          <div className="animate-pulse h-3 w-3 rounded-xl absolute -top-[5px] -right-[5px] border-green-600 bg-green-600"></div>
                          <div className="animate-ping  h-3 w-3 rounded-xl absolute -top-[5px] -right-[5px] border-green-600 bg-green-600"></div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Post Content Editor */}
              {!!isEdit && (
                <div className="w-full px-3 mt-2">
                  <label htmlFor="writePost" className="input-field-label">
                    Type POST Content
                  </label>
                  <div className="w-full flex items-center gap-x-2 mb-1 mt-2">
                    <div className="w-full">
                      <div>
                        <PostContentEditor
                          value={
                            generatedPost[currentPostContentIndex]
                              ?.description ?? ""
                          }
                          handleOnChange={(value) => {
                            setGeneratedPost((prev) => {
                              const updated = [...prev];
                              updated[currentPostContentIndex] = {
                                ...updated[currentPostContentIndex],
                                description: value,
                              };
                              return updated;
                            });
                          }}
                        />
                      </div>
                      <div className="w-full h-[2px] bg-gray-400 my-3"></div>
                    </div>
                  </div>
                </div>
              )}

              {/* Call To Action Buttons */}
              <div className="w-full px-3 my-3">
                <label
                  htmlFor="post_content"
                  className="text-base font-semibold text-gray-700 mb-3"
                >
                  Select Google Business Profile options
                </label>
                <div className="w-full my-3">
                  <label htmlFor="callToAction" className="input-field-label">
                    Call To Action Button
                  </label>
                  <FieldArray name="callToAction">
                    {({ insert, remove, push }) => (
                      <>
                        {values?.callToAction?.map((item, index) => (
                          <div
                            className="flex items-center gap-x-2 mb-1 mt-2 relative"
                            key={item.id || index}
                          >
                            <div className="flex gap-x-2 w-full">
                              <div className="w-[40%]">
                                <DropdownCommon
                                  className={"custom-input-field"}
                                  name={`callToAction.${index}.cta_btn_text`}
                                  values={item?.cta_btn_text ?? ""}
                                  setFieldValue={(name, value) => {
                                    setFieldValue(name, value);
                                    setFieldTouched(
                                      `callToAction.${index}.cta_btn_text`,
                                      true
                                    );
                                    setGeneratePostLoading(true);
                                    setCTAArray((prev) => {
                                      const updated = [...prev];
                                      updated[index] = {
                                        ...updated[index],
                                        cta_btn_text: value,
                                      };
                                      return updated;
                                    });
                                  }}
                                  setFieldTouched={setFieldTouched}
                                  options={callToActionOptions}
                                />
                                <ErrorMessage
                                  name={`callToAction.${index}.cta_btn_text`}
                                  component="div"
                                  className="field-error text-red-500"
                                />
                              </div>
                              <div className="w-[95%] pr-8">
                                <input
                                  type="url"
                                  name={`callToAction.${index}.cta_btn_url`}
                                  id={`callToAction.${index}.cta_btn_url`}
                                  value={item?.cta_btn_url ?? ""}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setCTAArray((prev) => {
                                      const updated = [...prev];
                                      updated[index] = {
                                        ...updated[index],
                                        cta_btn_url: e.target.value,
                                      };
                                      return updated;
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="Enter Action URL"
                                  className="input-field"
                                />
                                <ErrorMessage
                                  name={`callToAction.${index}.cta_btn_url`}
                                  component="div"
                                  className="field-error text-red-500"
                                />
                              </div>
                            </div>
                            {index > 0 && (
                              <div className="w-[10%] absolute -right-[52px]">
                                <button
                                  type="button"
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    setGeneratePostLoading(true);
                                    remove(index);
                                    setCTAArray((prev) =>
                                      prev.filter((_, i) => i !== index)
                                    );
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="Delete Icon"
                                    className="w-5 h-5"
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                        {values?.callToAction?.length < 5 && (
                          <button
                            type="button"
                            onClick={() => {
                              // setIsPostGenerated(false);
                              push({ cta_btn_text: "", cta_btn_url: "" });
                              setCTAArray((prev) => [
                                ...prev,
                                { cta_btn_text: "", cta_btn_url: "" },
                              ]);
                            }}
                          >
                            <span className="flex justify-center items-center gap-x-1 text-sm text-gray-700 font-semibold">
                              Add More
                              <img
                                src={plusIcon}
                                alt="plus icon"
                                className="w-4 h-4"
                              />
                            </span>
                          </button>
                        )}
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>

            {/* Submit Buttons */}
            <div className="form-submit-buttons w-full flex justify-between items-end mt-2">
              <button
                type="button"
                onClick={() => {
                  removeDataLocalStorage("CAMPAIGN_POST_DATA");
                  navigate(
                    `/marketing?tab=${
                      isValidObject(location?.state)
                        ? location?.state?.path
                        : location?.state
                    }`
                  );
                }}
                className="btn btn-gray"
              >
                Back
              </button>
              <div className="flex gap-2">
                <button
                  type="button"
                  className="text-sm py-2 px-7 text-lime-500 border border-lime-500 w-30 shadow-sm shadow-gray-300 disabled:cursor-not-allowed disabled:opacity-55"
                  onClick={() => {
                    setSaveAsDraft(true);
                    handleSubmit(values);
                  }}
                  disabled={addCampaignPostLoading}
                >
                  {addCampaignPostLoading ? (
                    <span className="h-[20px] flex justify-center items-center">
                      <PulseLoader color="#8bc34a" size={12} />
                    </span>
                  ) : (
                    <span className="h-[20px] flex justify-center items-center">
                      Save Draft
                    </span>
                  )}
                </button>
                <SpinnerButton
                  className="btn-green"
                  title={"Next"}
                  type={"submit"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
