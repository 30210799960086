import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getFailedPostList = createAsyncThunk(
  "post/failed-list",
  async (formData = {}, { rejectWithValue }) => {
    const { location_id } = formData;
    try {
      const response = await getApi(
        `api/get-post-schedule-failed-list?location_id=${location_id}`
      );

      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getFailedPostListSlice = createSlice({
  name: "getFailedPostList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFailedPostList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFailedPostList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getFailedPostList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getFailedPostListSlice.reducer;
