import imagePreview from "assets/images/dummyImage.svg";
import nextIcon from "assets/images/nextIcon.svg";
import prevIcon from "assets/images/prevIcon.svg";
import classNames from "classnames";
import { callToActionOptions } from "module/data";
import {
  getDataLocalStorage,
  getItemImage,
  isValidArray,
  isValidObject,
} from "module/util";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSearchParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function CampaignPostPreview({
  generatedPost = [],
  setGeneratedPost = () => {},
  setCurrentPostContentIndex = () => {},
}) {
  const swiperRef = useRef(null);
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId") || 0;
  const isEdit = !!campaignId;
  const [count, setCount] = useState(0);

  const getLabelForValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : null;
  };

  useEffect(() => {
    const campaignDetails = getDataLocalStorage("CAMPAIGN_POST_DATA");
    const writePost = isValidObject(campaignDetails)
      ? campaignDetails?.writePost
      : [
          {
            food_item_id: 0,
            post_image: "",
            cta_btn_text: "",
            cta_btn_url: "",
          },
        ];
    setGeneratedPost(writePost);
  }, []);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setCurrentPostContentIndex(swiperRef.current.swiper.activeIndex);
    }
  };

  return (
    <div className="w-full h-full">
      <div className="w-full mb-2 p-0.5">
        <HandleSlideButtons
          swiperRef={swiperRef}
          setCount={setCount}
          count={count}
          generatedPost={generatedPost}
        />
      </div>
      <Swiper
        ref={swiperRef}
        id="main"
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        className="w-full !h-full md:w-[99%] flex flex-col gap-y-3"
        allowTouchMove={false}
        onSlideChange={handleSlideChange}
      >
        {isValidArray(generatedPost) &&
          generatedPost?.map((post, postIndex) => {
            const imgSrc = !!post?.post_image
              ? getItemImage(post?.post_image)
              : imagePreview;
            return (
              <SwiperSlide
                key={postIndex}
                className="!block !h-full !bg-transparent"
              >
                <div className="w-full flex flex-col gap-y-2">
                  <div
                    className={classNames(
                      "flex justify-center items-center border-2 border-gray-300",
                      {
                        "h-[26rem]": post?.cta_btn_text,
                        "h-[28rem]": !post?.cta_btn_text,
                      }
                    )}
                  >
                    <img
                      src={imgSrc}
                      alt={post?.post_image}
                      className={classNames("!object-contain w-full p-2")}
                    />
                  </div>

                  <div className=" text-gray-800 break-words">
                    <div className="editor-container mt-1">
                      <ReactQuill
                        className={"custom-input-field h-28 !border"}
                        value={post?.description}
                        theme="snow"
                        modules={{
                          toolbar: false,
                        }}
                        placeholder={
                          !!isEdit
                            ? "Write Post Content"
                            : "AI Post message is generated prior to post date and time..."
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  {post?.cta_btn_text ? (
                    <div className="flex">
                      <button type="button" className="btn-pink">
                        {getLabelForValue(
                          post?.cta_btn_text,
                          callToActionOptions
                        )}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}

const HandleSlideButtons = ({
  swiperRef = {},
  setCount = () => {},
  count = 0,
  generatedPost = [],
}) => {
  const handlePrev = () => {
    swiperRef.current.swiper.slidePrev();
    setCount(count + 1);
  };

  const handleNext = () => {
    swiperRef.current.swiper.slideNext();
    setCount(count + 1);
  };

  const isBeginning = swiperRef?.current?.swiper?.isBeginning;
  const isEnd = swiperRef?.current?.swiper?.isEnd;

  if (swiperRef?.current) {
    return (
      <div className="flex justify-between items-center">
        {(generatedPost?.length && generatedPost[0].food_item_id) ? (
        <>
        <span>
          {swiperRef.current?.swiper?.activeIndex + 1} /{" "}
          {generatedPost?.length}
        </span>
        <div className="flex justify-center items-center">
          <button
            type="button"
            className={classNames("text-gray-700 ", {
              "opacity-50": isBeginning,
            })}
            title="Previous"
            onClick={handlePrev}
            disabled={!isValidArray(generatedPost) || isBeginning}
          >
            <img src={prevIcon} alt="Previous" />
          </button>

          <button
            type="button"
            className={classNames("text-gray-700 ", {
              "opacity-50": isEnd,
            })}
            title="Next"
            onClick={handleNext}
            disabled={!isValidArray(generatedPost) || isEnd}
          >
            <img src={nextIcon} alt="Next" />
          </button>
        </div>
        </>
        ) : (
          "0 Posts"
        )}
      </div>
    );
  }
};
