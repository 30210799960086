import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { statesArray } from "module/data";
import { MAP_API_KEY, MAP_LIBRARIES } from "module/util";
import { useRef } from "react";

const GoogleAddressField = (props) => {
  const {
    name = "",
    setFieldValue = () => {},
    value = "",
    onChange = () => {},
    onBlur = () => {},
    placeholder = "Enter Location",
    setLatLong = () => {},
    isNameField = false,
    isDisabled = false,
    isReadOnly = false,
    setFieldTouched = () => {},
    FieldValues = {},
  } = props;
  const inputRef = useRef();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let street = "";
      let route = "";
      let neighborhood = "";
      let sublocality_level_1 = "";
      let sublocality_level_2 = "";
      let address = [];

      if (place.formatted_address) {
        const addressRegex = /^(.+?),\s*(.*?)(?:,\s*(\w{2}))?(?:\s+(\d{5}))?$/;
        const match = place.formatted_address.match(addressRegex);

        const address = match && match[1] ? match[1] : "";
        setFieldValue("address1", address);
        const city = match && match[2] ? match[2] : "";
        setFieldValue("city", city);
        const state = match && match[3] ? match[3] : "";
        setFieldValue("state", state);
        const zip = match && match[4] ? match[4] : "";
        setFieldValue("zip", zip);
        FieldValues?.phone && setFieldTouched("phone", true),
          setFieldValue("phone", "");
      }

      const latLong = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      setFieldValue("lat_long", `${latLong.lat},${latLong.lng}`);

      if (place?.formatted_phone_number) {
        setFieldValue("phone", place?.formatted_phone_number ?? "");
      }

      if (place?.name && isNameField) {
        name !== "address1" && setFieldValue(name, place?.name ?? "");
        // setFieldValue("company", place?.name);
        // setFieldValue("name", place?.name);
      }

      for (var i = 0; i < place?.address_components.length; i++) {
        for (var j = 0; j < place?.address_components[i].types.length; j++) {
          if (
            place.address_components[i].types[j] == "street_number" ||
            place.address_components[i].types[j] == "route" ||
            place.address_components[i].types[j] == "neighborhood" ||
            place.address_components[i].types[j] == "sublocality_level_1" ||
            place.address_components[i].types[j] == "sublocality_level_2"
          ) {
            if (place.address_components[i].types[j] == "street_number") {
              street = place.address_components[i].long_name ?? "";
              address.push(street);
            }

            if (place.address_components[i].types[j] == "sublocality_level_2") {
              sublocality_level_2 = place.address_components[i].long_name ?? "";
              address.push(sublocality_level_2);
            }

            if (place.address_components[i].types[j] == "route") {
              route = place.address_components[i].long_name ?? "";
              address.push(route);
            }

            if (place.address_components[i].types[j] == "sublocality_level_1") {
              sublocality_level_1 = place.address_components[i].long_name ?? "";
              address.push(sublocality_level_1);
            }

            if (place.address_components[i].types[j] == "neighborhood") {
              neighborhood = place.address_components[i].long_name ?? "";
              address.push(neighborhood);
            }
            setFieldValue("address1", address.join(", "));

            // `${street}, ${sublocality_level_2}, ${route}, ${sublocality_level_1}, ${neighborhood}`
          }
          if (place.address_components[i].types[j] == "postal_code") {
            setFieldValue("zip", place.address_components[i].long_name ?? "");
          }

          if (
            place.address_components[i].types[j] ==
            "administrative_area_level_1"
          ) {
            const stateValues = statesArray?.map((state) => state.value);
            const state = place.address_components[i].short_name;
            const isIncluded = stateValues.includes(state);
            setFieldValue("state", isIncluded ? state : "");
          }
          if (place.address_components[i].types[j] == "locality") {
            const city = place.address_components[i].long_name ?? "";
            setFieldValue("city", city);
          }
        }
      }

      setTimeout(() => {
        setLatLong(latLong);
      }, 200);
    }
  };

  return (
    <>
      {isLoaded ? (
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <input
            type="text"
            name={name}
            id={name}
            className="input-field"
            placeholder={placeholder}
            value={value ?? ""}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isDisabled}
            readOnly={isReadOnly}
          />
        </StandaloneSearchBox>
      ) : (
        <input
          type="text"
          name={name}
          id={name}
          className="input-field"
          placeholder={placeholder}
          value={value ?? ""}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
          readOnly={isReadOnly}
        />
      )}
    </>
  );
};

export default GoogleAddressField;
