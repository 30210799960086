import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import Calender from "components/marketing/calender/Calender";
import CampaignPostList from "components/marketing/posts/CampaignPostList";
import PostList from "components/marketing/posts/PostList";
import Posts from "components/marketing/posts/Posts";
import {
  getAuthToken,
  getCompanyStatus,
  getCurrentLocation,
  getUserDataByParam,
  isCompanyUser,
  isValidObject,
  removeDataLocalStorage,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { launchPostToGoogle } from "redux/post/service/launchPostToGoogleSlice";
import { checkExpireToken } from "redux/reputation/checkExpireTokenSlice";

export default function Marketing() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const currentCompanyId = getUserDataByParam("COMPANY_ID");
  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();
  const [isPostToGoogleModal, setIsPostToGoogleModal] = useState(false);

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const checkExpireData = useSelector(
    (state) => state?.checkExpireToken?.data?.dataObj ?? false
  );
  const checkExpireDataLoading = useSelector(
    (state) => state?.checkExpireToken?.loading ?? false
  );
  const postToGoogleLoading = useSelector(
    (state) => state?.launchPostToGoogle?.loading ?? false
  );

  const urlTab = searchParams.get("tab") ? searchParams.get("tab") : "posts";
  const currentActiveTab = [
    "posts",
    "calender",
    "campaigns",
    "pending",
    "completed",
    // "error",
    "failed",
    "scheduled",
  ].includes(urlTab)
    ? urlTab
    : "posts";

  const [activeTab, setActiveTab] = useState(currentActiveTab);
  const type = searchParams.get("type") ? searchParams.get("type") : "";
  const form = searchParams.get("form") ? searchParams.get("form") : "";

  const marketingTabsArray = [
    { name: "Posts", value: "posts", href: "#", current: false },
    { name: "Calender", value: "calender", href: "#", current: false },
    { name: "Campaigns", value: "campaigns", href: "#", current: false },
    { name: "Pending", value: "pending", href: "#", current: false },
    { name: "Completed", value: "completed", href: "#", current: false },
    { name: "Scheduled", value: "scheduled", href: "#", current: false },
    // { name: "Error", value: "error", href: "#", current: false },
    { name: "Failed", value: "failed", href: "#", current: false },
  ];

  const breadCrumbsTitle = {
    posts: "Posts",
    calender: "Calender",
    campaigns: "Campaigns",
    pending: "Pending",
    completed: "Completed",
    // error: "Error",
    failed: "Failed",
    scheduled: "Scheduled",
    "post-details": "Detail Form",
    "post-schedule": "Schedule Form",
    "post-launch": "Launch Form",
  };

  useEffect(() => {
    setActiveTab(currentActiveTab);
    dispatch(
      setBreadCrumb([
        {
          name: "Marketing",
          href: "",
          current: false,
          isDisable: true,
        },
        {
          name: breadCrumbsTitle[currentActiveTab],
          href: `/marketing?tab=${location?.state}`,
          current: false,
          isDisable: false,
        },
        type && {
          name: type === "single" ? "Single Post" : "Camapign Post",
          href: "",
          current: false,
        },
        type && {
          name: type === "single" ? "Post Form" : breadCrumbsTitle[form],
          href: "",
          current: false,
        },
      ])
    );
  }, [currentActiveTab, navigate, type, form, location]);

  useEffect(() => {
    const fetchData = () => {
      const data = {
        location_id: currentLocationId,
        company_id: currentCompanyId,
        access_token: getAuthToken(),
      };
      dispatch(checkExpireToken(data));
    };
    fetchData();

    // const intervalId = setInterval(fetchData, 30000);
    // return () => clearInterval(intervalId);
  }, [locationId]);

  const isGoogleConnected = checkExpireData?.status ?? false;

  const handlePostToGoogle = () => {
    const formData = { location_id: currentLocationId };
    dispatch(launchPostToGoogle(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Post to Google successfully."
        );
        setIsPostToGoogleModal(false);
      } else {
        handleServerValidation(data);
        setIsPostToGoogleModal(false);
      }
    });
  };

  return (
    <div className="relative">
      {isCompanyActive &&
        isValidObject(checkExpireData) &&
        !isGoogleConnected &&
        !checkExpireDataLoading && (
          <button
            className="btn-pink fixed top-[50%] left-[50%] z-[99]"
            onClick={() => {
              navigate(
                "/user-settings?tab=integrations&setting=google&redirect=false",
                { state: "/marketing" }
              );
            }}
          >
            Connect Google
          </button>
        )}
      <div
        className={classNames("", {
          "pointer-events-none": !isCompanyActive,
          "pointer-events-none blur-[4px] grayscale": !isGoogleConnected,
        })}
      >
        <div className="flex border-b border-collapse border-gray-300  w-full">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {marketingTabsArray.map((tab) => (
              <span
                key={tab.name}
                onClick={() => {
                  setActiveTab(tab.value);
                  navigate(`/marketing?tab=${tab.value}`);
                }}
                className={classNames(
                  " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                  {
                    "border-pink-600 hover:border-pink-600 text-gray-800":
                      tab.value === activeTab,
                    "border-transparent": tab.value !== activeTab,
                  }
                )}
                aria-current={tab.value === activeTab ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
        {/* --------------------------------------------------------------------- */}

        <div className={`flex items-center mt-2 w-full`}>
          {!["posts"].includes(activeTab) && (
            <div className={"flex w-full justify-end items-center gap-2"}>
              {isCompanyUser() && isCompanyActive && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      if (isGoogleConnected) {
                        // removeDataLocalStorage("CAMPAIGN_POST_DATA");
                        navigate(
                          `/marketing?tab=posts&type=single&form=post-details`,
                          { state: activeTab }
                        );
                      }
                    }}
                    className="btn-pink w-28"
                  >
                    Create Post
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (isGoogleConnected) {
                        // removeDataLocalStorage("CAMPAIGN_POST_DATA");
                        navigate(
                          `/marketing?tab=posts&type=multi&form=post-details`,
                          { state: activeTab }
                        );
                      }
                    }}
                    className="btn-pink"
                  >
                    Create Campaign
                  </button>
                </>
              )}
            </div>
          )}
        </div>

        {/* --------------------------------------------------------------------- */}

        <div className="mt-2">
          <div className={currentActiveTab === "posts" ? "block" : "hidden"}>
            {currentActiveTab === "posts" && <Posts />}
          </div>

          <div className={currentActiveTab === "calender" ? "block" : "hidden"}>
            {currentActiveTab === "calender" && <Calender />}
          </div>

          <div
            className={currentActiveTab === "campaigns" ? "block" : "hidden"}
          >
            {currentActiveTab === "campaigns" && <CampaignPostList />}
          </div>

          <div
            className={
              ["pending", "completed", "scheduled", "failed"].includes(
                currentActiveTab
              )
                ? "block"
                : "hidden"
            }
          >
            {["pending", "completed", "scheduled", "failed"].includes(
              currentActiveTab
            ) && <PostList />}
          </div>
        </div>
      </div>
      {isPostToGoogleModal && (
        <ConfirmationModal
          isModalOpen={isPostToGoogleModal}
          setModalOpen={setIsPostToGoogleModal}
          handleAction={handlePostToGoogle}
          message={`You want to post ?`}
          isLoading={postToGoogleLoading}
        />
      )}
    </div>
  );
}
