import deleteIcon from "assets/images/deleteBoldIcon.svg";
import classNames from "classnames";
import CustomDateTimeInput from "components/common/fields/CustomDateTimeInput";
import DropdownCommon from "components/common/fields/DropdownCommon";
import SpinnerButton from "components/common/fields/SpinnerButton";
import SwitchButton from "components/common/fields/SwitchButton";
import PostContentEditor from "components/marketing/posts/PostContentEditor";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { callToActionOptions, postStatus, postStatusTab } from "module/data";
import {
  convertPostDate,
  getCurrentLocation,
  isValidArray,
  isValidObject,
} from "module/util";
import { handleServerValidation, postDetailsForm } from "module/validation";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { getMenuItemList } from "redux/gmbLocation/getMenuItemListSlice";
import { getMenuItemDetail } from "redux/gmbLocation/getMenuItemsDetailSlice";
import { getMenuList } from "redux/gmbLocation/getMenuListSlice";
import { getSectionList } from "redux/gmbLocation/getSectionListSlice";
import { addPost } from "redux/post/addPostSlice";
import { getPostDetails } from "redux/post/getPostDetailsSlice";
import { updatePost } from "redux/post/updatePostSlice";

export default function SinglePostForm({
  generatedPost = [],
  setGeneratedPost = () => {},
  setGeneratePostLoading = () => {},
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [postImages, setPostImages] = useState([]);
  const [postContent, setPostContent] = useState("");
  const [postCTA, setPostCTA] = useState();
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [itemIdArray, setItemIdArray] = useState([]);
  const [itemImageArray, setItemImageArray] = useState([]);

  const { id: currentLocationId, location_url = "" } = getCurrentLocation();
  const postId = searchParams.get("postId") || "";
  const isEdit = !!postId;

  const addPostLoading = useSelector(
    (state) => state?.addPost?.loading ?? false
  );
  const updatePostLoading = useSelector(
    (state) => state?.updatePost?.loading ?? false
  );
  const isLoading = addPostLoading || updatePostLoading;

  const postDetails = useSelector(
    (state) => state?.getPostDetails?.data?.data ?? {}
  );
  const menuList = useSelector(
    (state) => state?.getMenuList?.data?.dataObj?.data ?? []
  );
  const sectionList = useSelector(
    (state) => state?.getSectionList?.data?.dataObj?.data ?? []
  );
  const itemList = useSelector(
    (state) => state?.getMenuItemList?.data?.dataObj?.data ?? []
  );

  const getPostImageNames = (itemData, postDetails) => {
    let allImageNames = [];

    allImageNames = itemData?.primary_image
      ? [...allImageNames, itemData?.primary_image]
      : [];

    allImageNames = isValidArray(itemData?.secondary_image)
      ? [...allImageNames, ...itemData.secondary_image]
      : [...allImageNames];

    if (isEdit && isValidObject(postDetails)) {
      const image = postDetails?.descriptions[0]?.post_image ?? "";
      allImageNames = isValidArray(postDetails?.descriptions)
        ? [...allImageNames, image]
        : [...allImageNames];
    }

    const uniqueImageNames = isValidArray(allImageNames)
      ? [...new Set(allImageNames)]
      : [];

    return uniqueImageNames;
  };

  const handleGetItemData = (
    itemId,
    isIdChange = false,
    postDetails = {},
    setFieldValue = () => {},
    setFieldTouched = () => {}
  ) => {
    setGeneratePostLoading(true);
    dispatch(getMenuItemDetail(itemId)).then((data) => {
      const itemData = data?.payload?.dataObj?.data ?? {};
      const imageArray = getPostImageNames(itemData, postDetails);
      const finalData = isIdChange
        ? imageArray
        : [...itemImageArray, ...imageArray];

      if (isIdChange) {
        setItemImageArray([...itemImageArray, ...finalData]);
        if (isValidArray(imageArray)) {
          setPostImages([imageArray[0]]);
          setFieldValue(`writePost.0.post_image`, imageArray[0]);
        } else {
          setPostImages([""]);
          setFieldValue(`writePost.0.post_image`, "");
          setFieldTouched(`writePost.0.post_image`, true);
        }
      } else {
        setItemImageArray((pre) => {
          const uniqueImageNames = isValidArray(finalData)
            ? [...new Set([...pre, ...finalData])]
            : [];
          return uniqueImageNames;
        });
      }
      setGeneratePostLoading(false);
    });
  };

  const scheduleData = isValidArray(postDetails?.schedule)
    ? postDetails?.schedule[0]
    : {
        post_launch_date: moment().format("YYYY-MM-DD"),
        post_launch_time: moment().format("HH:mm"),
        post_launch_time_type: moment().format("A"),
      };

  const isMultiPost =
    isEdit && postDetails?.campaign_type == "multi" ? true : false;

  const initialValues = {
    postCampaignType: isEdit ? postDetails?.campaign_type : "single",
    campaignId: isEdit ? postDetails?.post_campaign_id ?? "" : "",
    postTo: "google_business_profile",
    isStructureData: isEdit ? postDetails?.is_structure_data === "true" : false,
    menuId: isEdit ? postDetails?.menu_id ?? "" : "",
    sectionId: isEdit ? postDetails?.section_id ?? "" : "",
    foodItemId: isEdit ? postDetails?.food_item_id ?? "" : "",
    writePost: isEdit
      ? postDetails?.descriptions
      : [{ description: "", post_image: "" }],
    callToAction:
      isEdit && isValidArray(postDetails?.call_to_actions)
        ? postDetails?.call_to_actions
        : [{ cta_btn_text: "", cta_btn_url: location_url }],
    postNowOrDate: isEdit ? postDetails?.postNowOrDate ?? "draft" : "now",
    postLaunchDate: isEdit
      ? convertPostDate(scheduleData?.post_launch_date)
      : "",
    postLaunchTime: isEdit ? scheduleData?.post_launch_time || "" : "",
    postLaunchTimeType: isEdit
      ? scheduleData?.post_launch_time_type ?? "AM"
      : "AM",
    postStatus: isEdit
      ? postStatus[postDetails?.post_status] || "launch"
      : "launch",
  };

  const handleSubmit = (values) => {
    const postStatus = saveAsDraft
      ? "draft"
      : (values?.postNowOrDate === "date" && "schedule") || "launch";
    const postListTab = postStatusTab[postStatus] || postStatusTab.default;

    const formattedDate = (dateString) => {
      const parsedDate = moment(dateString);
      if (!parsedDate.isValid()) {
        return "";
      }
      return parsedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    };

    const formData = {
      location_id: currentLocationId,
      postCampaignType: values?.postCampaignType ?? "single",
      campaignId: values?.campaignId ?? "",
      campaignName: isMultiPost ? postDetails?.campaign_name : null,
      postTo: values?.postTo ?? "",
      menuId: values?.menuId ?? "",
      sectionId: values?.sectionId ?? "",
      foodItemId: values?.foodItemId ?? "",
      writePost: values?.writePost ?? [],
      postImage: values?.postImage ?? [],
      postNowOrDate: values?.postNowOrDate ?? "",
      postLaunchDate: formattedDate(values?.postLaunchDate) ?? "",
      postLaunchTime: values?.postLaunchTime ?? "",
      postLaunchTimeType: values?.postLaunchTimeType ?? "",
      postStatus,
      isStructureData: values?.isStructureData,
      // callToAction: values?.callToAction ?? [],
      ...(!!values?.callToAction[0]?.cta_btn_text &&
        !!values?.callToAction[0]?.cta_btn_url && {
          callToAction: values?.callToAction,
        }),
    };

    const action = isEdit
      ? updatePost({ id: postId, formData })
      : addPost(formData);

    dispatch(action).then((data) => {
      if (data?.payload?.code == 200) {
        navigate(`/marketing?tab=${postListTab}`);
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleGetmenuList = () => {
    const formData = {
      location_id: currentLocationId,
    };
    dispatch(getMenuList(formData));
  };

  const handleGetSectionList = (menuId) => {
    const formData = {
      menu_id: menuId,
      location_id: currentLocationId,
    };
    dispatch(getSectionList(formData));
  };

  const handleItemList = (section_id) => {
    const formData = {
      id: section_id,
      location_id: currentLocationId,
    };
    dispatch(getMenuItemList(formData));
  };

  const handleGetPostDetails = () => {
    dispatch(getPostDetails(postId)).then((data) => {
      if (isValidObject(data?.payload?.data)) {
        const postDetails = data?.payload?.data ?? {};

        const ImageName = isValidArray(postDetails?.descriptions)
          ? postDetails?.descriptions[0]?.post_image ?? ""
          : "";

        const description = isValidArray(postDetails?.descriptions)
          ? postDetails?.descriptions[0]?.description ?? ""
          : "";

        setPostImages(!!ImageName ? [ImageName] : []);

        setPostContent(description);
        setPostCTA(postDetails?.call_to_actions[0]?.cta_btn_text ?? "none");

        if (postDetails?.is_structure_data === "true") {
          const { menu_id, section_id, food_item_id } = postDetails;

          handleGetSectionList(menu_id);
          handleItemList(section_id);
          setItemIdArray([food_item_id]);
          handleGetItemData(food_item_id, false, postDetails);
        } else {
          const finalImages =
            !!ImageName && !itemImageArray?.includes(ImageName)
              ? [...itemImageArray, ImageName]
              : itemImageArray;
          setItemImageArray(finalImages);
        }
      }
    });
  };

  useEffect(() => {
    handleGetmenuList();
    if (isEdit) {
      handleGetPostDetails();
    }
  }, [postId, currentLocationId]);

  useEffect(() => {
    setGeneratedPost([
      {
        post_image: postImages,
        post_content: postContent,
        cta_btn_text: postCTA,
      },
    ]);
  }, [postImages, postContent, postCTA]);

  return (
    <div className="w-full h-[calc(100% - 100px)] flex justify-center">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={postDetailsForm()}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          values,
          handleSubmit,
        }) => (
          <Form
            className="w-full max-h-full p-4 flex flex-col justify-between"
            id="form1"
            name="form1"
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              e.key == "Enter" && e.preventDefault();
            }}
          >
            <div>
              {/* Post To Field */}
              <div className="w-full px-3 my-2">
                <label htmlFor="postTo" className="input-field-label">
                  Post To
                </label>
                <div className="mt-2 w-full">
                  <select
                    className="input-field"
                    name="postTo"
                    id="postTo"
                    value={values?.postTo ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="google_business_profile">
                      Google Business Profile
                    </option>
                  </select>
                  <ErrorMessage
                    component="div"
                    className="field-error text-red-500"
                    name="postTo"
                  />
                </div>
              </div>

              {/* Structure Data Toggle */}
              <div className="w-full px-3 my-3">
                <div className="flex items-center gap-4 h-[25px]">
                  <label
                    htmlFor="isStructureData"
                    className="input-field-label flex items-center"
                  >
                    USE Structure Data
                  </label>
                  <SwitchButton
                    name="isStructureData"
                    value={values?.isStructureData}
                    isDisable={isMultiPost}
                    handleToggleForCampaigns={(value) => {
                      setFieldValue("isStructureData", value);

                      if (!value) {
                        const image = values?.writePost[0]?.post_image;

                        // Reset related fields when the toggle is turned off
                        setFieldValue("menuId", "");
                        setFieldValue("sectionId", "");
                        setFieldValue("foodItemId", "");

                        if (typeof image === "string") {
                          setItemImageArray((prev) => {
                            const updated = prev.filter((img) => img !== image);
                            const lastImage = updated[updated.length - 1] || "";

                            setPostImages([lastImage]);
                            setFieldValue("writePost.0.post_image", lastImage);
                            setFieldTouched("writePost.0.post_image", true);

                            return updated;
                          });
                        } else {
                          setPostImages([image]);
                        }
                      }
                    }}
                  />
                </div>

                {/* Conditional Structure Data Fields */}
                {values?.isStructureData && (
                  <div className="mt-2 w-full flex gap-4">
                    {/* Menu ID */}
                    <div className="w-full">
                      <select
                        className={classNames("input-field", {
                          "cursor-not-allowed": isMultiPost,
                        })}
                        name="menuId"
                        value={values?.menuId || ""}
                        disabled={isMultiPost}
                        onChange={(e) => {
                          if (!isMultiPost) {
                            setFieldValue("menuId", e.target.value);
                            handleGetSectionList(e.target.value);
                            handleItemList("");
                            setItemIdArray([]);
                          }
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Menu</option>
                        {isValidArray(menuList) &&
                          menuList?.map((menu) => (
                            <option key={menu?.id} value={menu?.id}>
                              {menu?.menu_name} ({menu?.sections_count})
                            </option>
                          ))}
                      </select>
                      <ErrorMessage
                        component="div"
                        className="field-error text-red-500"
                        name="menuId"
                      />
                    </div>

                    {/* Section ID */}
                    <div className="w-full">
                      <select
                        className={classNames("input-field", {
                          "cursor-not-allowed": isMultiPost,
                        })}
                        name="sectionId"
                        value={values?.sectionId || ""}
                        onChange={(e) => {
                          if (!isMultiPost) {
                            setFieldValue("sectionId", e.target.value);
                            handleItemList(e.target.value);
                            setItemIdArray([]);
                          }
                        }}
                        onBlur={handleBlur}
                        disabled={!isValidArray(sectionList) || isMultiPost}
                      >
                        <option value="">Select Section</option>
                        {isValidArray(sectionList) &&
                          sectionList?.map((section) => (
                            <option key={section?.id} value={section?.id}>
                              {section?.section_name} (
                              {section?.food_items_count})
                            </option>
                          ))}
                      </select>
                      <ErrorMessage
                        component="div"
                        className="field-error text-red-500"
                        name="sectionId"
                      />
                    </div>

                    {/* Food Item ID */}
                    <div className="w-full">
                      <select
                        className={classNames("input-field", {
                          "cursor-not-allowed": isMultiPost,
                        })}
                        name="foodItemId"
                        value={values?.foodItemId ?? ""}
                        onChange={(e) => {
                          if (!isMultiPost) {
                            setFieldValue("foodItemId", e?.target?.value);
                            handleGetItemData(
                              e?.target?.value,
                              true,
                              postDetails,
                              setFieldValue,
                              setFieldTouched
                            );
                            setItemIdArray(
                              e?.target?.value ? [e?.target?.value] : []
                            );
                          }
                        }}
                        onBlur={() => setFieldTouched("foodItemId", true)}
                        disabled={!isValidArray(itemList) || isMultiPost}
                      >
                        <option value="">Select Item</option>
                        {isValidArray(itemList) &&
                          itemList?.map((item) => (
                            <option key={item?.id} value={item?.id}>
                              {item?.item_name}
                            </option>
                          ))}
                      </select>
                      <ErrorMessage
                        component="div"
                        className="field-error text-red-500"
                        name="foodItemId"
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* Post Content */}
              <div className="w-full px-3 mt-2">
                <FieldArray name="writePost">
                  {({ insert, remove, push }) =>
                    isValidArray(values?.writePost) &&
                    values?.writePost?.map((item, index) => (
                      <div key={index}>
                        <label
                          htmlFor="writePost"
                          className="input-field-label"
                        >
                          Type POST Content
                        </label>
                        <div className="w-full flex items-center gap-x-2 mb-1 mt-2">
                          <div className="w-full">
                            <PostContentEditor
                              value={item?.description}
                              handleOnChange={(value) => {
                                setPostContent(value);
                                setFieldValue(
                                  `writePost.${index}.description`,
                                  value
                                );
                              }}
                              handleImageChange={(image) => {
                                const updatedPostImages = image ? [image] : [];
                                const updatedItemImageArray = image
                                  ? [...new Set([...itemImageArray, image])]
                                  : [];

                                setPostImages(updatedPostImages);
                                setItemImageArray(updatedItemImageArray);
                                setFieldValue(
                                  `writePost.${index}.post_image`,
                                  image || ""
                                );
                              }}
                              handleBlur={() => {
                                setFieldTouched(
                                  `writePost.${index}.description`,
                                  true
                                );
                              }}
                              itemIdArray={itemIdArray}
                              postImages={postImages}
                              itemImageArray={itemImageArray}
                              setItemImageArray={setItemImageArray}
                              postDetails={postDetails}
                            />
                            <ErrorMessage
                              name={`writePost.${index}.post_image`}
                              component="div"
                              className="field-error text-red-500"
                            />
                            <div className="w-full h-[2px] bg-gray-400 my-3"></div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>

              {/* Call to Action */}
              <div className="w-full px-3 mb-4">
                <label className="text-base font-semibold text-gray-700 mb-3">
                  Select Google Business Profile options
                </label>
                <div className="w-full">
                  <label htmlFor="callToAction" className="input-field-label">
                    Call To Action Button
                  </label>
                  <FieldArray name="callToAction">
                    {({ remove }) =>
                      isValidArray(values?.callToAction) &&
                      values?.callToAction?.map((item, index) => (
                        <div
                          className="flex items-center gap-x-2 mb-1 mt-2 relative"
                          key={index}
                        >
                          <div className="flex gap-x-2 w-full">
                            {/* CTA Button Text */}
                            <div className="w-[40%]">
                              <DropdownCommon
                                className="custom-input-field"
                                name={`callToAction.${index}.cta_btn_text`}
                                values={item?.cta_btn_text ?? ""}
                                setFieldValue={(name, value) => {
                                  setFieldValue(name, value);
                                  setPostCTA(value);
                                  if (!value) {
                                    setFieldValue(
                                      `callToAction.${index}.cta_btn_url`,
                                      ""
                                    );
                                  }
                                }}
                                setFieldTouched={setFieldTouched}
                                options={callToActionOptions}
                                isClearable={true}
                                placeholder={"Select Action Button"}
                              />
                              <ErrorMessage
                                name={`callToAction.${index}.cta_btn_text`}
                                component="div"
                                className="field-error text-red-500"
                              />
                            </div>

                            {/* CTA Button URL */}
                            <div className="w-[95%]">
                              <input
                                type="url"
                                name={`callToAction.${index}.cta_btn_url`}
                                id={`callToAction.${index}.cta_btn_url`}
                                value={item?.cta_btn_url ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Action URL"
                                className="input-field"
                                disabled={!item?.cta_btn_text}
                              />
                              <ErrorMessage
                                name={`callToAction.${index}.cta_btn_url`}
                                component="div"
                                className="field-error text-red-500"
                              />
                            </div>

                            {/* CTA Button Remove */}
                            {index > 0 && (
                              <div className="w-[10%] absolute -right-[52px]">
                                <button
                                  type="button"
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="Delete Icon"
                                    className="w-5 h-5"
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>
              </div>

              <div className="w-full px-3 my-4">
                <label
                  htmlFor="expires"
                  className="text-base font-semibold text-gray-700 mb-2"
                >
                  Post Now or Schedule
                </label>
                <div className="flex justify-between gap-x-5 h-[50px]">
                  <div className="flex items-center gap-x-3 w-[40%]">
                    {!isMultiPost && (
                      <>
                        <label className="text-sm font-light text-gray-800">
                          <input
                            type="radio"
                            name="postNowOrDate"
                            value="now"
                            checked={values?.postNowOrDate === "now" ?? false}
                            className="remove-focus-ring"
                            onChange={(e) =>
                              setFieldValue("postNowOrDate", e.target.value)
                            }
                          />{" "}
                          Now
                        </label>
                      </>
                    )}
                    <label className="text-sm font-light text-gray-800 ml-2">
                      <input
                        type="radio"
                        name="postNowOrDate"
                        value="date"
                        checked={values?.postNowOrDate === "date" ?? false}
                        className="remove-focus-ring"
                        onChange={(e) =>
                          setFieldValue("postNowOrDate", e.target.value)
                        }
                      />{" "}
                      Schedule
                    </label>
                  </div>

                  {values?.postNowOrDate === "date" && (
                    <div className="flex w-full mt-2">
                      <div className="w-full">
                        <CustomDateTimeInput
                          value={values?.postLaunchDate ?? ""}
                          time={values?.postLaunchTime}
                          period={values?.postLaunchTimeType}
                          showTimeInput={true}
                          handleChange={(date, time, period) => {
                            setFieldValue("postLaunchDate", date ?? "");
                            setFieldValue("postLaunchTime", time ?? "");
                            setFieldValue("postLaunchTimeType", period ?? "");
                          }}
                          handleBlur={() =>
                            setFieldTouched("postLaunchDate", true)
                          }
                        />
                        <ErrorMessage
                          name="postLaunchDate"
                          component="div"
                          className="field-error text-red-500"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classNames({ "mt-11": !values?.isStructureData })}>
              <div className="w-full flex justify-between items-end">
                <button
                  type="button"
                  onClick={() =>
                    navigate(
                      location?.state
                        ? `/marketing?tab=${location?.state}`
                        : `/marketing`
                    )
                  }
                  className="btn btn-gray w-16"
                >
                  Back
                </button>

                <div className="flex gap-2">
                  {!isEdit ||
                  (isEdit && postDetails?.post_status === "draft") ? (
                    <button
                      type="button"
                      className="text-sm py-2 px-7 text-lime-500 border border-lime-500 w-30 shadow-sm shadow-gray-300 disabled:cursor-not-allowed disabled:opacity-55"
                      onClick={() => {
                        setSaveAsDraft(true);
                        handleSubmit(values);
                      }}
                      disabled={saveAsDraft && isLoading}
                    >
                      <span className="h-[20px] flex justify-center items-center">
                        {saveAsDraft && isLoading ? (
                          <PulseLoader color="#8bc34a" size={12} />
                        ) : (
                          "Save Draft"
                        )}
                      </span>
                    </button>
                  ) : null}

                  <SpinnerButton
                    className="btn-green"
                    title={"Post"}
                    type={"submit"}
                    action={() => setSaveAsDraft(false)}
                    loading={!saveAsDraft && isLoading}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
