import {
  Bars3Icon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ConfirmationModal from "components/common/ConfirmationModal";
import { getItemImage, isValidArray, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { deleteMenuItem } from "redux/gmbLocation/deleteMenuItemSlice";
import deleteIcon from "assets/images/deleteIcon.svg";
import editIcon from "assets/images/editIcon.svg";
import ItemModal from "./ItemModal";
import dummyImage from "assets/images/dummyImage.svg";
import classNames from "classnames";
import { Bars2Icon } from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deleteSection } from "redux/gmbLocation/deleteSectionSlice";

// ItemTypes definition
const ItemTypes = {
  CARD: "card",
};

export default function SectionItem({
  itemData = {},
  index = 0,
  moveCard = () => {},

  dropCard = () => {},
  sectionData = {},
  onHover = false,
  setOnHover = () => {},
  handleSectionDetail = () => {},
  menuId = 0,
  idForDelete = 0,
  setIdForDelete = () => {},
  setSectionData = () => {},
  handleGetSectionList = () => {},
  setItemList = () => {},
  sectionDeleteLoading = false,
  setSectionIdForEdit = () => {},
  setIsSectionModalOpen = () => {},
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const itemId = !!itemData?.id ? itemData?.id : 0;
  const ref = useRef(null);
  const handleRef = useRef(null);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const deleteItemLoading = useSelector(
    (state) => state?.deleteMenuItem?.loading ?? false,
    shallowEqual
  );

  // Function for re-order item list
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // if (dragIndex === hoverIndex) return;

      dropCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ itemId, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(handleRef);
  drop(ref);
  preview(ref);

  const handleDeleteSection = () => {
    dispatch(deleteSection(idForDelete)).then((data) => {
      setIsConfirmationModalOpen(false);
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Section deleted successfully."
        );
        setSearchParams({ tab: "menu", menu: menuId });
        handleGetSectionList(true);
        setSectionData({});
        setItemList([]);
      } else {
        handleServerValidation(data);
      }
    });
  };

  return (
    <>
      <div
        ref={ref}
        key={itemData?.id}
        className={classNames(
          "flex items-center min-h-8 gap-2 px-2 hover:bg-gray-100",
          {
            "border-b border-[#F25B8E] bg-gray-100 shadow-sm text-gray-950 ":
              sectionData?.id == itemData?.id,
            "text-gray-700": sectionData?.id !== itemData?.id,
            "opacity-0": isDragging,
          }
        )}
      >
        <div
          className={`w-full flex gap-2 items-center`}
          data-handler-id={handlerId}
        >
          <button
            ref={handleRef}
            className={`h-6 w-6 cursor-grab font-semibold`}
          >
            <Bars2Icon strokeWidth={1.8} className="w-5 h-5 cursor-grab" />
          </button>
          <div
            className={`w-full  cursor-pointer flex items-center ${
              onHover ? `justify-between` : `justify-normal`
            } `}
            onMouseEnter={() => {
              setOnHover(itemData?.id);
            }}
            onMouseLeave={() => {
              setOnHover(0);
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleSectionDetail(itemData?.id);
              navigate(
                `/location?tab=menu&menu=${menuId}&sectionId=${itemData?.id}`
              );
            }}
          >
            <span
              className={classNames("max-w-[85%]", {
                "text-gray-950": sectionData?.id == itemData?.id,
                "text-gray-700": sectionData?.id !== itemData?.id,
              })}
            >
              <span className="">{itemData?.section_name}</span>
              <span className="text-sm text-gray-700 font-semibold ml-1">
                ({itemData?.food_items_count ?? 0})
              </span>
            </span>

            {onHover == itemData?.id && (
              <div className=" flex items-center max-w-[15%] w-full">
                <button
                  title="Edit"
                  className={`h-full   cursor-pointer  w-full`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSectionIdForEdit(itemData?.id);
                    setIsSectionModalOpen(true);
                  }}
                >
                  <PencilSquareIcon height={18} width={18} />
                </button>

                <button
                  title="Delete"
                  className={`h-full   cursor-pointer  w-full`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIdForDelete(itemData?.id);
                    setIsConfirmationModalOpen(true);
                  }}
                >
                  <TrashIcon height={18} width={18} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={() => {
            handleDeleteSection();
          }}
          isLoading={sectionDeleteLoading}
          message="You want to delete this section?"
        />
      )}
    </>
  );
}
