import classNames from "classnames";
import MovableBarcode from "components/campaigns/digitalOffer/MovableBarcode";
import SpinnerButton from "components/common/fields/SpinnerButton";
import {
  getCampaignCouponImage,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import {
  FILE_SIZE,
  SUPPORTED_FORMATS_IMAGE,
  handleServerValidation,
} from "module/validation";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCampaignDetail } from "redux/campaign/getCampaignDetailSlice";
import { updateCampaignDetail } from "redux/campaign/updateCampaignDetailSlice";

const DigitalOfferStep3 = (props) => {
  const {
    setSelectStep,
    type,
    isEdit,
    companyId = 0,
    currentLocationId = 0,
    campaignId = 0,
    updateCampaignDetailLoading,
  } = props;

  const dispatch = useDispatch();
  const { id } = useParams();
  const barcodeRef = useRef(null);
  const expirationDateRef = useRef(null);
  const navigate = useNavigate();

  const [imageName, setImageName] = useState("");
  const [image, setImage] = useState(null);
  const [frameId, setFrameId] = useState("");
  const [targetFrames, setTargetFrames] = useState({
    expirationDate: {
      width: 0,
      height: 0,
      rotate: 0,
      translate: [0, 0],
    },
    barcode: {
      width: 0,
      height: 0,
      rotate: 0,
      translate: [0, 150],
    },
  });

  const [{ getCampaignDetailData = {} }] = useSelector((state) => [
    {
      getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
    },
  ]);

  const digitalOfferStep3Data = getCampaignDetailData?.coupon_template ?? {};

  useEffect(() => {
    if (id) {
      dispatch(getCampaignDetail(id));
    }
  }, []);

  useEffect(() => {
    if (isValidObject(digitalOfferStep3Data)) {
      const couponImage = digitalOfferStep3Data?.coupon_image
        ? getCampaignCouponImage(digitalOfferStep3Data?.coupon_image)
        : "";
      const dimensions = {
        expirationDate: {
          width: 0,
          height: 0,
          rotate: 0,
          translate: [
            Number(digitalOfferStep3Data?.textPositionX ?? 0),
            Number(digitalOfferStep3Data?.textPositionY ?? 0),
          ],
        },
        barcode: {
          width: 0,
          height: 0,
          rotate: 0,
          translate: [
            Number(digitalOfferStep3Data?.barcodePositionX ?? 0),
            Number(digitalOfferStep3Data?.barcodePositionY ?? 150),
          ],
        },
      };
      setImage(couponImage);
      setTargetFrames(dimensions);
      setImageName(digitalOfferStep3Data?.coupon_image ?? "");

      setTimeout(() => {
        if (barcodeRef && expirationDateRef) {
          barcodeRef.current.style.left = `${dimensions?.["barcode"]?.translate[0]}px`;
          barcodeRef.current.style.top = `${dimensions?.["barcode"]?.translate[1]}px`;
          expirationDateRef.current.style.left = `${dimensions?.["expirationDate"]?.translate[0]}px`;
          expirationDateRef.current.style.top = `${dimensions?.["expirationDate"]?.translate[1]}px`;
        }
      }, 100);
    }
  }, [digitalOfferStep3Data]);

  const handleSubmit = () => {
    if (image) {
      const isValidFileSize =
        typeof image === "object" ? image?.size < FILE_SIZE : true;
      const isValidFileType =
        typeof image === "object"
          ? SUPPORTED_FORMATS_IMAGE.includes(image?.type)
          : true;

      if (!isValidFileSize) {
        showErrorMsg(
          "Coupon image file is too large, Please upload less than 5MB"
        );
      } else if (!isValidFileType) {
        showErrorMsg("Coupon image file must be of type jpg, jpeg or png");
      } else {
        const formData = {
          campaign_id: getCampaignDetailData?.id ?? 0,
          company_id: companyId,
          location_id: currentLocationId,
          coupon_image: typeof image === "string" ? "" : image,
          textPositionX: targetFrames?.expirationDate?.translate[0],
          textPositionY: targetFrames?.expirationDate?.translate[1],
          barcodePositionX: targetFrames?.barcode?.translate[0],
          barcodePositionY: targetFrames?.barcode?.translate[1],
          imageHeight: 600,
          imageWidth: 800,
        };
        dispatch(
          updateCampaignDetail({
            id: getCampaignDetailData?.id,
            formData: formData,
          })
        ).then((data) => {
          if (data?.payload?.code === 200) {
            isEdit && isValidObject(digitalOfferStep3Data)
              ? showSuccessMsg(
                  data?.payload?.message ?? "Campaign updated successfully."
                )
              : showSuccessMsg("Campaign added successfully.");
            navigate(`/campaigns/list?type=${type}`);
          } else {
            handleServerValidation(data);
            // showErrorMsg("Something went wrong");
          }
        });
      }
    } else {
      showErrorMsg("Please upload image");
    }
  };

  const handleTargetFrames = (frameId, value, coordinate) => {
    const transArray = [...targetFrames[frameId].translate];
    transArray[coordinate] = Number(value);
    const dataObj = {
      ...targetFrames,
      [frameId]: {
        ...targetFrames[frameId],
        translate: transArray,
      },
    };
    setTargetFrames(dataObj);
  };

  return (
    <div className="border border-gray-200 p-3 bg-white mt-4">
      <div className="mt-4 space-y-6 ">
        <div className="w-full">
          <div className="w-full md:w-1/2 mb-3">
            <label htmlFor="image" className="input-field-label">
              Offer Image
            </label>
            <div className="mt-2 relative">
              <label htmlFor="image">
                <input
                  type="file"
                  title={imageName}
                  name="image"
                  id="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => {
                    let filesData = e.target.files[0];
                    setImage(filesData);
                    setImageName(filesData?.name);
                  }}
                  autoComplete="image"
                  placeholder="Enter Image"
                  className="file-field"
                />

                <p className="absolute min-w-28 top-[2px] left-[115px] right-[10px] bg-white p-1 text-neutral-700 truncate">
                  {imageName}
                </p>
              </label>

              <span className="text-sm font-light mr-2">
                (Upload: JPEG or PNG, 1000x1000, max 500k)
              </span>
            </div>
          </div>
          <div className="flex  flex-wrap -mx-3 mb-3">
            <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0">
              <label htmlFor="expireX" className="input-field-label">
                Expiration X
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="expireX"
                  id="expireX"
                  value={targetFrames?.expirationDate?.translate[0] ?? 0}
                  min={0}
                  readOnly={frameId !== "expirationDate"}
                  onChange={(e) => {
                    handleTargetFrames("expirationDate", e.target.value, 0);
                  }}
                  placeholder="Enter X Expiration"
                  className={classNames("input-field", {
                    "opacity-40 cursor-default":
                      frameId !== "expirationDate" ? true : false,
                  })}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0">
              <label htmlFor="name" className="input-field-label">
                Expiration Y
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="name"
                  id="name"
                  value={targetFrames?.expirationDate?.translate[1] ?? 0}
                  min={0}
                  readOnly={frameId !== "expirationDate"}
                  onChange={(e) => {
                    handleTargetFrames("expirationDate", e.target.value, 1);
                  }}
                  placeholder="Enter Y Expiration"
                  className={classNames("input-field", {
                    "opacity-40 cursor-default":
                      frameId !== "expirationDate" ? true : false,
                  })}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0">
              <label htmlFor="name" className="input-field-label">
                Barcode X
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="name"
                  id="name"
                  value={targetFrames?.barcode?.translate[0] ?? 0}
                  min={0}
                  readOnly={frameId !== "barcode"}
                  onChange={(e) => {
                    handleTargetFrames("barcode", e.target.value, 0);
                  }}
                  placeholder="Enter X Barcode"
                  className={classNames("input-field", {
                    "opacity-40 cursor-default":
                      frameId !== "barcode" ? true : false,
                  })}
                />
              </div>
            </div>
            <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0">
              <label htmlFor="name" className="input-field-label">
                Barcode Y
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="name"
                  id="name"
                  value={targetFrames?.barcode?.translate[1] ?? 0}
                  min={0}
                  readOnly={frameId !== "barcode"}
                  onChange={(e) => {
                    handleTargetFrames("barcode", e.target.value, 1);
                  }}
                  placeholder="Enter Y Barcode"
                  className={classNames("input-field", {
                    "opacity-40 cursor-default":
                      frameId !== "barcode" ? true : false,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-auto">
        <MovableBarcode
          image={image}
          setTargetFrames={setTargetFrames}
          targetFrames={targetFrames}
          setFrameId={setFrameId}
          frameId={frameId}
          barcodeRef={barcodeRef}
          expirationDateRef={expirationDateRef}
        />
      </div>

      <div className="flex justify-center gap-x-3 mt-6">
        <button
          onClick={() => {
            setSelectStep(2);
            navigate(`/campaigns/edit/${campaignId}/${type}?step=2`);
          }}
          className="btn btn-light-gray ml-2"
        >
          Back
        </button>
        <SpinnerButton
          className="btn-pink"
          title={"Launch"}
          action={() => handleSubmit()}
          type={"button"}
          loading={updateCampaignDetailLoading}
        />
      </div>
    </div>
  );
};

export default DigitalOfferStep3;
