import {
  Bars3Icon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import { getCurrentLocation, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { selectServicePost } from "redux/gmbLocation/SelectServicePostSlice";
import { deleteService } from "redux/gmbLocation/deleteServiceSlice";

const ItemTypes = {
  CARD: "card",
};

export default function Services({
  serviceData = {},
  index = 0,
  moveCard = () => {},
  dropCard = () => {},
  handleServiceList = () => {},
  setIsSectionModalOpen = () => {},
  setServiceId = () => {},
  setSelectedServices = [],
  areAllServicesSelected = false,
  selectedServices = [],
  setAreAllServicesSelected = () => {},
  setIsSelectedServicesChanged = () => {},
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { id: currentLocationId } = getCurrentLocation();
  const category = searchParams?.get("category") || 0;
  const serviceId = serviceData?.id || 0;
  const ref = useRef(null);
  const handleRef = useRef(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isServiceSelected, setIsServiceSelected] = useState(
    serviceData?.is_selected
  );

  const deleteServiceLoading = useSelector(
    (state) => state?.deleteService?.loading ?? false,
    shallowEqual
  );

  // Function for re-order Service list
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // if (dragIndex === hoverIndex) return;

      dropCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ serviceId, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(handleRef);
  drop(ref);
  preview(ref);
  //

  const handleDeleteService = () => {
    // location_id: currentLocationId,
    // category_id: category,
    const formData = {
      service_id: serviceId,
    };
    dispatch(deleteService(serviceId)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Item deleted successfully."
        );
        handleServiceList();
      } else {
        handleServerValidation(data);
      }
      setIsConfirmationModalOpen(false);
    });
  };

  const handleEdit = (id) => {
    setIsSectionModalOpen(true);
    setServiceId(id);
  };

  const handleDelete = (id) => {
    setIsConfirmationModalOpen(true);
    setServiceId(id);
  };

  const handleSelectService = (selected) => {
    const formData = {
      location_id: currentLocationId,
      service_id: serviceData?.id,
      is_selected: selected,
    };
    dispatch(selectServicePost(formData)).then();
  };

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          "h-[125px] p-4 flex gap-4 shadow-md shadow-gray-200 my-1 items-center justify-between mb-2",
          {
            "opacity-0": isDragging,
          }
        )}
      >
        <div className="h-full flex flex-col items-center gap-5">
          <div className="flex items-center">
            <input
              id="select-service"
              name="select-service"
              type="checkbox"
              className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-0 cursor-pointer"
              checked={
                areAllServicesSelected || selectedServices.includes(serviceId)
              }
              // onChange={(e) => {
              //   const newValue = e.target.checked ? "yes" : "no";
              //   // handleSelectService(e.target.checked ? "yes" : "no");
              //   setIsServiceSelected(newValue);
              //   setSelectedServices((prevSelectedServices) => {
              //     if (newValue == "yes") {
              //       setIsSelectedServicesChanged(true);
              //       return [...prevSelectedServices, serviceId];
              //     } else {
              //       setAreAllServicesSelected(false);
              //       setIsSelectedServicesChanged(true);
              //       return prevSelectedServices.filter(
              //         (id) => id !== serviceId
              //       );
              //     }
              //   });
              // }}
              onChange={(e) => {
                const newValue = e.target.checked ? "yes" : "no";
                setIsServiceSelected(newValue);
                setSelectedServices((prevSelectedServices) => {
                  if (newValue === "yes") {
                    // Check if adding this service ID would exceed the limit
                    if (prevSelectedServices.length < 100) {
                      setIsSelectedServicesChanged(true);
                      return [...prevSelectedServices, serviceId];
                    } else {
                      // Optionally show a message to the user
                      alert("You cannot select more than 100 services.");
                      return prevSelectedServices; // Return previous state if limit exceeded
                    }
                  } else {
                    // Unselecting the service
                    setAreAllServicesSelected(false);
                    setIsSelectedServicesChanged(true);
                    return prevSelectedServices.filter(
                      (id) => id !== serviceId
                    );
                  }
                });
              }}
            />
          </div>
          <button ref={handleRef} className="h-6 w-6 cursor-grab font-semibold">
            <Bars3Icon strokeWidth={1.8} />
          </button>
        </div>
        <div
          className="w-full h-full flex gap-4 items-start"
          data-handler-id={handlerId}
        >
          <div className="flex gap-2 w-[95%]">
            <div className="w-full">
              <div className="text-md font-semibold text-gray-700 flex justify-between items-center">
                <div className="w-full">
                  <span className="">{serviceData?.service_name ?? ""}</span>{" "}
                  {serviceData?.is_gmb_uploaded === "yes" && (
                    <span className="text-xs">({"Uploaded on Google"})</span>
                  )}
                </div>
              </div>
              <p className="w-full text-md text-gray-700 flex justify-between mb-4">
                <span className="text-sm">
                  {serviceData?.service_description}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="h-full flex gap-2 items-center">
          <button
            title="Edit"
            className="h-5 w-5 cursor-pointer"
            onClick={() => handleEdit(serviceId)}
          >
            <PencilSquareIcon />
          </button>
          <button
            title="Delete"
            className="h-5 w-5 cursor-pointer"
            onClick={() => handleDelete(serviceId)}
          >
            <TrashIcon />
          </button>
        </div>
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={handleDeleteService}
          isLoading={deleteServiceLoading}
          message="Are you sure you want to delete this service? This will also remove the item from your GMB account."
        />
      )}
    </>
  );
}
