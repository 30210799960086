import classNames from "classnames";
import { showPerPage } from "module/util";

const Pagination = (props) => {
  const { totalRecords, perPage, setCurrentPage, currentPage, setPerPage } =
    props;

  const onChangeInSelect = (event) => {
    const page = event.target.value ? Number(event.target.value) : showPerPage;
    page > perPage && setCurrentPage(1);
    setPerPage(page);
  };

  function calculatePage(perPage, totalRecords) {
    return Math.ceil(totalRecords / perPage)
      ? Math.ceil(totalRecords / perPage)
      : 1;
  }

  const totalPages = calculatePage(perPage, totalRecords);

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) : 1;
    if (page && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const showCurrentPage = currentPage <= totalPages ? currentPage : 1;
  const isDisableNext = showCurrentPage >= totalPages;
  const isDisablePrevious = showCurrentPage <= 1;

  return (
    <div className="flex items-center justify-start gap-x-4  p-2 mt-1 leading-3">
      <div className="flex items-center gap-x-2">
        <button
          color="primary"
          onClick={() => setCurrentPage(1)}
          disabled={isDisablePrevious}
          className={classNames("btn btn-gray", {
            "hover:cursor-pointer": !isDisablePrevious,
            "hover:bg-gray-600": isDisablePrevious,
          })}
        >
          {"<<"}
        </button>
        <button
          color="primary"
          onClick={() => setCurrentPage(showCurrentPage - 1)}
          disabled={showCurrentPage <= 1}
          className={classNames("btn btn-gray", {
            "hover:cursor-pointer": !isDisablePrevious,
            "hover:bg-gray-600": isDisablePrevious,
          })}
        >
          {"<"}
        </button>
        <span className="text-sm">Page</span>
        <span className="text-sm font-semibold">
          {showCurrentPage} of {totalPages}
        </span>
      </div>

      <div className="flex gap-x-2 items-center">
        <button
          color="primary"
          onClick={() => setCurrentPage(showCurrentPage + 1)}
          disabled={isDisableNext}
          className={classNames("btn btn-gray", {
            "hover:cursor-pointer": !isDisableNext,
            "hover:bg-gray-600": isDisableNext,
          })}
        >
          {">"}
        </button>
        <button
          color="primary"
          onClick={() => setCurrentPage(totalPages)}
          disabled={isDisableNext}
          className={classNames("btn btn-gray", {
            "hover:cursor-pointer": !isDisableNext,
            "hover:bg-gray-600": isDisableNext,
          })}
        >
          {">>"}
        </button>
        <span className="text-sm ml-2">
          Total : <span className="font-semibold">{totalRecords ?? 0}</span>
        </span>

        <span className="text-sm ml-2">Go to page: </span>
        <input
          type="number"
          min={1}
          style={{ width: 70 }}
          max={totalPages ?? 0}
          onChange={onChangeInInput}
          className="text-sm"
          value={showCurrentPage ?? 1}
        />
        <select
          type="select"
          value={perPage}
          onChange={onChangeInSelect}
          className="text-sm"
        >
          {[10, 20, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Pagination;
