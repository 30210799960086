import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getAssociateList = createAsyncThunk(
  "reputation/associate-locations",
  async (data, { rejectWithValue }) => {
    const {
      google_location_id,
      location_id,
      google_location_company_name,
      google_location_category,
      type,
      can_add_menu,
      can_modify_service_list,
      region_code,
      language_code,
    } = data;

    try {
      const response = await getApi(
        `api/gmb/associate_location?company_location_id=${location_id}&google_location_id=${google_location_id}&google_location_company_name=${google_location_company_name}&google_location_category=${google_location_category}&type=${type}&can_add_menu=${can_add_menu}&can_modify_service_list=${can_modify_service_list}&region_code=${region_code}&language_code=${language_code}`
      );

      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAssociateListSlice = createSlice({
  name: "getAssociateList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssociateList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAssociateList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getAssociateList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getAssociateListSlice.reducer;
