import { GoogleOAuthProvider } from "@react-oauth/google";
import BreadCrumb from "components/common/BreadCrumb";
import Header from "components/common/Header";
import MulticolorDiv from "components/common/MulticolorDiv";
import Sidebar from "components/common/Sidebar";
import { setInterceptor } from "module/api";
import {
  defaultHeatMapConfig,
  getAuthToken,
  getCompanyStatus,
  getUserData,
  getUserDataByParam,
  isAdmin,
  isCompanyUser,
  isSuperAdmin,
  isValidObject,
  setDateFormatLocal,
  setHeatMapConfig,
  showErrorMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getSettingsDetails } from "redux/settings/getSettingsDetailsSlice";

const MainLayout = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const isLoggedIn = !!getAuthToken();
  const currentUserData = getUserData() ? JSON.parse(getUserData()) : "";
  const companyStatus = getCompanyStatus();

  const adminRoutes = [
    "/company/list",
    "/company/add",
    "/company/edit",
    "/admin-user",
    "/lead-list",
    "/dashboard",
    "/settings",
  ];

  const commonRoutes = [
    "/dashboard",
    // "/marketplace",
    "/reputation",
    "/location",
    "/automation",
  ];

  const isCommonRoutes = commonRoutes.find((items) =>
    pathname?.startsWith(items)
  );

  const handleRoutesOnNoLocation = () => {
    const locationCount = getUserDataByParam("LOCATION_COUNT");
    const noLocationRoutes = ["/dashboard", "/account", "/account/locations"];

    if (
      isCompanyUser() &&
      locationCount == 0 &&
      !noLocationRoutes.includes(pathname) &&
      ["active", "pending"].includes(companyStatus)
    ) {
      showErrorMsg("You can not access that page.");
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    } else {
      window.scrollTo({ top: 0 });
      if (
        isCompanyUser() &&
        !!adminRoutes.find((items) => pathname?.startsWith(items)) &&
        !isCommonRoutes
      ) {
        showErrorMsg("You can not access that page.");
        navigate("/dashboard");
      } else if (
        (isAdmin() || isSuperAdmin()) &&
        !!!adminRoutes.find((items) => pathname?.startsWith(items)) &&
        !isCommonRoutes
      ) {
        showErrorMsg("You can not access that page.");
        navigate("/dashboard");
      }
    }
  }, [navigate]);

  useEffect(() => {
    setInterceptor();
    handleRoutesOnNoLocation();
    const heatMapConfig = JSON.stringify(defaultHeatMapConfig);

    dispatch(getSettingsDetails()).then((data) => {
      if (data?.payload?.code === 200) {
        if (isValidObject(data?.payload?.dataObj)) {
          const dateFormat =
            data?.payload?.dataObj?.date_format?.settings_value ?? "MM/dd/yyyy";
          const heatmapColorObj =
            data?.payload?.dataObj?.heatmap_color?.settings_value ?? "";
          const heatmapZoomLevel =
            data?.payload?.dataObj?.heatmap_zoom?.settings_value ?? 13;
          const mapZoomLevel =
            data?.payload?.dataObj?.map_zoom?.settings_value ?? 17;
          const heatmapColors = heatmapColorObj
            ? JSON.parse(heatmapColorObj)
            : heatMapConfig;
          const heatmapConfigObj = {
            heatMapZoom: heatmapZoomLevel,
            mapZoom: mapZoomLevel,
            heatMapColor1: heatmapColors?.color_1 ?? "#86b54b",
            heatMapColor2: heatmapColors?.color_2 ?? "#fcbe29",
            heatMapColor3: heatmapColors?.color_3 ?? "#d21e60",
          };
          setDateFormatLocal(dateFormat);
          setHeatMapConfig(JSON.stringify(heatmapConfigObj));
        } else {
          setDateFormatLocal("MM/dd/yyyy");
          setHeatMapConfig(heatMapConfig);
        }
      } else {
        setDateFormatLocal("MM/dd/yyyy");
        setHeatMapConfig(heatMapConfig);
      }
    });
  }, []);

  return (
    <div className="sidebar">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* https://apipendio.projectdemo.us/gmb/oauth-callback */}
      <GoogleOAuthProvider
        clientId="267003296968-smmh1d5q4qovv0uu69pqjfbq1rvfac9k.apps.googleusercontent.com"
        // redirectUri="http://127.0.0.1:3000/dashboard"
        // responseType="code"
        // scope={[
        //   "https://www.googleapis.com/auth/business.manage",
        //   "https://www.googleapis.com/auth/userinfo.profile",
        //   "https://www.googleapis.com/auth/userinfo.email",
        // ]}
      >
        <main className="lg:pl-72">
          <MulticolorDiv />
          <Header
            currentUserData={currentUserData}
            setSidebarOpen={setSidebarOpen}
          />
          <BreadCrumb />

          <div className="px-4 py-4 sm:px-4 lg:px-4 lg:py-4 bg-gray-100">
            {" "}
            {children} <Outlet />
          </div>
        </main>
      </GoogleOAuthProvider>
    </div>
  );
};

export default MainLayout;
