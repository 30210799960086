import { Dialog, Transition } from "@headlessui/react";
import SpinnerButton from "components/common/fields/SpinnerButton";
import SwitchButton from "components/common/fields/SwitchButton";
import { getCurrentLocation } from "module/util";
import { handleServerValidation } from "module/validation";
import { Fragment, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addMenu } from "redux/gmbLocation/addMenuSlice";
import { getMenuDetail } from "redux/gmbLocation/getMenuDetailSlice";
import { updateMenu } from "redux/gmbLocation/updateMenuSlice";

export default function MenuModal({
  isModalOpen,
  setModalOpen,
  currentLocationId = "",
  currentCompanyId = "",
  handleAction = () => {},
  menuId = 0,
}) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const [menuName, setMenuName] = useState("");
  const [menuVisibility, setMenuVisibility] = useState(true);
  const { google_business_type = "service" } = getCurrentLocation();
  const isEdit = !!menuId;

  const MenuOrService =
    google_business_type === "Restaurant" ? "Menu" : "Service";

  const addMenuLoading = useSelector(
    (state) => state?.addMenu?.loading ?? false,
    shallowEqual
  );

  const updateMenuLoading = useSelector(
    (state) => state?.updateMenu?.loading ?? false,
    shallowEqual
  );

  const isLoading = addMenuLoading || updateMenuLoading;

  const handleGetMenuDetails = () => {
    const formData = {
      id: menuId,
    };
    dispatch(getMenuDetail(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const menuDetails = data?.payload?.dataObj;
        setMenuName(menuDetails?.menu_name);
        const visibility =
          menuDetails?.menu_visibility == "yes"
            ? true
            : menuDetails?.menu_visibility == "no" && false;
        setMenuVisibility(visibility);
      }
    });
  };

  const handleSubmit = () => {
    const formData = {
      menu_name: menuName,
      menu_visibility: menuVisibility ? "yes" : "no",
    };
    if (isEdit) {
      dispatch(updateMenu({ ...formData, id: menuId })).then((data) => {
        if (data?.payload?.code == 200) {
          setModalOpen(false);
          handleAction();
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      dispatch(
        addMenu({
          ...formData,
          company_id: currentCompanyId,
          location_id: currentLocationId,
        })
      ).then((data) => {
        if (data?.payload?.code == 200) {
          setModalOpen(false);
          handleAction();
        } else handleServerValidation(data);
      });
    }
  };

  useEffect(() => {
    if (isEdit) {
      handleGetMenuDetails();
    } else {
      setMenuName("");
      setMenuVisibility(true);
    }
  }, []);

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-[70%] items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-visible rounded-sm bg-white text-left align-middle shadow-xl transition-all p-5">
                <div className="flex justify-between">
                  <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
                    <span className="font-bold text-lg">
                      Add {MenuOrService}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center outline-0"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="w-full h-[1px] bg-gray-300 mt-1"></div>
                <div className="mt-5 w-full">
                  <div className="flex flex-start w-full gap-1 items-center">
                    <label
                      className="font-light leading-6 text-gray-900 text-center w-[25%] "
                      htmlFor="tag"
                    >
                      {MenuOrService} Name :
                    </label>
                    <div className=" w-[75%]">
                      <input
                        id="menu_name"
                        name="menu_name"
                        type="text"
                        value={menuName ?? ""}
                        onChange={(e) => setMenuName(e?.target?.value)}
                        placeholder={`${MenuOrService} Name`}
                        className={`input-field`}
                      />
                    </div>
                  </div>

                  <div className="flex w-full mt-2 gap-1 items-center">
                    <label
                      className="font-light leading-6 text-gray-900 text-center w-[25%]"
                      htmlFor="description"
                    >
                      {MenuOrService} Visibilty :
                    </label>
                    <div className="w-[75%]">
                      <SwitchButton
                        name="menu_visibility"
                        value={menuVisibility}
                        handleToggleForCampaigns={(value) => {
                          const visibility = value ? "yes" : "no";
                          setMenuVisibility(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-between  gap-x-4">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <SpinnerButton
                    className="inline-flex w-full justify-center btn-pink"
                    type="button"
                    action={handleSubmit}
                    title="Save"
                    isDisable={!menuName}
                    loading={isLoading}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
