import { PlusIcon } from "@heroicons/react/24/solid";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { isObjectValuesValid, isValidObject } from "module/util";
import React from "react";

const SectionForm = (props) => {
  const {
    sectionBasicDetails = {},
    setSectionBasicDetails = {},
    isCompanyActive,
    handleUpdateSection = () => {},
    sectionData = {},
    setAddItemModalOpen = false,
  } = props;

  return (
    <div className="section-form w-full border-b-2 border-gray-400 p-2">
      {/* <div className="flex flex-start w-full gap-1 items-center">
        <label
          className="font-light leading-6 text-gray-900 text-center w-[30%] md:w-[15.3%] xl:w-[11.5%] 2xl:w-[22.5%]"
          htmlFor="tag"
        >
          Section Name :
        </label>
        <div className="w-full">
          <input
            className={`input-field`}
            id="sectionName"
            name="sectionName"
            type="text"
            value={sectionBasicDetails?.section_name ?? ""}
            onChange={(e) => {
              setSectionBasicDetails({
                ...sectionBasicDetails,
                section_name: e.target.value,
              });
            }}
            placeholder="Section Name"
          />
        </div>
      </div>

      <div className="flex w-full mt-2 gap-2 items-center">
        <label
          className="font-light leading-6 text-gray-900 text-center w-[30%] md:w-[15%] xl:w-[11.3%] 2xl:w-[22%]"
          htmlFor="description"
        >
          Section Desc :
        </label>
        <input
          className={`input-field`}
          id="description"
          name="description"
          type="text"
          value={sectionBasicDetails?.section_description ?? ""}
          onChange={(e) => {
            setSectionBasicDetails({
              ...sectionBasicDetails,
              section_description: e.target.value,
            });
          }}
          placeholder="Section Description"
        />
      </div> */}

      {isCompanyActive && (
        <div className="flex w-full gap-2 items-center">
          <div className=" w-full flex gap-2 justify-between items-center px-3">
            {/* <div className="ml-56">
              <SpinnerButton
                className="btn-green w-20 mr-2"
                title={`Update`}
                action={() => {
                  if (
                    isValidObject(sectionBasicDetails) &&
                    isObjectValuesValid(sectionBasicDetails) &&
                    JSON.stringify(sectionBasicDetails) !==
                      JSON.stringify(sectionData)
                  ) {
                    handleUpdateSection();
                  }
                }}
                type={"button"}
                isDisable={
                  !isValidObject(sectionBasicDetails) ||
                  !isObjectValuesValid(sectionBasicDetails) ||
                  JSON.stringify(sectionBasicDetails) ===
                    JSON.stringify(sectionData)
                }
              />
              <button
                type="button"
                className="btn btn-light-gray"
                onClick={() => {
                  setSectionBasicDetails(sectionData);
                }}
              >
                Cancel
              </button>
            </div> */}
            <div>
              <span className="font-semibold text-gray-700">
                {sectionData?.section_name}
              </span>
            </div>
            <div>
              <button
                type={"button"}
                className="btn-pink"
                onClick={() => {
                  setAddItemModalOpen(true);
                }}
              >{`Add Items`}</button>
              {/* <button
                className="text-pink-600 hover:text-pink-700 font-semibold  w-full flex items-center gap-1"
                onClick={() => {
                  setAddItemModalOpen(true);
                }}
              >
                <PlusIcon className="w-5 h-5" />

                <span className="text-sm font-bold">Add Items</span>
              </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionForm;
