export const pageTitles = {
  homePage: "Home page",
  dashboard: "dashboard",
  campaings: "Campaings",
  campaingsType: "Campaings-select type",
  campaingsDirectMail: "Campaings - Direct Mail",
  campaingsDigitalCoupon: "Campaings - Digital Coupon",
  campaingsPrintAd: "Campaings - Print Ad",
  redemptions: "Redemptions",
  leadList: "Lead Lists",
  locations: "Locations",
  customers: "Customers",
  account: "Account",
  adminUsers: "Admin Users",
  companies: "Companies",
  login: "Login",
  register: "Sign Up",
  resetPassword: "Reset Password",
  marketplace: "Marketplace",
  reputation: "Reputation",
  settings: "Settings",
  companySettings: "Settings",
  verifyCode: "verify Security Code",
  contactsCustomer: "Contacts - Customers",
  contactsProspect: "Contacts - Prospects",
  contactsLeadList: "Contacts - Lead List",
  contactsImportLog: "Contacts - Import Logs",
  guestWifi: "Guest WIFI",
  customerVisit: "Customer - Visits",
  contactsAll: "Contacts - All",
  about: "Location - About",
  locationContact: "Location - Contact",
  location: "Location - Information",
  locationMenu: "Location - Menu",
  extra: "Location - Extra",
  posts: "Posts",
  postCalender: "Post - Calender",
  postCampaigns: "Post - Campaigns",
  postPending: "Post - Pending",
  postComplete: "Post - Complete",
  postError: "Post - Error",
  singlePost: "Post - Single",
  campaignPost: "Post - Campaign",
};

export const companyNameForMsg = {
  active: "active",
  pending: "pending",
  archived: "archive",
  denied: "deny",
  delete: "delete",
};

export const leadsColumnArray = [
  { id: "first_name", name: "First Name" },
  { id: "middle_name", name: "Middle Name" },
  { id: "last_name", name: "Last Name" },
  { id: "full_name", name: "Full Name" },
  { id: "address1", name: "Address 1" },
  { id: "city", name: "City" },
  { id: "state", name: "State" },
  { id: "zip", name: "Zip" },
  { id: "phone", name: "Phone" },
  { id: "email", name: "Email" },
  { id: "age", name: "Age" },
  { id: "income", name: "Income" },
  { id: "gender", name: "Gender" },
  { id: "marital_status", name: "Marital Status" },
  { id: "length_in_residence", name: "Length In Residence" },
  { id: "children_present", name: "Children Present" },
  { id: "seniors_present", name: "Seniors Present" },
  { id: "owner_renter", name: "Owner Renter" },
  { id: "no_of_people", name: "No. Of People" },
  { id: "home_type", name: "Home Type" },
  { id: "plus4", name: "Plus 4" },
  { id: "crrt", name: "CRRT" },
  { id: "dpb", name: "DPB" },
  { id: "user_id", name: "Customer Id" },
];
export const leadsColumnObj = {
  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  full_name: "Full Name",
  address1: "Address 1",
  city: "City",
  state: "State",
  zip: "Zip",
  phone: "Phone",
  email: "Email",
  age: "Age",
  income: "Income",
  gender: "Gender",
  marital_status: "Marital Status",
  length_in_residence: "Length In Residence",
  children_present: "Children Present",
  seniors_present: "Seniors Present",
  owner_renter: "Owner Renter",
  no_of_people: "No. Of People",
  home_type: "Home Type",
  plus4: "Plus 4",
  crrt: "CRRT",
  dpb: "DPB",
  user_id: "Customer Id",
};

export const customersColumnArray = [
  { id: "first_name", name: "First Name" },
  { id: "last_name", name: "Last Name" },
  { id: "address1", name: "Address 1" },
  { id: "address2", name: "Address 2" },
  { id: "city", name: "City" },
  { id: "state", name: "State" },
  { id: "zip", name: "Zip" },
  { id: "phone", name: "Phone" },
  { id: "email", name: "Email" },
];

export const customersColumnObj = {
  first_name: "First Name",
  last_name: "Last Name",
  address1: "Address 1",
  address2: "Address 2",
  city: "City",
  state: "State",
  zip: "Zip",
  phone: "Phone",
  email: "Email",
};

export const leadsRequiredColumnArray = [
  "first_name",
  "last_name",
  "address1",
  "city",
  "state",
  "zip",
];

export const customersRequiredColumnArray = [
  "first_name",
  "last_name",
  "address1",
  "city",
  "state",
  "zip",
  "phone",
  "email",
];

export const campaignTypeForPageTitle = {
  direct_mail: "campaingsDirectMail",
  digital_offer: "campaingsDigitalCoupon",
  print_ad: "campaingsPrintAd",
};

export const campaignTypeForList = {
  direct_mail: "Direct Mail",
  digital_offer: "Digital Offer",
  print_ad: "Print Ad",
  sms: "SMS",
  email: "Email",
  door_hanger: "Door Hanger",
};

export const directMailSteps = [
  { id: "1", name: "Direct Mail Setup", href: "#", status: "current" },
  {
    id: "2",
    name: "Direct Mail Campaign Start",
    href: "#",
    status: "upcoming",
  },
];

export const printAdSteps = [
  { id: "1", name: "Create Print Ad Tracker", href: "#", status: "current" },
  {
    id: "2",
    name: "Print Ad Tracker LIVE!!",
    href: "#",
    status: "upcoming",
  },
];

export const digitalOffer = [
  { id: "1", name: "Campaign Details", href: "#", status: "current" },
  {
    id: "2",
    name: "Configure Email",
    href: "#",
    status: "upcoming",
  },
  {
    id: "3",
    name: "Image Upload",
    href: "#",
    status: "upcoming",
  },
];

export const statesArray = [
  { label: "AL", value: "AL", value2: "alabama" },
  { label: "AK", value: "AK", value2: "alaska" },
  { label: "AZ", value: "AZ", value2: "arizona" },
  { label: "AR", value: "AR", value2: "arkansas" },
  { label: "CA", value: "CA", value2: "california" },
  { label: "CO", value: "CO", value2: "colorado" },
  { label: "CT", value: "CT", value2: "connecticut" },
  { label: "DE", value: "DE", value2: "delaware" },
  { label: "FL", value: "FL", value2: "florida" },
  { label: "GA", value: "GA", value2: "georgia" },
  { label: "HI", value: "HI", value2: "hawaii" },
  { label: "ID", value: "ID", value2: "idaho" },
  { label: "IL", value: "IL", value2: "illinois" },
  { label: "IN", value: "IN", value2: "indiana" },
  { label: "IA", value: "IA", value2: "iowa" },
  { label: "KS", value: "KS", value2: "kansas" },
  { label: "KY", value: "KY", value2: "kentucky" },
  { label: "LA", value: "LA", value2: "louisiana" },
  { label: "ME", value: "ME", value2: "maine" },
  { label: "MD", value: "MD", value2: "maryland" },
  { label: "MA", value: "MA", value2: "massachusetts" },
  { label: "MI", value: "MI", value2: "michigan" },
  { label: "MN", value: "MN", value2: "minnesota" },
  { label: "MS", value: "MS", value2: "mississippi" },
  { label: "MO", value: "MO", value2: "missouri" },
  { label: "MT", value: "MT", value2: "montana" },
  { label: "NE", value: "NE", value2: "nebraska" },
  { label: "NV", value: "NV", value2: "nevada" },
  { label: "NH", value: "NH", value2: "new hampshire" },
  { label: "NJ", value: "NJ", value2: "new jersey" },
  { label: "NM", value: "NM", value2: "new mexico" },
  { label: "NY", value: "NY", value2: "new york" },
  { label: "NC", value: "NC", value2: "north carolina" },
  { label: "ND", value: "ND", value2: "north dakota" },
  { label: "OH", value: "OH", value2: "ohio" },
  { label: "OK", value: "OK", value2: "oklahoma" },
  { label: "OR", value: "OR", value2: "oregon" },
  { label: "PA", value: "PA", value2: "pennsylvania" },
  { label: "RI", value: "RI", value2: "rhode island" },
  { label: "SC", value: "SC", value2: "south carolina" },
  { label: "SD", value: "SD", value2: "south dakota" },
  { label: "TN", value: "TN", value2: "tennessee" },
  { label: "TX", value: "TX", value2: "texas" },
  { label: "UT", value: "UT", value2: "utah" },
  { label: "VT", value: "VT", value2: "vermont" },
  { label: "VA", value: "VA", value2: "virginia" },
  { label: "WA", value: "WA", value2: "washington" },
  { label: "WV", value: "WV", value2: "west virginia" },
  { label: "WI", value: "WI", value2: "wisconsin" },
  { label: "WY", value: "WY", value2: "wyoming" },
];

export const defaultEmailBody = `<p>%%Customer_First_Name%%<br><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">We have received your request for the %%OfferName%% and Your OFFER is attached!</span></p>
<p><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;"><strong>Please print the attached PDF</strong> out and bring it with you on your next visit, a physical copy is REQUIRED. We look forward to seeing you at %%CompanyName%% at %%Location%%<br></span><br><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">%%LocationAddress1%%</span><br><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">%%LocationCity%%, %%LocationState%% %%LocationZip%%</span><br><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">%%LocationPhone%%</span><br></p>
<p><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">The OFFER PDF expires on %%expirationDate%%, so don't delay. The DIGITAL OFFER requires a purchase of $35 or more during your visit and cannot be combined with any other offer or coupon.</span></p>
<p><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: Calibri, sans-serif;">- The Staff @ %%CompanyName%%</span><br></p>
`;

export const validationMsg = {
  company: {
    required: "The company name is required.",
    max: "The company name may not be greater than 100 characters.",
  },
  name: {
    required: "The name is required.",
    max: "The name may not be greater than 100 characters.",
  },
  email: {
    required: "The email is required.",
    email: "The email must be a valid email address.",
    unique: "The email has already been taken by another company.",
  },
  status: {
    sometimes: "The status field is conditionally required.",
  },
  phone: {
    required: "The phone number is required.",
    regex:
      "The phone number format is invalid. It should be formatted like +1 (533) 213-2132.",
    min: "The phone number must be at least 10 characters.",
  },
};

export const postTabsArray = [
  {
    name: "Campaign Type",
    value: "campaignType",
    href: "#",
    current: false,
  },

  { name: "Post Type", value: "postType", href: "#", current: false },
  { name: "Post Details", value: "postDetails", href: "#", current: false },
  { name: "Launch", value: "launch", href: "#", current: false },
];

export const callToActionOptions = [
  // {
  //   label: "None",
  //   value: "none",
  // },
  {
    label: "Book",
    value: "BOOK",
  },
  {
    label: "Order Online",
    value: "ORDER",
  },
  { label: "Buy", value: "SHOP" },
  {
    label: "Learn More",
    value: "LEARN_MORE",
  },
  {
    label: "Sign Up",
    value: "SIGN_UP",
  },
  // {
  //   label: "Call Now",
  //   value: "CALL",
  // },
];

export const postStatus = {
  launch: "launch",
  schedule: "schedule",
  draft: "draft",
};

export const events = [
  {
    id: "1426a9d1-fa29-4d41-b3a3-99f2cb1e920a",
    title: "Tiramisussss - July 25, 2024",
    status: "launch",
    allDay: true,
    start: "2024-07-23T04:06:01-07:00",
    end: "2024-07-23T04:07:01-07:00",
    desc: "Treat your taste buds to a heavenly dining experience at Pizzo's Pizzeria and Wine Bar! Dive in to our Tiramisu; Imagine delicate clouds of light mascarpone cream resting on perfectly balanced pillows of coffee-brandy-soaked lady fingers. It's the perfect union of sweet and sophisticated flavors. We can't wait to welcome you! Enjoy a slice and sip of Italy at Pizzo's today.",
  },
  {
    id: "1e0d7114-4265-43e3-9984-a62737024eb9",
    title: "Tiramisu - July 25, 2024",
    status: "launch",
    allDay: true,
    start: "2024-07-23T04:06:27-07:00",
    end: "2024-07-23T00:00:00-07:00",
    desc: "\"Indulge in the luxury of tasting our heavenly Tiramisu at Pizzo's Pizzeria and Wine Bar! Lose yourself in the clouds of our light mascarpone cream, delicately placed over plush pillows of coffee-brandy-soaked lady fingers. A true Italian dessert that will surely take your dining experience to the next level. Unforgettable moments of delight await you at Pizzo's! 🍮🌟\"",
  },
  // {
  //     "id": "46ff601a-9477-45e5-85b4-8c62bacda883",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T04:31:49-07:00",
  //     "end": "2024-07-25T04:31:49-07:00",
  //     "desc": "Step into Pizzo's Pizzeria and Wine Bar and treat your palate to our decadent Tiramisu. Indulge in the airy delight of soft mascarpone cream laced atop generously soaked ladyfingers that have found the perfect coffee-brandy balance. Each bite is a heavenly escape into an Italian dessert wonderland. True culinary pleasure awaits you at Pizzo's. Every evening deserves a sweet ending."
  // },
  // {
  //     "id": "556f2b74-f78b-4cb2-9cdd-40dbed685fd0",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T03:45:24-07:00",
  //     "end": "2024-07-25T03:45:24-07:00",
  //     "desc": "Get ready to embark on an Italian culinary journey right here at Pizzo's Pizzeria &amp; Wine Bar! Engulf your senses in our sumptuous Tiramisu, a classic Italian dessert that seamlessly mingles the bold flavors of coffee and brandy. Every spoonful delivers light mascarpone cream and coffee-brandy-soaked ladyfingers, promising a taste that truly floats like a cloud. Come indulge in this decadent delight today, it's the perfect companion to our fine wine selection. Because at Pizzo's, we're all about creating memorable moments. Don't miss out!"
  // },
  // {
  //     "id": "56a43046-bb4d-4b53-abb5-93b2fab9d032",
  //     "title": "Torpedo (Mustang) - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T09:51:39-07:00",
  //     "end": "2024-07-25T09:51:39-07:00",
  //     "desc": "Step into Pizzo's Pizzeria and Wine Bar and taste an explosion of Italian flavors! Our Torpedo (Mustang) boasts layer upon layer of succulent Mortadella, spicy salami, and creamy provolone. That's just the start, get ready to delve into the crunch of fresh lettuce, juicy tomatoes, and sharp onions - all harmonizing with our house-made Italian dressing. 🍅🥬 At Pizzo's, we're not just making meals, we're creating experiences. Get ready to enjoy an authentic taste of Italy at its finest!"
  // },
  // {
  //     "id": "6db76989-f1e2-4a71-96bf-13b5efaf5b98",
  //     "title": "Tiramisu - No date",
  //     "status": "launch",
  //     "start": null,
  //     "end": null,
  //     "desc": ""
  // },
  // {
  //     "id": "77684744-939e-49ae-94d9-24ac5e689c13",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T06:13:06-07:00",
  //     "end": "2024-07-25T06:13:06-07:00",
  //     "desc": "Craving something sweet? Dive into Pizzo's Pizzeria &amp; Wine Bar's Tiramisu - an elegant dessert that will transport you straight to Italy! Relish the clouds of light mascarpone cream, nestled delicately on pillows of coffee-brandy soaked lady fingers. A perfect balance of indulgence, it promises to be a dream in every bite. A charming finish to your meal, or just a little mid-week treat for yourself. 🍮☕ Don't miss this dessert from the heavens at Pizzo's!"
  // },
  // {
  //     "id": "7de59f18-24c2-4024-aacf-9337b7efdf6a",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T05:35:04-07:00",
  //     "end": "2024-07-25T05:35:04-07:00",
  //     "desc": "Experience the indulgence of our Tiramisu at Pizzo's Pizzeria and Wine Bar! ☁️🍷 Imagine biting into clouds of velvety mascarpone cream resting on coffee-brandy-infused lady fingers that melt in your mouth. This classic Italian dessert at our place is not just food, it's a work of art. You'll be transported to Italy with each bite. Visit us and let your taste buds embark on an unbelievable journey!"
  // },
  // {
  //     "id": "9730abc7-b7ba-4546-8158-c4d1527aa36e",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T05:24:41-07:00",
  //     "end": "2024-07-25T05:24:41-07:00",
  //     "desc": "Experience the sweet finale to your dining at Pizzo's Pizzeria and Wine Bar with our divine Tiramisu! Surrender to the heavenly folds of light mascarpone cream embracing the coffee-brandy-soaked lady fingers. We guarantee, it's not just an exquisite tiramisu, it's a fluffy cloud of joy for your taste buds!"
  // },
  // {
  //     "id": "9c29a68c-630c-4bab-9fa4-5ce238f10a96",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T04:16:08-07:00",
  //     "end": "2024-07-25T04:16:08-07:00",
  //     "desc": "Experiencing Pizzo's Pizzeria and Wine Bar isn't just about the savory pizza; our desserts are unforgettable too! Delight your palate with our made-from-scratch Tiramisu. Indulge in the heavenly combination of light mascarpone cream resting on a bed of coffee-brandy-soaked ladyfingers. Deliciousness in every bite, it's the perfect finish to any meal. See you at Pizzo's!"
  // },
  // {
  //     "id": "9e6b58ef-0fe7-497d-ba35-44ecbd832cbb",
  //     "title": "Tiramisu - July 27, 2024",
  //     "status": "schedule",
  //     "start": "2024-07-27T18:30:00-07:00",
  //     "end": "2024-07-27T18:30:00-07:00",
  //     "desc": "Have a sweet tooth? At Pizzo's Pizzeria and Wine Bar, we're serving up the most delightful slices of heaven just for you! Our Tiramisu will whisk you off to cloud nine with layers of the lightest mascarpone cream atop coffee-brandy-soaked lady fingers. It's a fluffy delight cooled to absolute perfection. Stop by and let your taste buds revel in the bliss. Your dessert awaits you at Pizzo's! 🍰🥂"
  // },
  // {
  //     "id": "a9e0e8b0-856c-49de-82bb-a3c6d601b3e7",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T06:01:52-07:00",
  //     "end": "2024-07-25T06:01:52-07:00",
  //     "desc": "\"Indulge your taste buds at Pizzo's Pizzeria and Wine Bar with our mouthwatering Tiramisu! Every bite brings you fluffy clouds of light mascarpone cream layered on pillows of delicately coffee-brandy-soaked ladyfingers. 🍰☁️ A dessert that's a delicious masterpiece, it's the perfect sweet ending to any meal! Join us at Pizzo's Pizzeria and taste la dolce vita.\""
  // },
  // {
  //     "id": "b1109234-27bb-477d-b718-8210ef391aff",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T04:45:23-07:00",
  //     "end": "2024-07-25T04:45:23-07:00",
  //     "desc": "Indulge in a slice of pure delight at Pizzo's Pizzeria and Wine Bar! Our Tiramisu is the perfect ending to any meal. It is a sweet symphony of light and airy mascarpone cream nestled on soft coffee-brandy infused lady fingers. A taste so heavenly, it will surely whisk you away to cloud nine. Come experience it tonight at Pizzo's!"
  // },
  // {
  //     "id": "b99cf436-714e-4814-a8cc-a5b8b4e97918",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T04:38:58-07:00",
  //     "end": "2024-07-25T04:38:58-07:00",
  //     "desc": "Indulge in Pizzo's Pizzeria and Wine Bar's authentic Tiramisu! Experience clouds of light mascarpone cream resting atop coffee-brandy-soaked lady fingers that we aptly call pillows. This literal slice of Italy will whisk your taste buds away. Perfectly paired with a fine wine from our extensive menu. The ultimate dessert awaits your pleasure at Pizzo's."
  // },
  // {
  //     "id": "e4a560e6-9610-440c-bc62-1439d903c8d0",
  //     "title": "Tiramisu - July 26, 2024",
  //     "status": "launch",
  //     "start": "2024-07-26T00:07:39-07:00",
  //     "end": "2024-07-26T00:07:39-07:00",
  //     "desc": "\"Indulge your senses with our decadent Tiramisu. Light and airy, our rich mascarpone cream is subtly balanced by pillows of coffee-brandy-soaked lady fingers that melt-in-your-mouth. It’s a dreamlike dessert designed to tantalize your palate. At Pizzo's Pizzeria and Wine Bar, we put a slice of love in every bite. 🍷🍮 Surrender to elegance, surrender to sweetness. Taste the irresistible at Pizzo's!\""
  // },
  // {
  //     "id": "e89464b5-5a4b-4b36-9917-242041be1de5",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T07:05:50-07:00",
  //     "end": "2024-07-25T07:05:50-07:00",
  //     "desc": "Savor a delectable treat at Pizzo's Pizzeria and Wine Bar tonight! 🍷Indulge in our heavenly Tiramisu, featuring clouds of exquisite mascarpone cream lying delightfully on cushions of coffee-brandy-soaked lady fingers. A true Italian finale to your delicious meal! Come, be whisked away on a flavor journey that whispers 'la dolce vita' with every mouthful. Buon Appetito! 🍮"
  // },
  // {
  //     "id": "e95aa029-2d5d-40bf-825e-3d2cc4f7aed2",
  //     "title": "Tiramisu - No date",
  //     "status": "launch",
  //     "start": null,
  //     "end": null,
  //     "desc": ""
  // },
  // {
  //     "id": "ece2c73b-2506-4b2d-a9fb-2d691a73ca46",
  //     "title": "Tiramisu - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T05:14:05-07:00",
  //     "end": "2024-07-25T05:14:05-07:00",
  //     "desc": "Experience the truly Italian dessert - our Tiramisu at Pizzo's Pizzeria and Wine Bar! Indulge yourself in the heavenly realms of light mascarpone cream, delicately arranged on coffee-brandy-soaked lady fingers. These luxurious bites are set to light up your taste buds and your day. Come in and let your senses dive into this cloud of sweetness today."
  // },
  // {
  //     "id": "fffd6957-1932-44f5-bb70-42d7736a8127",
  //     "title": "Caprese - July 25, 2024",
  //     "status": "launch",
  //     "start": "2024-07-25T09:43:41-07:00",
  //     "end": "2024-07-25T09:43:41-07:00",
  //     "desc": "Open for LUNCH Thursday at 11:30am.Experience the essence of Italy on your plate with our Caprese! Indulge in soft, fresh mozzarella paired with ripe, juicy tomatoes and vibrant basil leaves. Our Caprese comes kissed with a drizzle of premium olive oil, a splash of tangy balsamic, and a spread of aromatic pesto. This beautifully balanced symphony of flavors beckons the foodie in you. 🍅🧀 Join us at Pizzo's Pizzeria and Wine Bar and elevate your dining experience with our authentic Italian cuisine."
  // }
];

export const postStatusTab = {
  launch: "completed",
  schedule: "scheduled",
  default: "pending",
};

export const gmbLocationTab = {
  Restaurant: {
    services: "menu",
    products: "extras",
  },
  other: {
    menu: "services",
    extras: "information",
  },
};
