import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import CategoryList from "components/location/services/CategoryList";
import CategoryModal from "components/location/services/CategoryModal";
import ServiceManagement from "components/location/services/ServiceManagement";
import {
  getAuthToken,
  getCompanyStatus,
  getCurrentLocation,
  getUserDataByParam,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { categoryPostToGoogle } from "redux/gmbLocation/categoryPostToGoogleSlice";
import { getCategoryList } from "redux/gmbLocation/getCategoryListSlice";
import { getServicesFromGoogle } from "redux/gmbLocation/getServicesFromGoogleSlice";
import { selectAllServicePost } from "redux/gmbLocation/selectAllServicePostSlice";
import { storeGoogleCategoryList } from "redux/gmbLocation/storeGoogleCategoryListSlice";
import { checkExpireToken } from "redux/reputation/checkExpireTokenSlice";

export default function ServiceCategory({
  getNextUpdate = () => {},
  // isGoogleConnected = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [categoryList, setCategoryList] = useState([]);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [isPostToGoogleModal, setPostToGoogleModal] = useState(false);
  const [isGetServiceFromGoogleModal, setIsGetServiceFromGoogleModal] =
    useState(false);

  const [selectedServices, setSelectedServices] = useState([]);
  const [areAllServicesSelected, setAreAllServicesSelected] = useState(false);

  const isCompanyActive = getCompanyStatus() === "active";
  const currentCompanyId = getUserDataByParam("COMPANY_ID");
  const {
    id: currentLocationId,
    google_business_type = "service",
    region_code = "",
    language_code = "",
  } = getCurrentLocation();
  const category = searchParams?.get("category") || "";

  const categoryListLoading = useSelector(
    (state) => state?.getCategoryList?.loading ?? false
  );
  const getServicesFromGoogleLoading = useSelector(
    (state) => state?.getServicesFromGoogle?.loading ?? false
  );
  const postToGoogleLoading = useSelector(
    (state) => state?.categoryPostToGoogle?.loading ?? false
  );
  const storeGoogleCategoryLoading = useSelector(
    (state) => state?.storeGoogleCategoryList?.loading ?? false
  );

  const handleGetCategoryList = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: currentCompanyId,
    };
    dispatch(getCategoryList(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const listData = data?.payload?.dataObj?.data ?? [];
        const menuListData = isValidArray(listData)
          ? [...listData].sort((a, b) => a.order - b.order)
          : [];
        setCategoryList(menuListData);
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleGetServicesFromGoogle = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: currentCompanyId,
    };
    dispatch(getServicesFromGoogle(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        handleGetCategoryList();
        setIsGetServiceFromGoogleModal(false);
        showSuccessMsg("GMB services fetched successfully.");
      } else {
        handleServerValidation(data);
        setIsGetServiceFromGoogleModal(false);
      }
    });
  };

  const handleGetGoogleCategory = () => {
    const formData = {
      location_id: currentLocationId,
      region_code: region_code,
      language_code: language_code,
    };
    dispatch(storeGoogleCategoryList(formData));
  };

  const handlePostCategoryToGoogle = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: currentCompanyId,
    };
    dispatch(categoryPostToGoogle(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        getNextUpdate();
        setPostToGoogleModal(false);
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Category post to google successfully."
        );
      } else {
        setPostToGoogleModal(false);
        handleServerValidation(data);
      }
    });
  };

  useEffect(() => {
    !category && handleGetCategoryList();
  }, [currentLocationId, category]);

  // useEffect(() => {
  //   if (!isGoogleConnected) {
  //     navigate("/location");
  //     showErrorMsg("Google is not connected.");
  //     return;
  //   }
  // }, [isGoogleConnected]);

  useEffect(() => {
    const fetchData = () => {
      let data = {
        location_id: currentLocationId,
        company_id: currentCompanyId,
        access_token: getAuthToken(),
      };
      dispatch(checkExpireToken(data)).then((data) => {
        const isGoogleConnected = data?.payload?.dataObj?.status;
        if (!isGoogleConnected) {
          navigate("/location");
          showErrorMsg("Google is not connected.");
          return;
        }
      });
    };
    fetchData();

    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSelectAllServicePost = (
    categoryId = 0,
    isSelected = "no",
    all = false,
    handleFunction = () => {},
    selctedServicesList = []
  ) => {
    const formData = {
      location_id: currentLocationId,
      is_selected: isSelected ? "yes" : "no",
      ...(categoryId && { category_id: categoryId }),
      ...(isValidArray(selctedServicesList) &&
        !all && {
          selected_services: selctedServicesList,
        }),
    };
    dispatch(selectAllServicePost(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            `Service selection saved successfully.`
        );
        handleFunction();
      }
    });
  };

  return (
    <div>
      <ShowPageTitle title="locationMenu" />
      <div
        className={classNames(`flex items-center my-2`, {
          "justify-between": category,
          "justify-end": !category,
        })}
      >
        {category && (
          <button
            type="button"
            className="btn btn-light-gray w-20"
            onClick={() => navigate("/location?tab=services")}
          >
            Back
          </button>
        )}
        {isCompanyActive && !category && (
          <>
            <SpinnerButton
              type={"button"}
              title={"Add Category"}
              className="btn-pink w-32"
              action={() => {
                setIsCategoryModalOpen(true);
                handleGetGoogleCategory(true);
              }}
            />
            <SpinnerButton
              type={"button"}
              title={`Get Services`}
              className="btn-pink w-28 ml-2"
              action={() => {
                setIsGetServiceFromGoogleModal(true);
              }}
            />
            <SpinnerButton
              type={"button"}
              title={`Post Services`}
              className="btn-pink w-28 ml-2"
              action={() => {
                setPostToGoogleModal(true);
              }}
            />
          </>
        )}
      </div>

      {category ? (
        <ServiceManagement
          currentCompanyId={currentCompanyId}
          currentLocationId={currentLocationId}
          setSelectedServices={setSelectedServices}
          selectedServices={selectedServices}
          setAreAllServicesSelected={setAreAllServicesSelected}
          areAllServicesSelected={areAllServicesSelected}
          handleSelectAllServicePost={handleSelectAllServicePost}
        />
      ) : (
        <CategoryList
          categoryList={categoryList}
          setCategoryList={setCategoryList}
          categoryListLoading={
            !isGetServiceFromGoogleModal &&
            (categoryListLoading || getServicesFromGoogleLoading)
          }
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          isCategoryModalOpen={isCategoryModalOpen}
          setIsCategoryModalOpen={setIsCategoryModalOpen}
          handleGetCategoryList={handleGetCategoryList}
          currentLocationId={currentLocationId}
        />
      )}

      {isCategoryModalOpen && (
        <CategoryModal
          isModalOpen={isCategoryModalOpen}
          setModalOpen={setIsCategoryModalOpen}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          currentCompanyId={currentCompanyId}
          currentLocationId={currentLocationId}
          region_code={region_code}
          language_code={language_code}
          handleAction={handleGetCategoryList}
          categoryListLoading={storeGoogleCategoryLoading}
        />
      )}
      {isPostToGoogleModal && (
        <ConfirmationModal
          isModalOpen={isPostToGoogleModal}
          setModalOpen={setPostToGoogleModal}
          handleAction={handlePostCategoryToGoogle}
          message={
            isValidArray(selectedServices)
              ? `This will overwrite ALL Service data in Google with the data from Pendio.`
              : `No services available for posting. This will overwrite all services on GMB, and only the primary category will be displayed.`
          }
          isLoading={postToGoogleLoading}
        />
      )}
      {isGetServiceFromGoogleModal && (
        <ConfirmationModal
          isModalOpen={isGetServiceFromGoogleModal}
          setModalOpen={setIsGetServiceFromGoogleModal}
          handleAction={handleGetServicesFromGoogle}
          message={`This will overwrite ALL Service data in Pendio with the data from Googe.`}
          isLoading={categoryListLoading || getServicesFromGoogleLoading}
        />
      )}
    </div>
  );
}
