import CustomDateTimeInput from "components/common/fields/CustomDateTimeInput";
import CustomTimeInput from "components/common/fields/CustomTimeInput";
import DropdownCommon from "components/common/fields/DropdownCommon";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  convertPostDate,
  generate24TimeOptions,
  getDataLocalStorage,
  getDateFormatLocal,
  isValidObject,
  setDataLocalStorage,
} from "module/util";
import { postScheduleForm } from "module/validation";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export default function PostScheduleForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const scheduledData = isValidObject(location?.state) && location?.state;

  const defaultDateFormat = getDateFormatLocal();
  const timeOptions = generate24TimeOptions();
  const [searchParams] = useSearchParams();

  const campaignId = searchParams.get("campaignId")
    ? searchParams.get("campaignId")
    : "";
  const isEdit = !!campaignId ? campaignId : false;

  const campaignDetails = getDataLocalStorage("CAMPAIGN_POST_DATA");
  const postCount = isValidObject(campaignDetails)
    ? campaignDetails?.post_count
    : 0;

  const initialValues = {
    campaign_name:
      campaignDetails?.campaign_name || scheduledData?.campaign_name || "",
    post_launch_date:
      convertPostDate(campaignDetails?.post_launch_date) ||
      convertPostDate(scheduledData?.post_launch_date) ||
      "",
    postSchedule:
      campaignDetails?.postSchedule || scheduledData?.postSchedule || "daily",
    dailyPost: campaignDetails?.dailyPost || scheduledData?.dailyPost || "1",
    post_start_time:
      campaignDetails?.post_start_time || scheduledData?.post_start_time || "",
    post_end_time:
      campaignDetails?.post_end_time || scheduledData?.post_end_time || "",
    weekdays: campaignDetails?.weekdays || scheduledData?.weekdays || [],
    isEdit: isEdit,
  };

  const handleSubmit = (values) => {
    // Extract launch time type
    const [postLaunchTime, postLaunchTimeType] =
      values?.post_start_time?.split(" ") ?? [];

    const formattedDate = (dateString) => {
      const parsedDate = moment(dateString);
      if (!parsedDate.isValid()) {
        return "";
      }
      return parsedDate.tz("America/Los_Angeles").format();
    };

    // Create form data object
    const formData = {
      ...campaignDetails,
      campaign_name: values?.campaign_name ?? "",
      post_count: postCount ?? 0,
      postNowOrDate: "date",
      post_launch_date: formattedDate(values?.post_launch_date) ?? "",
      postSchedule: values?.postSchedule ?? "",
      dailyPost: values?.dailyPost ?? "",
      post_start_time: values?.post_start_time ?? "",
      post_end_time: values?.post_end_time ?? "",
      weekdays: values?.weekdays ?? [],
      post_status: "schedule",
      post_launch_time: postLaunchTime ?? "",
      post_launch_time_type: postLaunchTimeType ?? "",
    };

    // Save form data to local storage
    setDataLocalStorage("CAMPAIGN_POST_DATA", formData);

    // Navigate and show success message
    const successMessage = isEdit
      ? "Post schedule updated successfully."
      : "Post scheduled successfully.";

    // showSuccessMsg(successMessage);

    navigate(
      `/marketing?tab=posts&type=multi&form=post-launch${
        isEdit ? `&campaignId=${campaignId}` : ""
      }`,
      { state: location?.state }
    );
  };

  useEffect(() => {
    if (isValidObject(campaignDetails)) return;

    const basePath = `/marketing?tab=posts&type=multi&form=post-details`;
    const path = isEdit ? `${basePath}&campaignId=${campaignId}` : basePath;
    navigate(path);
  }, [campaignDetails, isEdit, navigate, campaignId]);

  return (
    <div className="w-full h-[40rem] flex justify-center relative">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={postScheduleForm()}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          handleBlur,
          resetForm,
          setFieldValue,
          setFieldTouched,
          values,
          handleSubmit,
        }) => (
          <Form
            className="w-full h-full gap-x-4 p-4"
            id="form1"
            name="form1"
            onSubmit={handleSubmit}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          >
            {/* Campaign Name */}
            <div className="w-full px-3 my-2">
              <label htmlFor="campaign_name" className="input-field-label">
                Campaign Name
              </label>
              <div className="mt-2 w-full">
                <Field
                  type="text"
                  id="campaign_name"
                  name="campaign_name"
                  className="input-field"
                  placeholder="Campaign Name"
                  value={values?.campaign_name ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  className="field-error text-red-500"
                  name="campaign_name"
                />
              </div>
            </div>

            {/* Post Count */}
            <div className="w-full px-3 my-3">
              <label
                htmlFor="postCount"
                className="input-field-label flex items-center"
              >
                Post Count:
                <span className="ml-2">{postCount}</span>
              </label>
            </div>

            {/* Post Schedule Header */}
            <div className="w-full px-3">
              <div className="text-base font-semibold text-gray-700">
                Post Schedule
              </div>
            </div>

            {/* Post Launch Date & Schedule */}
            <div className="w-full flex gap-2 px-3 my-2">
              {/* Post Launch Date */}
              <div className="w-full">
                <label htmlFor="post_launch_date" className="input-field-label">
                  Post Launch Date
                </label>
                <div className="w-full mt-2 relative">
                  <CustomDateTimeInput
                    value={values?.post_launch_date ?? ""}
                    handleChange={(date, time, period) => {
                      setFieldValue("post_launch_date", date);
                    }}
                    handleBlur={() => setFieldTouched("post_launch_date", true)}
                  />
                  <ErrorMessage
                    name="post_launch_date"
                    component="div"
                    className="field-error text-red-500"
                  />
                </div>
              </div>

              {/* Post Schedule Dropdown */}
              <div className="w-full">
                <label htmlFor="postSchedule" className="input-field-label">
                  Post Schedule
                </label>
                <div className="mt-2 w-full">
                  <DropdownCommon
                    className={"custom-input-field"}
                    values={values?.postSchedule ?? "daily"}
                    setFieldValue={setFieldValue}
                    name="postSchedule"
                    isDisabled={false}
                    isReadOnly={false}
                    setFieldTouched={setFieldTouched}
                    options={[
                      { label: "Daily", value: "daily" },
                      { label: "Weekly", value: "weekly" },
                    ]}
                  />
                  <ErrorMessage
                    name="postSchedule"
                    component="div"
                    className="field-error text-red-500"
                  />
                </div>
              </div>
            </div>

            {/* Conditional Daily or Weekly Schedule */}
            {values?.postSchedule === "daily" && (
              <div className="w-full px-3 my-4">
                <label
                  htmlFor="dailySchedule"
                  className="w-full my-4 text-base font-semibold text-gray-700"
                >
                  Daily Schedule
                </label>

                <div className="w-full flex items-center gap-2 mt-4">
                  <div className="w-28 flex items-center gap-2 relative">
                    <Field
                      type="number"
                      name="dailyPost"
                      id="dailyPost"
                      value={values?.dailyPost ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field !w-11 !flex !justify-center !items-center pl-4 pr-[1px]"
                    />
                    <label
                      htmlFor="daily"
                      className="input-field-label flex items-center"
                    >
                      Daily
                    </label>
                    <ErrorMessage
                      name="dailyPost"
                      component="div"
                      className="field-error text-red-500 w-44 absolute -bottom-6"
                    />
                  </div>
                  <div className="w-5">{" | "}</div>
                  <div className="w-full flex items-center gap-4">
                    <label
                      htmlFor="dailyPost"
                      className="input-field-label flex items-center"
                    >
                      Between
                    </label>
                    <div className="w-full relative">
                      <CustomTimeInput
                        value={values?.post_start_time ?? ""}
                        timeFormat24Hr={true}
                        handleChange={(time) => {
                          setFieldValue("post_start_time", time);
                        }}
                        handleBlur={() =>
                          setFieldTouched("post_start_time", true)
                        }
                      />
                      <ErrorMessage
                        name="post_start_time"
                        component="div"
                        className="field-error text-red-500 w-full absolute"
                      />
                    </div>
                    <div className="w-full relative">
                      <CustomTimeInput
                        value={values?.post_end_time ?? ""}
                        timeFormat24Hr={true}
                        handleChange={(time) =>
                          setFieldValue("post_end_time", time)
                        }
                        handleBlur={() =>
                          setFieldTouched("post_end_time", true)
                        }
                      />
                      <ErrorMessage
                        name="post_end_time"
                        component="div"
                        className="field-error text-red-500 w-full absolute "
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {values?.postSchedule === "weekly" && (
              <div className="w-full px-3 my-4">
                <label
                  htmlFor="weeklySchedule"
                  className="w-full my-2 text-base font-semibold text-gray-700"
                >
                  Weekly Schedule
                </label>

                <div className="w-full mt-2">
                  {[
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ].map((day) => (
                    <label
                      key={day}
                      className="text-gray-800 text-sm font-light w-24 flex items-center gap-2"
                    >
                      <Field
                        type="checkbox"
                        className="checkbox-field"
                        id={`weekdays-${day}`}
                        name="weekdays"
                        value={day}
                      />
                      <span className="input-field-label">{day}</span>
                    </label>
                  ))}
                  <ErrorMessage
                    name="weekdays"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="w-[90%] flex items-center gap-4 mt-4">
                  <label
                    htmlFor="weeklyTime"
                    className="input-field-label flex items-center"
                  >
                    Between
                  </label>
                  <div className="w-full relative">
                    <CustomTimeInput
                      value={values?.post_start_time ?? ""}
                      timeFormat24Hr={true}
                      handleChange={(time) =>
                        setFieldValue("post_start_time", time)
                      }
                      handleBlur={() =>
                        setFieldTouched("post_start_time", true)
                      }
                    />
                    <ErrorMessage
                      component="div"
                      className="field-error text-red-500 absolute bottom-[-25px]"
                      name="post_start_time"
                    />
                  </div>
                  <div className="w-full relative">
                    <CustomTimeInput
                      value={values?.post_end_time ?? ""}
                      timeFormat24Hr={true}
                      handleChange={(time) =>
                        setFieldValue("post_end_time", time)
                      }
                      handleBlur={() => setFieldTouched("post_end_time", true)}
                    />
                    <ErrorMessage
                      component="div"
                      className="field-error text-red-500 absolute bottom-[-25px]"
                      name="post_end_time"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Form Navigation */}
            <div className="w-full flex justify-between items-end absolute bottom-0 right-0 p-4 mt-2">
              <button
                type="button"
                onClick={() => {
                  const basePath = `/marketing?tab=posts&type=multi&form=post-details`;
                  const navigatePath = isEdit
                    ? `${basePath}&campaignId=${campaignId}`
                    : basePath;
                  const stateData =
                    isValidObject(campaignDetails) &&
                    campaignDetails?.campaign_name
                      ? { ...campaignDetails, path: location?.state }
                      : location?.state;

                  navigate(navigatePath, { state: stateData });
                }}
                className="btn btn-gray"
              >
                Back
              </button>
              <div className="flex gap-2">
                <SpinnerButton
                  type={"submit"}
                  title={"Next"}
                  className="btn-green"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
