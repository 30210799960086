import deleteIcon from "assets/images/deleteIcon.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import LoaderCommon from "components/common/fields/LoaderCommon";
import SearchInput from "components/common/fields/SearchInput";
import { isNull } from "lodash";
import {
  convertTimeStampToDate,
  getCurrentLocation,
  isCompanyUser,
  isValidArray,
  isValidObject,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { getLeadLists } from "redux/leadList/getLeadListsSlice";
import { leadListStatusChange } from "redux/leadList/leadListStatusChangeSlice";

const LeadList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [sortIndex, setSortIndex] = useState(7);
  const [sortOrder, setSortOrder] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [customInterval, setCustomInterval] = useState(false);

  const getLeadListsData = useSelector(
    (state) => state?.getLeadLists?.data ?? []
  );
  const leadListsLoading = useSelector(
    (state) => state?.getLeadLists?.loading ?? false
  );
  const leadListStatusChangeLoading = useSelector(
    (state) => state?.leadListStatusChange?.loading ?? false
  );

  const { id: currentLocationId } = getCurrentLocation();

  const totalRecords = getLeadListsData?.total ?? 0;
  const currentPerPage = getLeadListsData?.per_page ?? showPerPage;

  const handleLeadList = (search) => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
      ...(isCompanyUser() && { location_id: currentLocationId }),
    };
    if (!isCompanyUser() || (isCompanyUser() && currentLocationId))
      dispatch(getLeadLists(sortObj)).then((data) => {
        if (data?.payload && isValidArray(data?.payload?.data)) {
          const needToRefresh = data?.payload?.data?.find(
            (item) =>
              isNull(item?.customer_match) ||
              item?.percent_processed != "100.00"
          );
          if (isValidObject(needToRefresh)) {
            setCustomInterval(true);
          }
        }
      });
  };

  const handleLeadListInterval = () => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: searchInput,
      page: currentPage,
      perPage: perPage,
      ...(isCompanyUser() && { location_id: currentLocationId }),
    };
    if (!isCompanyUser() || (isCompanyUser() && currentLocationId)) {
      dispatch(getLeadLists(sortObj)).then((data) => {
        if (data?.payload && isValidArray(data?.payload?.data)) {
          const needToRefresh = data?.payload?.data?.find(
            (item) =>
              isNull(item?.customer_match) ||
              item?.percent_processed != "100.00"
          );
          if (isValidObject(needToRefresh)) {
            setCustomInterval(!customInterval);
          }
        }
      });
    }
  };
  let timeout = null;
  useEffect(() => {
    timeout = setTimeout(() => {
      handleLeadListInterval();
    }, 25000);
    return () => clearTimeout(timeout);
  }, [customInterval]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Contacts",
          href: "",
          current: false,
          isDisable: true,
        },
        {
          name: "Leads",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  const handleStatusChange = () => {
    dispatch(
      leadListStatusChange({ leadListId: selectedId, status: "archived" })
    ).then((data) => {
      if (data) {
        setModalOpen(false);
        showSuccessMsg("Lead Lists archived successfully");
        handleLeadList(searchInput);
      }
    });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Company",
      accessor: "comp_name",
    },

    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Leads",
      accessor: "lead_count",
    },
    {
      Header: "Customer Match(s)",
      accessor: "customer_match",
      Cell: ({ cell }) => (
        <>
          {isNull(cell?.row?.values?.customer_match) ? (
            <div className="flex items-center">
              <LoaderCommon color="#000" size={20} marginTop="0px" />
              <span className="ml-2">Processing...</span>
            </div>
          ) : cell?.row?.values?.customer_match ? (
            cell?.row?.values?.customer_match
          ) : (
            0
          )}
        </>
      ),
    },
    {
      Header: "Status",
      accessor: "percent_processed",
      Cell: ({ cell }) => (
        <div className="flex items-center">
          {cell?.row?.values?.percent_processed == "100.00" ? (
            <>{`${cell?.row?.values?.percent_processed ?? 0}%`}</>
          ) : (
            <>
              <LoaderCommon color="#000" size={20} marginTop="0px" />
              <span className="ml-1">{`${
                cell?.row?.values?.percent_processed ?? 0
              }%`}</span>
              <span className="ml-1">Processing...</span>
            </>
          )}
        </div>
      ),
    },
    {
      Header: "Date Added",
      accessor: "date_added",
      Cell: ({ cell }) => (
        <span>
          {cell?.row?.values?.date_added
            ? convertTimeStampToDate(cell?.row?.values?.date_added)
            : ""}
        </span>
      ),
    },
    ...(!isCompanyUser()
      ? [
          {
            width: 100,
            Header: "Actions",
            accessor: "lead_list_id",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className="flex items-center gap-x-2">
                {/* {cell?.row?.values?.percent_processed == "100.00" ? ( */}
                <img
                  alt="archive"
                  src={deleteIcon}
                  className="h-5 w-5 hover:cursor-pointer"
                  onClick={() => {
                    if (cell?.row?.values?.percent_processed !== "100.00") {
                      setModalOpen(true);
                      setSelectedId(cell?.row?.values?.lead_list_id);
                    }
                  }}
                />
                {/* ) : (
                  <img
                    alt="archive"
                    src={deleteIcon}
                    className={`h-5 w-5 opacity-40 cursor-not-allowed`}
                    onClick={() => {}}
                  />
                )} */}
              </div>
            ),
          },
        ]
      : []),
  ];

  const globalSearch = (search) => {
    clearTimeout(timeout);
    handleLeadList(search);
  };

  useEffect(() => {
    clearTimeout(timeout);
    handleLeadList(searchInput);
  }, [sortOrder, currentPage, perPage, currentLocationId]);

  return (
    <div className="mt-[-8px]">
      <ShowPageTitle title="contactsLeadList" />
      <div className="flex  justify-end items-center gap-x-2">
        <div className="flex rounded-md shadow-sm">
          <SearchInput
            globalSearch={globalSearch}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div>
      </div>
      <div className="mt-2 flex flex-col">
        <CustomTable
          data={getLeadListsData?.data ?? []}
          columns={columns}
          isLoading={leadListsLoading}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
        />
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleStatusChange}
          isLoading={leadListStatusChangeLoading}
        />
      )}
    </div>
  );
};

export default LeadList;
