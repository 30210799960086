import {
  addDecimalToNumber,
  getDateFormatLocal,
  isValidArray,
  isValidObject,
} from "module/util";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getDailyRedemption } from "redux/campaign/getDailyRedemptionSlice";

const DateRedemptionGraph = ({
  getCampaignReportData = {},
  campaignId = 0,
}) => {
  const currentDate = moment();
  const [endDate, setEndDate] = useState(new Date(currentDate));
  const isFirstRedemptionObj = isValidObject(
    getCampaignReportData?.firstRedemption
  )
    ? moment(getCampaignReportData?.firstRedemption?.date)
    : "";

  const firstRedemptionDate = isFirstRedemptionObj
    ? isFirstRedemptionObj
    : getCampaignReportData?.firstRedemption
    ? moment(getCampaignReportData?.firstRedemption)
    : currentDate;

  const daysDifference = currentDate.diff(firstRedemptionDate, "days");

  var sevenDaysBefore = currentDate.subtract(7, "days");
  // var sevenDaysAfter = currentDate.add(1, "days");
  const [startDate, setStartDate] = useState(
    daysDifference > 7
      ? new Date(sevenDaysBefore)
      : new Date(firstRedemptionDate)
  );
  const dispatch = useDispatch();

  const [{ getDailyRedemptionData = {} }] = useSelector((state) => [
    {
      getDailyRedemptionData: state?.getDailyRedemption?.data ?? {},
    },
  ]);

  useEffect(() => {
    const formData = {
      campaign_id: campaignId,
      start_date: startDate,
      end_date: endDate,
    };

    if (!!startDate && !!endDate) {
      dispatch(getDailyRedemption(formData));
    }
  }, [startDate, endDate]);

  const offerData1 = getDailyRedemptionData?.dataObj?.offer_1?.data ?? [];
  const offerData2 = getDailyRedemptionData?.dataObj?.offer_2?.data ?? [];

  const seriesArray = [
    {
      name: getDailyRedemptionData?.dataObj?.offer_1?.offer_name,
      type: "bar",
      data: offerData1,
    },
    ...(isValidArray(offerData2)
      ? [
          {
            name: getDailyRedemptionData?.dataObj?.offer_2?.offer_name,
            type: "bar",
            data: offerData2,
          },
        ]
      : []),
  ];

  const hasData = seriesArray.some((value) => value > 0);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            formatter: function (val, opt) {
              return `$${addDecimalToNumber(val)}`;
            },
            style: {},
          },
        },
      },
    },
    title: {
      text: "Date Vs Redemption",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return `$${addDecimalToNumber(val)}`;
      },
    },
    xaxis: {
      type: "numeric",
      min: startDate.getTime(),
      tickAmount: offerData1?.length - 1,
      labels: {
        show: true,
        showDuplicates: false,
        formatter: (value) => moment(value).format("DD MMM"),
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: "dd MMM yyyy",
      },
      y: {
        formatter: (seriesName, { series, dataPointIndex, seriesIndex, w }) => {
          const finalValue = series[seriesIndex][dataPointIndex];
          return `$${addDecimalToNumber(finalValue)} `;
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      markers: {
        radius: 12,
      },
    },
  };

  return (
    <div className="relative">
      {hasData ? (
        <>
          <div className="absolute z-[99] top-[-5px] right-[160px] bg-red-400">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
          <div id="chart">
            <ReactApexChart
              options={options}
              series={seriesArray}
              type="bar"
              height={350}
            />
          </div>
        </>
      ) : (
        <div className="h-72 w-full flex justify-center items-center text-gray-600">
          No Data
        </div>
      )}
    </div>
  );
};

export default DateRedemptionGraph;

const DateRangePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const defaultDateFormat = getDateFormatLocal();
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <ReactDatePicker
      className="block  border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
      selected={startDate}
      dateFormat={defaultDateFormat}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      // maxDate={new Date()}
      selectsRange
    />
  );
};
