import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const addServiceCampaignPost = createAsyncThunk(
  "post/service-campaign-post-add",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await postApi(
        `api/store-multiple-service-post`,
        { ...formData, _method: "POST" },
        true
      );
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const addServiceCampaignPostSlice = createSlice({
  name: "addServiceCampaignPost",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addServiceCampaignPost.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addServiceCampaignPost.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(addServiceCampaignPost.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default addServiceCampaignPostSlice.reducer;
