import deleteIcon from "assets/images/deleteIcon.svg";
import editIcon from "assets/images/editIcon.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import { showPerPage, showSuccessMsg } from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeAdminUserStatus } from "redux/adminUser/changeAdminUserStatusSlice";
import { getAdminUserList } from "redux/adminUser/getAdminUserListSlice";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const AdminUserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const [
    {
      getAdminUserListData = {},
      userListLoading = false,
      changeAdminUserStatusLoading = false,
    },
  ] = useSelector((state) => [
    {
      getAdminUserListData: state?.getAdminUserList ?? {},
      userListLoading: state?.getAdminUserList?.loading ?? false,
      changeAdminUserStatusLoading:
        state?.changeAdminUserStatus?.loading ?? false,
    },
  ]);

  const totalRecords =
    getAdminUserListData?.total ?? getAdminUserListData?.data?.length ?? [];
  const currentPerPage = getAdminUserListData?.per_page ?? showPerPage;
  const totalAdminUserList = getAdminUserListData?.data ?? [];

  const handleChangeAdminUser = () => {
    dispatch(
      changeAdminUserStatus({ userId: selectedId, status: "archived" })
    ).then((data) => {
      setModalOpen(false);
      if (data?.payload.code === 200) {
        showSuccessMsg(
          data?.payload?.message ?? "Admin user deleted successfully"
        );
      }
      handleAdminUserList(searchInput);
    });
  };

  const handleAdminUserList = (search) => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
    };
    dispatch(getAdminUserList(sortObj));
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell }) => (
        <span
          className="hover:underline hover:cursor-pointer "
          onClick={() => navigate(`/admin-user/edit/${cell.row.values.id}`)}
        >
          {cell.row.values.name}
        </span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      width: 100,
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ cell }) => (
        <div className="flex items-center gap-x-2 ">
          <img
            alt="Edit"
            src={editIcon}
            className="h-5 w-5 hover:cursor-pointer"
            onClick={() => navigate(`/admin-user/edit/${cell.row.values.id}`)}
          />
          <img
            alt="Edit"
            src={deleteIcon}
            className="h-5 w-5 hover:cursor-pointer"
            onClick={() => {
              setModalOpen(true);
              setSelectedId(cell?.row?.values?.id);
            }}
          />
        </div>
      ),
    },
  ];

  const globalSearch = (search) => {
    handleAdminUserList(search);
  };

  useEffect(() => {
    handleAdminUserList(searchInput);
  }, [sortOrder, currentPage, perPage]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Admin Users List",
          href: "/admin-user",
          current: false,
        },
      ])
    );
  }, []);

  return (
    <div className="mt-4">
      <ShowPageTitle title="adminUsers" />
      <div className="flex justify-end items-center mt-2">
        <button
          type="button"
          onClick={() => navigate("/admin-user/add")}
          className="btn-pink"
        >
          Add User
        </button>
        <div className="ml-2 flex shadow-sm">
          <SearchInput
            globalSearch={globalSearch}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <CustomTable
          data={totalAdminUserList}
          isLoading={userListLoading}
          columns={columns}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
        />
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleChangeAdminUser}
          isLoading={changeAdminUserStatusLoading}
        />
      )}
    </div>
  );
};

export default AdminUserList;
