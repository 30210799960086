import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import CustomCostInput from "components/common/fields/CustomCostInput";
import MultiSelectDropdown from "components/common/fields/MultiSelectDropdown";
import MultiWithCreateDropdown from "components/common/fields/MultiWithCreateDropdown";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import {
  getCurrentLocation,
  getItemImage,
  isValidArray,
  removeEmptyObjects,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation, menuItemsForm } from "module/validation";
import { Fragment, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addMenuItem } from "redux/gmbLocation/addMenuItemSlice";
import { getAllergensList } from "redux/gmbLocation/getAllergensListSlice";
import { getMenuItemDetail } from "redux/gmbLocation/getMenuItemsDetailSlice";
import { updateMenuItem } from "redux/gmbLocation/updateMenuItemSlice";

export default function ItemModal({
  isModalOpen = false,
  setModalOpen = () => {},
  sectionList = [],
  itemId = 0,
  sectionId = 0,
  handleSectionDetail = () => {},
  handleGetSectionList = () => {},
  handleItemList = () => {},
}) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const formRef = useRef();
  const [imageName, setImageName] = useState({
    primary_image: "",
    secondary_image: "",
  });
  const [hoverOnImage, setHoverOnImage] = useState({
    primary_image: 0,
    secondary_image: 0,
  });
  const [isSaveAndAdd, saveAndAdd] = useState(false);
  const { id: currentLocationId } = getCurrentLocation();
  const isEdit = !!itemId;

  const addItemsLoading = useSelector(
    (state) => state?.addMenuItem?.loading ?? false,
    shallowEqual
  );

  const updateMenuItemLoading = useSelector(
    (state) => state?.updateMenuItem?.loading ?? false,
    shallowEqual
  );

  const isLoading = addItemsLoading || updateMenuItemLoading;

  const itemData = useSelector(
    (state) => state?.getMenuItemDetail?.data?.dataObj?.data ?? {}
  );

  const allergensList = useSelector(
    (state) => state?.getAllergensList?.data?.dataObj?.data ?? {}
  );

  const modifiedOptions = (data) => {
    const result = data?.map((item) => {
      return {
        id: item?.id ?? null,
        option_name: item?.option_name ?? "",
        option_calories: item?.option_calories ?? "",
        option_price: item?.option_price ?? "",
      };
    });

    return result;
  };

  const getAllergensOption = (data = []) => {
    if (isValidArray(data)) {
      const options = data?.map((item) => {
        return {
          label: item?.allergen_name ?? "",
          value: item?.id ?? "",
        };
      });
      return options;
    } else {
      return [];
    }
  };

  const allergensOptions = getAllergensOption(allergensList);

  const selectedAllergens =
    isEdit &&
    allergensOptions?.filter((item) => {
      return itemData?.allergens?.find(
        (single) => single.allergen_id == item.value
      );
    });

  const updateSecondaryImgArray = (data) => {
    const result = data?.map((item) => {
      if (item) {
        return item;
      }
      return "";
    });
    return result;
  };

  const initialValues = {
    section_id: isEdit ? itemData?.section_id : sectionId ?? "",
    item_name: isEdit ? itemData?.item_name ?? "" : "",
    item_price: isEdit ? itemData?.item_price ?? "" : "",
    item_calories: isEdit ? itemData?.item_calories ?? "" : "",
    item_description: isEdit ? itemData?.item_description ?? "" : "",
    url: isEdit ? itemData?.url ?? "" : "",
    allergen: isEdit ? selectedAllergens : [],
    featured_menu_item: isEdit
      ? itemData?.featured_menu_item ?? "Unspecified"
      : "Unspecified",
    primary_image: isEdit
      ? itemData?.primary_image
        ? [itemData?.primary_image]
        : ""
      : [],
    secondary_image:
      isEdit && isValidArray(itemData?.secondary_image)
        ? updateSecondaryImgArray(itemData?.secondary_image)
        : [],
    item_image_name: isEdit
      ? updateSecondaryImgArray(itemData?.secondary_image ?? [])
      : [],
    options:
      isEdit && isValidArray(itemData?.food_options)
        ? modifiedOptions(itemData?.food_options)
        : [
            {
              id: null,
              option_name: "",
              option_calories: "",
              option_price: "",
            },
          ],
  };

  const handleGetItemsDetails = () => {
    dispatch(getMenuItemDetail(itemId));
  };

  const updateSectionItems = (sectionId, itemId) => {
    // Retrieve the current sections from localStorage
    const storedSections = JSON.parse(localStorage.getItem("SECTIONS")) ?? [];

    // Find the section by sectionId
    const sectionIndex = storedSections.findIndex(
      (section) => section.sectionId === sectionId
    );

    if (sectionIndex !== -1) {
      // Section exists, update the items array
      const currentItems = storedSections[sectionIndex].items;
      const updatedItems = [...new Set([...currentItems, itemId])]; // Ensure items are unique
      storedSections[sectionIndex].items = updatedItems;
    } else {
      // Section does not exist, create a new section
      const newSection = { sectionId: sectionId, items: [itemId] };
      storedSections.push(newSection);
    }

    // Store the updated sections back to localStorage
    localStorage.setItem("SECTIONS", JSON.stringify(storedSections));
  };

  const handleSubmit = (values, { resetForm }) => {
    const allergensData = values?.allergen?.map((item) => item?.value) ?? [];
    const formData = {
      section_id: values?.section_id,
      item_name: values?.item_name,
      item_price: values?.item_price,
      item_calories: values?.item_calories,
      item_description: values?.item_description,
      url: values?.url,
      allergens: allergensData ?? [],
      featured_menu_item: values?.featured_menu_item,
      primary_image: values?.primary_image,
      secondary_image: values?.secondary_image,
      item_image_name: values?.item_image_name,
      options: removeEmptyObjects(values?.options) ?? [],
      location_id: currentLocationId,
    };

    if (isEdit) {
      dispatch(updateMenuItem({ formData, itemId })).then((data) => {
        if (data?.payload?.code == 200) {
          setModalOpen(false);
          showSuccessMsg(
            data?.payload?.dataObj?.message ??
              data?.payload?.message ??
              "Item updated successfully."
          );

          // Example usage
          // const sectionId = sectionId;
          // const itemId = itemId;
          // updateSectionItems(sectionId, itemId);

          handleItemList();
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      dispatch(addMenuItem(formData)).then((data) => {
        if (data?.payload?.code == 200) {
          if (isSaveAndAdd) {
            resetForm();
            setImageName({
              primary_image: "",
              secondary_image: "",
            });
            saveAndAdd(false);
          } else {
            setModalOpen(false);
          }
          handleGetSectionList();
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  useEffect(() => {
    if (isEdit) {
      handleGetItemsDetails();
    }
    dispatch(getAllergensList());
  }, []);

  const addImage = (isMulti = true, file, { values, setFieldValue }) => {
    const imagesArray = isMulti
      ? values?.secondary_image ?? []
      : values?.primary_image ?? [];

    const reader = new FileReader();
    reader.onload = (event) => {
      const base64Image = event.target.result;
      if (imagesArray.includes(base64Image)) {
        return;
      }
      if (isMulti) {
        const newImagesArray = [...imagesArray, file];
        setFieldValue("secondary_image", newImagesArray);
      } else {
        const newImagesArray = [file];
        setFieldValue("primary_image", newImagesArray);
      }
    };
    reader.readAsDataURL(file);
  };

  const removeImage = (
    isMulti = false,
    imageToRemove,
    { setFieldValue, setFieldTouched, values }
  ) => {
    if (isMulti) {
      setFieldValue(
        "secondary_image",
        values?.secondary_image?.filter((image) => image !== imageToRemove)
      );
      setFieldValue(
        "item_image_name",
        values?.secondary_image?.filter((image) => image !== imageToRemove)
      );
      setTimeout(() => {
        setFieldTouched("secondary_image", true, true);
      }, 100);
      setImageName({
        ...imageName,
        secondary_image: "",
      });
    } else {
      setFieldValue(
        "primary_image",
        values?.primary_image?.filter((image) => image !== imageToRemove)
      );
      setFieldValue(
        "item_image_name",
        values?.primary_image?.filter((image) => image !== imageToRemove)
      );
      setTimeout(() => {
        setFieldTouched("primary_image", true, true);
      }, 100);
      setImageName({
        ...imageName,
        primary_image: "",
      });
    }
  };

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1111]"
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform rounded-sm bg-white text-left align-middle shadow-xl transition-all">
                <div className="p-4">
                  <div className="flex justify-between">
                    <div className="w-full flex gap-2 mb-6 md:mb-0">
                      <span className="font-bold text-lg">
                        {isEdit ? "Edit" : "Add"} Item
                      </span>
                    </div>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center outline-0"
                      onClick={() => setModalOpen(false)}
                      ref={cancelButtonRef}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div className="w-full h-[1px] bg-gray-300 mt-1"></div>
                </div>

                <div className="overflow-auto h-[45rem] mb-2 relative">
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={menuItemsForm()}
                    onSubmit={handleSubmit}
                    innerRef={formRef}
                  >
                    {({
                      errors,
                      touched,
                      validateField,
                      validateForm,
                      handleChange,
                      handleBlur,
                      resetForm,
                      setFieldValue,
                      setFieldTouched,
                      values,
                      handleSubmit,
                    }) => {
                      return (
                        <Form
                          className="w-full p-4"
                          id="form1"
                          name="form1"
                          onSubmit={handleSubmit}
                          onKeyDown={(e) => {
                            e.key == "Enter" && e.preventDefault();
                          }}
                        >
                          <div className="mb-4 flex flex-start w-full gap-1 items-center">
                            <label
                              className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                              htmlFor="section_id"
                            >
                              Section :
                            </label>
                            <div className="w-full">
                              <Field
                                as="select"
                                className="input-field"
                                name="type"
                                value={values?.section_id ?? ""}
                                onChange={(e) => {
                                  setFieldValue("section_id", e.target.value);
                                }}
                                onBlur={() => {
                                  setFieldTouched("section_id", true);
                                }}
                              >
                                <option key={""} value={""}>
                                  Section
                                </option>
                                {isValidArray(sectionList) &&
                                  sectionList?.map((section) => (
                                    <option
                                      key={section?.id}
                                      value={section?.id ?? ""}
                                      defaultValue={sectionId == section?.id}
                                      // selected={values?.section_id == section?.id}
                                    >
                                      {section?.section_name}
                                    </option>
                                  ))}
                              </Field>
                              <ErrorMessage
                                component="div"
                                className="field-error text-red-500"
                                name="section_id"
                              />
                            </div>
                          </div>

                          <div className="mb-4 flex flex-start w-full gap-1 items-center">
                            <label
                              className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                              htmlFor="item_name"
                            >
                              Name :
                            </label>
                            <div className="w-full">
                              <Field
                                className={`input-field`}
                                id="item_name"
                                name="item_name"
                                type="text"
                                values={values?.item_name ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Item Name"
                              />
                              <ErrorMessage
                                component="div"
                                className="field-error text-red-500"
                                name="item_name"
                              />
                            </div>
                          </div>

                          <div className="mb-4 mt-[-6px] flex flex-start w-full gap-1 items-center">
                            <label
                              className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                              htmlFor="tag"
                            >
                              Price :
                            </label>
                            <div className="w-full">
                              <CustomCostInput
                                values={values?.item_price ?? ""}
                                setFieldValue={setFieldValue}
                                name={"item_price"}
                                title="Price"
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                component="div"
                                className="field-error text-red-500"
                                name="item_price"
                              />
                            </div>
                          </div>

                          <div className="mb-4 flex flex-start w-full gap-1 items-center">
                            <label
                              className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                              htmlFor="item_calories"
                            >
                              Calories :
                            </label>
                            <div className="w-full">
                              <Field
                                className={`input-field`}
                                id="item_calories"
                                name="item_calories"
                                type="number"
                                values={values?.item_calories ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Calories"
                              />
                              <ErrorMessage
                                component="div"
                                className="field-error text-red-500"
                                name="item_calories"
                              />
                            </div>
                          </div>

                          <div className="mb-4">
                            <FieldArray name="options" value={values?.options}>
                              {({ remove, push }) => (
                                <>
                                  <div className="flex flex-start gap-1 w-full items-center">
                                    <label
                                      className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                                      htmlFor="options"
                                    >
                                      Option :
                                    </label>
                                    <div className="w-full">
                                      {values?.options?.length > 0 &&
                                        values.options.map((option, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="w-full flex  gap-4"
                                            >
                                              <div className="w-full mt-2">
                                                <Field
                                                  type="text"
                                                  value={
                                                    option["option_name"] ?? ""
                                                  }
                                                  name={`options.${index}.option_name`}
                                                  id={`options.${index}.option_name`}
                                                  placeholder="Enter Option Name"
                                                  className="input-field"
                                                />
                                                <ErrorMessage
                                                  name={`options.${index}.option_name`}
                                                  component="div"
                                                  className="field-error text-red-500"
                                                />
                                              </div>
                                              <div className="w-full mt-2">
                                                <Field
                                                  type="number"
                                                  value={
                                                    option["option_calories"] ??
                                                    0
                                                  }
                                                  name={`options.${index}.option_calories`}
                                                  id={`options.${index}.option_calories`}
                                                  placeholder="Enter Calories"
                                                  className="input-field"
                                                />
                                                <ErrorMessage
                                                  name={`options.${index}.option_calories`}
                                                  component="div"
                                                  className="field-error text-red-500"
                                                />
                                              </div>
                                              <div className="w-full">
                                                <CustomCostInput
                                                  values={
                                                    option["option_price"] ?? []
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  name={`options.${index}.option_price`}
                                                  title="Price"
                                                  onBlur={handleBlur}
                                                />
                                                <ErrorMessage
                                                  name={`options.${index}.option_price`}
                                                  component="div"
                                                  className="field-error text-red-500"
                                                />
                                              </div>
                                              <div className="mt-2 flex items-center">
                                                {index > 0 ? (
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <XCircleIcon
                                                      strokeWidth={1.8}
                                                      className="w-5 cursor-pointer text-pink-600"
                                                    />
                                                  </button>
                                                ) : (
                                                  <span className="w-[20px]"></span>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="flex flex-start w-full items-center">
                                    <label
                                      className=" font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                                      htmlFor="options"
                                    >
                                      {/* Option : */}
                                    </label>
                                    <button
                                      type="button"
                                      className="mt-2 w-full text-md flex gap-x-2 items-center text-pink-600"
                                      onClick={() =>
                                        push({
                                          id: null,
                                          option_name: "",
                                          option_calories: "",
                                          option_price: "",
                                        })
                                      }
                                    >
                                      <PlusIcon
                                        strokeWidth={1.8}
                                        className="w-5 h-5 cursor-grab "
                                      />
                                      Add option
                                    </button>
                                  </div>
                                </>
                              )}
                            </FieldArray>
                          </div>

                          <div className="mb-4 flex w-full gap-2 items-center">
                            <label
                              className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                              htmlFor="description"
                            >
                              Description :
                            </label>
                            <div className="w-full">
                              <Field
                                as="textarea"
                                rows={2}
                                className={`input-field`}
                                id="item_description"
                                name="item_description"
                                type="text"
                                values={values?.item_description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Description"
                              />
                              <ErrorMessage
                                component="div"
                                className="field-error text-red-500"
                                name={"item_description"}
                              />
                            </div>
                          </div>

                          <div
                            className={classNames(
                              "flex w-full gap-2 items-center ",
                              {
                                "mb-3": !isValidArray(
                                  values?.primary_image ?? []
                                ),
                              }
                            )}
                          >
                            <label
                              htmlFor="primary_image"
                              className="mb-7 font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                            >
                              Primary Image :
                            </label>
                            <div className="w-full relative overflow-hidden">
                              <label htmlFor="primary_image relative">
                                <input
                                  type="file"
                                  title={"No file chosen"}
                                  name="primary_image"
                                  id="primary_image"
                                  accept=".png, .jpg, .jpeg"
                                  onChange={(e) => {
                                    let filesData = e.target.files[0];

                                    if (filesData) {
                                      setImageName({
                                        ...imageName,
                                        primary_image: filesData?.name,
                                      });
                                      addImage(false, filesData, {
                                        values,
                                        // setFieldError,
                                        setFieldValue,
                                      });
                                      // setImageName(filesData?.name);
                                    } else {
                                      setImageName({
                                        ...imageName,
                                        primary_image: "",
                                      });
                                    }
                                  }}
                                  multiple={true}
                                  autoComplete="primary_image"
                                  placeholder="Enter item_image"
                                  className="file-field"
                                />
                                <p className="min-w-28 w-full absolute top-[6px] left-[127px] bg-white text-neutral-700 truncate">
                                  {imageName?.primary_image
                                    ? imageName?.primary_image
                                    : "No file chosen"}
                                </p>
                                <span className="text-xs font-light mr-2">
                                  (Upload: JPG or PNG, min: 900 x 900 and max:
                                  3000 x 3000, max 25MB)
                                </span>
                                <ErrorMessage
                                  component="div"
                                  className="field-error text-red-500"
                                  name="primary_image"
                                />
                              </label>
                            </div>
                          </div>

                          {isValidArray(values?.primary_image) && (
                            <div className="mt-1 flex w-full gap-2 items-center pl-9 mb-2">
                              <label
                                htmlFor="primary_image"
                                className="font-light leading-6 text-gray-900 text-start max-w-[30%] w-full md:w-[15%] xl:w-[11.3%] 2xl:w-[20%]"
                              ></label>
                              <div className="my-1 flex flex-wrap gap-2">
                                {values?.primary_image?.map((img, imgIndex) => {
                                  const imgSrc =
                                    isEdit && typeof img == "string"
                                      ? getItemImage(img)
                                      : img
                                      ? URL.createObjectURL(img)
                                      : "";

                                  if (imgSrc) {
                                    return (
                                      <div
                                        key={imgIndex}
                                        className="relative"
                                        onMouseEnter={() => {
                                          setHoverOnImage({
                                            ...hoverOnImage,
                                            primary_image: img,
                                          });
                                        }}
                                        onMouseLeave={() => {
                                          setHoverOnImage({
                                            ...hoverOnImage,
                                            primary_image: 0,
                                          });
                                        }}
                                      >
                                        <img
                                          src={imgSrc}
                                          alt={img}
                                          className={`w-[130px] h-[100px] border-2 border-gray-300 cursor-pointer ${
                                            hoverOnImage?.primary_image ==
                                              img && `brightness-95`
                                          }`}
                                        />
                                        {hoverOnImage?.primary_image == img && (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              removeImage(false, img, {
                                                setFieldValue,
                                                setFieldTouched,
                                                values,
                                              });
                                            }}
                                            className="absolute -top-3.5 -right-2.5 z-99"
                                          >
                                            <XCircleIcon
                                              strokeWidth={1}
                                              className="w-6 h-6 cursor-pointer text-red-700 "
                                            />
                                          </button>
                                        )}
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          )}

                          <div className="flex w-full gap-2 items-center">
                            <label
                              htmlFor="secondary_image"
                              className="mb-7 font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                            >
                              Support Images :
                            </label>
                            <div className="w-full relative overflow-hidden">
                              <label htmlFor="secondary_image relative">
                                <input
                                  type="file"
                                  title={"No file chosen"}
                                  name="secondary_image"
                                  id="secondary_image"
                                  accept=".png, .jpg, .jpeg"
                                  onChange={(e) => {
                                    let filesData = e.target.files[0];

                                    if (filesData) {
                                      setImageName({
                                        ...imageName,
                                        secondary_image: filesData?.name,
                                      });
                                      addImage(true, filesData, {
                                        values,
                                        // setFieldError,
                                        setFieldValue,
                                      });
                                    } else {
                                      setImageName({
                                        ...imageName,
                                        secondary_image: "",
                                      });
                                    }
                                  }}
                                  multiple={true}
                                  autoComplete="secondary_image"
                                  placeholder="Enter secondary_image"
                                  className="file-field"
                                />
                                <p className="min-w-28 absolute top-[6px] left-[127px] bg-white text-neutral-700 truncate">
                                  {!!imageName?.secondary_image
                                    ? imageName?.secondary_image
                                    : "No file chosen"}
                                </p>
                                <span className="text-xs font-light mr-2">
                                  (Upload: JPG or PNG, min: 900 x 900 and max:
                                  3000 x 3000, max 25MB)
                                </span>
                                <ErrorMessage
                                  component="div"
                                  className="field-error text-red-500"
                                  name="secondary_image"
                                />
                              </label>
                            </div>
                          </div>

                          {isValidArray(values?.secondary_image) && (
                            <div className="mt-1 flex w-full gap-2 items-center pl-9">
                              <label
                                htmlFor="secondary_image"
                                className="font-light leading-6 text-gray-900 text-start max-w-[30%] w-full md:w-[15%] xl:w-[11.3%] 2xl:w-[20%]"
                              ></label>
                              <div className="my-1 flex flex-wrap gap-2">
                                {values?.secondary_image?.map(
                                  (img, imgIndex) => {
                                    const imgSrc =
                                      isEdit && img && typeof img == "string"
                                        ? getItemImage(img)
                                        : img
                                        ? img
                                          ? URL.createObjectURL(img)
                                          : ""
                                        : "";

                                    if (imgSrc) {
                                      return (
                                        <div
                                          key={imgIndex}
                                          className="relative"
                                          onMouseEnter={() => {
                                            setHoverOnImage({
                                              ...hoverOnImage,
                                              secondary_image: img,
                                            });
                                          }}
                                          onMouseLeave={() => {
                                            setHoverOnImage({
                                              ...hoverOnImage,
                                              secondary_image: 0,
                                            });
                                          }}
                                        >
                                          <img
                                            src={imgSrc}
                                            alt={img}
                                            className={`w-[130px] h-[100px] border-2 border-gray-300 cursor-pointer ${
                                              hoverOnImage?.secondary_image ==
                                                img && `brightness-95`
                                            }`}
                                          />
                                          {hoverOnImage?.secondary_image ==
                                            img && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                removeImage(true, img, {
                                                  setFieldValue,
                                                  setFieldTouched,
                                                  values,
                                                });
                                              }}
                                              className="absolute -top-3.5 -right-2.5 z-99"
                                            >
                                              <XCircleIcon
                                                strokeWidth={1}
                                                className="w-6 h-6 cursor-pointer text-red-700 "
                                              />
                                            </button>
                                          )}
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                          )}

                          <div className="my-4 flex flex-start w-full gap-1 items-center">
                            <label
                              className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                              htmlFor="url"
                            >
                              Url :
                            </label>
                            <div className="w-full">
                              <Field
                                className={`input-field`}
                                id="url"
                                name="url"
                                type="text"
                                values={values?.url ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="https://www.google.com"
                              />
                              <ErrorMessage
                                component="div"
                                className="field-error text-red-500"
                                name={"url"}
                              />
                            </div>
                          </div>

                          <div className="mb-4 flex flex-start w-full gap-1 items-center">
                            <label
                              className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                              htmlFor="allergen"
                            >
                              Allergens :
                            </label>
                            <div className="w-full">
                              {/* <Field
                                className={`input-field`}
                                id="allergen"
                                name="allergen"
                                type="text"
                                values={values?.allergen ?? ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Allergen"
                              />{" "} */}
                              {/* <MultiWithCreateDropdown
                                // defaultValue={values?.allergen ?? []}
                                options={allergensOptions}
                                values={values?.allergen ?? []}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                name={"allergen"}
                              /> */}
                              <MultiSelectDropdown
                                selectedOptions={values?.allergen ?? []}
                                options={allergensOptions}
                                onChange={(e) => {
                                  setFieldValue("allergen", e);
                                }}
                                name="allergen"
                                value={values?.allergen ?? []}
                              />
                              <ErrorMessage
                                component="div"
                                className="field-error text-red-500"
                                name={"allergen"}
                              />
                            </div>
                          </div>

                          <div className="mb-4 flex flex-start w-full gap-1 items-center">
                            <label
                              className="font-light leading-6 text-gray-900 text-start px-4 w-[30%]"
                              htmlFor="tag"
                            >
                              Featured Menu Item :
                            </label>
                            <div className="w-full">
                              <Field
                                as="select"
                                className="input-field"
                                name="type"
                                value={values?.featured_menu_item ?? ""}
                                onChange={(e) => {
                                  setFieldValue(
                                    "featured_menu_item",
                                    e.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                              >
                                {["Unspecified", "Yes", "No"]?.map((item) => (
                                  <option
                                    key={item}
                                    value={item ?? ""}
                                    defaultValue={
                                      values?.featured_menu_item ?? ""
                                    }
                                  >
                                    {item}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                component="div"
                                className="field-error text-red-500"
                                name={"featured_menu_item"}
                              />
                            </div>
                          </div>

                          <div className="flex gap-2 mt-10">
                            <SpinnerButton
                              className="btn-pink w-20"
                              title={`Save`}
                              action={() => {
                                saveAndAdd(false);
                              }}
                              type={"submit"}
                              loading={!isSaveAndAdd && isLoading}
                              isDisable={isLoading}
                            />
                            {!isEdit && (
                              <SpinnerButton
                                className="btn-pink w-30"
                                title={`Save & Add`}
                                action={() => {
                                  saveAndAdd(true);
                                }}
                                type={"submit"}
                                loading={isSaveAndAdd && isLoading}
                                isDisable={isLoading}
                              />
                            )}
                            <div>
                              <button
                                type="button"
                                className="btn btn-light-gray"
                                onClick={() => {
                                  resetForm();
                                  setModalOpen(false);
                                }}
                                disabled={isLoading}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
