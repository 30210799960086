import deleteIcon from "assets/images/deleteIcon.svg";
import editIcon from "assets/images/editIcon.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import CustomTable from "components/common/fields/CustomTable";
import SearchInput from "components/common/fields/SearchInput";
import {
  createFirstLetterCapital,
  formatPhoneNumberAlways,
  getCompanyStatus,
  showPerPage,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { changeLocationUserStatus } from "redux/location/changeLocationUserStatusSlice";
import { getLocationUserList } from "redux/location/getLocationUserListSlice";

const UserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [locationUserData, setLocationUserData] = useState([]);
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const isCompanyActive = ["active"].includes(getCompanyStatus());

  const getLocationUserListData = useSelector(
    (state) => state?.getLocationUserList?.data?.data ?? {}
  );
  const locationUserLoading = useSelector(
    (state) => state?.getLocationUserList?.loading ?? false
  );
  const changeLocationUserStatusLoading = useSelector(
    (state) => state?.changeLocationUserStatus?.loading ?? false
  );

  const totalRecords = getLocationUserListData?.total ?? 0;
  const currentPerPage = getLocationUserListData?.per_page ?? showPerPage;

  const handleLocationUserList = (search) => {
    const sortObj = {
      sort: columns[sortIndex].accessor,
      order: sortOrder,
      search: search,
      page: currentPage,
      perPage: perPage,
    };
    dispatch(getLocationUserList(sortObj)).then((data) => {
      if (data?.payload?.data?.data) {
        setLocationUserData(data?.payload?.data?.data);
      }
    });
  };

  const handleChangeLocationUserStatus = () => {
    dispatch(
      changeLocationUserStatus({ id: selectedId, status: "archived" })
    ).then((data) => {
      if (data?.payload?.code == 200) {
        setModalOpen(false);
        showSuccessMsg(
          data?.payload?.message ?? "Location user deleted successfully."
        );
        handleLocationUserList(searchInput);
      } else {
        setModalOpen(false);
        handleServerValidation();
        handleLocationUserList(searchInput);
      }
    });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell }) => (
        <span
          className="hover:underline hover:cursor-pointer "
          onClick={() => {
            navigate(`/account/users/edit/${cell.row.values?.id}`);
          }}
        >
          {cell.row.values.name}
        </span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ cell }) => {
        return formatPhoneNumberAlways(cell?.row?.values?.phone);
      },
    },
    {
      Header: "Last Login",
      accessor: "lastLogin",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => (
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-sm font-medium   
                     ${
                       cell?.row?.values?.status === "active"
                         ? `text-green-600`
                         : `text-orange-600`
                     }`}
        >
          {createFirstLetterCapital(cell?.row?.values?.status)}
        </span>
      ),
    },

    ...(isCompanyActive
      ? [
          {
            width: 100,
            Header: "Actions",
            accessor: "id",
            disableSortBy: true,
            Cell: ({ cell }) => (
              <div className="flex items-center gap-x-2 ">
                <img
                  alt="Edit"
                  src={editIcon}
                  className="h-5 w-5 hover:cursor-pointer"
                  onClick={() => {
                    navigate(`/account/users/edit/${cell.row.values?.id}`);
                  }}
                />

                <img
                  alt="archive"
                  src={deleteIcon}
                  className="h-5 w-5 hover:cursor-pointer"
                  onClick={() => {
                    setModalOpen(true);
                    setSelectedId(cell?.row?.values?.id);
                  }}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const globalSearch = (search) => {
    handleLocationUserList(search);
  };

  useEffect(() => {
    handleLocationUserList(searchInput);
  }, [sortOrder, currentPage, perPage]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Account",
          href: "",
          current: false,
        },
        {
          name: "Users List",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  return (
    <div className="">
      <div className="flex justify-end  items-center mt-2 mb-2">
        {isCompanyActive && (
          <button
            type="button"
            onClick={() => {
              navigate("/account/users/add");
            }}
            className="btn-pink"
          >
            Add User
          </button>
        )}
        <div className="sm:ml-2 flex rounded-md shadow-sm">
          <SearchInput
            globalSearch={globalSearch}
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div>
      </div>

      <CustomTable
        data={locationUserData}
        columns={columns}
        defaultSortIndexColumn={sortIndex}
        defaultOrder={sortOrder}
        setSortIndex={setSortIndex}
        setSortOrder={setSortOrder}
        isLoading={locationUserLoading}
        totalRecords={totalRecords}
        perPage={perPage ? perPage : currentPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPerPage={setPerPage}
      />
      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleChangeLocationUserStatus}
          isLoading={changeLocationUserStatusLoading}
        />
      )}
    </div>
  );
};

export default UserList;
