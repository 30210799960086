import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import {
  createFirstLetterCapital,
  getCurrentLocation,
  handleAddress,
  isNoLocationExist,
  isValidArray,
  setCurrentLocation,
} from "module/util";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { getLocationByUser } from "redux/common/getLocationByUserSlice";
import { changeLocation } from "redux/common/selectedLocationSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LocationsDropdown = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const [selectedLocation, setSelectedLocation] = useState({});
  const menu = !!searchParams?.get("menu") ?? false;
  const postForm = !!searchParams?.get("form") ?? false;
  const category = !!searchParams?.get("category") ?? false;

  const locationData = useSelector(
    (state) => state?.getLocationByUser?.data ?? []
  );
  const switchToCompany = useSelector(
    (state) => state?.switchToCompany?.data ?? []
  );
  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );

  const handleLocationList = (isInit = false) => {
    dispatch(getLocationByUser()).then((data) => {
      if (isValidArray(data?.payload)) {
        if (isInit) {
          const locationObj = getCurrentLocation();
          const dataObj = {
            id: data?.payload[0]?.location_id,
            location: data?.payload[0]?.location_name,
            address: handleAddress(
              data?.payload[0]?.location_address1,
              data?.payload[0]?.location_address2,
              data?.payload[0]?.location_city,
              data?.payload[0]?.location_state,
              data?.payload[0]?.location_zip
            ),
            lat_long: data?.payload[0]?.lat_long ?? "",
            google_business_type:
              data?.payload[0]?.google_business_type ?? "service",
            location_url: data?.payload[0]?.location_url ?? "",
            language_code: data?.payload[0]?.language_code ?? "",
            region_code: data?.payload[0]?.region_code ?? "",
          };
          setSelectedLocation(locationObj ? locationObj : dataObj);
          setCurrentLocation(locationObj ? locationObj : dataObj);
        }
      }
    });
  };

  useEffect(() => {
    handleLocationList(true);
  }, [switchToCompany]);

  if (isNoLocationExist()) {
    return <NoLocationAlert />;
  }

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left w-3/4 sm:w-auto"
      >
        {selectedLocation?.location && (
          <div
            className={classNames(menu ? "opacity-80 cursor-not-allowed" : "")}
          >
            <Menu.Button
              className={classNames(
                "inline-flex w-full  justify-between gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                {
                  "shadow-lg hover:cursor-pointer":
                    isValidArray(locationData) && locationData?.length > 1,
                  "hover:cursor-default":
                    !isValidArray(locationData) || locationData?.length <= 1,
                }
              )}
              onClick={() => handleLocationList(false)}
              disabled={menu || postForm || category}
            >
              <span className="flex">
                <MapPinIcon
                  className="mr-1 h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
                <p className="text-[10px] md:text-sm ">
                  {selectedLocation?.location ?? "Select Location"}
                </p>
              </span>

              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-700"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
        )}

        {isValidArray(locationData) && locationData?.length > 1 && (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-10 mt-0 max-h-[300px] overflow-auto w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {locationData.map((item) => (
                  <Menu.Item key={item?.location_id}>
                    {({ active }) => (
                      <span
                        onClick={() => {
                          const locationObj = {
                            id: item?.location_id,
                            location: createFirstLetterCapital(
                              item?.location_name
                            ),
                            address: handleAddress(
                              item?.location_address1,
                              item?.location_address2,
                              item?.location_city,
                              item?.location_state,
                              item?.location_zip
                            ),
                            lat_long: item?.lat_long ?? "",
                            google_business_type:
                              item?.google_business_type ?? "service",
                            location_url: item?.location_url ?? "",
                            language_code: item?.language_code ?? "",
                            region_code: item?.region_code ?? "",
                          };
                          setSelectedLocation(locationObj);
                          setCurrentLocation(locationObj);
                          dispatch(changeLocation(item?.location_id));
                        }}
                        className={classNames(
                          item?.location_id === selectedLocation?.id || active
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm hover:cursor-pointer"
                        )}
                      >
                        {createFirstLetterCapital(item?.location_name)}
                      </span>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        )}
      </Menu>

      <div className="ml-2 hidden sm:block">
        <p className="text-[12px] xl:text-[14px] font-light">
          {selectedLocation?.location ?? ""}
        </p>
        <p className="text-[12px] xl:text-[12px] font-light -mt-1">
          {selectedLocation?.address ?? ""}
        </p>
      </div>
    </>
  );
};

export default LocationsDropdown;

const NoLocationAlert = () => {
  return (
    <div className=" bg-yellow-50 p-3">
      <div className="flex items-center">
        <ExclamationTriangleIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />

        <div className="ml-3">
          <div className=" text-sm text-yellow-700">
            <span className="font-normal">
              No locations added yet!{" "}
              <Link
                className="underline ml-2 text-yellow-900"
                to="/account/locations/add"
              >
                Add Location
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
