import ReactApexChart from "react-apexcharts";

const GenderGraph = ({ demoGraphicData = {} }) => {
  const graphDetails = demoGraphicData?.gender || {};

  // Extract percentages safely, defaulting to 0 if data is missing
  const seriesArray = [
    graphDetails?.["Male"]?.[0]?.percent ?? 0,
    graphDetails?.["Female"]?.[0]?.percent ?? 0,
    graphDetails?.["Unisex"]?.[0]?.percent ?? 0,
  ];

  // Check if the seriesArray contains any data
  const hasData = seriesArray.some((value) => value > 0);

  const config = {
    series: seriesArray,
    options: {
      chart: {
        height: 300,
        type: "donut",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: ["#37d6eb", "#a5d583", "#f35288"],
      labels: ["Male", "Female", "Unisex"],
      tooltip: {
        enabled: true,
        y: {
          formatter: (value) => {
            return `${value}%`;
          },
        },
      },
    },
  };

  return (
    <div id="chart">
      {hasData ? (
        <ReactApexChart
          options={config.options}
          series={config.series}
          type="donut"
          height={300}
        />
      ) : (
        <div className="h-72 w-full flex justify-center items-center text-gray-600">
          No Data
        </div>
      )}
    </div>
  );
};

export default GenderGraph;
