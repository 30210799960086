import eyeIcon from "assets/images/eyeIcon.svg";
import eyeSlashIcon from "assets/images/eyeSlashIcon.svg";
import Logo from "assets/images/logo-pendio.png";
import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import EmailInUseModal from "components/front/EmailInUseModal";
import { Form, Formik } from "formik";
import { setUserTempEmail, showSuccessMsg } from "module/util";
import {
  handleServerValidation,
  registerForm,
  showError,
} from "module/validation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { register } from "redux/front/registerSlice";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPasswordShow, setPasswordShow] = useState(false);
  const [isResetPasswordShow, setResetPasswordShow] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const isLoading = useSelector((state) => state?.register?.loading ?? false);
  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  const togglePasswordShow = () => setPasswordShow(!isPasswordShow);
  const toggleResetPasswordShow = () =>
    setResetPasswordShow(!isResetPasswordShow);

  const initialValues = {
    company: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    rememberMe: false,
  };

  const handleSubmit = (values) => {
    const formData = {
      company: values?.company ?? "",
      name: values?.name ?? "",
      email: values?.email ?? "",
      password: values?.password ?? "",
      password_confirmation: values?.confirmPassword ?? "",
    };
    dispatch(register(formData))
      .then((data) => {
        if (data?.payload?.code === 200) {
          showSuccessMsg(data?.payload?.dataObj?.message, true);
          setUserTempEmail(values?.email);
          navigate(
            `/verify-email?token=${data?.payload?.dataObj?.token ?? ""}`
          );
        } else {
          handleServerValidation(data);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <ShowPageTitle title={"register"} />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-30 w-auto" src={Logo} alt="Your Company" />
      </div>
      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-[480px] px-8 sm:px-0">
        <div className="bg-white  shadow shadow-gray-400">
          <div className="  bg-white px-4 py-2 sm:px-6">
            <h2 className="mt-3  text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create account
            </h2>
          </div>
          <div className="sm:px-12 py-4 px-4">
            <Formik
              initialValues={initialValues}
              validationSchema={() =>
                registerForm(validationMsgObj?.signUp ?? {})
              }
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                errors,
                touched,
                validateField,
                validateForm,
                handleChange,
                values,
                handleSubmit,
                handleBlur,
              }) => (
                <Form
                  className="space-y-2"
                  onSubmit={handleSubmit}
                  // onKeyDown={(e) => {
                  //   e.key == "Enter" && e.preventDefault();
                  // }}
                >
                  <div>
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Company
                    </label>
                    <div className="mt-2">
                      <input
                        id="company"
                        name="company"
                        type="text"
                        value={values?.company ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="company"
                        placeholder="Enter Company"
                        className="input-field"
                      />
                    </div>
                    {touched.company && showError(errors.company)}
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={values?.name ?? ""}
                        onChange={handleChange}
                        autoComplete="name"
                        onBlur={handleBlur}
                        placeholder="Enter Name"
                        className="input-field"
                      />
                    </div>
                    {touched.name && showError(errors.name)}
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        value={values?.email ?? ""}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoComplete="email"
                        placeholder="Enter Email"
                        className="input-field"
                      />
                    </div>
                    {touched.email && showError(errors.email)}
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="relative mt-2 shadow-sm">
                      <input
                        type={isPasswordShow ? "text" : "password"}
                        name="password"
                        id="password"
                        value={values?.password ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="input-field pr-10"
                        placeholder="Enter Password"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <img
                          alt="eye"
                          src={isPasswordShow ? eyeIcon : eyeSlashIcon}
                          className="hover:cursor-pointer"
                          onClick={() => togglePasswordShow()}
                        />
                      </div>
                    </div>
                    {touched.password && showError(errors.password)}
                  </div>

                  <div>
                    <label
                      htmlFor="re-type-password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Confirm password
                    </label>
                    <div className="relative mt-2">
                      <input
                        id="re-type-password"
                        name="confirmPassword"
                        type={isResetPasswordShow ? "text" : "password"}
                        value={values?.confirmPassword ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="current-password"
                        placeholder="Confirm Password"
                        className="input-field pr-10"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <img
                          alt="eye"
                          src={isResetPasswordShow ? eyeIcon : eyeSlashIcon}
                          className="hover:cursor-pointer"
                          onClick={() => toggleResetPasswordShow()}
                        />
                      </div>
                    </div>
                    {touched.confirmPassword &&
                      showError(errors.confirmPassword)}
                  </div>

                  <div className="items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="rememberMe"
                        name="rememberMe"
                        type="checkbox"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.rememberMe ?? false}
                        className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-0 cursor-pointer"
                      />
                      <label
                        htmlFor="rememberMe"
                        className="ml-3 block text-sm leading-6 text-gray-900 cursor-pointer"
                      >
                        I agree to the{" "}
                        <Link to="#" className="color-blue-500 underline">
                          Term of Use
                        </Link>{" "}
                        and{" "}
                        <Link to="#" className="color-blue-500 underline">
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                    {touched.rememberMe && showError(errors.rememberMe)}
                  </div>

                  <div className="flex justify-center items-center">
                    <SpinnerButton
                      className="flex justify-center btn-pink"
                      title={"Sign up"}
                      action={() => {}}
                      type={"submit"}
                      loading={isLoading}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <p className="mt-1 underline text-center text-sm text-gray-500">
          <Link
            to="/"
            className="font-semibold leading-6 text-pink-600 hover:text-pink-500"
          >
            Go to Login
          </Link>
        </p>
      </div>
      {isModalOpen && (
        <EmailInUseModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={() => {}}
          isLoading={false}
        />
      )}
    </div>
  );
};

export default Register;
