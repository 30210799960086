import eyeIcon from "assets/images/eyeIcon.svg";
import CustomTable from "components/common/fields/CustomTable";

import OrderDetailsModal from "components/contacts/orders/OrderDetailsModal";
import {
  convertTimeStampToDate,
  convertTimeStampToDateTime,
  getCurrentLocation,
  isValidArray,
  parsedListOfObject,
  showPerPage,
  showPrice,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { getOrdersList } from "redux/customer/getOrdersListSlice";

const OrdersList = (props) => {
  const { data, customerId } = props;
  const dispatch = useDispatch();
  const [sortIndex, setSortIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const currentPerPage = showPerPage;
  const { id: currentLocationId } = getCurrentLocation();

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );
  const orderListData = useSelector(
    (state) => state?.getOrdersList?.data?.dataObj ?? {}
  );
  const orderListLoading = useSelector(
    (state) => state?.getOrdersList?.loading ?? false
  );

  const lastUpdated = orderListData?.last_executed_at ?? "";
  const totalRecords = orderListData?.dataObj?.length ?? 0;

  const handleOrdersList = () => {
    const sortObj = {
      location_id: currentLocationId,
      customer_id: customerId,
    };
    dispatch(getOrdersList(sortObj));
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Redemptions",
          href: "",
          current: false,
        },
        {
          name: "Orders",
          href: "",
          current: false,
        },
      ])
    );
    handleOrdersList();
  }, [locationId]);

  const columns = [
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "Created Date",
      accessor: "created_at",
      Cell: ({ cell }) => <span>{convertTimeStampToDate(cell?.value)}</span>,
    },
    {
      Header: "Total Items",
      accessor: "quantity",
    },
    {
      Header: "Order Type",
      accessor: "order_fulfillment_type",
    },
    {
      Header: "Coupon",
      accessor: "orders_discount_name",
    },
    {
      Header: "Discount",
      accessor: "total_discount_money",
      Cell: ({ cell }) => <span>{showPrice(cell?.value)}</span>,
    },
    {
      Header: "Paid Amount",
      accessor: "total_money",
      Cell: ({ cell }) => <span>{showPrice(cell?.value)}</span>,
    },
    {
      Header: "Action",
      accessor: "order_id",
      Cell: ({ cell }) => (
        <span
          className="cursor-pointer"
          title="View"
          onClick={() => {
            setSelectedId(cell?.row?.values?.order_id);
            setModalOpen(true);
          }}
        >
          <img alt="view" title="View" src={eyeIcon} className="w-6" />
        </span>
      ),
    },
  ];

  return (
    <div className="">
      {parsedListOfObject(orderListData)?.length !== 0 && (
        <div className="w-full flex justify-end font-bold text-gray-800">
          <p className="text-md font-semibold text-gray-700">
            Last Update:
            {convertTimeStampToDateTime(lastUpdated)}
          </p>
        </div>
      )}
      <div className="mt-4 flex flex-col">
        <CustomTable
          data={
            isValidArray(parsedListOfObject(orderListData))
              ? parsedListOfObject(orderListData)
              : []
          }
          isLoading={orderListLoading}
          columns={columns}
          defaultSortIndexColumn={sortIndex}
          defaultOrder={sortOrder}
          setSortIndex={setSortIndex}
          setSortOrder={setSortOrder}
          totalRecords={totalRecords}
          perPage={perPage ? perPage : currentPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
          isPaginationHide={false}
          colSpan={12}
        />
        {isModalOpen && (
          <OrderDetailsModal
            orderData={
              isValidArray(parsedListOfObject(orderListData))
                ? parsedListOfObject(orderListData)
                : []
            }
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            selectedId={selectedId}
          />
        )}
      </div>
    </div>
  );
};

export default OrdersList;
