import UserTabs from "components/account/location/UserTabs";
import CustomPhoneInput from "components/common/fields/CustomPhoneInput";
import DropdownField from "components/common/fields/DropdownField";
import GoogleAddressField from "components/common/fields/GoogleAddressField";
import GoogleMapComponent from "components/common/fields/GoogleMapComponent";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, Form, Formik } from "formik";
import {
  convertLatLongObj,
  formatPhoneNumberAlways,
  getCurrentLocation,
  getUserData,
  getUserDataByParam,
  handleAddress,
  isCompanyUser,
  isValidArray,
  isValidObject,
  setCurrentLocation,
  setUserData,
} from "module/util";
import {
  companyLocationForm,
  handleServerValidation,
  showError,
} from "module/validation";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { addLocation } from "redux/location/addLocationSlice";
import { getLocationDetail } from "redux/location/getLocationDetailSlice";
import { updateLocationDetail } from "redux/location/updateLocationDetailSlice";

const LocationForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const formRef = useRef();
  const [latLong, setLatLong] = useState(null);

  const isEdit = location.pathname.includes("/account/locations/edit") && id;
  const currentLocation = getCurrentLocation();

  const getLocationDetailData = useSelector(
    (state) => state?.getLocationDetail?.data ?? []
  );
  const addLocationLoading = useSelector(
    (state) => state?.addLocation?.loading ?? false
  );
  const updateLocationLoading = useSelector(
    (state) => state?.updateLocationDetail?.loading ?? false
  );
  const locationListData = useSelector(
    (state) => state?.locationList?.data ?? []
  );
  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  useEffect(() => {
    if (isCompanyUser() && isEdit) {
      dispatch(getLocationDetail(id)).then((data) => {
        if (data?.payload && data?.payload?.companyLocation) {
          const latLong = convertLatLongObj(
            data?.payload?.companyLocation?.lat_long
          );
          setLatLong(latLong);
        }
      });
    }

    dispatch(
      setBreadCrumb([
        {
          name: "Account",
          href: "/account",
          current: true,
        },
        {
          name: "Locations List",
          href: "/account/locations",
          current: true,
        },
        {
          name: isEdit ? "Edit Location" : "Add Location",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  const locationDetailData = isValidObject(
    getLocationDetailData?.companyLocation
  )
    ? getLocationDetailData?.companyLocation
    : {};

  const initialValues = {
    address1: isEdit ? locationDetailData?.address1 : "",
    address2: isEdit ? locationDetailData?.address2 : "",
    city: isEdit ? locationDetailData?.city : "",
    company_id: isEdit ? locationDetailData?.company_id : "",
    email: isEdit ? locationDetailData?.email : "",
    name: isEdit ? locationDetailData?.name : "",
    phone: isEdit ? formatPhoneNumberAlways(locationDetailData?.phone) : "",
    state: isEdit ? locationDetailData?.state : "",
    status: isEdit ? locationDetailData?.status : "",
    zip: isEdit ? locationDetailData?.zip : "",
    primaryContact: isEdit ? locationDetailData?.primaryContact : "",
    primaryPhone: isEdit ? locationDetailData?.primaryPhone : "",
    primaryEmail: isEdit ? locationDetailData?.primaryEmail : "",
    location_url: isEdit ? locationDetailData?.location_url : "",
    lat_long: "",
  };

  const handleSubmit = (values) => {
    const companyId = getUserDataByParam("COMPANY_ID");

    const formData = {
      address1: values?.address1 ?? "",
      address2: values?.address2 ?? "",
      city: values?.city ?? "",
      company_id: companyId ?? "",
      email: values?.email ?? "",
      name: values?.name ?? "",
      phone: values?.phone ?? "",
      state: values?.state ?? "",
      status: "active",
      zip: values?.zip ?? "",
      lat_long: values?.lat_long ?? "",
      location_url: values?.location_url ?? "",
    };

    if (isEdit) {
      dispatch(updateLocationDetail({ id, formData })).then((data) => {
        if (data?.payload?.code === 200) {
          if (currentLocation?.id === id) {
            const address = handleAddress(
              data?.payload?.dataObj?.companyLocation?.address1 ?? "",
              data?.payload?.dataObj?.companyLocation?.address2 ?? "",
              data?.payload?.dataObj?.companyLocation?.city ?? "",
              data?.payload?.dataObj?.companyLocation?.state ?? "",
              data?.payload?.dataObj?.companyLocation?.zip ?? ""
            );

            const finalLocationData = {
              ...currentLocation,
              address: address,
              location: values?.name ?? "",
              location_url:
                data?.payload?.dataObj?.companyLocation?.location_url ?? "",
            };
            setCurrentLocation(finalLocationData);
            window.location.reload(false);
          }
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      dispatch(addLocation(formData)).then((data) => {
        if (data?.payload?.code === 200) {
          navigate("/account/locations");
          if (!isEdit) {
            if (!isValidArray(locationListData?.data)) {
              const userData = getUserData();
              const userObj = userData ? JSON.parse(userData) : {};
              const newUserObj = {
                ...userObj,
                location_count: 1,
              };
              setUserData(JSON.stringify(newUserObj));

              window.location.reload(false);
            }
          }
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  return (
    <div className="flex flex-wrap flex-col gap-y-2 xl:flex-row gap-x-2 location-form-main mt-4">
      <div className="flex-1">
        <Formik
          initialValues={initialValues}
          validationSchema={() =>
            companyLocationForm(validationMsgObj?.location ?? {})
          }
          onSubmit={handleSubmit}
          enableReinitialize={true}
          // validateOnBlur={false}
          innerRef={formRef}
        >
          {({
            errors,
            touched,
            validateField,
            validateForm,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form
              className="w-full min-w-xl border border-gray-200  p-3 bg-white"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                e.key == "Enter" && e.preventDefault();
              }}
            >
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="name" className="input-field-label">
                    Name
                  </label>
                  <div className="mt-2">
                    <GoogleAddressField
                      name="name"
                      setFieldValue={setFieldValue}
                      value={values?.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Name"}
                      setLatLong={setLatLong}
                      isNameField={true}
                      setFieldTouched={setFieldTouched}
                      FieldValues={values}
                    />
                  </div>
                  {touched?.name && showError(errors?.name)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="address" className="input-field-label">
                    Address
                  </label>
                  <div className="mt-2">
                    <GoogleAddressField
                      name="address1"
                      setFieldValue={setFieldValue}
                      value={values?.address1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Address"}
                      setLatLong={setLatLong}
                      setFieldTouched={setFieldTouched}
                      FieldValues={values}
                    />
                  </div>
                  {touched?.address1 && showError(errors?.address1)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="Address2" className="input-field-label">
                    Address2
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="address2"
                      id="Address2"
                      value={values?.address2 ?? ""}
                      onChange={handleChange}
                      autoComplete="Address2"
                      placeholder="Enter Address2"
                      className="input-field"
                    />
                  </div>
                  {touched?.address2 && showError(errors?.address2)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label htmlFor="city" className="input-field-label">
                    City
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="city"
                      id="city"
                      value={values?.city ?? ""}
                      onChange={handleChange}
                      autoComplete="address-level2"
                      placeholder="Enter City"
                      className="input-field"
                    />
                  </div>
                  {touched?.city && showError(errors?.city)}
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label htmlFor="state" className="input-field-label">
                    State
                  </label>
                  <div className="mt-2">
                    <DropdownField
                      setFieldValue={setFieldValue}
                      values={values}
                      name="state"
                    />
                  </div>
                  {touched?.state && showError(errors?.state)}
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label htmlFor="zip" className="input-field-label">
                    ZIP / Postal code
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="zip"
                      id="zip"
                      value={values?.zip ?? ""}
                      onChange={handleChange}
                      autoComplete="zip"
                      placeholder="Enter Zip/Postal Code"
                      className="input-field"
                    />
                  </div>
                  {touched?.zip && showError(errors?.zip)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <label htmlFor="phone" className="input-field-label">
                    Phone
                  </label>
                  <CustomPhoneInput
                    values={values}
                    name="phone"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                  />
                  {touched?.phone && showError(errors?.phone)}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label htmlFor="email" className="input-field-label">
                    Email
                  </label>
                  <div className="mt-2">
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      value={values?.email ?? ""}
                      onChange={handleChange}
                      autoComplete="email"
                      placeholder="Enter Email"
                      className="input-field"
                    />
                  </div>
                  {touched?.email && showError(errors?.email)}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label htmlFor="location_url" className="input-field-label">
                    Location URL
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="location_url"
                      id="location_url"
                      value={values?.location_url ?? ""}
                      onChange={handleChange}
                      autoComplete="location_url"
                      placeholder="Enter Location URL"
                      className="input-field"
                    />
                  </div>
                  {touched?.location_url && showError(errors?.location_url)}
                </div>
              </div>

              <div className="flex flex-wrap justify-start">
                <SpinnerButton
                  className="btn-pink"
                  title={"Save"}
                  action={() => {}}
                  type={"submit"}
                  loading={addLocationLoading || updateLocationLoading}
                />

                <button
                  onClick={() => navigate("/account/locations")}
                  type="button"
                  className="btn btn-gray ml-2"
                  disabled={addLocationLoading || updateLocationLoading}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex-2 w-full xl:w-[50%] border border-gray-200 bg-white p-2 ">
        <GoogleMapComponent latLong={latLong} />
        <div className="user-list mt-8">
          {isEdit && <UserTabs locationId={id} />}
        </div>
      </div>
    </div>
  );
};

export default LocationForm;
