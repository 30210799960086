import SpinnerButton from "components/common/fields/SpinnerButton";
import SquareImportModal from "components/companySettings/integrations/square/SquareImportModal";
import GuestWifiLoader from "components/front/GuestWifiLoader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { API_URL } from "module/api";
import { PulseLoader } from "react-spinners";

import {
  changeDateFormat,
  createListOfObject,
  isValidArray,
  isValidObject,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { associateSquareLocation } from "redux/companySetting/associateSquareLocationSlice";
import { getSquareLocations } from "redux/companySetting/getSquareLocationsSlice";
import { getSquareLoginDetail } from "redux/companySetting/getSquareLoginDetailSlice";
import { refreshSquareToken } from "redux/companySetting/refreshSquareTokenSlice";
import { revokeSquareToken } from "redux/companySetting/revokeSquareTokenSlice";
import * as yup from "yup";

export default function SquareDetails({
  integration = {},
  currentLocationId = "",
  currentCompanyId = "",
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState({
    buttonLoading: false,
    pageLoading: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportingCompleted, setIsImportingCompleted] = useState();

  const [searchParams] = useSearchParams();
  const settingRedirect =
    searchParams.get("redirect") === "true"
      ? true
      : searchParams.get("redirect") === "false"
      ? false
      : null;

  const [
    {
      squareLoginDetails = [],
      getSquareLocationData = {},
      disconnectSquareLoading = false,
      refreshSquareTokenLoading = false,
    },
  ] = useSelector((state) => [
    {
      squareLoginDetails: state?.getSquareLoginDetail?.data ?? [],
      getSquareLocationData:
        state?.getSquareLocations?.data?.dataObj?.data ?? {},
      disconnectSquareLoading: state?.revokeSquareToken?.loading ?? false,
      refreshSquareToken: state?.refreshSquareToken?.loading ?? false,
    },
  ]);

  const isSquareLogin = isValidObject(squareLoginDetails?.dataObj)
    ? squareLoginDetails?.dataObj?.access_token
    : false;

  const squareNotConnected = !isSquareLogin || !settingRedirect;

  const squareDetails =
    isValidObject(squareLoginDetails?.dataObj) && squareLoginDetails?.dataObj;
  const squqreTokenExpiredDate = squareDetails?.expires_at ?? "";

  const locationData = getSquareLocationData || {};
  const currentAssociatedLocation = squareDetails?.locationDetails;

  const valueFromCurrentLocationId =
    currentAssociatedLocation?.[currentLocationId];

  const handleSquareLoginDetail = () => {
    const formData = {
      company_id: currentCompanyId,
    };
    dispatch(getSquareLoginDetail(formData));
  };

  const handleGetSquareLocation = () => {
    setIsLoading({ pageLoading: true });
    const formData = {
      company_id: currentCompanyId,
    };
    dispatch(getSquareLocations(formData)).then((data) => {
      if (data?.payload.code == 200) {
        setIsLoading({ pageLoading: false });
      } else {
        setIsLoading({ pageLoading: false });
      }
    });
  };

  useEffect(() => {
    handleSquareLoginDetail();
    handleGetSquareLocation();
  }, [currentLocationId, isImportingCompleted]);

  const handleDissconnectSquareUp = () => {
    const formData = {
      company_id: currentCompanyId,
    };
    setIsLoading({ pageLoading: true });
    dispatch(revokeSquareToken(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        handleSquareLoginDetail();
        setIsLoading({ pageLoading: false });
        showSuccessMsg(data?.payload?.message ?? "Disconnected Successfully.");
      } else {
        setIsLoading({ pageLoading: false });
        handleServerValidation(data);
      }
    });
  };

  const handleAssociateLocation = (values) => {
    setIsLoading({ buttonLoading: true });
    const formData = {
      company_location_id: currentLocationId,
      square_location_id: values?.location_id,
    };
    dispatch(associateSquareLocation(formData)).then((data) => {
      if (data?.payload.code === 200) {
        setIsLoading({ buttonLoading: false });
        setIsModalOpen(true);
        showSuccessMsg("Location Associated Successfully!");
      } else {
        setIsLoading({ buttonLoading: false });
        handleServerValidation(data);
      }
    });
  };

  const handleRefreshToken = () => {
    const formData = {
      company_id: currentCompanyId,
    };
    dispatch(refreshSquareToken(formData)).then((data) => {
      if (data?.payload.code === 200) {
        handleSquareLoginDetail();
      } else {
        handleServerValidation(data);
      }
    });
  };

  const initialValues = {
    // client_id: squareDetails?.client_id ?? "",
    // client_secret: squareDetails?.client_secret ?? "",
    location_id: !!valueFromCurrentLocationId ? valueFromCurrentLocationId : "",
  };

  const checkSquareTokenExpiration = (expirationDate) => {
    if (!!expirationDate) {
      const expirationTimestamp = new Date(expirationDate).getTime();
      const currentTimestamp = new Date().getTime();

      const isExpired = expirationTimestamp <= currentTimestamp;

      const expirationDateString = new Date(
        expirationTimestamp
      ).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" });

      const expirationMessage = isExpired
        ? "Your token is expired! Click to Refresh."
        : `Your token will expire on ${expirationDateString}`;

      return { isExpired, expirationMessage };
    }
    return;
  };

  return (
    <div className="w-full py-4">
      <div className="w-full flex justify-between">
        <button
          type="button"
          className="btn-pink w-20"
          onClick={() => {
            navigate(`/user-settings?tab=integrations`);
          }}
        >
          Back
        </button>
        <button
          type="button"
          title={
            checkSquareTokenExpiration(squqreTokenExpiredDate)
              ?.expirationMessage
          }
          className={
            !settingRedirect ||
            !checkSquareTokenExpiration(squqreTokenExpiredDate)?.isExpired
              ? `btn-green`
              : `btn-yellow  w-[148px]`
          }
          disabled={
            !checkSquareTokenExpiration(squqreTokenExpiredDate)?.isExpired ||
            refreshSquareTokenLoading
          }
          onClick={() => {
            handleRefreshToken();
          }}
        >
          {refreshSquareTokenLoading ? (
            <span className="h-[20px] flex justify-center items-center">
              <PulseLoader color="#ffff" size={12} />
            </span>
          ) : (
            `Refresh Key @`
          )}
        </button>
      </div>

      <div className={`mt-2 flex flex-col xl:flex-row gap-4`}>
        <div
          className={`bg-white border-2 border-gray-400 2xl:w-[25%] xl:w-[40%] w-full flex flex-col items-center min-h-80`}
        >
          <div className="p-4 flex flex-col gap-y-6 items-center h-full">
            <div className=" h-16 w-16">
              <img className="h-16 w-16" src={integration?.image} />
            </div>
            <p className="text-md font-semibold text-gray-700">{`Square`}</p>
            <div>
              <p className="text-md text-gray-700">
                {integration?.description}
              </p>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------ */}
        <div className="bg-white border-2 border-gray-400 2xl:w-[80%] xl:w-[75%] w-full flex flex-col justify-between">
          <div className="flex flex-col gap-6 ">
            <div className="p-4">
              <p className="text-md font-semibold text-gray-700">
                {`Connect to your Square account`}
              </p>
              <div>
                <p className="text-md text-gray-700">
                  {integration?.instruction}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <form
                className="w-full flex flex-col"
                action={`${API_URL}square/saveauth`}
                method="POST"
                onSubmit={() => {
                  setIsLoading({ buttonLoading: true });
                }}
                id="form1"
                name="form1"
              >
                <div className="px-3">
                  <div className="flex w-full">
                    <label
                      className="font-light leading-6 text-gray-900 px-4 flex items-center justify-end w-[30%]"
                      htmlFor="client_id"
                    >
                      Authorization:
                    </label>
                    <div className="w-[70%]">
                      {squareNotConnected ? (
                        <SpinnerButton
                          className={"btn btn-yellow"}
                          title={"Connect Square up"}
                          action={() => {}}
                          type={"submit"}
                          loading={isLoading?.buttonLoading}
                        />
                      ) : (
                        <SpinnerButton
                          className={"btn btn-red"}
                          title={`Disconnect Square up`}
                          action={() => {
                            handleDissconnectSquareUp();
                          }}
                          type={"button"}
                          loading={disconnectSquareLoading}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex w-full mt-4">
                    <label
                      className="font-light leading-6 text-gray-900 px-4 flex items-center justify-end w-[30%]"
                      htmlFor="client_secret"
                    >
                      Status:
                    </label>
                    <p
                      className={`w-[70%] font-semibold ${
                        squareNotConnected
                          ? `text-orange-600`
                          : `text-green-600`
                      } `}
                    >
                      {squareNotConnected ? `Not Connected` : `Connected`}
                    </p>

                    <input
                      className="input-field w-full  h-10"
                      id="location_id"
                      name="location_id"
                      type="hidden"
                      value={currentLocationId}
                    />
                    <input
                      className="input-field w-full  h-10"
                      id="company_id"
                      name="company_id"
                      type="hidden"
                      value={currentCompanyId}
                    />
                  </div>
                </div>
              </form>
              {!squareNotConnected && (
                <Formik
                  enableReinitialize={true}
                  onSubmit={handleAssociateLocation}
                  validationSchema={yup.object({
                    location_id: yup.string().required("Location required."),
                  })}
                  initialValues={initialValues}
                >
                  {({
                    errors,
                    touched,
                    validateField,
                    validateForm,
                    handleChange,
                    setFieldValue,
                    values,
                    handleSubmit,
                  }) => (
                    <Form
                      className="w-full"
                      id="form1"
                      name="form1"
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => {
                        e.key == "Enter" && e.preventDefault();
                      }}
                    >
                      <div className="px-3">
                        <div className="flex w-full mt-2">
                          <label
                            className="font-light leading-6 text-gray-900 px-4 flex items-center justify-end w-[30%]"
                            htmlFor="location"
                          >
                            Location:
                          </label>
                          <div className="w-[70%]">
                            <Field
                              as="select"
                              className="input-field relative h-10"
                              name="location_id"
                              onChange={(e) =>
                                setFieldValue("location_id", e.target.value)
                              }
                              defaultValue={values?.location_id ?? ""}
                            >
                              <option
                                key={1}
                                value={""}
                                className="font-light "
                              >
                                Select Location
                              </option>
                              {isValidArray(createListOfObject(locationData)) &&
                                createListOfObject(locationData)?.map(
                                  (location, locationIndex) => {
                                    return (
                                      <option
                                        key={locationIndex}
                                        value={location?.id}
                                        defaultValue={
                                          valueFromCurrentLocationId ==
                                          location?.id
                                        }
                                      >
                                        {location?.name}
                                      </option>
                                    );
                                  }
                                )}
                            </Field>
                            <ErrorMessage
                              component={"span"}
                              className="absolute top-0 text-red-500"
                              name={"location_id"}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-6 !bg-gray-300 w-full flex justify-between p-3">
                        <p className="text-md font-semibold text-gray-700">
                          Last Update:{" "}
                          {changeDateFormat(squareDetails?.last_updated)}
                        </p>

                        <SpinnerButton
                          className="btn-blue "
                          title={"Associate locations and import"}
                          action={() => {}}
                          type={"submit"}
                          loading={isLoading?.buttonLoading}
                          isDisable={
                            JSON.stringify(initialValues) ===
                            JSON.stringify(values)
                          }
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <SquareImportModal
          currentCompanyId={currentCompanyId}
          currentLocationId={currentLocationId}
          setIsImportingCompleted={setIsImportingCompleted}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {isLoading?.pageLoading && (
        <GuestWifiLoader
          isModalOpen={isLoading?.pageLoading}
          message="Please wait a while, Fetching Details..."
        />
      )}
    </div>
  );
}
