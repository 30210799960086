import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const Calender = ({ setEditModal = () => {}, handleEditModal = () => {} }) => {
  const events = [
    {
      id: 0,
      title: "All Day Event very long title",
      allDay: true,
      start: moment("2024-07-01").toDate(),
      end: moment("2024-07-01").toDate(),
      desc: "Pre-meeting meeting, to prepare for the meeting",
    },
    {
      id: 1,
      title: "Long Event",
      start: moment("2024-07-07").toDate(),
      end: moment("2024-07-07").toDate(),
    },
    {
      id: 4,
      title: "Long Event 2",
      start: moment("2024-07-08").toDate(),
      end: moment("2024-07-08").toDate(),
    },
    {
      id: 5,
      title: "Long Event",
      start: moment("2024-07-08").toDate(),
      end: moment("2024-07-08").toDate(),
    },
    {
      id: 6,
      title: "Long Event",
      start: moment("2024-07-08").toDate(),
      end: moment("2024-07-08").toDate(),
    },
    {
      id: 2,
      title: "DTS STARTS",
      start: moment("2024-07-08").toDate(),
      end: moment("2024-07-10").toDate(),
    },
  ];
  const localizer = momentLocalizer(moment);
  const [myEvents, setMyEvents] = useState(events);

  const DragAndDropCalendar = withDragAndDrop(Calendar);

  const eventPropGetter = useCallback(
    (event) => ({
      ...(event.isDraggable
        ? { className: "isDraggable" }
        : { className: "nonDraggable" }),
    }),
    []
  );

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }
      if (allDay && !droppedOnAllDaySlot) {
        event.allDay = false;
      }

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay }];
      });
    },
    [setMyEvents]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setMyEvents]
  );

  useEffect(() => {
    setMyEvents(events);
  }, []);

  return (
    <div className="border border-gray-200 bg-white p-4">
      <DragAndDropCalendar
        localizer={localizer}
        events={myEvents}
        style={{ height: "65vh" }}
        eventPropGetter={eventPropGetter}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        onSelectSlot={(e) => {}}
        onDoubleClickEvent={(e) => {
          handleEditModal(e?.id);
        }}
        // popup
        resizable
        selectable
      />
    </div>
  );
};

export default Calender;
