import classNames from "classnames";
import SpinnerButton from "components/common/fields/SpinnerButton";
import GuestWifiLoader from "components/front/GuestWifiLoader";
import CustomDropDown from "components/reputation/CustomDropDown";
import { API_URL } from "module/api";
import {
  convertTimeStampToDateTime,
  getCurrentLocation,
  isTokenExpired,
  setCurrentLocation,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkExpireToken } from "redux/reputation/checkExpireTokenSlice";
import { getAssociateList } from "redux/reputation/getAssociateListSlice";
import { getGoogleLocation } from "redux/reputation/getGoogleLocationSlice";
import { getReviewTemplate } from "redux/reputation/getReviewTemplateSlice";
import { googleDisconnect } from "redux/reputation/googleDisconnectSlice";
import { googleRefreshKey } from "redux/reputation/googleRefeshKeySlice";

export default function GoogleIntegration({
  integration = {},
  currentCompanyId = "",
  currentLocationId = "",
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [buttonDisable, setButtonDisable] = useState(true);
  const currentLocationObj = getCurrentLocation();

  const [selectedLocation, setSelectedLocation] = useState({
    name: "Select Locations",
    id: "",
    category: "",
    type: "",
    can_add_menu: "",
    can_modify_service_list: "",
    address: "",
    region_code: "",
    language_code: "",
  });
  let locationname = selectedLocation?.name?.split("|");
  let data = {
    company_id: currentCompanyId,
    location_id: currentLocationId,
    google_location_id: selectedLocation?.id,
    google_location_company_name: locationname[0],
    google_location_category: selectedLocation?.category,
  };

  const [
    {
      locationListData = {},
      checkExpireData = {},
      googleLocationLoading = false,
      associateLocationLoading = false,
      googleRefreshKeyLaoding = false,
    },
  ] = useSelector((state) => [
    {
      locationListData: state?.getGoogleLocation?.data?.dataObj ?? {},
      googleLocationLoading: state?.getGoogleLocation?.loading ?? false,
      checkExpireData: state?.checkExpireToken?.data?.dataObj ?? {},
      associateLocationLoading: state?.getAssociateList?.loading ?? false,
      googleRefreshKeyLaoding: state?.googleRefreshKey?.loading ?? false,
    },
  ]);

  const handleGetGoogleLocation = (message = "") => {
    dispatch(getGoogleLocation(data)).then((data) => {
      if (data?.payload?.code === 200) {
        !!message && showSuccessMsg(message);
        setIsLoading(false);
        setSelectedLocation({
          name: data?.payload?.dataObj?.location?.name ?? "Select Locations",
          id: data?.payload?.dataObj?.location?.id ?? "",
          category: data?.payload?.dataObj?.location?.category ?? "",
          type: data?.payload?.dataObj?.location?.type ?? "",
          can_add_menu: data?.payload?.dataObj?.location?.can_add_menu ?? "",
          can_modify_service_list:
            data?.payload?.dataObj?.location?.can_modify_service_list ?? "",
          address: data?.payload?.dataObj?.location?.address ?? "",
          region_code: data?.payload?.dataObj?.location?.region_code ?? "",
          language_code: data?.payload?.dataObj?.location?.language_code ?? "",
        });
      } else {
        setIsLoading(false);
        handleServerValidation(data);
        // showErrorMsg(data ?? "Something went wrong.");
      }
    });
  };

  const handleCheckExpireToken = () => {
    setIsLoading(true);
    dispatch(checkExpireToken(data)).then((res) => {
      if (res?.payload?.dataObj?.status === true) {
        handleGetGoogleLocation();
        // setIsLoading(false);
      } else if (
        res?.payload?.dataObj?.status === false ||
        res?.payload?.dataObj?.status == `refresh_key`
      ) {
        setTimeout(() => setIsLoading(false), [1000]);
      }
    });
  };

  const handleSaveAssociateLocation = () => {
    // setIsLoading(true);
    const formData = {
      ...data,
      type: selectedLocation?.type ?? "",
      can_add_menu: selectedLocation?.can_add_menu ?? "",
      can_modify_service_list: selectedLocation?.can_modify_service_list ?? "",
      region_code: selectedLocation?.region_code ?? "",
      language_code: selectedLocation?.language_code ?? "",
    };
    dispatch(getAssociateList(formData)).then((res) => {
      setButtonDisable(true);
      if (res?.payload?.code == 200) {
        const message =
          res?.payload?.dataObj?.message ??
          "GMB location associated succefully.";
        const locationData = {
          ...currentLocationObj,
          google_business_type: res?.payload?.dataObj?.google_business_type,
        };
        setCurrentLocation(locationData);
        handleGetGoogleLocation(message);
      } else {
        handleServerValidation(data);
      }
      dispatch(getReviewTemplate(data));
    });
  };

  const handleRefreshToken = () => {
    dispatch(googleRefreshKey(data)).then((data) => {
      if (data?.payload?.code === 200) {
        handleCheckExpireToken();
        showSuccessMsg(data?.payload?.dataObj?.message);
      } else handleServerValidation(data);
    });
  };

  const handleDisconnectGoogle = () => {
    setIsLoading(true);
    dispatch(googleDisconnect(data)).then((data) => {
      if (data?.payload?.code === 200) {
        handleCheckExpireToken();
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Disconnected Successfully"
        );
      }
    });
  };

  useEffect(() => {
    handleCheckExpireToken();
  }, [currentLocationId]);

  return (
    <div className="w-full py-4">
      <div className="w-full flex justify-between">
        <button
          type="button"
          className="btn-pink w-20"
          onClick={() => {
            const url = !!location?.state
              ? location?.state
              : "/user-settings?tab=integrations";
            navigate(url);
          }}
        >
          Back
        </button>

        <div>
          <SpinnerButton
            title={`Refresh Key @`}
            type={"button"}
            className={classNames({
              "btn-green": !isTokenExpired(checkExpireData?.key_expired_at)
                .expired,
              "btn-yellow  w-[148px]": isTokenExpired(
                checkExpireData?.key_expired_at
              ).expired,
            })}
            action={() => {
              handleRefreshToken();
            }}
            loading={googleRefreshKeyLaoding}
            isDisable={
              !checkExpireData?.status ||
              !isTokenExpired(checkExpireData?.key_expired_at).expired
            }
          />
        </div>
      </div>

      <div className="mt-2 flex flex-col xl:flex-row gap-4">
        <div
          className={`bg-white border-2 border-gray-400 2xl:w-[25%] xl:w-[40%] w-full flex flex-col items-center min-h-80`}
        >
          <div className="p-4 flex flex-col gap-y-6 items-center">
            <div className=" h-16 w-16">
              <img className="h-16 w-16" src={integration?.image} />
            </div>
            <p className="text-md font-semibold text-gray-700">{`Google`}</p>
            <div>
              <p className="text-md text-gray-700">
                {integration?.description}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white border-2 border-gray-400 2xl:w-[80%] xl:w-[75%] w-full flex flex-col justify-between">
          <div className="flex flex-col ">
            <div className="p-4">
              <p className="text-md font-semibold text-gray-700">
                {`Connect to your Google account`}
              </p>
              <div>
                <p className="text-md text-gray-700">
                  {integration?.instruction}
                </p>
              </div>
            </div>

            <div className="">
              <form
                className="w-full flex flex-col gap-20"
                action={`${API_URL}gmb/oauth?company_id=${currentCompanyId}&location_id=${currentLocationId}`}
                method="POST"
                id="form1"
                name="form1"
                onKeyDown={(e) => {
                  e.key == "Enter" && e.preventDefault();
                }}
              >
                <div className="w-full">
                  <div className="flex w-full px-4 mt-2 gap-2 ">
                    <div className="w-[40%] text-end">
                      <label
                        className="font-light leading-6 text-gray-900 px-4 "
                        htmlFor="authorization"
                      >
                        Authorization
                      </label>
                    </div>
                    <div className="w-full  ">
                      {checkExpireData?.status === false ? (
                        <button className=" btn btn-yellow">
                          Connect Google
                        </button>
                      ) : checkExpireData?.status === "refresh_key" ? (
                        <button className="btn btn-yellow">
                          Refresh Connection
                        </button>
                      ) : (
                        <button
                          type="button"
                          className=" btn btn-red"
                          onClick={() => {
                            handleDisconnectGoogle();
                          }}
                        >
                          Disconnect Google
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="flex w-full gap-2 px-4 mt-4">
                    <div className="w-[40%] text-end">
                      <label
                        className="font-light leading-6 text-gray-900 px-4"
                        htmlFor="status"
                      >
                        Status
                      </label>
                    </div>
                    <div
                      className={classNames(
                        "w-full font-semibold text-amber-600",
                        {
                          "text-green-600": checkExpireData?.status,
                          "text-orange-600": !checkExpireData?.status,
                        }
                      )}
                    >
                      <p>
                        {checkExpireData?.status
                          ? "Success"
                          : !checkExpireData?.status
                          ? "Not Connected"
                          : checkExpireData?.status === "refresh_key" &&
                            "Token Expired"}
                      </p>
                    </div>
                  </div>

                  {checkExpireData?.status === true && (
                    <div className="flex w-full gap-2 px-4 mt-5 ">
                      <div className="w-[40%] text-end ">
                        <label
                          className="font-light leading-6 text-gray-900 px-4 "
                          htmlFor="location"
                        >
                          Location
                        </label>
                      </div>
                      <div className="w-full">
                        <CustomDropDown
                          filterDropDown={locationListData?.data}
                          locationListData={locationListData}
                          selectedLocation={selectedLocation}
                          setSelectedLocation={setSelectedLocation}
                          setButtonDisable={setButtonDisable}
                          buttonDisable={buttonDisable}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          {checkExpireData?.status === true && (
            <div
              className={classNames(
                `bg-gray-200 w-full flex mt-4 items-center p-[11px]`,
                {
                  "justify-between": checkExpireData?.status === true,
                  "justify-end": checkExpireData?.status !== true,
                }
              )}
            >
              {" "}
              <p>
                Last Updated:
                {convertTimeStampToDateTime(checkExpireData?.key_refreshed_at)}
              </p>
              <div>
                <SpinnerButton
                  className={`btn-blue mr-2`}
                  title={`Associate Location`}
                  action={() => {
                    handleSaveAssociateLocation();
                  }}
                  type={"button"}
                  isDisable={buttonDisable}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {(associateLocationLoading || googleLocationLoading || isLoading) && (
        <GuestWifiLoader
          isModalOpen={
            associateLocationLoading || googleLocationLoading || isLoading
          }
          message={
            associateLocationLoading
              ? "Please wait a while, Associating selected location...."
              : "Please wait a while, Fetching Details..."
          }
        />
      )}
    </div>
  );
}
