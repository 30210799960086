import { CheckCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Bars2Icon } from "@heroicons/react/24/solid";
import serviceIcon from "assets/images/service-icon.png";
import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import { showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCategory } from "redux/gmbLocation/deleteCategorySlice";
import { setCategoryPrimary } from "redux/gmbLocation/setCategoryPrimarySlice";

// ItemTypes definition
const ItemTypes = {
  CARD: "card",
};

export default function Category({
  index = 0,
  dropCard = () => {},
  moveCard = () => {},
  categoryData = {},
  categoryId = 0,
  setCategoryId = () => {},
  setIsCategoryModalOpen = () => {},
  handleGetCategoryList = () => {},
  itemData = {},
  currentLocationId = 0,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemId = itemData?.id || 0;
  const ref = useRef(null);
  const handleRef = useRef(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [hoverOnCategory, setHoverOnCategory] = useState(0);
  const [makeCategoryPrimary, setMakeCategoryPrimary] = useState(false);

  const deleteCategoryLoading = useSelector(
    (state) => state?.deleteCategory?.loading ?? false
  );
  const setCategoryPrimaryloading = useSelector(
    (state) => state?.setCategoryPrimary?.loading ?? false
  );

  // Function for re-order list
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      dropCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ itemId, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(handleRef);
  drop(ref);
  preview(ref);
  //

  const handleDeleteCategory = () => {
    dispatch(deleteCategory({ id: categoryId })).then((data) => {
      if (data?.payload?.code == 200) {
        setIsConfirmationModalOpen(false);
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Category deleted successfully."
        );
        handleGetCategoryList();
      } else {
        setIsConfirmationModalOpen(false);
        handleServerValidation(data);
      }
    });
  };

  const handleEdit = (id) => {
    setCategoryId(id);
    setIsConfirmationModalOpen(true);
  };
  const handleDelete = (id) => {
    setCategoryId(id);
    setIsConfirmationModalOpen(true);
  };

  const handleSetCategoryPrimary = () => {
    const formData = {
      id: categoryId,
      location_id: currentLocationId,
    };
    dispatch(setCategoryPrimary(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        setIsConfirmationModalOpen(false);
        showSuccessMsg("Category set to primary successfully.");
        handleGetCategoryList();
      } else {
        setIsConfirmationModalOpen(false);
        handleServerValidation(data);
      }
    });
  };

  return (
    <div
      ref={ref}
      key={categoryData?.id}
      className={classNames("gap-2", {
        "opacity-0": isDragging,
      })}
    >
      <div className="flex items-center min-h-8 gap-2">
        <div
          className="w-full flex gap-2 items-center"
          data-handler-id={handlerId}
        >
          <div
            className="text-center relative w-40 h-52"
            onMouseEnter={() => setHoverOnCategory(categoryData?.id)}
            onMouseLeave={() => setHoverOnCategory(0)}
          >
            {/* {categoryData?.is_primary === "no" && (
              <button
                ref={handleRef}
                title="Drag Here"
                className="h-6 w-6 cursor-grab font-semibold absolute top-0 right-0 z-[99]"
              >
                <Bars2Icon strokeWidth={1.8} className="w-5 h-5 cursor-grab" />
              </button>
            )} */}
            <div
              title="Manage Service"
              className={classNames(
                "relative h-40 w-full shadow-md shadow-gray-300 border-2 border-gray-400 bg-gray-50 cursor-pointer flex justify-center items-center",
                { "brightness-75": hoverOnCategory === categoryData?.id }
              )}
              onClick={() => {
                setCategoryId(categoryData?.id);
                navigate(
                  `/location?tab=services&category=${categoryData?.id}`,
                  { state: "services" }
                );
              }}
            >
              <img
                src={serviceIcon}
                className="object-contain opacity-50 w-[50%] h-full m-auto top-50"
                alt="Menu Icon"
              />
            </div>
            {hoverOnCategory === categoryData?.id &&
              categoryData?.is_primary === "no" && (
                <div className="absolute bottom-14 z-99 w-full flex justify-center items-center gap-0.5">
                  <button
                    title="Make it Primary"
                    className="h-5 w-5 cursor-pointer"
                    onClick={() => {
                      setMakeCategoryPrimary(true),
                        handleEdit(categoryData?.id);
                    }}
                  >
                    <CheckCircleIcon />
                  </button>
                  <button
                    title="Delete"
                    className="h-5 w-5 cursor-pointer"
                    onClick={() => {
                      handleDelete(categoryData?.id);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              )}
            {categoryData?.is_primary === "yes" && (
              <div className="absolute bottom-[50px] !z-9 text-[13.5px] right-0.5 w-18 bg-[#fc4b28] px-1 py-0.5 text-white">
                Primary
              </div>
            )}
            <div className="">
              <p className="font-bold text-gray-700 break-words">
                {categoryData?.category_name ?? ""}
              </p>
              {categoryData?.selected_services_count > 0 && (
                <p className="text-xs font-semibold text-gray-700 break-words">
                  Selected Services:{" "}
                  {`${categoryData?.selected_services_count}/${categoryData?.services_count}`}
                </p>
              )}

              {categoryData?.gmb_uploaded_service_count > 0 && (
                <p className="text-xs font-semibold text-gray-700 break-words">
                  Uploaded Services:{" "}
                  {`${categoryData?.gmb_uploaded_service_count}/${categoryData?.services_count}`}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={() => {
            makeCategoryPrimary
              ? handleSetCategoryPrimary()
              : handleDeleteCategory();
          }}
          isLoading={deleteCategoryLoading || setCategoryPrimaryloading}
          message={
            makeCategoryPrimary
              ? "Do you want to set this category to primary ?"
              : "Do you want to delete this category ?"
          }
        />
      )}
    </div>
  );
}
