import { statesArray } from "module/data";
import React from "react";
import Select from "react-select";

const DropdownCommon = (props) => {
  const {
    values = {},
    setFieldValue = () => {},
    name = "",
    isDisabled = false,
    isReadOnly = false,
    setFieldTouched = () => {},
    options = [],
    className = "w-full",
    isClearable = false,
    placeholder = "Select",
  } = props;
  return (
    <Select
      name={name}
      value={options.filter(function (option) {
        return option.value === values;
      })}
      onChange={(e) => setFieldValue(name, e?.value)}
      onBlur={(e) => {
        setFieldTouched(name, true);
      }}
      className={className}
      options={options}
      styles={{
        control: (baseStyles, { isFocused, isSelected }) => ({
          ...baseStyles,
          borderColor: isFocused ? "#64656680" : "#abadb180",
          borderRadius: 0,
          pointerEvents: isDisabled || isReadOnly ? "none" : "all",
          cursor: isDisabled || isReadOnly ? "text" : "pointer",
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          backgroundColor: isSelected ? "#abadb180" : "#fff",
          color: "black",
          ":hover": {
            backgroundColor: "#abadb180",
          },
        }),
        input: (baseStyles, { isFocused, isSelected }) => ({
          ...baseStyles,
          borderColor: isFocused ? "#64656680" : "#abadb180",
          ":hover": {
            borderColor: "#64656680",
            border: "1px",
          },
        }),
      }}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

export default DropdownCommon;
