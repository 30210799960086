import classNames from "classnames";
import CommonProcessingModal from "components/common/CommonProcessingModal";
import SpinnerButton from "components/common/fields/SpinnerButton";
import ShowCustomersCountsModal from "components/contacts/customer/ShowCustomersCountsModal";
import ColumnMappingForm from "components/leadList/ColumnMappingForm";
import { postApi } from "module/api";
import {
  customersColumnArray,
  customersRequiredColumnArray,
} from "module/data";
import {
  getCurrentLocation,
  getUserDataByParam,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation, showError } from "module/validation";
import { useEffect, useState } from "react";
import {
  formatFileSize,
  lightenDarkenColor,
  readString,
  useCSVReader,
} from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const ImportCustomersForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { CSVReader } = useCSVReader();
  const dispatch = useDispatch();
  const [isSaveClicked, setSaveClicked] = useState(false);

  const GREY = "#CCC";
  const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
  const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
  const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
    DEFAULT_REMOVE_HOVER_COLOR,
    40
  );
  const GREY_DIM = "#686868";

  const styles = {
    zone: {
      alignItems: "center",
      border: `2px dashed`,
      borderColor: GREY,
      borderRadius: 20,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      padding: 20,
    },
    file: {
      background: "linear-gradient(to bottom, #EEE, #DDD)",
      borderRadius: 20,
      display: "flex",
      height: 120,
      width: 120,
      position: "relative",
      zIndex: 10,
      flexDirection: "column",
      justifyContent: "center",
    },
    info: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 10,
      paddingRight: 10,
    },
    size: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      marginBottom: "0.5em",
      justifyContent: "center",
      display: "flex",
    },
    name: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      fontSize: 12,
      marginBottom: "0.5em",
    },
    progressBar: {
      bottom: 14,
      position: "absolute",
      width: "100%",
      paddingLeft: 10,
      paddingRight: 10,
    },
    zoneHover: {
      borderColor: GREY_DIM,
    },
    default: {
      borderColor: GREY,
    },
    remove: {
      height: 23,
      position: "absolute",
      right: 6,
      top: 6,
      width: 23,
    },
  };

  const [csvData, setCsvData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [importedCustomersData, setImportedCustomersData] = useState({});
  const [zoneHover, setZoneHover] = useState(false);
  const [fileName, setFileName] = useState("");

  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const [{ importCustomerLoading = false }] = useSelector((state) => [
    {
      importCustomerLoading: state?.importCustomerData?.loading,
    },
  ]);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => {
      return (
        String(object[key]) !== "" &&
        String(value) !== "" &&
        String(object[key]) === String(value)
      );
    });
  };

  const handleCustomersDataModal = (data) => {
    setImportedCustomersData(data);
    setModalOpen(true);
  };

  const isValueBlank = (value) => {
    return value === null || value === undefined || value === "";
  };

  const checkIsObjectValid = () => {
    if (isValidObject(selectedData)) {
      for (const key in selectedData) {
        if (selectedData.hasOwnProperty(key)) {
          const value = selectedData[key];
          if (
            isValueBlank(value) &&
            customersRequiredColumnArray.includes(key)
          ) {
            showErrorMsg(`Please select ${key} field`);
            return false;
          }
        }
      }
      return true;
    }
  };

  const createArrayToObject = (headerArray, data) => {
    const dataArray = isValidArray(data) ? data : [];

    if (isValidArray(dataArray)) {
      const headerRow = headerArray;

      let rawData = [];
      if (isValidArray(dataArray)) {
        rawData = dataArray.map((row) => {
          const obj = {};
          headerRow.forEach((key, index) => {
            const finalKey = getKeyByValue(selectedData, index);
            if (finalKey !== "" && finalKey !== undefined) {
              obj[finalKey] = row[index];
            }
          });
          return obj;
        });
      }

      return rawData;
    }
    return [];
  };

  const handleReadString = () => {
    const dataArray = isValidArray(csvData) ? csvData : [];
    const headerArray = dataArray[0];

    const companyId = getUserDataByParam("COMPANY_ID");
    const { id: currentLocationId } = getCurrentLocation();

    if (isValidArray(dataArray.slice(1)) && checkIsObjectValid()) {
      setLoading(true);

      let customer_import_id = "";
      let csvString = "";
      let customerDataObj = {};
      let total_items_processed = 0;
      let customer_matched = 0;
      let invalid_items_count = 0;
      let valid_items_count = 0;
      let total_new_records_inserted = 0;
      let total_old_customers_updated = 0;
      let reasons = "";

      dataArray.slice(1).map((item) => {
        csvString += item.join("^") + "\n";
      });
      readString(csvString, {
        chunkSize: 200000,
        linebreak: "\n",
        skipEmptyLines: true,
        delimiter: "^",
        chunk: async (results, parser) => {
          if (isValidArray(results?.data)) {
            const rowData = createArrayToObject(headerArray, results?.data);
            parser.pause();

            const finalObj = {
              company_id: companyId,
              location_id: currentLocationId,
              data: rowData,
              customer_import_id: customer_import_id,
              total_records: isValidArray(dataArray)
                ? dataArray?.length - 2
                : 0,
              file_name: fileName,
            };

            await postApi(`api/bulk-customer-import`, finalObj)
              .then((data) => {
                if (data.status === 200) {
                  if (isValidObject(data?.data?.dataObj)) {
                    customerDataObj = data?.data?.dataObj ?? {};

                    customer_import_id =
                      data?.data?.dataObj?.customer_import_id ?? "";
                    total_items_processed =
                      data?.data?.dataObj?.total_items_processed ?? 0;
                    customer_matched +=
                      data?.data?.dataObj?.customer_matched ?? 0;
                    invalid_items_count +=
                      data?.data?.dataObj?.invalid_items_count ?? 0;
                    valid_items_count +=
                      data?.data?.dataObj?.valid_items_count ?? 0;
                    total_new_records_inserted +=
                      data?.data?.dataObj?.total_new_records_inserted ?? 0;
                    total_old_customers_updated +=
                      data?.data?.dataObj?.total_old_customers_updated ?? 0;
                    reasons = data?.data?.dataObj?.reasons ?? 0;
                  }
                  parser.resume();
                } else {
                  showErrorMsg("Something went wrong");
                }
              })
              .catch((error) => {
                if (error?.code === 422) {
                  const invalid = error?.dataObj?.dataObj?.invalid_count;
                  const valid = error?.dataObj?.dataObj?.valid_count;
                  const errorString = `Your data is invalid,  Invalid count: ${invalid}, valid count: ${valid}`;
                  showErrorMsg(errorString);
                } else {
                  handleServerValidation(error);
                }
                setLoading(false);
              });
          }
        },
        complete: (results) => {
          setLoading(false);
          if (results) {
            showSuccessMsg("customer imported successfully");
          }
          if (isValidObject(customerDataObj)) {
            const dataObj = {
              total_items_processed: total_items_processed ?? 0,
              customer_matched: customer_matched ?? 0,
              invalid_items_count: invalid_items_count ?? 0,
              valid_items_count: valid_items_count ?? 0,
              total_new_records_inserted: total_new_records_inserted ?? 0,
              total_old_customers_updated: total_old_customers_updated ?? 0,
              reasons: reasons ?? 0,
            };
            handleCustomersDataModal(dataObj);
          }
        },
      });
    }
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Contacts",
          href: `/contacts?tab=${location?.state}`,
          current: true,
        },
        {
          name: "Import Customers",
          href: "",
          current: false,
        },
      ])
    );
  }, []);

  return (
    // min-w-[40%] max-w-[60%]
    <div className="xl:flex gap-x-4 w-full border border-gray-200 p-3 bg-white">
      <div className="flex-1 w-full xl:w-2/5 p-1">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              htmlFor="location"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Upload File
            </label>
            <div className="mt-2">
              <CSVReader
                onUploadAccepted={(results, file) => {
                  if (isValidArray(results.data)) {
                    setCsvData(results.data);
                  } else {
                    if (isValidArray(results.error)) {
                      showErrorMsg("Something went wrong.");
                    }
                  }
                  setFileName(file?.name ?? "");
                  setZoneHover(false);
                }}
                config={{
                  delimiter: ",",
                }}
                onDragOver={(event) => {
                  event.preventDefault();
                  setZoneHover(true);
                }}
                onDragLeave={(event) => {
                  event.preventDefault();
                  setZoneHover(false);
                }}
              >
                {({
                  getRootProps,
                  acceptedFile,
                  ProgressBar,
                  getRemoveFileProps,
                  Remove,
                }) => (
                  <>
                    <div
                      {...getRootProps()}
                      style={Object.assign(
                        {},
                        styles.zone,
                        zoneHover && styles.zoneHover
                      )}
                    >
                      {acceptedFile ? (
                        <>
                          <div style={styles.file}>
                            <div style={styles.info}>
                              <span style={styles.size}>
                                {formatFileSize(acceptedFile.size)}
                              </span>
                              <span style={styles.name}>
                                {acceptedFile.name}
                              </span>
                            </div>
                            <div style={styles.progressBar}>
                              <ProgressBar />
                            </div>
                            <div
                              {...getRemoveFileProps()}
                              onClick={(event) => {
                                getRemoveFileProps().onClick(event);
                                setCsvData([]);
                                setSaveClicked(true);
                              }}
                              style={styles.remove}
                              onMouseOver={(event) => {
                                event.preventDefault();
                                setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                              }}
                              onMouseOut={(event) => {
                                event.preventDefault();
                                setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                              }}
                            >
                              <Remove color={removeHoverColor} />
                            </div>
                          </div>
                        </>
                      ) : (
                        "Drop CSV file here or click to upload"
                      )}
                    </div>
                    {isValidArray(csvData) && (
                      <span className="font-light text-sm ml-2">
                        Total{" "}
                        <span className="font-semibold">
                          {csvData?.length - 2}
                        </span>{" "}
                        Contacts.
                      </span>
                    )}
                    {isValidArray(csvData) && csvData?.length == 2 && (
                      <span className="text-red-500 ml-2">
                        File should not empty.
                      </span>
                    )}
                  </>
                )}
              </CSVReader>
            </div>
            {isSaveClicked && !isValidArray(csvData) && (
              <span className="text-red-500 ml-2">File is required.</span>
            )}
            {/* {false && showError("Please select location.")} */}
          </div>

          <div className="flex w-full px-3 mt-4 md:mb-0">
            {
              <SpinnerButton
                // className={classNames("btn-pink", {
                // "cursor-not-allowed": !isValidArray(csvData),
                // })}
                className="btn-pink"
                type="button"
                action={() =>
                  isValidObject(csvData)
                    ? handleReadString()
                    : setSaveClicked(true)
                }
                title="Import"
                loading={importCustomerLoading || loading}
                // isDisable={!isValidArray(csvData)}
              />
            }
            <button
              type="button"
              className="btn btn-gray ml-2"
              onClick={() =>
                location?.state === `/dashboard`
                  ? navigate(location?.state)
                  : navigate(`/contacts?tab=${location?.state}`)
              }
              disabled={importCustomerLoading || loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="block xl:hidden w-full h-[1px] bg-gray-300 my-4"></div>
      <div className="flex-2 w-full xl:w-3/5 bg-gray-100 shadow-lg">
        <ColumnMappingForm
          csvData={csvData}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          mappingColumnsArray={customersColumnArray}
        />
      </div>
      {isModalOpen && (
        <ShowCustomersCountsModal
          setModalOpen={setModalOpen}
          isModalOpen={isModalOpen}
          importedCustomersData={importedCustomersData}
        />
      )}
      {importCustomerLoading ||
        (loading && (
          <CommonProcessingModal
            processingModal={true}
            message="Please wait a while, Processing Customers..."
          />
        ))}
    </div>
  );
};

export default ImportCustomersForm;
