import { TrashIcon } from "@heroicons/react/24/solid";
import pendioIcon from "assets/images/pendioIcon.png";
import classNames from "classnames";
import {
  convertTimeStampToDateMoment,
  createFirstLetterCapital,
  getItemImage,
  isValidArray,
} from "module/util";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";

export default function CompletedPostList({
  postList = [],
  postListLoading = false,
  getCtaBtnLabel = () => {},
  setIdForDelete = () => {},
  setModalOpen = () => {},
}) {
  return postListLoading ? (
    <div
      role="status"
      className={`w-full space-y-4 rounded animate-pulse  mb-4`}
    >
      <div className="flex flex-wrap w-full gap-y-2 ">
        {[...Array(3)]?.map((_, menuIndex) => {
          return (
            <div key={menuIndex} className="h-48 w-1/3">
              <div className="h-full bg-gray-300 m-2"></div>
            </div>
          );
        })}
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <div className="flex items-center flex-wrap px-3 py-3 border border-gray-300 bg-white post-list overflow-y-auto max-h-[calc(100vh-230px)] shadow-inner">
      {isValidArray(postList) ? (
        postList?.map((post, index) => {
          let schedule = "N/A";
          if (post?.schedule[0]?.post_launch_date) {
            schedule = `${convertTimeStampToDateMoment(
              post?.schedule[0]?.post_launch_date ?? ""
            )} ${post?.schedule[0]?.post_launch_time ?? "00:00"} ${
              post?.schedule[0]?.post_launch_time_type ?? ""
            } `;
          }

          const ctaBtn = post?.call_to_actions[0]?.cta_btn_text
            ? getCtaBtnLabel(post?.call_to_actions[0]?.cta_btn_text)?.label ??
              ""
            : "";

          return (
            <div className="p-2 w-1/3" key={index}>
              <div
                key={index}
                className={classNames(
                  "bg-gray-200 min-h-[200px] border hover:border-blue-300 max-h-[300px] text-center group relative border-blue-300"
                )}
              >
                <div
                  className={classNames(
                    "py-2 flex justify-between group-hover:bg-blue-400 bg-blue-400"
                  )}
                >
                  <p className={classNames("font-bold text-white ml-4")}>
                    {`${createFirstLetterCapital(post?.campaign_type)} Post `}
                    {post?.campaign_type == "multi" &&
                      `(${post?.campaign_name})`}
                  </p>
                  {/* <div className="flex mr-4 cursor-pointer">
                    <TrashIcon
                      fill="#ffff"
                      width={20}
                      className="p-0 ml-1"
                      height={20}
                      title="Delete"
                      onClick={() => {
                        setIdForDelete(post?.id);
                        setModalOpen(true);
                      }}
                    />
                  </div> */}
                </div>
                <div className="flex gap-x-2">
                  <div className="h-[120px] max-w-[150px] w-full  p-2 flex justify-center">
                    <img
                      src={
                        isValidArray(post?.descriptions)
                          ? getItemImage(post?.descriptions[0]?.post_image) ??
                            pendioIcon
                          : pendioIcon
                      }
                      className={classNames(
                        "max-h-[150px] w-auto !object-fill"
                      )}
                      alt="Post Image"
                    />
                  </div>
                  <div className="space-y-2 w-full">
                    <div className="text-sm  my-2 !h-24 w-full  overflow-auto">
                      <ReactQuill
                        className="w-full   !border-none"
                        value={post?.descriptions[0]?.description}
                        theme="snow"
                        modules={{
                          toolbar: false,
                        }}
                        readOnly
                        placeholder="No Post Content"
                      />
                    </div>
                  </div>
                </div>

                <div className="py-2 bg-gray-200 border-t border-gray-300 absolute bottom-0 w-full flex justify-between items-center px-2">
                  <div className="text-start text-sm font-light">
                    <span className="font-normal text-gray-800">
                      {"Post Date"} :
                    </span>{" "}
                    <span>{!!schedule ? schedule : "N/A"}</span>
                  </div>
                  <div>
                    {!!ctaBtn && !!post?.call_to_actions[0]?.cta_btn_url}
                    <Link
                      className="text-sm text-blue-500"
                      to={post?.call_to_actions[0]?.cta_btn_url ?? ""}
                      target="_blank"
                    >
                      {ctaBtn}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="w-full h-40 flex justify-center items-center text-lg font-light ">
          No Post Available
        </div>
      )}
    </div>
  );
}
