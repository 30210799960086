import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";
import { showSuccessMsg } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const menuPricingToggle = createAsyncThunk(
  "gmb/update-gmb-menu-price-visibility",
  async (formData, { rejectWithValue }) => {
    const { company_id, location_id, is_price_show } = formData;
    try {
      const response = await postApi(
        `api/update-gmb-menu-price-visibility?company_id=${company_id}&location_id=${location_id}&is_price_show=${is_price_show}`,
        formData,
        true
      );
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const menuPricingToggleSlice = createSlice({
  name: "menuPricingToggle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(menuPricingToggle.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(menuPricingToggle.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(menuPricingToggle.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default menuPricingToggleSlice.reducer;
