import Account from "components/account/Account";
import AdminUserForm from "components/adminUser/AdminUserForm";
import AdminUserList from "components/adminUser/AdminUserList";
import CampaignTypes from "components/campaigns/CampaignTypes";
import CampaignsDetails from "components/campaigns/CampaignsDetails";
import CampaignsForm from "components/campaigns/CampaignsForm";
import CampaingsList from "components/campaigns/CampaingsList";
import NoMatch from "components/common/NoMatch";
import AddCompanyForm from "components/companies/AddCompanyForm";
import CompanyList from "components/companies/CompanyList";
import CompanySettings from "components/companySettings/CompanySettings";
import ContactsMain from "components/contacts/ContactsMain";
import CustomerFormMain from "components/contacts/customer/CustomerFormMain";
import ImportCustomersForm from "components/contacts/customer/ImportCustomersForm";
import Dashboard from "components/dashboard/Dashboard";
import GuestWifiPage from "components/front/GuestWifiPage";
import Login from "components/front/Login";
import Logout from "components/front/Logout";
import Register from "components/front/Register";
import ResetPassword from "components/front/ResetPassword";
import VerifyEmailResponsePage from "components/front/VerifyEmailResponsePage";
import VerifySecurityCode from "components/front/VerifySecurityCode";
import MainLayout from "components/layoutes/MainLayout";
import NoAuthLayout from "components/layoutes/NoAuthLayout";
import LeadList from "components/leadList/LeadList";
import LeadListForm from "components/leadList/LeadListForm";
import Location from "components/location/Location";
import LocationForm from "components/locations/LocationForm";
import LocationList from "components/locations/LocationList";
import MarketPlace from "components/marketPlace/MarketPlace";
import Marketing from "components/marketing/Marketing";
import RedemptionMain from "components/redemption/RedemptionMain";
import Reputation from "components/reputation/Reputation";
import ReputationList from "components/reputation/ReputationList";
import Settings from "components/settings/Settings";
import { Route, Routes } from "react-router-dom";

const RoutesFile = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getMessages());
  // }, []);

  //There is no such API from backend side for Validation message, that's why removed it

  return (
    <Routes>
      {/* No AUTH LAYOUT */}
      <Route element={<NoAuthLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-email" element={<VerifySecurityCode />} />
        <Route path="/verified" element={<VerifyEmailResponsePage />} />
        <Route
          path="/wifi/:locationId/:locationName/:companyId/:companyName"
          element={<GuestWifiPage />}
        />
        <Route
          path="/wifi/:locationId/:locationName/:companyId/:companyName/verify-otp/:guestId"
          element={<GuestWifiPage />}
        />
      </Route>

      {/* AUTH LAYOUT */}
      <Route element={<MainLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/locations/list" element={<LocationList />} />
        <Route path="/locations/add" element={<LocationForm />} />
        <Route path="/locations/edit/:id" element={<LocationForm />} />

        <Route path="/contacts" element={<ContactsMain />} />
        <Route path="/contacts/add" element={<CustomerFormMain />} />
        <Route path="/contacts/imports" element={<ImportCustomersForm />} />
        <Route path="/contacts/edit/:id" element={<CustomerFormMain />} />

        <Route path="/account" element={<Account />} />
        <Route path="/account/locations" element={<Account />} />
        <Route path="/account/locations/add" element={<Account />} />
        <Route path="/account/locations/edit/:id" element={<Account />} />
        <Route path="/account/users" element={<Account />} />
        <Route path="/account/users/add" element={<Account />} />
        <Route path="/account/users/edit/:id" element={<Account />} />

        <Route path="/admin-user" element={<AdminUserList />} />
        <Route path="/admin-user/add" element={<AdminUserForm />} />
        <Route path="/admin-user/edit/:id" element={<AdminUserForm />} />

        <Route path="/company/list" element={<CompanyList />} />
        <Route path="/company/add" element={<AddCompanyForm />} />
        <Route path="/company/edit/:id" element={<AddCompanyForm />} />

        <Route path="/campaigns/list" element={<CampaingsList />} />
        <Route path="/campaigns/details/:id" element={<CampaignsDetails />} />
        <Route path="/campaigns/type" element={<CampaignTypes />} />
        <Route path="/campaigns/add/:type" element={<CampaignsForm />} />
        <Route path="/campaigns/edit/:id/:type" element={<CampaignsForm />} />

        {/* <Route path="/posts/:page" element={<PostMain />} /> */}
        <Route path="/marketing" element={<Marketing />} />

        <Route path="/redemptions" element={<RedemptionMain />} />
        <Route path="/lead-list" element={<LeadList />} />
        <Route path="/lead-list/add" element={<LeadListForm />} />
        <Route path="/marketplace" element={<MarketPlace />} />
        <Route path="/reputation/add" element={<Reputation />} />
        <Route path="/reputation" element={<ReputationList />} />

        <Route path="/settings" element={<Settings />} />
        <Route path="/user-settings" element={<CompanySettings />} />
        <Route path="/location" element={<Location />} />

        <Route path="/logout" element={<Logout />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default RoutesFile;
