import starIcon from "assets/images/star.svg";
import ConfirmationModal from "components/common/ConfirmationModal";
import SpinnerButton from "components/common/fields/SpinnerButton";
import SwitchButton from "components/common/fields/SwitchButton";
import { getCurrentLocation, isValidArray, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deactivateActionLink } from "redux/gmbLocation/deactivateActionLinkSlice";
import { getOnlineOrderStatus } from "redux/gmbLocation/extras/getOnlineOrderStatusSlice";
import { updateOnlineOrderStatus } from "redux/gmbLocation/extras/updateOnlineOrderStatusSlice";
import { getActionLinkFromGoogle } from "redux/gmbLocation/getActionLinkFromGoogleSlice";
import { getActionLinkList } from "redux/gmbLocation/getActionLinkListSlice";
import { removeActionLink } from "redux/gmbLocation/removeActionLinkSlice";
import { setActionLinkPrimary } from "redux/gmbLocation/setActionLinkPrimarySlice";

export const Extras = () => {
  const dispatch = useDispatch();
  const { id: currentLocationId } = getCurrentLocation();
  const [linkId, setLinkId] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [onlineOrderStatus, setOnlineOrderStatus] = useState(false);
  const actionLinkList = useSelector(
    (state) => state?.getActionLinkList?.data?.dataObj ?? []
  );

  const onlineOrderStatusLoading = useSelector(
    (state) => state?.getOnlineOrderStatus?.loading ?? false
  );
  const actionLinkFromGoogleLoading = useSelector(
    (state) => state?.getActionLinkFromGoogle?.loading ?? false
  );
  const actionLinkListLoading = useSelector(
    (state) => state?.getActionLinkList?.loading ?? false
  );
  const updateStatusLoading = useSelector(
    (state) => state?.updateOnlineOrderStatus?.loading ?? false
  );

  const deleteListLoading = useSelector(
    (state) => state?.removeActionLink?.loading ?? false
  );
  const setActionLinkPrimaryLoading = useSelector(
    (state) => state?.setActionLinkPrimary?.loading ?? false
  );
  const deactivateActionLinkLoading = useSelector(
    (state) => state?.deactivateActionLink?.loading ?? false
  );

  const handleGetActionLink = () => {
    const formData = { location_id: currentLocationId };
    dispatch(getActionLinkList(formData));
  };

  const handleRemoveLink = () => {
    const formData = {
      place_action_link_id: linkId,
      location_id: currentLocationId,
    };
    dispatch(removeActionLink(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.message ?? "Action link deleted successfully."
        );
        handleGetActionLink();
      } else {
        handleServerValidation(data);
      }
      setIsConfirmationModalOpen(false);
    });
  };

  const handleSetActionLinkPrimary = (linkId, linkType) => {
    const formData = {
      place_action_link_id: linkId,
      location_id: currentLocationId,
      place_action_type: linkType,
    };
    dispatch(setActionLinkPrimary(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.message ?? "Action link deleted successfully."
        );
        handleGetActionLink();
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleDeactivateActionLink = (linkId) => {
    const formData = {
      place_action_link_id: linkId,
      location_id: currentLocationId,
    };
    dispatch(deactivateActionLink(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.message ?? "Action link deleted successfully."
        );
        handleGetActionLink();
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleGetActionLinkFromGoogle = () => {
    const formData = { location_id: currentLocationId };
    dispatch(getActionLinkFromGoogle(formData)).then((data) => {
      handleGetActionLink();
    });
  };

  const getOnlineOrdersStatus = () => {
    dispatch(getOnlineOrderStatus(currentLocationId)).then((data) => {
      if (data?.payload?.code == 200) {
        const isOn = data?.payload?.dataObj == "1" ? true : false;
        setOnlineOrderStatus(isOn);
        if (isOn) {
          handleGetActionLinkFromGoogle();
          // handleGetActionLink();
        }
      }
    });
  };

  useEffect(() => {
    getOnlineOrdersStatus();
  }, [currentLocationId]);

  const removeUnderscore = (str) => {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleUpdateStatus = (value) => {
    dispatch(updateOnlineOrderStatus({ currentLocationId, value })).then(
      (data) => {
        if (data?.payload.code == 200) {
          showSuccessMsg(
            data?.payload.dataObj?.message ??
              "Online order status updated successfully"
          );
        } else {
          handleServerValidation(data);
        }
      }
    );
  };

  const isAnyLoading = (...loadingStates) => {
    return loadingStates?.some((loading) => loading === true);
  };

  return (
    <div className="mt-4">
      <div className="bg-white w-1/2 p-4 border-gray-400 border-2">
        <div className="flex w-full  gap-4 items-center">
          <label
            className="font-semibold  text-gray-700  text-[15px] text-center "
            htmlFor="description"
          >
            Online Order LINKS
          </label>
          <div className="">
            <SwitchButton
              name="menu_visibility"
              value={onlineOrderStatus}
              isDisable={updateStatusLoading}
              handleToggleForCampaigns={(value) => {
                if (!updateStatusLoading) {
                  const visibility = value ? "yes" : "no";
                  handleUpdateStatus(visibility);
                  setOnlineOrderStatus(value);
                }
              }}
            />
          </div>
          <label
            className="font-light text-[15px]  text-gray-700 text-center "
            htmlFor="description"
          >
            Online Ordering is ACTIVE
          </label>
        </div>
      </div>
      {onlineOrderStatus && (
        <div className="flex  justify-between gap-2  mt-4">
          <div className="bg-white w-1/2  border-gray-400 border-2 p-4 overflow-auto">
            <div className="">
              <span className="font-bold text-gray-700 text-[15px]">
                Third Party Links (Active)
              </span>
            </div>
            <div className=" ">
              {isAnyLoading(
                onlineOrderStatusLoading,
                actionLinkFromGoogleLoading,
                actionLinkListLoading
              ) ? (
                <div
                  role="status"
                  className={`w-full flex flex-wrap gap-4  rounded animate-pulse`}
                >
                  {[...Array(3)]?.map((_, menuIndex) => {
                    return (
                      <div key={menuIndex} className="w-[30rem]">
                        <div className="h-40 bg-gray-300 mt-3"></div>
                      </div>
                    );
                  })}
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div className="w-full flex flex-wrap gap-4  mt-5">
                  {isValidArray(actionLinkList?.placeActionLinksActive) ? (
                    actionLinkList?.placeActionLinksActive?.map(
                      (link, linkIndex) => {
                        const isPrimary =
                          link?.is_primary == "yes" ? true : false;
                        return (
                          <div key={link?.id} className="w-full px-3 py-2 ">
                            <div className="w-full flex items-center gap-2 my-2">
                              {isPrimary && (
                                <img src={starIcon} className="w-5 h-5" />
                              )}
                              <a
                                className="text-blue-500 underline "
                                href={link?.uri}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link?.uri}
                              </a>
                            </div>
                            <div>
                              <span className="text-gray-700  text-sm">
                                Link Type :{" "}
                                {removeUnderscore(
                                  link?.place_action_type ?? ""
                                )}
                              </span>
                            </div>

                            <div className="w-full flex gap-4 mt-4">
                              <SpinnerButton
                                title={isPrimary ? "PRIMARY" : "MAKE PRIMARY"}
                                className="btn-green"
                                action={() => {
                                  setLinkId(`${link?.name}primary`);
                                  handleSetActionLinkPrimary(
                                    link?.name,
                                    link?.place_action_type
                                  );
                                }}
                                loading={
                                  linkId == `${link?.name}primary` &&
                                  setActionLinkPrimaryLoading
                                }
                                isDisable={isPrimary}
                              />
                              {/* <button className="btn-green" disabled>
                            ALLOWED
                          </button>
                          <button
                            type="button"
                            title="Delete Link"
                            className="btn btn-red"
                            onClick={() => {
                              setLinkId(link?.name);
                              setIsConfirmationModalOpen(true);
                            }}
                          >
                            DELETE
                          </button> */}

                              {/* <SpinnerButton
                                title={"BAN"}
                                className="btn btn-gray"
                                action={() => {
                                  setLinkId(`${link?.name}ban`);
                                  handleDeactivateActionLink(link?.name);
                                }}
                                loading={
                                  linkId == `${link?.name}ban` &&
                                  deactivateActionLinkLoading
                                }
                              /> */}
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="w-full h-28 flex justify-center text-[15px] items-center  text-gray-700 font-semibold">
                      No Links
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="bg-white w-1/2  border-gray-400 border-2 p-4 overflow-auto">
            <div className="">
              <span className="font-bold text-gray-700 text-[15px]">
                Third Party Links (Inactive)
              </span>
            </div>
            <div className=" ">
              {isAnyLoading(
                onlineOrderStatusLoading,
                actionLinkFromGoogleLoading,
                actionLinkListLoading
              ) ? (
                <div
                  role="status"
                  className={`w-full flex flex-wrap gap-4  rounded animate-pulse`}
                >
                  {[...Array(3)]?.map((_, menuIndex) => {
                    return (
                      <div key={menuIndex} className="w-full">
                        <div className="h-40 bg-gray-300 mt-3"></div>
                      </div>
                    );
                  })}
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div className="w-full flex flex-wrap gap-4  mt-5">
                  {isValidArray(actionLinkList?.placeActionLinksInactive) ? (
                    actionLinkList?.placeActionLinksInactive?.map(
                      (link, linkIndex) => {
                        return (
                          <div key={link?.id} className="w-full px-3 py-2 ">
                            <div className="w-full flex items-center gap-4 my-2">
                              <a
                                className="text-blue-500 underline "
                                href={link?.uri}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link?.uri}
                              </a>
                            </div>
                            <div>
                              <span className="text-gray-700  text-sm">
                                Link Type :{" "}
                                {removeUnderscore(
                                  link?.place_action_type ?? ""
                                )}
                              </span>
                            </div>

                            <div className="w-full flex gap-4 mt-4">
                              <SpinnerButton
                                title={"ALLOW"}
                                className="btn-green"
                                isDisable={true}
                                action={() => {
                                  // setLinkId(`${link?.name}primary`);
                                  // handleSetActionLinkPrimary(link?.name);
                                }}
                                loading={
                                  linkId == `${link?.name}primary` &&
                                  setActionLinkPrimaryLoading
                                }
                              />
                              {/* <button className="btn-green" disabled>
                            ALLOWED
                          </button>
                          <button
                            type="button"
                            title="Delete Link"
                            className="btn btn-red"
                            onClick={() => {
                              setLinkId(link?.name);
                              setIsConfirmationModalOpen(true);
                            }}
                          >
                            DELETE
                          </button> */}

                              {/* <SpinnerButton
                                title={"BAN"}
                                className="btn btn-gray"
                                action={() => {
                                  setLinkId(`${link?.name}ban`);
                                  handleDeactivateActionLink(link?.name);
                                }}
                                loading={
                                  linkId == `${link?.name}ban` &&
                                  deactivateActionLinkLoading
                                }
                              /> */}
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="w-full h-28 flex justify-center items-center text-[15px] text-gray-700 font-semibold">
                      No Links
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={() => {
            handleRemoveLink();
          }}
          isLoading={deleteListLoading}
          message={"You want to delete this link?"}
        />
      )}
    </div>
  );
};
