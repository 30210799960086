import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import CustomTable from "components/common/fields/CustomTable";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  checkParagraphWordsLimit,
  getCompanyStatus,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTag } from "redux/companySetting/addTagSlice";
import { deleteTag } from "redux/companySetting/deleteTagSlice";
import { getTagDetail } from "redux/companySetting/getTagDetailSlice";
import { getTagsList } from "redux/companySetting/getTagsListSlice";
import { updateTag } from "redux/companySetting/updateTagSlice";
import * as yup from "yup";

export default function Tags({ currentLocationId, currentCompanyId }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState({
    pageLoading: "",
    tableLoading: "",
  });

  const [linkedContacts, setLinkedContacts] = useState(0);
  const [isEdit, setIsEdit] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const isCompanyActive = ["active"].includes(getCompanyStatus());

  const tagsList = useSelector(
    (state) => state?.getTagsList?.data?.dataObj?.data
  );
  const tagsListLoading = useSelector(
    (state) => state?.getTagsList?.loading ?? false
  );
  const addTagloading = useSelector((state) => state?.addTag?.loading ?? false);
  const tagDetail = useSelector(
    (state) => state?.getTagDetail?.data?.dataObj ?? {}
  );
  const updateTagLoading = useSelector(
    (state) => state?.updateTag?.loading ?? false
  );
  const deleteTagLoading = useSelector(
    (state) => state?.deleteTag?.loading ?? false
  );

  const initialValues = {
    tag: !!isEdit ? tagDetail?.tag || "" : "",
    description: !!isEdit ? tagDetail?.description || "" : "",
    color: !!isEdit ? tagDetail?.hex || "" : "",
  };

  const columns = [
    {
      Header: "Name",
      accessor: "tag",
      Cell: ({ cell }) => {
        return (
          <div className={isEdit === cell.row.values?.id && `opacity-60`}>
            {cell?.row?.values?.tag ? cell?.row?.values?.tag : ""}
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ cell }) => {
        return (
          <div className={isEdit === cell.row.values?.id && `opacity-60`}>
            {cell?.row?.values?.description
              ? cell?.row?.values?.description
              : "No Details"}
          </div>
        );
      },
    },
    {
      Header: "Linked Contacts",
      accessor: "total_association_count",
      Cell: ({ cell }) => {
        return (
          <div className={isEdit === cell.row.values?.id && `opacity-60`}>
            {cell?.row?.values?.total_association_count
              ? cell?.row?.values?.total_association_count
              : "0"}
          </div>
        );
      },
    },
    ...(isCompanyActive
      ? [
          {
            Header: "Action",
            accessor: "id",
            Cell: ({ cell }) => (
              <div className="flex  items-center gap-x-[4px]">
                <button
                  className={`hover:text-gray-900 hover:underline 
                  ${isEdit === cell.row.values?.id && `disabled-btn`}
                  `}
                  onClick={() => {
                    handleGetTagDetail(cell.row.values?.id);
                  }}
                  disabled={isEdit === cell.row.values?.id}
                >
                  <PencilSquareIcon title={"Edit"} className="w-[1.3rem]" />
                </button>
                <button
                  className={`hover:text-gray-900 hover:underline 
                  ${isEdit === cell.row.values?.id && `disabled-btn`}
                  `}
                  onClick={() => {
                    setLinkedContacts(
                      cell?.row?.values?.total_association_count
                    );
                    setDeleteId(cell?.row?.values?.id);
                    setIsConfirmationModalOpen(true);
                  }}
                  disabled={isEdit === cell.row.values?.id}
                >
                  <TrashIcon title={"Delete"} className="w-[1.3rem]" />
                </button>
              </div>
            ),
          },
        ]
      : []),
  ];

  const handleGetTagDetail = (tagId) => {
    const formData = {
      tag: tagId,
    };
    dispatch(getTagDetail(formData)).then((data) => {
      if (data?.payload.code === 200) {
        setIsEdit(tagId);
      } else {
        showErrorMsg(data);
      }
    });
  };

  const handleSubmit = (value, { resetForm }) => {
    if (!value.tag.trim()) {
      showErrorMsg("Tag cannot be blank");
      return;
    }

    const formData = {
      company_id: currentCompanyId,
      location_id: currentLocationId,
      tag: value?.tag,
      description: value?.description,
      hex: value?.color,
      id: isEdit,
    };
    if (!!isEdit) {
      dispatch(updateTag(formData)).then((data) => {
        if (data?.payload.code === 200) {
          showSuccessMsg(data?.payload?.message ?? "Tag updated successfully.");
          setIsEdit("");
          handleTagList();
          Form;
        } else {
          handleServerValidation(data);
        }
      });
    } else {
      dispatch(addTag(formData)).then((data) => {
        if (data?.payload.code === 201) {
          showSuccessMsg(data?.payload?.message ?? "Tag Added Successfully.");
          resetForm();
          handleTagList();
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  const handleDeleteTag = () => {
    const isForcedAction = linkedContacts === 0 ? false : true;
    const formData = {
      tag: deleteId,
    };

    dispatch(deleteTag({ formData, isForcedAction })).then((data) => {
      setIsConfirmationModalOpen(false);
      if (data?.payload.code === 200) {
        handleTagList();
        showSuccessMsg(data?.payload?.message ?? "Tag Deleted Successfully.");
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleTagList = () => {
    const formData = {
      location_id: currentLocationId,
    };
    dispatch(getTagsList(formData));
  };

  useEffect(() => {
    handleTagList();
    setIsLoading({ pageLoading: true });
    setTimeout(() => {
      setIsLoading({ pageLoading: false });
    }, [900]);
  }, [currentLocationId]);

  return (
    <div
      className={classNames(
        "flex mt-4 border gap-x-4 border-gray-200 p-3 pb-10 bg-white w-full"
      )}
    >
      <div className="block 2xl:flex w-full  mt-3 gap-4">
        {isLoading?.pageLoading ? (
          <div
            role="status"
            className={`w-full 2xl:w-[62%] space-y-4 rounded animate-pulse`}
          >
            {[...Array(4)]?.map((_, arrayIndex) => {
              return (
                <div
                  key={arrayIndex}
                  className="w-full items-center px-3 mb-3 "
                >
                  <div
                    className={`${
                      arrayIndex === 2 ? `h-24` : `h-8`
                    }  bg-gray-300`}
                  ></div>
                </div>
              );
            })}

            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div
            className={`w-full tems-center  px-3 mb-3 ${
              !isCompanyActive && `pointer-events-none opacity-60`
            }`}
          >
            <div className="text-lg font-semibold text-gray-700 mb-4">Tag</div>
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, { resetForm });
              }}
              validationSchema={yup.object({
                tag: yup
                  .string()
                  .trim()
                  .matches(/\S+/, "Tag cannot be blank.")
                  .required("Tag is required."),
                description: yup
                  .string()
                  .trim()
                  .matches(/\S+/, "Tag cannot be blank.")
                  .test(
                    "wordCount",
                    "Description cannot exceed 300 words.",
                    (value) => {
                      // Check only if there's a value
                      if (value) {
                        return !checkParagraphWordsLimit(value, 300);
                      }
                      return true; // Pass the test if the field is empty
                    }
                  ),
              })}
              enableReinitialize={true}
            >
              {({
                errors,
                touched,
                validateField,
                validateForm,
                handleChange,
                handleBlur,
                resetForm,
                setFieldValue,
                setFieldTouched,
                setTouched,
                values,
                handleSubmit,
              }) => (
                <Form
                  className="w-full"
                  id="form1"
                  name="form1"
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    e.key == "Enter" && e.preventDefault();
                  }}
                >
                  <div className="flex flex-start w-full gap-1">
                    <label
                      className="font-light leading-6 text-gray-900 w-[30%] md:w-[15.3%] xl:w-[11.5%] 2xl:w-[22.5%]"
                      htmlFor="tag"
                    >
                      Tags :
                    </label>
                    <div className="w-full">
                      <Field
                        className={`input-field`}
                        id="tag"
                        name="tag"
                        type="text"
                        values={values?.tag}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Tag Name"
                        readOnly={!isCompanyActive}
                      ></Field>
                      <ErrorMessage
                        name={`tag`}
                        component="div"
                        className="field-error text-red-500"
                      />
                      {/* {touched?.tag && showError(errors?.tag)} */}
                    </div>
                  </div>

                  <div className="flex w-full mt-2 gap-2">
                    <label
                      className="font-light leading-6 text-gray-900 w-[30%] md:w-[15%] xl:w-[11.3%] 2xl:w-[22%]"
                      htmlFor="description"
                    >
                      Description :
                    </label>
                    <div className="w-full">
                      <Field
                        as="textarea"
                        rows={5}
                        className={`input-field`}
                        id="description"
                        name="description"
                        type="text"
                        values={values?.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Tag Description"
                        readOnly={!isCompanyActive}
                      />
                      <ErrorMessage
                        name={`description`}
                        component="div"
                        className="field-error text-red-500"
                      />
                    </div>
                  </div>

                  {isCompanyActive && (
                    <div className="mt-4 flex gap-2">
                      <SpinnerButton
                        className="btn-pink w-20"
                        title={`Save`}
                        action={() => {}}
                        type={"submit"}
                        loading={updateTagLoading || addTagloading}
                      />
                      {
                        <div>
                          <button
                            type="button"
                            className="btn btn-light-gray"
                            onClick={() => {
                              resetForm();
                              setIsEdit("");
                            }}
                            disabled={updateTagLoading || addTagloading}
                          >
                            Cancel
                          </button>
                        </div>
                      }
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        )}

        <div className="block 2xl:hidden w-full h-[1px] bg-gray-300 my-6"></div>

        {isLoading?.pageLoading ? (
          <div
            role="status"
            className={`w-full space-y-4 rounded animate-pulse`}
          >
            {[...Array(2)]?.map((_, arrayIndex) => {
              return (
                <div
                  key={arrayIndex}
                  className="w-full items-center px-3 mb-3 "
                >
                  <div
                    className={`${
                      arrayIndex === 1 ? `h-48` : `h-8`
                    }  bg-gray-300`}
                  ></div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="w-full">
            <span className="text-lg font-semibold text-gray-700">
              Tag List
            </span>
            <div className="custom-scrollbar mt-4 flex flex-col 2xl:max-h-[25rem]">
              <CustomTable
                data={tagsList ?? []}
                isLoading={tagsListLoading}
                columns={columns}
                defaultSortIndexColumn={""}
                colSpan={4}
              />
            </div>
          </div>
        )}
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={handleDeleteTag}
          isLoading={deleteTagLoading}
          message={
            linkedContacts !== 0
              ? `This tag is linked with ${linkedContacts} contact(s). Please confirm if you want to delete tag.`
              : "You want to delete this tag ?"
          }
        />
      )}
    </div>
  );
}
