import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi, postApi } from "module/api";
import { createQueryParams } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const companyList = createAsyncThunk(
  "company/list",
  async (data, { rejectWithValue }) => {
    const {
      companyStatus,
      search,
      sort,
      order,
      page,
      perPage,
      with_location = false,
    } = data;

    const queryString = createQueryParams({
      sort,
      search,
      order,
      companyStatus,
      page,
      perPage,
      with_location,
    });
    try {
      const response = await getApi(`api/companies/${queryString}`);
      // const response = search
      //   ? await postApi(`api/companies/${queryString}`, { search })
      //   : await getApi(`api/companies/${queryString}`);

      return response?.data?.dataObj;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const companyListSlice = createSlice({
  name: "companyList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(companyList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(companyList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default companyListSlice.reducer;
