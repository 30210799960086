import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import GetReview from "components/reputation/GetReview";
import ReputationSetting from "components/reputation/ReputationSetting";
import Review from "components/reputation/Review";
import {
  convertTimeStampToDateTime,
  getCurrentLocation,
  getUserDataByParam,
  isCompanyUser,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { getLastUpdatedReviewsTime } from "redux/reputation/getLastUpdatedReviewsTimeSlice";

const ReputationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentActiveTab = searchParams.get("tab");
  const subActiveTab = searchParams.get("sub_tab");
  const [lastUpdatedReview, setLastUpdatedReview] = useState("N/A");
  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();
  const currentCompanyId = getUserDataByParam("COMPANY_ID");

  const totalTabs = [
    "reviews",
    "get_reviews",
    "settings",
    "need_response",
    "all",
    "quarantine",
  ];
  const [currentTab, setTab] = useState(
    totalTabs.includes(currentActiveTab)
      ? currentActiveTab
      : isCompanyUser()
      ? "reviews"
      : "get_reviews"
  );
  const [subTab, setSubTab] = useState(
    totalTabs.includes(subActiveTab) ? subActiveTab : "need_response"
  );

  const reputationTabsArray = [
    ...(isCompanyUser()
      ? [
          {
            name: "Reviews",
            value: "reviews",
            href: "#",
            current: false,
          },
        ]
      : []),
    {
      name: "Get Reviews",
      value: "get_reviews",
      href: "#",
      current: false,
    },
    ...(isCompanyUser()
      ? [
          {
            name: "Settings",
            value: "settings",
            href: "#",
            current: false,
          },
        ]
      : []),
  ];

  const handleLastUpdatedReviewTime = () => {
    const formData = { location_id: currentLocationId };
    dispatch(getLastUpdatedReviewsTime(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const time = data?.payload?.dataObj?.data?.created_at;
        setLastUpdatedReview(convertTimeStampToDateTime(time) ?? "N/A");
      }
    });
  };

  const breadCrumbTitle = {
    reviews: "Reviews",
    get_reviews: "Get Reviews",
    settings: "Settings",
  };

  useEffect(() => {
    if (!isCompanyUser()) {
      dispatch(
        setBreadCrumb([
          {
            name: "Reputation",
            href: "",
            current: false,
          },
          {
            name: breadCrumbTitle["get_reviews"],
            href: "",
            current: false,
          },
        ])
      );
      navigate("/reputation?tab=get_reviews");
      setTab("get_reviews");
    } else {
      dispatch(
        setBreadCrumb([
          {
            name: "Reputation",
            href: "",
            current: false,
          },
          {
            name: breadCrumbTitle[currentTab],
            href: "",
            current: false,
          },
        ])
      );
    }
  }, [currentTab]);

  return (
    <div>
      <ShowPageTitle title="reputation" />

      <div className="flex border-b border-collapse border-gray-300">
        <div className="flex justify-between items-center  w-full">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {reputationTabsArray?.map((tab) => (
              <span
                key={tab.name}
                onClick={() => {
                  setTab(tab.value);
                  navigate(`/reputation?tab=${tab.value}`);
                }}
                className={classNames(
                  " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                  {
                    "border-pink-600 hover:border-pink-600 text-gray-800":
                      tab.value === currentTab,
                    "border-transparent": tab.value !== currentTab,
                  }
                )}
                aria-current={tab.value === currentTab ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
          <div className="flex flex-wrap">
            <span className="font-semibold text-sm text-gray-700">
              Last Updated Reviews :
            </span>{" "}
            <span className="font-light text-sm ml-1">{lastUpdatedReview}</span>
          </div>
        </div>
      </div>
      {currentTab === "reviews" && isCompanyUser() && (
        <Review
          currentTab={currentTab}
          setTab={setTab}
          subTab={subTab}
          setSubTab={setSubTab}
          handleLastUpdatedReviewTime={handleLastUpdatedReviewTime}
        />
      )}
      {currentTab === "settings" && isCompanyUser() && <ReputationSetting />}

      {currentTab === "get_reviews" && <GetReview />}
    </div>
  );
};

export default ReputationList;
