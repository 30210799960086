import datePicker from "assets/images/datePicker.svg";
import searchIconWhite from "assets/images/searchIconWhite.svg";
import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, Form, Formik } from "formik";
import {
  changeDateFormat,
  getCompanyStatus,
  getCurrentLocation,
  getDateFormatLocal,
  getUserDataByParam,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import {
  handleServerValidation,
  redemptionForm,
  showError,
} from "module/validation";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsByTrackId } from "redux/redemption/getDetailsByTrackIdSlice";
import { redeemLead } from "redux/redemption/redeemLeadSlice";
import * as yup from "yup";

const RedemptionSub = () => {
  const dispatch = useDispatch();
  const [scanCode, setScanCode] = useState("");
  const [isShowRedeemBtn, setShowRedeemBtn] = useState(false);
  const amountFieldRef = useRef();

  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const defaultDateFormat = getDateFormatLocal();

  const [
    {
      redemptionData = {},
      redeemLeadLoading = false,
      searchLeadLoading = false,
    },
  ] = useSelector((state) => [
    {
      redemptionData: state?.getDetailsByTrackId?.data?.dataObj ?? {},
      redeemLeadLoading: state?.redeemLead?.loading ?? false,
      searchLeadLoading: state?.getDetailsByTrackId?.loading ?? false,
    },
  ]);

  const campaignDetails = redemptionData?.campaign ?? {};
  const isCustomerDataExist = isValidObject(redemptionData?.customer)
    ? redemptionData?.customer
    : {};
  const leadData = isValidObject(redemptionData?.lead)
    ? redemptionData?.lead
    : isValidObject(redemptionData?.prospect)
    ? redemptionData?.prospect
    : isCustomerDataExist;
  const offersData = redemptionData?.offer ?? {};
  const isFieldsDisabled = campaignDetails?.type === "print_ad" ? false : true;

  const initialValues = {
    firstName: leadData ? leadData?.first_name : "",
    lastName: leadData ? leadData?.last_name : "",
    address1: leadData ? leadData?.address1 : "",
    address2: leadData ? leadData?.address2 : "",
    city: leadData ? leadData?.city : "",
    state: leadData ? leadData?.state : "",
    zip: leadData ? leadData?.zip : "",
  };

  const handleSearch = (values) => {
    setScanCode(values?.scan_code);
    const company_id = getUserDataByParam("COMPANY_ID");
    const { id } = getCurrentLocation();
    const formData = {
      scanCode: !!values?.scan_code ? values?.scan_code : "",
      company_id: company_id,
      location_id: id,
    };
    dispatch(getDetailsByTrackId(formData)).then((data) => {
      if (isValidObject(data?.payload) && data?.payload?.code === 200) {
        if (!!data?.payload?.dataObj) {
          setShowRedeemBtn(true);
        } else {
          showErrorMsg("Record Not Found");
        }
      } else {
        if (data?.payload?.code === 400) {
          showErrorMsg(data?.payload?.dataObj?.message ?? "");
        } else {
          showErrorMsg("Record Not Found");
        }
      }
    });
  };

  const handleRedeem = (values) => {
    const formData = {
      redemption_date: values?.date,
      redemption_value: values?.amount,
      tracking_id: values?.scan_code,
      offer_id: offersData?.id ?? 0,
    };
    dispatch(redeemLead(formData)).then((data) => {
      setShowRedeemBtn(false);
      if (data?.payload?.code === 200) {
        setScanCode("");
        showSuccessMsg(
          data?.payload?.dataObj?.message ?? "Code redeemed successfully"
        );
      } else {
        handleServerValidation(data);
      }
    });
  };

  useEffect(() => {
    if (isShowRedeemBtn) {
      amountFieldRef?.current?.focus();
    }
  }, [isShowRedeemBtn]);

  return (
    <>
      <ShowPageTitle title="redemptions" />

      <div
        className={classNames(
          "flex flex-col xl:flex-row justify-between min-h-[70%] border gap-x-4 border-gray-200 p-3 bg-white",
          {
            "pointer-events-none opacity-70": !isCompanyActive,
          }
        )}
      >
        <Formik
          initialValues={{
            scan_code: !!scanCode ? scanCode : "",
            amount: "",
            date: new Date(),
          }}
          validationSchema={yup.object({
            scan_code: isShowRedeemBtn
              ? yup.string()
              : yup
                  .string()
                  .trim()
                  .matches(/\S+/, "Code can't be blank.")
                  .required("Scan code is required."),
            amount: isShowRedeemBtn
              ? yup
                  .string()
                  .required("Amount is required.")
                  .test(
                    "is-greater-than-zero",
                    "Amount must be greater than 0.",
                    (value) => parseFloat(value) > 0
                  )
              : yup.string(),
            date: isShowRedeemBtn
              ? yup.string().required("Date is required.")
              : yup.string(),
          })}
          onSubmit={isShowRedeemBtn ? handleRedeem : handleSearch}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {({
            errors,
            touched,
            validateField,
            validateForm,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              className="w-full xl:w-1/2 flex-1 mt-3"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                e.key == "Enter" && e.preventDefault();
              }}
            >
              <div className="flex-wrap -mx-3 mb-3">
                <div className="flex w-full items-center px-3 mb-3">
                  <label
                    htmlFor="scan_code"
                    className="w-[30%] xl:w-[25%] flex justify-center text-md font-light leading-6 text-gray-900"
                  >
                    Scan Code:
                  </label>
                  <div className="ml-2 w-full">
                    <Field
                      type="text"
                      name="scan_code"
                      id="scan_code"
                      onPaste={(e) => {
                        const values = {
                          scan_code: e?.clipboardData?.getData("text") ?? "",
                        };
                        handleSearch(values);
                      }}
                      value={values?.scan_code}
                      placeholder="Enter Scan Code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classNames("input-field", {
                        "opacity-70 cursor-default": isShowRedeemBtn,
                      })}
                      readOnly={isShowRedeemBtn || !isCompanyActive}
                    />
                    {touched?.scan_code && showError(errors?.scan_code)}
                  </div>
                </div>
                {isShowRedeemBtn && (
                  <>
                    <div
                      className={classNames(
                        "flex w-full items-center  px-3 mb-3",
                        {
                          "opacity-60": !isShowRedeemBtn,
                        }
                      )}
                    >
                      <label
                        htmlFor="amount"
                        className="w-[30%] xl:w-[25%] flex justify-center text-md font-light leading-6 text-gray-900"
                      >
                        Amount :
                      </label>
                      <div className="ml-2 w-full">
                        <Field
                          ref={amountFieldRef}
                          type="number"
                          name="amount"
                          id="amount"
                          disabled={!isShowRedeemBtn}
                          value={
                            /^\d*\.?\d*$/.test(values?.amount)
                              ? values?.amount
                              : ""
                          }
                          placeholder="Enter Amount"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="input-field"
                        />
                        {touched?.amount && showError(errors?.amount)}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "flex w-full items-center  px-3 mb-3",
                        {
                          "opacity-60": !isShowRedeemBtn,
                        }
                      )}
                    >
                      <label
                        htmlFor="name"
                        className="w-[30%] xl:w-[25%] flex justify-center text-md font-light leading-6 text-gray-900"
                      >
                        Date :
                      </label>
                      <div className="relative ml-2 w-full">
                        <ReactDatePicker
                          name="date"
                          className="input-field"
                          selected={values?.date}
                          dateFormat={defaultDateFormat}
                          onChange={(date) => {
                            setFieldValue("date", date);
                          }}
                          placeholderText="Select Date"
                          maxDate={new Date()}
                          defaultValue={new Date()}
                          onBlur={handleBlur}
                        />
                        <img
                          src={datePicker}
                          className={`pointer-events-none w-6 h-6 absolute ${
                            touched?.date ? `top-[30%]` : `top-1/2`
                          } transform -translate-y-1/2 right-3`}
                          alt="Date picker"
                        />
                        {touched?.date && showError(errors?.date)}
                      </div>
                    </div>
                  </>
                )}
                <div className="w-full flex items-center px-3 mb-3">
                  <label
                    htmlFor="scan_code"
                    className="w-[30%] xl:w-[25%] flex justify-center text-md font-light leading-6 text-gray-900"
                  ></label>
                  {isCompanyActive &&
                    (isShowRedeemBtn ? (
                      <div className="w-full flex justify-center">
                        <SpinnerButton
                          className="bg-pink-600 w-full max-w-[65%] px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                          title={"Redeem"}
                          action={() => {}}
                          type={"submit"}
                          loading={redeemLeadLoading}
                        />

                        <button
                          className="btn btn-gray ml-2"
                          onClick={() => {
                            setShowRedeemBtn(false);
                          }}
                        >
                          Back
                        </button>
                      </div>
                    ) : (
                      <div className="w-full flex justify-center mt-3">
                        <SpinnerButton
                          className="max-w-[65%] w-full font-medium text-md py-2 px-3 bg-pink-600 text-white shadow-sm shadow-pink-300 hover:bg-pink-500"
                          title={
                            <div className="flex gap-2 items-center justify-center">
                              Search
                              <img
                                src={searchIconWhite}
                                alt="scan"
                                className="w-6 h-6"
                              />
                            </div>
                          }
                          action={() => {}}
                          type={"submit"}
                          loading={searchLeadLoading}
                        />
                      </div>
                    ))}
                </div>
              </div>
              {/* </div> */}
            </Form>
          )}
        </Formik>
        {isShowRedeemBtn && (
          <div className="block xl:hidden w-full h-[1px]bg-gray-300 my-6"></div>
        )}

        <div
          className={classNames("w-full xl:w-1/2 flex-2 mt-3", {
            "opacity-60": !isShowRedeemBtn,
          })}
        >
          {isShowRedeemBtn && (
            <>
              <div className="border border-gray-300 p-2 bg-gray-100">
                <p>
                  <span className="font-semibold text-gray-800">
                    Campaign :
                  </span>{" "}
                  {campaignDetails?.name ?? "--"}
                </p>
                <p>
                  <span className="font-semibold text-gray-800">Offers :</span>{" "}
                  {isValidObject(offersData) ? offersData?.offer_name : ""}
                </p>
                {campaignDetails?.type !== "direct_mail" && (
                  <p>
                    <span className="font-semibold text-gray-800">
                      Medium :
                    </span>{" "}
                    {campaignDetails?.source ?? "--"}
                  </p>
                )}
                {/* <p>
                  <span className="font-semibold">Minimum Spend :</span> {"0"}
                </p> */}
                <p>
                  <span className="font-semibold text-gray-800">Sent :</span>{" "}
                  {campaignDetails?.start_date
                    ? changeDateFormat(campaignDetails?.start_date)
                    : "--"}
                </p>
              </div>
              {isFieldsDisabled && (
                <div className="border p-3 mt-3 shadow-sm">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={redemptionForm}
                    onSubmit={() => {}}
                    enableReinitialize={true}
                    // validateOnBlur={false}
                  >
                    {({
                      errors,
                      touched,
                      validateField,
                      validateForm,
                      handleChange,
                      setFieldValue,
                      values,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="w-full min-w-xl bg-white">
                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3">
                              <label
                                htmlFor="firstName"
                                className="input-field-label"
                              >
                                First Name
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="firstName"
                                  id="firstName"
                                  disabled={isFieldsDisabled}
                                  value={values?.firstName ?? ""}
                                  onChange={handleChange}
                                  className="input-field"
                                />
                                {touched?.firstName &&
                                  showError(errors?.firstName)}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3">
                              <label
                                htmlFor="lastName"
                                className="input-field-label"
                              >
                                Last Name
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  value={values?.lastName ?? ""}
                                  onChange={handleChange}
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.lastName &&
                                  showError(errors?.lastName)}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3">
                              <label
                                htmlFor="address1"
                                className="input-field-label"
                              >
                                Address
                              </label>
                              <div className="mt-2">
                                <input
                                  id="address1"
                                  name="address1"
                                  type="text"
                                  value={values?.address1 ?? ""}
                                  onChange={handleChange}
                                  autoComplete="address1"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.address1 &&
                                  showError(errors?.address1)}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full px-3">
                              <label
                                htmlFor="address2"
                                className="input-field-label"
                              >
                                Address2
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="address2"
                                  id="address2"
                                  value={values?.address2 ?? ""}
                                  onChange={handleChange}
                                  autoComplete="address2"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.address2 &&
                                  showError(errors?.address2)}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
                              <label
                                htmlFor="city"
                                className="input-field-label"
                              >
                                City
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="city"
                                  id="city"
                                  value={values?.city ?? ""}
                                  onChange={handleChange}
                                  autoComplete="City"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.city && showError(errors?.city)}
                              </div>
                            </div>
                            <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
                              <label
                                htmlFor="state"
                                className="input-field-label"
                              >
                                State
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="state"
                                  id="state"
                                  value={values?.state ?? ""}
                                  onChange={handleChange}
                                  autoComplete="address-level1"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.state && showError(errors?.state)}
                              </div>
                            </div>
                            <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
                              <label
                                htmlFor="zip"
                                className="input-field-label"
                              >
                                ZIP / Postal code
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="zip"
                                  id="zip"
                                  value={values?.zip ?? ""}
                                  onChange={handleChange}
                                  autoComplete="zip"
                                  disabled={isFieldsDisabled}
                                  className="input-field"
                                />
                                {touched?.zip && showError(errors?.zip)}
                              </div>
                            </div>
                          </div>
                          {campaignDetails?.type === "print_ad" && (
                            <div className="flex justify-start mt-4">
                              <SpinnerButton
                                className="btn-pink"
                                title={"Save"}
                                action={() => {}}
                                type={"submit"}
                                loading={false}
                              />
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const Redemption = () => {
  return <RedemptionSub />;
};

export default Redemption;
