import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getServiceCalenderPost = createAsyncThunk(
  "post/service-calender-post-list",
  async (data, { rejectWithValue }) => {
    // const { location_id } = data;
    try {
      const response = await postApi(`api/service-post-calender`, data);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getServiceCalenderPostSlice = createSlice({
  name: "getServiceCalenderPost",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceCalenderPost.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getServiceCalenderPost.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getServiceCalenderPost.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getServiceCalenderPostSlice.reducer;
