import { combineReducers, configureStore } from "@reduxjs/toolkit";
import addCompanyUserSlice from "redux/account/addCompanyUserSlice";
import getCompanyAccountDetailSlice from "redux/account/getCompanyAccountDetailSlice";
import getCompanyUserListSlice from "redux/account/getCompanyUserListSlice";
import updateCompanyAccountSlice from "redux/account/updateCompanyAccountSlice";
import updateCompanyUserSlice from "redux/account/updateCompanyUserSlice";
import addAdminUserSlice from "redux/adminUser/addAdminUserSlice";
import changeAdminUserStatusSlice from "redux/adminUser/changeAdminUserStatusSlice";
import getAdminUserDetailSlice from "redux/adminUser/getAdminUserDetailSlice";
import getAdminUserListSlice from "redux/adminUser/getAdminUserListSlice";
import updateAdminUserSlice from "redux/adminUser/updateAdminUserSlice";
import addCampaignSlice from "redux/campaign/addCampaignSlice";
import campaignEnquirySlice from "redux/campaign/campaignEnquirySlice";
import changeCampaignStatusSlice from "redux/campaign/changeCampaignStatusSlice";
import getCampaignDetailSlice from "redux/campaign/getCampaignDetailSlice";
import getCampaignListSlice from "redux/campaign/getCampaignListSlice";
import getCampaignReportSlice from "redux/campaign/getCampaignReportSlice";
import getDailyRedemptionSlice from "redux/campaign/getDailyRedemptionSlice";
import getSourceListSlice from "redux/campaign/getSourceListSlice";
import removeCustomerFromLeadSlice from "redux/campaign/removeCustomerFromLeadSlice";
import updateCampaignDetailSlice from "redux/campaign/updateCampaignDetailSlice";
import getLocationByUserSlice from "redux/common/getLocationByUserSlice";
import getMessagesSlice from "redux/common/getMessagesSlice";
import getProcessPercentageSlice from "redux/common/getProcessPercentageSlice";
import selectedLocationSlice from "redux/common/selectedLocationSlice";
import setBreadCrumbsSlice from "redux/common/setBreadCrumbsSlice";
import addCompanySlice from "redux/company/addCompanySlice";
import changeCompanyStatusSlice from "redux/company/changeCompanyStatusSlice";
import companyListSlice from "redux/company/companyListSlice";
import getCompanyDetailSlice from "redux/company/getCompanyDetailSlice";
import switchToCompanySlice from "redux/company/switchToCompanySlice";
import toggleCampaignsSlice from "redux/company/toggleCampaignsSlice";
import updateCompanyDetailSlice from "redux/company/updateCompanyDetailSlice";
import companySettingSlice from "redux/companySetting/companySettingSlice";
import getSquareLoginDetailSlice from "redux/companySetting/getSquareLoginDetailSlice";
import guestWifiToggleSlice from "redux/companySetting/guestWifiToggleSlice";
import saveSquareImportSlice from "redux/companySetting/saveSquareImportSlice";
import getAllContactsSlice from "redux/contacts/getAllContactsSlice";
import addCustomerSlice from "redux/customer/addCustomerSlice";
import addCustomerToLocationSlice from "redux/customer/addCustomerToLocationSlice";
import changeCustomerStatusSlice from "redux/customer/changeCustomerStatusSlice";
import customerListSlice from "redux/customer/customerListSlice";
import deleteImportedCustomersSlice from "redux/customer/deleteImportedCustomersSlice";
import exportCustomersSlice from "redux/customer/exportCustomersSlice";
import getCustomerDetailSlice from "redux/customer/getCustomerDetailSlice";
import getCustomerReportSlice from "redux/customer/getCustomerReportSlice";
import getOrdersListSlice from "redux/customer/getOrdersListSlice";
import getVisitsListSlice from "redux/customer/getVisitsListSlice";
import importCustomerDataSlice from "redux/customer/importCustomerDataSlice";
import importedCustomersListSlice from "redux/customer/importedCustomersListSlice";
import updateCustomerSlice from "redux/customer/updateCustomerSlice";
import dashboardSlice from "redux/dashboard/dashboardSlice";
import getCustomHeatmapDataSlice from "redux/dashboard/getCustomHeatmapDataSlice";
import addWifiDataSlice from "redux/front/addWifiDataSlice";
import getDetailByLocationSlice from "redux/front/getDetailByLocationSlice";
import loginSlice from "redux/front/loginSlice";
import logoutSlice from "redux/front/logoutSlice";
import registerSlice from "redux/front/registerSlice";
import resendVerificationCodeSlice from "redux/front/resendVerificationCodeSlice";
import resetPasswordSlice from "redux/front/resetPasswordSlice";
import setNewPasswordSlice from "redux/front/setNewPasswordSlice";
import verifyEmailSlice from "redux/front/verifyEmailSlice";
import verifyOtpForWIfiSlice from "redux/front/verifyOtpForWIfiSlice";
import ImportBulkLeadSlice from "redux/leadList/ImportBulkLeadSlice";
import deleteProspectSlice from "redux/leadList/deleteProspectSlice";
import getLeadListsSlice from "redux/leadList/getLeadListsSlice";
import getLeadSourcesSlice from "redux/leadList/getLeadSourcesSlice";
import getLocationsByCompanySlice from "redux/leadList/getLocationsByCompanySlice";
import getProspectsListSlice from "redux/leadList/getProspectsListSlice";
import leadListStatusChangeSlice from "redux/leadList/leadListStatusChangeSlice";
import addLocationSlice from "redux/location/addLocationSlice";
import changeLocationStatusSlice from "redux/location/changeLocationStatusSlice";
import changeLocationUserStatusSlice from "redux/location/changeLocationUserStatusSlice";
import getLocationDetailSlice from "redux/location/getLocationDetailSlice";
import getLocationUserDetailSlice from "redux/location/getLocationUserDetailSlice";
import getLocationUserListSlice from "redux/location/getLocationUserListSlice";

import addTagSlice from "redux/companySetting/addTagSlice";
import associateSquareLocationSlice from "redux/companySetting/associateSquareLocationSlice";
import deleteTagSlice from "redux/companySetting/deleteTagSlice";
import getGooglePostSlice from "redux/companySetting/getGooglePostSlice";
import getSquareLocationsSlice from "redux/companySetting/getSquareLocationsSlice";
import getTagDetailSlice from "redux/companySetting/getTagDetailSlice";
import getTagsListSlice from "redux/companySetting/getTagsListSlice";
import guestWifiOtpToggleSlice from "redux/companySetting/guestWifiOtpToggleSlice";
import refreshSquareTokenSlice from "redux/companySetting/refreshSquareTokenSlice";
import revokeSquareTokenSlice from "redux/companySetting/revokeSquareTokenSlice";
import updateSessionTimeoutSlice from "redux/companySetting/updateSessionTimeoutSlice";
import updateTagSlice from "redux/companySetting/updateTagSlice";
import deleteContactsSlice from "redux/contacts/deleteContactsSlice";
import exportContactsSlice from "redux/contacts/exportContactsSlice";
import addTagToContactSlice from "redux/customer/addTagToContactSlice";
import getContactDetailSlice from "redux/customer/getContactDetailSlice";
import getProspectReportSlice from "redux/customer/getProspectReportSlice";
import updateContactDetailSlice from "redux/customer/updateContactDetailSlice";
import SelectServicePostSlice from "redux/gmbLocation/SelectServicePostSlice";
import addCategorySlice from "redux/gmbLocation/addCategorySlice";
import addMenuItemSlice from "redux/gmbLocation/addMenuItemSlice";
import addMenuSlice from "redux/gmbLocation/addMenuSlice";
import addSectionSlice from "redux/gmbLocation/addSectionSlice";
import addServiceSlice from "redux/gmbLocation/addServiceSlice";
import categoryPostToGoogleSlice from "redux/gmbLocation/categoryPostToGoogleSlice";
import deactivateActionLinkSlice from "redux/gmbLocation/deactivateActionLinkSlice";
import deleteCategorySlice from "redux/gmbLocation/deleteCategorySlice";
import deleteMenuItemSlice from "redux/gmbLocation/deleteMenuItemSlice";
import deleteMenuSlice from "redux/gmbLocation/deleteMenuSlice";
import deleteSectionSlice from "redux/gmbLocation/deleteSectionSlice";
import deleteServiceSlice from "redux/gmbLocation/deleteServiceSlice";
import getOnlineOrderStatusSlice from "redux/gmbLocation/extras/getOnlineOrderStatusSlice";
import updateOnlineOrderStatusSlice from "redux/gmbLocation/extras/updateOnlineOrderStatusSlice";
import getActionLinkFromGoogleSlice from "redux/gmbLocation/getActionLinkFromGoogleSlice";
import getActionLinkListSlice from "redux/gmbLocation/getActionLinkListSlice";
import getAllergensListSlice from "redux/gmbLocation/getAllergensListSlice";
import getCategoryDetailsSlice from "redux/gmbLocation/getCategoryDetailsSlice";
import getCategoryListSlice from "redux/gmbLocation/getCategoryListSlice";
import getGoogleCategoryListSlice from "redux/gmbLocation/getGoogleCategoryListSlice";
import getMenuDetailSlice from "redux/gmbLocation/getMenuDetailSlice";
import getMenuFromGoogleSlice from "redux/gmbLocation/getMenuFromGoogleSlice";
import getMenuItemListSlice from "redux/gmbLocation/getMenuItemListSlice";
import getMenuItemsDetailSlice from "redux/gmbLocation/getMenuItemsDetailSlice";
import getMenuListSlice from "redux/gmbLocation/getMenuListSlice";
import getMenuPricingVisibilitySlice from "redux/gmbLocation/getMenuPricingVisibilitySlice";
import getNextUpdateToPostMenuSlice from "redux/gmbLocation/getNextUpdateToPostMenuSlice";
import getNextUpdateToPostServiceSlice from "redux/gmbLocation/getNextUpdateToPostServiceSlice";
import getSectionDetailSlice from "redux/gmbLocation/getSectionDetailSlice";
import getSectionListSlice from "redux/gmbLocation/getSectionListSlice";
import getServiceDetailsSlice from "redux/gmbLocation/getServiceDetailsSlice";
import getServiceListSlice from "redux/gmbLocation/getServiceListSlice";
import getServicesFromGoogleSlice from "redux/gmbLocation/getServicesFromGoogleSlice";
import menuPricingToggleSlice from "redux/gmbLocation/menuPricingToggleSlice";
import postToGoogleSlice from "redux/gmbLocation/postToGoogleSlice";
import reOrderCategorySlice from "redux/gmbLocation/reOrderCategorySlice";
import reOrderMenuSlice from "redux/gmbLocation/reOrderMenuSlice";
import reOrderServiceSlice from "redux/gmbLocation/reOrderServiceSlice";
import removeActionLinkSlice from "redux/gmbLocation/removeActionLinkSlice";
import reorderItemSlice from "redux/gmbLocation/reorderItemSlice";
import reorderSectionSlice from "redux/gmbLocation/reorderSectionSlice";
import selectAllServicePostSlice from "redux/gmbLocation/selectAllServicePostSlice";
import servicesPostToGoogleSlice from "redux/gmbLocation/servicesPostToGoogleSlice";
import setActionLinkPrimarySlice from "redux/gmbLocation/setActionLinkPrimarySlice";
import setCategoryPrimarySlice from "redux/gmbLocation/setCategoryPrimarySlice";
import storeGoogleCategoryListSlice from "redux/gmbLocation/storeGoogleCategoryListSlice";
import updateCategorySlice from "redux/gmbLocation/updateCategorySlice";
import updateMenuItemSlice from "redux/gmbLocation/updateMenuItemSlice";
import updateMenuSlice from "redux/gmbLocation/updateMenuSlice";
import updateSectionSlice from "redux/gmbLocation/updateSectionSlice";
import updateServiceSlice from "redux/gmbLocation/updateServiceSlice";
import locationListSlice from "redux/location/locationListSlice";
import updateLocationDetailSlice from "redux/location/updateLocationDetailSlice";
import submitQuerySlice from "redux/marketplace/submitQuerySlice";
import addCampaignPostSlice from "redux/post/addCampaignPostSlice";
import addPostSlice from "redux/post/addPostSlice";
import deleteCampaignPostSlice from "redux/post/deleteCampaignPostSlice";
import deletePostSlice from "redux/post/deletePostSlice";
import generateAIContentSlice from "redux/post/generateAIContentSlice";
import getCalenderPostSlice from "redux/post/getCalenderPostSlice";
import getCampaignPostDetailsSlice from "redux/post/getCampaignPostDetailsSlice";
import getCampaignPostListSlice from "redux/post/getCampaignPostListSlice";
import getFailedPostListSlice from "redux/post/getFailedPostListSlice";
import getMenuItemImageListSlice from "redux/post/getMenuItemImageListSlice";
import getPostDetailsSlice from "redux/post/getPostDetailsSlice";
import getPostListSlice from "redux/post/getPostListSlice";
import getScheduledPostListSlice from "redux/post/getScheduledPostListSlice";
import pauseCampaignPostSlice from "redux/post/pauseCampaignPostSlice";
import postUpdateFromCalenderSlice from "redux/post/postUpdateFromCalenderSlice";
import addServiceCampaignPostSlice from "redux/post/service/addServiceCampaignPostSlice";
import addServiceSinglePostSlice from "redux/post/service/addServiceSinglePostSlice";
import deleteServiceCampaignPostSlice from "redux/post/service/deleteServiceCampaignPostSlice";
import deleteServiceSinglePostSlice from "redux/post/service/deleteServiceSinglePostSlice";
import generateServicePostContentSlice from "redux/post/service/generateServicePostContentSlice";
import getServiceCalenderPostSlice from "redux/post/service/getServiceCalenderPostSlice";
import getServiceCampaignPostDetailsSlice from "redux/post/service/getServiceCampaignPostDetailsSlice";
import getServiceCampaignPostListSlice from "redux/post/service/getServiceCampaignPostListSlice";
import getServicePostListSlice from "redux/post/service/getServicePostListSlice";
import getServiceSinglePostDetailsSlice from "redux/post/service/getServiceSinglePostDetailsSlice";
import launchPostToGoogleSlice from "redux/post/service/launchPostToGoogleSlice";
import pauseServiceCampaignSlice from "redux/post/service/pauseServiceCampaignSlice";
import updateServiceCampaignPostSlice from "redux/post/service/updateServiceCampaignPostSlice";
import updateServiceSinglePostSlice from "redux/post/service/updateServiceSinglePostSlice";
import updateCampaignPostSlice from "redux/post/updateCampaignPostSlice";
import updatePostSlice from "redux/post/updatePostSlice";
import getAllCouponsSlice from "redux/redemption/getAllCouponsSlice";
import getAllOrdersSlice from "redux/redemption/getAllOrdersSlice";
import getDetailsByTrackIdSlice from "redux/redemption/getDetailsByTrackIdSlice";
import redeemLeadSlice from "redux/redemption/redeemLeadSlice";
import addStarTemplateSlice from "redux/reputation/addStarTemplateSlice";
import checkExpireTokenSlice from "redux/reputation/checkExpireTokenSlice";
import deleteReputationSlice from "redux/reputation/deleteReputationSlice";
import deleteReviewReplySlice from "redux/reputation/deleteReviewReplySlice";
import deleteStarTemplateSlice from "redux/reputation/deleteStarTemplateSlice";
import generateUrlSlice from "redux/reputation/generateUrlSlice";
import getAiSuggestionSlice from "redux/reputation/getAiSuggestionSlice";
import getAssociateListSlice from "redux/reputation/getAssociateListSlice";
import getGmbSlice from "redux/reputation/getGmbSlice";
import getGoogleLocationSlice from "redux/reputation/getGoogleLocationSlice";
import getLastUpdatedReviewsTimeSlice from "redux/reputation/getLastUpdatedReviewsTimeSlice";
import getQuarantineSlice from "redux/reputation/getQuarantineSlice";
import getReputationListSlice from "redux/reputation/getReputationListSlice";
import getReviewTemplateSlice from "redux/reputation/getReviewTemplateSlice";
import getStarReviewSlice from "redux/reputation/getStarReviewSlice";
import getStarTemlpateSlice from "redux/reputation/getStarTemlpateSlice";
import getStarTemplateDetailSlice from "redux/reputation/getStarTemplateDetailSlice";
import googleDisconnectSlice from "redux/reputation/googleDisconnectSlice";
import googleRefeshKeySlice from "redux/reputation/googleRefeshKeySlice";
import replyReviewSlice from "redux/reputation/replyReviewSlice";
import updateStarTemplateSlice from "redux/reputation/updateStarTemplateSlice";
import getSettingsDetailsSlice from "redux/settings/getSettingsDetailsSlice";
import updateSettingsSlice from "redux/settings/updateSettingsSlice";
const rootReducer = combineReducers({
  login: loginSlice,
  register: registerSlice,
  logout: logoutSlice,
  locationList: locationListSlice,
  companyList: companyListSlice,
  switchToCompany: switchToCompanySlice,
  getCompanyAccountDetail: getCompanyAccountDetailSlice,
  getCompanyUserList: getCompanyUserListSlice,
  updateCompanyAccount: updateCompanyAccountSlice,
  getLocationDetail: getLocationDetailSlice,
  getLocationUserList: getLocationUserListSlice,
  addLocation: addLocationSlice,
  updateLocationDetail: updateLocationDetailSlice,
  getLocationUserDetail: getLocationUserDetailSlice,
  updateCompanyUser: updateCompanyUserSlice,
  addCompanyUser: addCompanyUserSlice,
  customerList: customerListSlice,
  addCustomer: addCustomerSlice,
  getCustomerDetail: getCustomerDetailSlice,
  updateCustomer: updateCustomerSlice,
  getAdminUserList: getAdminUserListSlice,
  addAdminUser: addAdminUserSlice,
  getAdminUserDetail: getAdminUserDetailSlice,
  updateAdminUser: updateAdminUserSlice,
  changeCompanyStatus: changeCompanyStatusSlice,
  changeAdminUserStatus: changeAdminUserStatusSlice,
  changeCustomerStatus: changeCustomerStatusSlice,
  changeLocationStatus: changeLocationStatusSlice,
  changeLocationUserStatus: changeLocationUserStatusSlice,
  getCompanyDetail: getCompanyDetailSlice,
  addCompany: addCompanySlice,
  updateCompanyDetail: updateCompanyDetailSlice,
  verifyEmail: verifyEmailSlice,
  importCustomerData: importCustomerDataSlice,
  getLocationByUser: getLocationByUserSlice,
  selectedLocation: selectedLocationSlice,
  ImportBulkLead: ImportBulkLeadSlice,
  getLeadLists: getLeadListsSlice,
  getLocationsByCompany: getLocationsByCompanySlice,
  leadListStatusChange: leadListStatusChangeSlice,
  getCampaignList: getCampaignListSlice,
  addCampaign: addCampaignSlice,
  changeCampaignStatus: changeCampaignStatusSlice,
  getCampaignDetail: getCampaignDetailSlice,
  resendVerificationCode: resendVerificationCodeSlice,
  updateCampaignDetail: updateCampaignDetailSlice,
  resetPassword: resetPasswordSlice,
  setNewPassword: setNewPasswordSlice,
  getSourceList: getSourceListSlice,
  getDetailsByTrackId: getDetailsByTrackIdSlice,
  redeemLead: redeemLeadSlice,
  getCampaignReport: getCampaignReportSlice,
  getCustomerReport: getCustomerReportSlice,
  getLeadSources: getLeadSourcesSlice,
  removeCustomerFromLead: removeCustomerFromLeadSlice,
  generateUrl: generateUrlSlice,
  getReputationList: getReputationListSlice,
  deleteReputation: deleteReputationSlice,
  addCustomerToLocation: addCustomerToLocationSlice,
  updateSettings: updateSettingsSlice,
  getSettingsDetails: getSettingsDetailsSlice,
  importedCustomersList: importedCustomersListSlice,
  deleteImportedCustomers: deleteImportedCustomersSlice,
  getProcessPercentage: getProcessPercentageSlice,
  submitQuery: submitQuerySlice,
  dashboard: dashboardSlice,
  getDailyRedemption: getDailyRedemptionSlice,
  getProspectsList: getProspectsListSlice,
  getBreadCrumbs: setBreadCrumbsSlice,
  getCustomHeatmapData: getCustomHeatmapDataSlice,
  campaignEnquiry: campaignEnquirySlice,
  companySetting: companySettingSlice,
  exportCustomers: exportCustomersSlice,
  getMessages: getMessagesSlice,
  deleteProspect: deleteProspectSlice,
  toggleCampaigns: toggleCampaignsSlice,
  addWifiData: addWifiDataSlice,
  getDetailByLocation: getDetailByLocationSlice,
  verifyOtpForWIfi: verifyOtpForWIfiSlice,
  guestWifiToggle: guestWifiToggleSlice,
  getVisitsList: getVisitsListSlice,
  getAllContacts: getAllContactsSlice,
  getOrdersList: getOrdersListSlice,
  getSquareLoginDetail: getSquareLoginDetailSlice,
  saveSquareImport: saveSquareImportSlice,
  getAllOrders: getAllOrdersSlice,
  getAllCoupons: getAllCouponsSlice,
  getGmb: getGmbSlice,
  addStarTemplate: addStarTemplateSlice,
  getStarTemplate: getStarTemlpateSlice,
  deleteStarTemplate: deleteStarTemplateSlice,
  updateStarTemplate: updateStarTemplateSlice,
  getSquareLocations: getSquareLocationsSlice,
  getGoogleLocation: getGoogleLocationSlice,
  associateSquareLocation: associateSquareLocationSlice,
  getReviewTemplate: getReviewTemplateSlice,
  getAssociateList: getAssociateListSlice,
  refreshSquareToken: refreshSquareTokenSlice,
  getTagsList: getTagsListSlice,
  addTag: addTagSlice,
  updateTag: updateTagSlice,
  deleteTag: deleteTagSlice,
  getTagDetail: getTagDetailSlice,
  revokeSquareToken: revokeSquareTokenSlice,
  getStarReview: getStarReviewSlice,
  checkExpireToken: checkExpireTokenSlice,
  googleRefreshKey: googleRefeshKeySlice,
  addTagToContact: addTagToContactSlice,
  googleDisconnect: googleDisconnectSlice,
  replyReview: replyReviewSlice,
  deleteReplyReview: deleteReviewReplySlice,
  deleteContacts: deleteContactsSlice,
  exportContacts: exportContactsSlice,
  getAiSuggestion: getAiSuggestionSlice,
  getContactDetail: getContactDetailSlice,
  updateContactDetail: updateContactDetailSlice,
  getQuarantine: getQuarantineSlice,
  getProspectReport: getProspectReportSlice,
  guestWifiOtpToggle: guestWifiOtpToggleSlice,
  updateSessionTimeout: updateSessionTimeoutSlice,
  getStarTemplateDetail: getStarTemplateDetailSlice,
  addPost: addPostSlice,
  addMenu: addMenuSlice,
  getMenuList: getMenuListSlice,
  getMenuDetail: getMenuDetailSlice,
  updateMenu: updateMenuSlice,
  deleteMenu: deleteMenuSlice,
  addSection: addSectionSlice,
  updateSection: updateSectionSlice,
  getSectionList: getSectionListSlice,
  deleteSection: deleteSectionSlice,
  addMenuItem: addMenuItemSlice,
  updateMenuItem: updateMenuItemSlice,
  deleteMenuItem: deleteMenuItemSlice,
  getMenuItemDetail: getMenuItemsDetailSlice,
  getSectionDetail: getSectionDetailSlice,
  getMenuItemList: getMenuItemListSlice,
  reorderItem: reorderItemSlice,
  reorderSection: reorderSectionSlice,
  getGooglePost: getGooglePostSlice,
  getPostList: getPostListSlice,
  getPostDetails: getPostDetailsSlice,
  deletePost: deletePostSlice,
  updatePost: updatePostSlice,
  generateAIContent: generateAIContentSlice,
  postToGoogle: postToGoogleSlice,
  removeActionLink: removeActionLinkSlice,
  getActionLinkFromGoogle: getActionLinkFromGoogleSlice,
  getActionLinkList: getActionLinkListSlice,
  getAllergensList: getAllergensListSlice,
  reOrderMenu: reOrderMenuSlice,
  setActionLinkPrimary: setActionLinkPrimarySlice,
  deactivateActionLink: deactivateActionLinkSlice,
  getOnlineOrderStatus: getOnlineOrderStatusSlice,
  updateOnlineOrderStatus: updateOnlineOrderStatusSlice,
  getMenuItemImageList: getMenuItemImageListSlice,
  addCampaignPost: addCampaignPostSlice,
  getCalenderPost: getCalenderPostSlice,
  postUpdateFromCalender: postUpdateFromCalenderSlice,
  getScheduledPostList: getScheduledPostListSlice,
  getFailedPostList: getFailedPostListSlice,
  getCampaignPostDetails: getCampaignPostDetailsSlice,
  getCampaignPostList: getCampaignPostListSlice,
  updateCampaignPost: updateCampaignPostSlice,
  getNextUpdateToPostMenu: getNextUpdateToPostMenuSlice,
  deleteCampaignPost: deleteCampaignPostSlice,
  pauseCampaignPost: pauseCampaignPostSlice,
  getCategoryList: getCategoryListSlice,
  addCategory: addCategorySlice,
  getCategoryDetails: getCategoryDetailsSlice,
  updateCategory: updateCategorySlice,
  deleteCategory: deleteCategorySlice,
  reOrderCategory: reOrderCategorySlice,
  addService: addServiceSlice,
  getServiceList: getServiceListSlice,
  updateService: updateServiceSlice,
  deleteService: deleteServiceSlice,
  getServiceDetails: getServiceDetailsSlice,
  reorderService: reOrderServiceSlice,
  servicesPostToGoogle: servicesPostToGoogleSlice,
  getNextUpdateToPostService: getNextUpdateToPostServiceSlice,
  storeGoogleCategoryList: storeGoogleCategoryListSlice,
  getGoogleCategoryList: getGoogleCategoryListSlice,
  setCategoryPrimary: setCategoryPrimarySlice,
  categoryPostToGoogle: categoryPostToGoogleSlice,
  getMenuFromGoogle: getMenuFromGoogleSlice,
  getLastUpdatedReviewsTime: getLastUpdatedReviewsTimeSlice,
  menuPricingToggle: menuPricingToggleSlice,
  getMenuPricingVisibility: getMenuPricingVisibilitySlice,
  getServicesFromGoogle: getServicesFromGoogleSlice,
  addServiceSinglePost: addServiceSinglePostSlice,
  getServicePostList: getServicePostListSlice,
  getServiceSinglePostDetails: getServiceSinglePostDetailsSlice,
  updateServiceSinglePost: updateServiceSinglePostSlice,
  deleteServiceSinglePost: deleteServiceSinglePostSlice,
  getServiceCalenderPost: getServiceCalenderPostSlice,
  addServiceCampaignPost: addServiceCampaignPostSlice,
  getServiceCampaignPostList: getServiceCampaignPostListSlice,
  getServiceCampaignPostDetails: getServiceCampaignPostDetailsSlice,
  updateServiceCampaignPost: updateServiceCampaignPostSlice,
  deleteServiceCampaignPost: deleteServiceCampaignPostSlice,
  pauseServiceCampaign: pauseServiceCampaignSlice,
  selectServicePost: SelectServicePostSlice,
  selectAllServicePost: selectAllServicePostSlice,
  generateServicePostContent: generateServicePostContentSlice,
  launchPostToGoogle: launchPostToGoogleSlice,
});

export default configureStore({
  reducer: rootReducer,
});
