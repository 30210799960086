import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Bars2Icon } from "@heroicons/react/24/solid";
import menuIcon from "assets/images/menuIcon.png";
import serviceIcon from "assets/images/service-icon.png";
import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// ItemTypes definition
const ItemTypes = {
  CARD: "card",
};

export default function MenuItem({
  menuData = {},
  setHoverOnMenu = () => {},
  hoverOnMenu = false,
  setMenuId = () => {},
  setIsMenuModalOpen = () => {},
  handleDeleteMenu = () => {},
  itemData = {},
  index = 0,
  moveCard = () => {},
  dropCard = () => {},
  deleteMenuLoading = false,
  google_business_type = "service",
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemId = !!itemData?.id ? itemData?.id : 0;
  const ref = useRef(null);
  const handleRef = useRef(null);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const deleteItemLoading = useSelector(
    (state) => state?.deleteMenuItem?.loading ?? false,
    shallowEqual
  );

  // Function for re-order item list
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // if (dragIndex === hoverIndex) return;

      dropCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ itemId, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(handleRef);
  drop(ref);
  preview(ref);

  return (
    <div
      ref={ref}
      key={menuData?.id}
      className={classNames("gap-2", {
        "opacity-0": isDragging,
      })}
    >
      <div className={classNames("flex items-center min-h-8 gap-2  ")}>
        <div
          className={`w-full flex gap-2 items-center `}
          data-handler-id={handlerId}
        >
          <div
            className="text-center relative"
            onMouseEnter={() => {
              setHoverOnMenu(menuData?.id);
            }}
            onMouseLeave={() => {
              setHoverOnMenu(0);
            }}
          >
            <button
              ref={handleRef}
              title="Drag Here"
              className={`h-6 w-6 cursor-grab font-semibold absolute top-0 right-0 z-[99]`}
            >
              <Bars2Icon strokeWidth={1.8} className="w-5 h-5 cursor-grab" />
            </button>
            <div
              title={
                google_business_type == "Restaurant"
                  ? "Manage Menu"
                  : "Manage Service"
              }
              className={`h-40 w-40 shadow-md shadow-gray-300 border-2 border-gray-400 bg-gray-50 cursor-pointer flex justify-center items-center ${
                hoverOnMenu == menuData?.id && `brightness-75`
              }`}
              onClick={() => {
                setMenuId(menuData?.id);
                navigate(`/location?tab=menu&menu=${menuData?.id}`);
              }}
            >
              <img
                src={
                  google_business_type == "Restaurant" ? menuIcon : serviceIcon
                }
                className="object-contain opacity-50 w-[50%] h-full m-auto top-50"
                alt="Menu Icon"
              />
            </div>
            {hoverOnMenu == menuData?.id && (
              <div className="absolute bottom-9 z-99  w-full flex justify-center items-center gap-1">
                <button
                  title="Edit"
                  className={`h-5 w-5 cursor-pointer`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuId(menuData?.id);
                    setIsMenuModalOpen(true);
                  }}
                >
                  <PencilSquareIcon />
                </button>
                <button
                  title="Edit"
                  className={`h-5 w-5 cursor-pointer`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuId(menuData?.id);
                    setIsConfirmationModalOpen(true);
                  }}
                >
                  <TrashIcon />
                </button>
              </div>
            )}
            {menuData?.menu_visibility == "no" && (
              <div className="absolute bottom-[26px] !z-9 text-sm right-[1px] w-18 bg-[#fc4b28] px-1  py-0.5 text-white">
                Hidden
              </div>
            )}
            <p className="font-bold text-gray-700">
              {menuData?.menu_name ?? ""}
            </p>
          </div>
        </div>
      </div>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={() => {
            handleDeleteMenu();
          }}
          isLoading={deleteMenuLoading}
          message="You want to delete this menu?"
        />
      )}
    </div>
  );
}
