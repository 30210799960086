import { CheckIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MultiSelectDropdown from "components/common/fields/MultiSelectDropdown";
import { showErrorMsg, showSuccessMsg } from "module/util";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { addTag } from "redux/companySetting/addTagSlice";
import { getTagsList } from "redux/companySetting/getTagsListSlice";

export default function TagsSelect({
  currentCompanyId = "",
  currentLocationId = "",
  value = [],
  setFieldValue = () => {},
  tagsOptions = [],
  addTags = {},
  setAddTags = () => {},
  tags = [],
  setTags = () => {},
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const contactType = searchParams.get("type") ? searchParams.get("type") : "";

  const handleAddTag = () => {
    const formData = {
      company_id: currentCompanyId,
      location_id: currentLocationId,
      tag: addTags?.tag,
    };

    dispatch(addTag(formData)).then((data) => {
      if (data?.payload.code === 201) {
        const newTag = {
          label: data?.payload?.dataObj?.data?.tag,
          value: data?.payload?.dataObj?.data?.id,
        };
        const currentTags = value || [];
        setFieldValue("tags", [...currentTags, newTag]);
        setTags([...tags, newTag]);
        setAddTags({ ...addTags, isAdding: false });

        const formData = {
          location_id: currentLocationId,
        };
        dispatch(getTagsList(formData));
        showSuccessMsg(data?.payload?.message ?? "Tag Added Successfully.");
      } else {
        // setAddTags({ ...addTags, tag: "" });
        const errorMsg = data?.payload?.exception?.errors?.tag[0];
        showErrorMsg(errorMsg);
      }
    });
  };

  return (
    <div className="w-full">
      <label
        htmlFor="tag"
        className={
          contactType
            ? `input-field-label`
            : `font-normal text-gray-900 text-lg my-1`
        }
      >
        Tags
      </label>

      <div className="mt-2 flex gap-1">
        {addTags?.isAdding ? (
          <>
            <input
              className={`input-field`}
              id="tag"
              name="tag"
              type="text"
              value={addTags?.tag}
              placeholder="Tag Name..."
              onChange={(e) => {
                setAddTags({
                  ...addTags,
                  tag: e.target.value,
                });
              }}
            />
            <button
              type="button"
              title="Add Tag"
              className="icon-btn text-gray-700 hover:text-gray-800 border"
              disabled={!addTags?.tag}
              onClick={() => {
                handleAddTag();
              }}
            >
              <CheckIcon
                className={`w-6 text-gray-400 ${
                  !addTags?.tag ? `disabled-btn` : `cursor-pointer`
                }`}
              />
            </button>
            <button
              type="button"
              className="icon-btn text-gray-700 hover:text-gray-800 border"
              onClick={() => {
                setAddTags({ isAdding: false });
              }}
            >
              <XMarkIcon title="Cancel" className="w-6 text-gray-400" />
            </button>
          </>
        ) : (
          <>
            <MultiSelectDropdown
              selectedOptions={[]}
              value={value ?? []}
              options={tagsOptions}
              name={"tags"}
              onChange={(e) => {
                setFieldValue("tags", e);
                setTags(e);
              }}
            />
            <button
              type="button"
              title="Add Tags"
              className="icon-btn text-gray-700 hover:text-gray-800 border"
              onClick={() => {
                setAddTags({ isAdding: true });
              }}
            >
              <PlusIcon className="w-6 text-gray-400" />
            </button>
          </>
        )}
      </div>
    </div>
  );
}
