import { Dialog, Transition } from "@headlessui/react";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { debounce } from "lodash";
import { isValidArray, showSuccessMsg } from "module/util";
import { handleServerValidation } from "module/validation";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { addCategory } from "redux/gmbLocation/addCategorySlice";
import { getGoogleCategoryList } from "redux/gmbLocation/getGoogleCategoryListSlice";

export default function CategoryModal({
  isModalOpen = false,
  setModalOpen = () => {},
  currentLocationId = "",
  currentCompanyId = "",
  categoryListLoading = false,
  handleAction = () => {},
}) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const [categoryObj, setCategoryObj] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [searchInput, setSearchInput] = useState("a");
  const [categoryOptions, setCategoryOptions] = useState([]);

  const addCategoryLoading = useSelector(
    (state) => state?.addCategory?.loading ?? false
  );
  const getGoogleCategoryListLoading = useSelector(
    (state) => state?.getGoogleCategoryList?.loading ?? false
  );

  const handleSubmit = () => {
    const formData = {
      category_name: categoryObj?.label,
      google_category_id: categoryObj?.value,
      is_primary: "no",
      location_id: currentLocationId,
    };

    const action = addCategory({ ...formData, company_id: currentCompanyId });
    dispatch(action).then((data) => {
      if (data?.payload?.code === 200) {
        setModalOpen(false);
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Category added successfully."
        );
        handleAction();
      } else {
        handleServerValidation(data);
      }
    });
  };

  const transformToLabelValueArray = (arr) => {
    return isValidArray(arr)
      ? arr?.map((item) => ({
          label: item?.gmb_category_display_name,
          value: item?.id,
        }))
      : [];
  };

  const handleGetGoogleCategory = (searchInput) => {
    const formData = {
      search: searchInput ?? "",
      perPage: 50,
    };
    dispatch(getGoogleCategoryList(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const categoryList = transformToLabelValueArray(
          data?.payload?.dataObj?.data?.data
        );
        setCategoryOptions(categoryList);
      } else {
        handleServerValidation(data);
      }
    });
  };

  const debouncedSetSearchInput = useCallback(
    debounce((value) => {
      setSearchInput(value);
    }, 600),
    []
  );

  useEffect(() => {
    handleGetGoogleCategory(searchInput);
  }, [searchInput]);

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}} static>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-[80%] items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full h-60 max-w-2xl transform rounded-sm bg-white text-left align-middle shadow-xl transition-all p-5">
                <div className="flex justify-between">
                  <span className="font-bold text-lg">{"Add Category"}</span>
                  <button
                    type="button"
                    className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5"
                    onClick={() => {
                      setModalOpen(false);
                      // setCategoryId(0);
                    }}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="w-full h-[1px] bg-gray-300 mt-1"></div>
                <div className="flex flex-col space-y-16">
                  <div className="mt-5">
                    <div className="flex items-center gap-1 relative">
                      <label
                        className="font-light w-[25%] flex justify-center"
                        htmlFor="category_name"
                      >
                        Category :
                      </label>

                      <Select
                        name={`category_name`}
                        className={"custom-input-field"}
                        value={categoryObj ?? ""}
                        onChange={(e) => {
                          setCategoryObj({ label: e?.label, value: e?.value });
                          setCategoryList([...categoryList, e?.value]);
                        }}
                        onInputChange={(value) => {
                          debouncedSetSearchInput(value);
                        }}
                        options={
                          getGoogleCategoryListLoading || categoryListLoading
                            ? [
                                {
                                  label: "Loading...",
                                  value: "",
                                  isDisabled: true,
                                },
                              ]
                            : categoryOptions
                        }
                        styles={{
                          control: (baseStyles, { isFocused, isSelected }) => ({
                            ...baseStyles,
                            borderColor: isFocused ? "#64656680" : "#abadb180",
                            borderRadius: 0,
                            pointerEvents: "all",
                            cursor: "pointer",
                          }),
                          option: (baseStyles, { isSelected }) => ({
                            ...baseStyles,
                            backgroundColor: isSelected ? "#abadb180" : "#fff",
                            color: "black",
                            ":hover": {
                              backgroundColor: "#abadb180",
                            },
                          }),
                          input: (baseStyles, { isFocused, isSelected }) => ({
                            ...baseStyles,
                            borderColor: isFocused ? "#64656680" : "#abadb180",
                            ":hover": {
                              borderColor: "#64656680",
                              border: "1px",
                            },
                          }),
                        }}
                        isClearable={true}
                        placeholder={"Search Category"}
                        isLoading={getGoogleCategoryListLoading}
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-end  gap-x-4">
                    <SpinnerButton
                      className="inline-flex w-24 justify-center btn-pink"
                      type="button"
                      action={handleSubmit}
                      title="Save"
                      isDisable={!categoryObj?.label && !categoryObj?.value}
                      loading={addCategoryLoading}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
