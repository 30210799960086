import { Dialog, Transition } from "@headlessui/react";
import SpinnerButton from "components/common/fields/SpinnerButton";
import {
  checkParagraphWordsLimit,
  getCurrentLocation,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addService } from "redux/gmbLocation/addServiceSlice";
import { getServiceDetails } from "redux/gmbLocation/getServiceDetailsSlice";
import { updateService } from "redux/gmbLocation/updateServiceSlice";

export default function ServiceModal({
  isModalOpen,
  setModalOpen,
  categoryId = 0,
  serviceId = 0,
  setServiceId = () => {},
  handleAction = () => {},
}) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const { id: currentLocationId } = getCurrentLocation();
  const [serviceData, setServiceData] = useState({
    service_name: "",
    service_description: "",
  });
  const [isDescriptionLengthy, setIsDescriptionLengthy] = useState(false);

  const addSectionLoading = useSelector(
    (state) => state?.addSection?.loading ?? false
  );
  const updateSectionLoading = useSelector(
    (state) => state?.updateSection?.loading ?? false
  );

  const isLoading = addSectionLoading || updateSectionLoading;

  const handleSubmit = () => {
    const formData = {
      ...serviceData,
      location_id: currentLocationId,
      category_id: categoryId,
    };

    const handleResponse = (data) => {
      if (data?.payload?.code === 200) {
        const successMessage =
          data?.payload?.dataObj?.message ??
          data?.payload?.message ??
          (serviceId
            ? "Service updated successfully."
            : "Service added successfully.");
        setModalOpen(false);
        showSuccessMsg(successMessage);
        handleAction();
        setServiceId(0);
      } else {
        handleServerValidation(data);
      }
    };

    const action = serviceId
      ? updateService({
          formData: { ...formData },
          id: serviceId,
        })
      : addService(formData);

    dispatch(action).then(handleResponse);
  };

  const handleServiceDetail = (id) => {
    const formData = {
      id: id,
      location_id: currentLocationId,
    };
    dispatch(getServiceDetails(formData)).then((data) => {
      const dataObj = data?.payload?.dataObj;
      if (data?.payload?.code == 200) {
        setServiceData({
          service_name: dataObj?.service_name ?? "",
          service_price: dataObj?.service_price ?? "",
          service_price_type: dataObj?.service_price_type ?? "",
          service_description: dataObj?.service_description ?? "",
        });
      }
    });
  };

  useEffect(() => {
    if (serviceId) {
      handleServiceDetail(serviceId);
    }
  }, [serviceId]);

  const isButtonDisabled = () => {
    const { service_name, service_description } = serviceData;
    return (
      service_name.trim() === "" ||
      // service_description.trim() === "" ||
      isDescriptionLengthy
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsDescriptionLengthy(checkParagraphWordsLimit(value));
    setServiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handlePaste = (e) => {
  //   const pastedText = e.clipboardData.getData("text");
  //   const currentValue = e.target.value;
  //   const newValue = currentValue + pastedText;
  //   setIsDescriptionLengthy(checkParagraph(newValue));
  //   setServiceData((prevData) => ({
  //     ...prevData,
  //     ["service_description"]: newValue,
  //   }));
  // };

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-[70%] items-center justify-center p-4 text-center my-10">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl max-h-[35rem] transform overflow-visible rounded-sm bg-white text-left align-middle shadow-xl transition-all p-5">
                <div className="flex justify-between">
                  <span className="font-bold text-lg">
                    {serviceId ? "Edit" : "Add"} Service
                  </span>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center outline-0"
                    onClick={() => {
                      setModalOpen(false);
                      setServiceId(0);
                    }}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="w-full h-[1px] bg-gray-300 mt-1"></div>
                <div className="mt-5 flex flex-col gap-y-4 overflow-y-auto">
                  <div className="flex flex-col w-full gap-1 items-start">
                    <label className="input-field-label" htmlFor="service_name">
                      Service Name :
                    </label>
                    <input
                      id="service_name"
                      name="service_name"
                      type="text"
                      value={serviceData?.service_name ?? ""}
                      onChange={handleChange}
                      placeholder="Service Name"
                      className="input-field"
                    />
                  </div>
                  <div className="flex flex-col w-full gap-1 items-start">
                    <label
                      className="input-field-label"
                      htmlFor="service_description"
                    >
                      Service Description :
                    </label>
                    <textarea
                      id="service_description"
                      name="service_description"
                      value={serviceData?.service_description ?? ""}
                      onChange={handleChange}
                      // onPaste={handlePaste}
                      placeholder="Service Description"
                      className="input-field h-32 max-h-36"
                    ></textarea>
                    {isDescriptionLengthy && (
                      <div className="text-red-500">
                        {"Description can't exceed 300 words."}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-between gap-x-4">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      setModalOpen(false);
                      setServiceId(0);
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <SpinnerButton
                    className="inline-flex w-full justify-center btn-pink"
                    type="button"
                    action={handleSubmit}
                    title="Save"
                    isDisable={isButtonDisabled()}
                    loading={isLoading}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
