import { CheckIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import DigitalOfferStep1 from "components/campaigns/digitalOffer/DigitalOfferStep1";
import DirectMailStep1 from "components/campaigns/directMail/DirectMailStep1";
import PrintAdStep1 from "components/campaigns/printAd/PrintAdStep1";
import ShowPageTitle from "components/common/ShowPageTitle";
import {
  campaignTypeForList,
  campaignTypeForPageTitle,
  digitalOffer,
  directMailSteps,
  printAdSteps,
} from "module/data";
import { isValidObject } from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";

const CampaignsForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { type } = useParams();
  const { id } = useParams();
  const campaignType = location?.state ?? "direct_mail";

  const isEdit = !!id;

  const campaignStep = searchParams.get("step");
  const defaultSteps = ["1", "2", "3"];
  const [selectedStep, setSelectStep] = useState(
    defaultSteps.includes(campaignStep) ? campaignStep : "1"
  );
  const [stepsArray, setStepsArray] = useState([]);

  const [{ getCampaignDetailData = {} }] = useSelector((state) => [
    {
      getCampaignDetailData: state?.getCampaignDetail?.data ?? {},
    },
  ]);

  const handleStepsArray = () => {
    let campaignTypesArray = [];
    if (type === "direct_mail") {
      campaignTypesArray = directMailSteps;
    } else if (type === "print_ad") {
      campaignTypesArray = printAdSteps;
    } else if (type === "digital_offer") {
      campaignTypesArray = digitalOffer;
    }

    const filtered = campaignTypesArray.map((item) => {
      const redirectLink = isEdit
        ? `/campaigns/edit/${id}/${type}?step=${item.id}`
        : `/campaigns/add/${type}?step=${item.id}`;
      return {
        ...item,
        status:
          selectedStep == item.id
            ? "current"
            : selectedStep > item.id
            ? "complete"
            : "upcomping",
        href: redirectLink,
      };
    });

    dispatch(
      setBreadCrumb([
        {
          name: "Campaigns List",
          href: `/campaigns/list?type=${campaignType}`,
          current: true,
        },

        {
          name: `${isEdit ? "Edit" : "Add"} ${
            campaignTypeForList[type]
          } Campaign`,
          href: "",
          current: false,
          isDisable: true,
        },
        {
          name: filtered.find((item) => item?.id == selectedStep)?.name ?? "",
          href: "",
          current: false,
        },
      ])
    );

    setStepsArray(filtered);
  };

  useEffect(() => {
    handleStepsArray();
  }, [selectedStep]);

  return (
    <>
      <NewLayout
        steps={stepsArray}
        navigate={navigate}
        setSelectStep={setSelectStep}
        isEdit={isEdit}
        getCampaignDetailData={getCampaignDetailData}
        type={type}
        selectedStep={selectedStep}
      />
      <ShowPageTitle title={campaignTypeForPageTitle[type]} />

      {type === "direct_mail" && (
        <>
          <DirectMailStep1
            setSearchParams={setSearchParams}
            setSelectStep={setSelectStep}
            type={type}
            selectedStep={selectedStep}
          />
        </>
      )}
      {type === "digital_offer" && (
        <DigitalOfferStep1
          setSearchParams={setSearchParams}
          setSelectStep={setSelectStep}
          type={type}
          selectedStep={selectedStep}
        />
      )}
      {type === "print_ad" && (
        <PrintAdStep1
          setSearchParams={setSearchParams}
          setSelectStep={setSelectStep}
          type={type}
          selectedStep={selectedStep}
        />
      )}
      {/* </div> */}
    </>
  );
};

export default CampaignsForm;

const NewLayout = (props) => {
  const {
    steps,
    navigate,
    setSelectStep,
    isEdit,
    getCampaignDetailData = {},
    type = "",
    selectedStep = "1",
  } = props;

  const isAllow = isValidObject(getCampaignDetailData?.email_template);

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-gray-300 bg-white  border border-gray-300 md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li key={step.id} className="relative md:flex md:flex-1">
            {step.status === "complete" ? (
              <span
                onClick={() => {
                  if (isEdit) {
                    navigate(step.href);
                    setSelectStep(step.id);
                  }
                }}
                className="group flex w-full items-center cursor-pointer"
              >
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center  bg-gray-600 group-hover:bg-gray-800">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </span>
            ) : step.status === "current" ? (
              <span
                onClick={() => {
                  if (isEdit) {
                    navigate(step.href);
                    setSelectStep(step.id);
                  }
                }}
                className="flex items-center px-6 py-4 text-sm font-medium cursor-pointer"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center bg-pink-500  border-2 border-pink-500">
                  <span className="text-white">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-900">
                  {step.name}
                </span>
              </span>
            ) : (
              <span
                onClick={() => {
                  if (isEdit && (isAllow || step.id == "2")) {
                    navigate(step.href);
                    setSelectStep(step.id);
                  }
                }}
                className={classNames("group flex items-center", {
                  "cursor-pointer": isEdit && (isAllow || step.id == "2"),
                })}
              >
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span
                    className={classNames(
                      "flex h-10 w-10 flex-shrink-0 items-center justify-center  border-2 bg-gray-300 border-gray-300 ",
                      {
                        "group-hover:border-gray-400":
                          isEdit && (isAllow || step.id == "2"),
                      }
                    )}
                  >
                    <span
                      className={classNames("text-gray-500 ", {
                        "group-hover:text-gray-900":
                          isEdit && (isAllow || step.id == "2"),
                      })}
                    >
                      {step.id}
                    </span>
                  </span>
                  <span
                    className={classNames(
                      "ml-4 text-sm font-medium text-gray-500 ",
                      {
                        "group-hover:text-gray-900":
                          isEdit && (isAllow || step.id == "2"),
                      }
                    )}
                  >
                    {step.name}
                  </span>
                </span>
              </span>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};
