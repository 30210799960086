import {
  BookmarkIcon,
  CheckCircleIcon,
  ClockIcon,
  InformationCircleIcon,
  NoSymbolIcon,
  PauseCircleIcon,
  PencilSquareIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import imagePreview from "assets/images/dummyImage.svg";
import classNames from "classnames";
import ConfirmationModal from "components/common/ConfirmationModal";
import {
  convertTimeStampToDateMoment,
  createFirstLetterCapital,
  getCurrentLocation,
  getItemImage,
  isValidArray,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCampaignPost } from "redux/post/deleteCampaignPostSlice";
import { getCampaignPostList } from "redux/post/getCampaignPostListSlice";
import { pauseCampaignPost } from "redux/post/pauseCampaignPostSlice";
import { deleteServiceCampaignPost } from "redux/post/service/deleteServiceCampaignPostSlice";
import { getServiceCampaignPostList } from "redux/post/service/getServiceCampaignPostListSlice";
import { pauseServiceCampaign } from "redux/post/service/pauseServiceCampaignSlice";

export default function CampaignPostList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(0);
  const [idForPause, setIdForPause] = useState(0);

  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );
  const campaignPostList = useSelector(
    (state) => state?.getCampaignPostList?.data?.dataObj?.data ?? []
  );
  const campaignPostListLoading = useSelector(
    (state) => state?.getCampaignPostList?.loading ?? false
  );
  const deleteCampaignPostLoading = useSelector(
    (state) => state?.deleteCampaignPost?.loading ?? false
  );
  const pauseCampaignPostLoading = useSelector(
    (state) => state?.pauseCampaignPost?.loading ?? false
  );

  const handleGetPostList = () => {
    const formData = {
      location_id: currentLocationId,
    };
    const action =
      google_business_type === "Restaurant"
        ? getCampaignPostList
        : getServiceCampaignPostList;
    dispatch(action(formData));
  };

  const handleDeletePost = () => {
    const action =
      google_business_type === "Restaurant"
        ? deleteCampaignPost
        : deleteServiceCampaignPost;
    dispatch(action(idForDelete)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Post deleted successfully."
        );
        setModalOpen(false);
        handleGetPostList();
      } else {
        handleServerValidation(data);
        setModalOpen(false);
      }
    });
  };

  const handlePausePost = () => {
    const action =
      google_business_type === "Restaurant"
        ? pauseCampaignPost
        : pauseServiceCampaign;
    dispatch(action(idForPause)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Post paused successfully."
        );
        setModalOpen(false);
        handleGetPostList();
      } else {
        handleServerValidation(data);
        setModalOpen(false);
      }
    });
  };

  const handleModalAction = () => {
    if (!!idForDelete) {
      handleDeletePost();
    } else if (!!idForPause) {
      handlePausePost();
    }
  };

  useEffect(() => {
    handleGetPostList();
  }, [currentLocationId]);

  return (
    <div className="w-full flex justify-center">
      {campaignPostListLoading ? (
        <div
          role="status"
          className={`w-full space-y-4 rounded animate-pulse mb-4 p-4`}
        >
          <div className="flex gap-4 ">
            {[...Array(3)]?.map((_, menuIndex) => {
              return (
                <div key={menuIndex} className="h-56 w-[32rem]">
                  <div className="h-full bg-gray-300"></div>
                </div>
              );
            })}
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="flex justify-start items-center flex-wrap w-full gap-4 mt-1">
          {isValidArray(campaignPostList) ? (
            campaignPostList?.map((campaign, campaignIndex) => {
              const imageSrc = campaign?.post_image
                ? getItemImage(campaign?.post_image)
                : imagePreview;

              const campaignStatus = !!campaign?.campaign_status
                ? campaign?.campaign_status
                : "";

              const isCampaignCompleted = campaignStatus === "completed";
              const isCampaignPaused = campaignStatus === "paused";
              const isCampaignFailed = campaignStatus === "failed";
              const isCampaignSchedule = campaignStatus === "scheduled";
              const isCampaignDrafted = campaignStatus === "draft";

              return (
                <div
                  key={campaignIndex}
                  className={classNames(
                    "relative h-72 flex flex-col justify-between w-full 2xl:w-[32.5%] md:w-[48%] rounded-sm bg-white border-2 border-gray-400"
                  )}
                >
                  <div
                    className={classNames(
                      "flex items-center gap-4 overflow-hidden bg-clip-border"
                    )}
                  >
                    <div className="flex w-full h-full flex-col gap-0.5">
                      <div className="flex justify-between items-center bg-gray-300 p-1">
                        <div className="flex items-center gap-1 text-md font-semibold my-2">
                          <span
                            className={classNames({
                              "text-green-600": isCampaignCompleted,
                              "text-orange-500": isCampaignPaused,
                              "text-red-500": isCampaignFailed,
                              "text-yellow-600": isCampaignDrafted,
                            })}
                          >
                            {isCampaignCompleted ? (
                              <CheckCircleIcon
                                className="w-6 h-6"
                                strokeWidth={2.5}
                              />
                            ) : isCampaignPaused ? (
                              <NoSymbolIcon
                                className="w-6 h-6"
                                strokeWidth={2.5}
                              />
                            ) : isCampaignFailed ? (
                              <XCircleIcon
                                className="w-6 h-6"
                                strokeWidth={2.5}
                              />
                            ) : isCampaignDrafted ? (
                              <BookmarkIcon
                                className="w-6 h-5"
                                strokeWidth={2}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="text-gray-800">
                            {campaign?.campaign_name ?? "Campaign Draft"}
                          </span>
                        </div>
                        <div className="w-[25%] flex justify-end items-center gap-1 mr-2">
                          {isCampaignSchedule || isCampaignDrafted ? (
                            <>
                              <button
                                type="button"
                                title="Edit"
                                className=""
                                onClick={() => {
                                  navigate(
                                    `/marketing?tab=posts&type=multi&form=post-details&campaignId=${campaign?.post_campaign_id}`,
                                    { state: "campaigns" }
                                  );
                                }}
                              >
                                <PencilSquareIcon className="w-5 h-5" />
                              </button>
                              <button
                                type="button"
                                title="Delete"
                                className=""
                                onClick={() => {
                                  setIdForPause(0);
                                  setModalOpen(true);
                                  setIdForDelete(campaign?.post_campaign_id);
                                }}
                              >
                                <TrashIcon className="w-5 h-5" />
                              </button>
                              {!isCampaignDrafted && (
                                <button
                                  type="button"
                                  title="Pause"
                                  className=""
                                  onClick={() => {
                                    setIdForDelete(0);
                                    setModalOpen(true);
                                    setIdForPause(campaign?.post_campaign_id);
                                  }}
                                >
                                  <PauseCircleIcon className="w-5 h-5" />
                                </button>
                              )}
                            </>
                          ) : !isCampaignCompleted ? (
                            <button
                              type="button"
                              title="Delete"
                              className=""
                              onClick={() => {
                                setModalOpen(true);
                                setIdForDelete(campaign?.post_campaign_id);
                              }}
                            >
                              <TrashIcon className="w-5 h-5" />
                            </button>
                          ) : ""}
                        </div>
                      </div>
                      <div className="p-2 flex justify-between items-center">
                        <div>
                          <div>
                            <span className="text-sm w-80">
                              Post Schedule :
                            </span>
                            <span className="text-sm font-light ml-2 w-40">
                              {createFirstLetterCapital(
                                campaign?.postSchedule ?? "N/A"
                              ) ?? "N/A"}
                            </span>
                          </div>
                          <div>
                            <span className="text-sm w-40">Post Timing :</span>
                            <span className="text-sm font-light ml-2 w-40">
                              {`${campaign?.post_start_time ?? `N/A`} - ${
                                campaign?.post_end_time ?? `N/A`
                              }`}
                            </span>
                          </div>
                          <div>
                            <span className="text-sm w-40">Total Post :</span>
                            <span className="text-sm font-light ml-2 w-40">
                              {campaign?.total_post_count ?? "N/A"}
                            </span>
                          </div>
                          <div>
                            <span className="text-sm w-40">
                              Scheduled Post :
                            </span>
                            <span className="text-sm font-light ml-2 w-40">
                              {campaign?.pending_post_count ?? "N/A"}
                            </span>
                          </div>
                          <div>
                            <span className="text-sm w-80">
                              Completed Post :
                            </span>
                            <span className="text-sm font-light ml-2 w-40">
                              {campaign?.completed_post_count ?? "N/A"}
                            </span>
                          </div>
                          <div>
                            <span className="text-sm w-80">Failed Post :</span>
                            <span className="text-sm font-light ml-2 w-40">
                              {campaign?.failed_post_count ?? "N/A"}
                            </span>
                          </div>
                        </div>

                        <div className="h-[120px] max-w-[150px] w-full shadow-inner border-2 border-gray-400">
                          <img
                            src={imageSrc}
                            alt={"campaign-image"}
                            className={classNames(
                              classNames("h-full w-full !object-fill")
                            )}
                          />
                          {/* {post?.length > 1 && (
                              <div className="absolute bottom-10 right-10 text-white font-medium">{`+ ${
                                post?.length - 1
                              }`}</div>
                            )} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-12 p-2 flex gap-2 justify-between items-center border-t my-2">
                    <div className="w-full">
                      <span className="text-sm w-80">
                        {`Campaign Start Date: `}
                      </span>
                      <span className="text-sm font-light">
                        {convertTimeStampToDateMoment(
                          campaign?.post_launch_date
                        ) ?? "N/A"}
                      </span>
                    </div>
                    <div className="w-full flex justify-end items-center">
                      {isCampaignSchedule ? (
                        <>
                          <div className="w-5 h-5 flex justify-center items-center border mr-1 border-gray-200 bg-white text-yellow-600 text-sm font-medium rounded-xl ">
                            <ClockIcon className="w-4 h-4" strokeWidth={2.5} />
                          </div>
                          <div className="text-sm">
                            {`Upcoming Post: `}
                            {convertTimeStampToDateMoment(
                              campaign?.upcoming_schedule_post_date
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            title={`All posts related to this campaign are show in ${
                              isCampaignCompleted
                                ? "Completed"
                                : isCampaignPaused
                                ? "Failed"
                                : isCampaignFailed
                                ? "Error"
                                : ""
                            } tab.`}
                            className={classNames(
                              "w-5 h-5 flex justify-center items-center border mr-1 text-yellow-600 border-gray-200 bg-white text-sm font-medium rounded-xl"
                            )}
                          >
                            <InformationCircleIcon
                              className="w-5 h-5"
                              strokeWidth={2.5}
                            />
                          </div>
                          <div className="text-md font-semibold text-gray-700">
                            {`Campaign ${createFirstLetterCapital(
                              campaignStatus
                            )}`}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-start items-center flex-wrap w-full gap-x-4 gap-y-3 py-4 border border-gray-300 bg-white">
              <div className="w-full h-40 flex justify-center items-center text-lg font-light ">
                No Campaign Available
              </div>
            </div>
          )}
        </div>
      )}

      {isModalOpen && (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleAction={handleModalAction}
          isLoading={deleteCampaignPostLoading || pauseCampaignPostLoading}
          message={
            !!idForDelete
              ? "You want to delete this campaign ? <br /> #Note: Completed posts will not get deleted."
              : !!idForPause && "You want to pause this campaign ?"
          }
        />
      )}
    </div>
  );
}
