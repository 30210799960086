import { isValidArray } from "module/util";
import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

const TypeAheadSource = (props) => {
  const {
    setFieldValue = () => {},
    data = [],
    values = "",
    setFieldTouched = () => {},
  } = props;
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (isValidArray(data)) {
      const finalOptions = data?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      setResults(finalOptions);
    }
  }, [data]);

  return (
    <CreatableSelect
      isClearable={true}
      name="source"
      value={
        isValidArray(results)
          ? results?.filter(function (option) {
              return option?.value === values;
            })
          : { label: values, value: values }
      }
      onChange={(e) => {
        const isExist = results?.find((item) => item.value === e?.value);
        !isExist &&
          !!e?.value &&
          setResults([...results, { label: e?.value, value: e?.value }]);
        setFieldValue("source", e?.value ?? "");
      }}
      onBlur={(e) => {
        setFieldTouched("source", true);
      }}
      options={results}
      styles={{
        control: (baseStyles, { isFocused }) => ({
          ...baseStyles,
          borderColor: isFocused ? "#64656680" : "#abadb180",
          borderRadius: 0,
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          backgroundColor: isSelected ? "#abadb180" : "#fff",
          color: "black",
          ":hover": {
            backgroundColor: "#abadb180",
          },
        }),
        input: (baseStyles, { isFocused, isSelected }) => ({
          ...baseStyles,
          borderColor: isFocused ? "#64656680" : "#abadb180",
          ":hover": {
            borderColor: "#64656680",
            border: "1px",
          },
        }),
      }}
    />
  );
};

export default TypeAheadSource;
