import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const deleteMenu = createAsyncThunk(
  "gmb/delete-menu",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await deleteApi(`api/gmblocationmenu/${formData?.id}`);
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const deleteMenuSlice = createSlice({
  name: "deleteMenu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteMenu.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteMenu.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(deleteMenu.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default deleteMenuSlice.reducer;
