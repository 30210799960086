import { Cog6ToothIcon, NoSymbolIcon } from "@heroicons/react/24/solid";
import facebookLogo from "assets/images/facebook-logo.png";
import googleLogo from "assets/images/google-logo.png";
import squareLogo from "assets/images/square-up-logo.svg";
import toastLogo from "assets/images/toast-logo.svg";
import FacebookIntegration from "components/companySettings/integrations/facebook/FacebookIntegration";
import GoogleIntegration from "components/companySettings/integrations/google/GoogleIntegration";
import SquareDetails from "components/companySettings/integrations/square/SquareDetails";
import ToastIntegration from "components/companySettings/integrations/toast/ToastIntegration";
import {
  createFirstLetterCapital,
  getAuthToken,
  getCompanyStatus,
  isValidObject,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSquareLoginDetail } from "redux/companySetting/getSquareLoginDetailSlice";
import { revokeSquareToken } from "redux/companySetting/revokeSquareTokenSlice";
import { checkExpireToken } from "redux/reputation/checkExpireTokenSlice";
import { googleDisconnect } from "redux/reputation/googleDisconnectSlice";

const Integrations = ({ currentLocationId = 0, currentCompanyId = 0 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [revokeToken, setRevokeToken] = useState();
  const [searchParams] = useSearchParams();
  const currentSetting = !!searchParams?.get("setting")
    ? searchParams?.get("setting")
    : "";
  const isCompanyActive = ["active"].includes(getCompanyStatus());

  const squareLoginDetails = useSelector(
    (state) => state?.getSquareLoginDetail?.data?.dataObj ?? []
  );

  const isSquareLogin = isValidObject(squareLoginDetails)
    ? squareLoginDetails?.access_token
    : false;

  const checkExpireData = useSelector(
    (state) => state?.checkExpireToken?.data?.dataObj ?? {}
  );

  const handleSquareLoginDetail = () => {
    const formData = {
      company_id: currentCompanyId,
    };
    dispatch(getSquareLoginDetail(formData));
  };

  const handleCheckExpiration = () => {
    const data = {
      company_id: currentCompanyId,
      location_id: currentLocationId,
      access_token: getAuthToken(),
    };
    dispatch(checkExpireToken(data));
  };

  const handleSquareDisconnect = () => {
    const formData = {
      company_id: currentCompanyId,
    };
    dispatch(revokeSquareToken(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        handleSquareLoginDetail();
        setRevokeToken(false);
        showSuccessMsg(data?.payload?.message ?? "Disconnected Successfully.");
      } else {
        setRevokeToken(false);
        handleServerValidation(data);
      }
    });
  };

  const handleGoogleDisconnect = () => {
    const formData = {
      company_id: currentCompanyId,
      location_id: currentLocationId,
      access_token: getAuthToken(),
    };
    dispatch(googleDisconnect(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        handleCheckExpiration();
        setRevokeToken(false);
        showSuccessMsg(
          data?.payload?.dataObj?.message ?? "Disconnected Successfully."
        );
      } else {
        setRevokeToken(false);
        handleServerValidation(data);
      }
    });
  };

  const handleRevokeToken = (id) => {
    //handleRevoke function to revoke connection of integrations specifically
    setRevokeToken(true);
    if (id === "square") {
      handleSquareDisconnect();
    } else if (id === "google") {
      handleGoogleDisconnect();
    }
  };

  useEffect(() => {
    if (!currentSetting) {
      handleSquareLoginDetail();
      handleCheckExpiration();
      setRevokeToken(true);
      const pageLoading = setTimeout(() => {
        setRevokeToken(false);
      }, [600]);
      return () => {
        clearTimeout(pageLoading);
      };
    }
  }, [currentLocationId, currentSetting]);

  const integrationsArray = [
    //Integration settings Array to get specific setting component
    {
      id: "google",
      connection: checkExpireData?.status ? "connected" : "not connected",
      image: googleLogo,
      description:
        " Google Business Profile (formerly GMB) is a powerful FREE listing available to businesses. Pendio's Integration supports the management of your business . GMB profile, reviews, posts, data and more.",
      instruction:
        "To connect Pendio to Google, start by clicking on the `Authorize` button below. This will pop-up a window from google that you must ALLOW to authorize and complete the integration.",
    },
    {
      id: "square",
      connection: isSquareLogin ? "connected" : "not connected",
      image: squareLogo,
      description:
        "Use Square to conveniently import. Square orders and customers into Pendio to connect and drive more targeted marketing. Track orders, coupons, ROI and more.",
      instruction:
        "To connect Pendio to Square, start by clicking on the connect button and follow the instructions on screen by clicking on allow button in square page. This will import Square customers as Pendio Contacts, enable you to create targeted marketing campaigns, and see orders on Pendio, guest profiles and campaigns.z",
    },
    {
      id: "facebook",
      connection: "",
      image: facebookLogo,
      description:
        "Use Facebook to conveniently import. Facebook orders and customers into Pendio to connect and drive more targeted marketing. Track orders, coupons, ROI and more.",
      instruction:
        "To connect Pendio to Facebook, start by entering your Application ID and Application Secret. This will import Facebook customers as Pendio Contacts, enable you to create targeted marketing campaigns, and see orders on Pendio guest profiles and campaigns.",
    },
    {
      id: "toast",
      connection: "",
      image: toastLogo,
      description:
        "Use Toast to conveniently import. Toast orders and customers into Pendio to connect and drive more targeted marketing. Track orders, coupons, ROI and more.",
      instruction:
        "To connect Pendio to Toast, start by entering your Application ID and Application Secret. This will import Toast customers as Pendio Contacts, enable you to create targeted marketing campaigns, and see orders on Pendio guest profiles and campaigns.",
    },
  ];

  const integration = integrationsArray?.find(
    (integration) => integration?.id == currentSetting
  );

  return revokeToken && !currentSetting ? (
    <div
      role="status"
      className={`w-full space-y-4 rounded animate-pulse py-4 mb-4`}
    >
      <div className="flex flex-wrap gap-4 ">
        {integrationsArray?.map((_, integrationIndex) => {
          return (
            <div
              key={integrationIndex}
              className="w-full md:w-[48%] 2xl:w-[32%]"
            >
              <div className="h-80 bg-gray-300"></div>
            </div>
          );
        })}
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <div className="mt-4">
      {!currentSetting ? (
        <div className="flex flex-wrap gap-4">
          {integrationsArray.map((integration, integrationIndex) => {
            return (
              <div
                key={integrationIndex}
                className="min-h-80 bg-white border-2 border-gray-400 w-full 2xl:w-[32%] md:w-[48%] flex flex-col justify-between itemscenter"
              >
                <div className="p-4 flex flex-col gap-y-4 justify-center items-center">
                  <img
                    className={
                      integration?.id === `toast` ? "h-16 w-24" : "h-16 w-16"
                    }
                    src={integration?.image}
                  />
                  <p className="text-md font-semibold text-gray-700">
                    {createFirstLetterCapital(integration?.id)}
                  </p>
                  <p className="text-md text-gray-700">
                    {integration?.description}
                  </p>
                </div>
                <div className="bg-gray-300 w-full flex gap-2 justify-center py-2 h-[56px]">
                  {isCompanyActive && (
                    <>
                      <button
                        className={`${
                          integration?.connection === "connected"
                            ? `btn-pink`
                            : integration?.connection === "not connected"
                            ? `btn-blue`
                            : integration?.connection === "connection issue"
                            ? `btn-yellow`
                            : `btn btn-gray`
                        } flex gap-x-2 justify-center items-center`}
                        onClick={() => {
                          const redirect =
                            integration?.connection == "connected" ||
                            integration?.connection === "connection issue"
                              ? true
                              : integration?.connection === "not connected"
                              ? false
                              : false;

                          navigate(
                            `/user-settings?tab=integrations&setting=${integration?.id}&redirect=${redirect}`
                          );
                        }}
                        disabled={["toast", "facebook"].includes(
                          integration?.id
                        )}
                      >
                        {!["toast", "facebook"].includes(integration?.id) && (
                          <Cog6ToothIcon width={24} height={24} />
                        )}

                        {integration?.connection === ""
                          ? "Coming Soon..."
                          : integration?.connection === "not connected"
                          ? `Connect`
                          : `Manage`}
                      </button>

                      {integration?.connection == "connected" && (
                        <button
                          className={`w-[118px] btn btn-gray flex gap-x-2 justify-center items-center`}
                          onClick={() => {
                            handleRevokeToken(integration?.id);
                          }}
                        >
                          <NoSymbolIcon width={24} height={24} /> {`Disconnect`}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : currentSetting == "google" ? (
        <GoogleIntegration
          integration={integration}
          currentLocationId={currentLocationId}
          currentCompanyId={currentCompanyId}
        />
      ) : currentSetting == "square" ? (
        <SquareDetails
          integration={integration}
          currentLocationId={currentLocationId}
          currentCompanyId={currentCompanyId}
        />
      ) : currentSetting == "facebook" ? (
        <FacebookIntegration
          integration={integration}
          currentLocationId={currentLocationId}
          currentCompanyId={currentCompanyId}
        />
      ) : (
        currentSetting == "toast" && (
          <ToastIntegration
            integration={integration}
            currentLocationId={currentLocationId}
            currentCompanyId={currentCompanyId}
          />
        )
      )}
    </div>
  );
};

export default Integrations;
