import classNames from "classnames";
import Attributes from "components/location/attributes/Attributes";
import Details from "components/location/details/Details";
import { Extras } from "components/location/extras/Extras";
import Information from "components/location/information/Information";
import Media from "components/location/media/Media";
import Menu from "components/location/menu/Menu";
import ServiceCategory from "components/location/services/ServiceCategory";
import { gmbLocationTab } from "module/data";
import {
  addMinutesToDateTime,
  convertToPDTFormatDateTimeStamp,
  getAuthToken,
  getCompanyStatus,
  getCurrentLocation,
  getUserDataByParam,
  isValidObject,
} from "module/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { getNextUpdateToPostMenu } from "redux/gmbLocation/getNextUpdateToPostMenuSlice";
import { getNextUpdateToPostService } from "redux/gmbLocation/getNextUpdateToPostServiceSlice";
import { checkExpireToken } from "redux/reputation/checkExpireTokenSlice";

export default function Location() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateDateTime, setUpdateDateTime] = useState("");
  const [lastUpdatedTime, setLastUpdatedTime] = useState();
  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();
  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const currentCompanyId = getUserDataByParam("COMPANY_ID");

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );
  const checkExpireData = useSelector(
    (state) => state?.checkExpireToken?.data?.dataObj ?? false
  );
  const checkExpireDataLoading = useSelector(
    (state) => state?.checkExpireToken?.loading ?? false
  );

  const urlTab = searchParams.get("tab") || "information";

  const currentActiveTab = [
    "information",
    "details",
    "media",
    "menu",
    "services",
    "attributes",
    // "extras",
  ].includes(urlTab)
    ? urlTab
    : google_business_type == "Restaurant"
    ? "menu"
    : "services";

  const [activeTab, setActiveTab] = useState(currentActiveTab);

  const locationTabsArray = [
    { name: "Information", value: "information", href: "#", current: false },
    { name: "Details", value: "details", href: "#", current: false },
    ...(google_business_type == "Restaurant"
      ? [
          {
            name: "Menu",
            value: "menu",
            href: "#",
            current: false,
          },
        ]
      : [
          {
            name: "Services",
            value: "services",
            href: "#",
            current: false,
          },
        ]),
    { name: "Media", value: "media", href: "#", current: false },
    {
      name: "Attributes",
      value: "attributes",
      href: "#",
      current: false,
    },
    // ...(google_business_type == "Restaurant"
    //   ? [
    //       {
    //         name: "Extras",
    //         value: "extras",
    //         href: "#",
    //         current: false,
    //       },
    //     ]
    //   : []),
  ];

  const breadCrumbsTitle = {
    menu: "Menu",
    services: "Services",
    // extras: "Extras",
    information: "Information",
    details: "Details",
    media: "Media",
    attributes: "Attributes",
  };

  useEffect(() => {
    const tab =
      google_business_type == "Restaurant"
        ? gmbLocationTab.Restaurant[activeTab] || activeTab
        : gmbLocationTab.other[activeTab] || activeTab;
    setSearchParams({ tab });
    setActiveTab(tab);

    dispatch(
      setBreadCrumb([
        {
          name: "Location",
          href: "",
          current: false,
          isDisable: true,
        },
        {
          name: breadCrumbsTitle[currentActiveTab],
          href: "",
          current: false,
        },
      ])
    );
  }, [currentActiveTab, currentLocationId]);

  const getNextUpdate = () => {
    const action =
      google_business_type == "Restaurant"
        ? getNextUpdateToPostMenu
        : getNextUpdateToPostService;

    dispatch(action(currentLocationId)).then((data) => {
      if (data?.payload?.code == 200) {
        const dateTime = data?.payload?.dataObj?.data?.created_at ?? "";
        const result = dateTime ? addMinutesToDateTime(dateTime) : "";
        setUpdateDateTime(result);
        const lastUpdate =
          data?.payload?.dataObj?.last_updated?.created_at ?? "";
        setLastUpdatedTime(convertToPDTFormatDateTimeStamp(lastUpdate));
      }
    });
  };

  useEffect(() => {
    getNextUpdate();
    const fetchData = () => {
      let data = {
        location_id: currentLocationId,
        company_id: currentCompanyId,
        access_token: getAuthToken(),
      };

      dispatch(checkExpireToken(data)).then((data) => {
        const isGoogleConnected = data?.payload?.dataObj?.status;
        !isGoogleConnected && setSearchParams({ tab: "menu" });
      });
    };
    fetchData();

    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId);
  }, [currentLocationId]);

  const isGoogleConnected = checkExpireData?.status ?? false;

  return (
    <div className="relative">
      {isCompanyActive &&
        isValidObject(checkExpireData) &&
        !isGoogleConnected &&
        !checkExpireDataLoading && (
          <button
            className="btn-pink fixed top-[50%] left-[50%] z-[99]"
            onClick={() => {
              navigate(
                "/user-settings?tab=integrations&setting=google&redirect=false",
                { state: "/location" }
              );
            }}
          >
            Connect Google
          </button>
        )}
      <div
        className={classNames("", {
          "pointer-events-none": !isCompanyActive,
          "pointer-events-none blur-[4px] grayscale": !isGoogleConnected,
        })}
      >
        <div
          className={classNames(
            "flex justify-between gap-x-2 items-center border-b border-collapse border-gray-300  w-full"
          )}
        >
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {locationTabsArray.map((tab) => (
              <span
                key={tab.name}
                onClick={() => {
                  setActiveTab(tab.value);
                  navigate(`/location?tab=${tab.value}`);
                }}
                className={classNames(
                  " cursor-pointer text-gray-700 hover:border-pink-600 hover:text-gray-800 whitespace-nowrap border-b-4 py-2 px-1 text-[15px]",
                  {
                    "border-pink-600 hover:border-pink-600 text-gray-800":
                      tab.value === activeTab,
                    "border-transparent": tab.value !== activeTab,
                  }
                )}
                aria-current={tab.value === activeTab ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>

          <div className="flex gap-2 justify-center items-center">
            <div className="flex flex-wrap justify-center items-center">
              <span className="font-semibold text-sm text-gray-700">
                Last updated on Google:
              </span>{" "}
              <span className="font-light text-sm ml-1 mt-[1.5px]">
                {lastUpdatedTime ? lastUpdatedTime : "N/A"}
              </span>
            </div>
            <span className="text-gray-500">{" | "}</span>

            <div className="flex flex-wrap justify-center items-center">
              <span className="font-semibold text-sm text-gray-700">
                Next update scheduled on Google:
              </span>{" "}
              <span className="font-light text-sm ml-1 mt-[1.5px]">
                {updateDateTime ? updateDateTime : "N/A"}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <div className={activeTab === "information" ? "block" : "hidden"}>
            {activeTab === "information" && <Information />}
          </div>

          <div className={activeTab === "details" ? "block" : "hidden"}>
            {activeTab === "details" && <Details />}
          </div>

          <div className={activeTab === "menu" ? "block" : "hidden"}>
            {activeTab === "menu" && (
              <Menu
                getNextUpdate={getNextUpdate}
                isGoogleConnected={isGoogleConnected}
              />
            )}
          </div>

          <div className={activeTab === "services" ? "block" : "hidden"}>
            {activeTab === "services" && (
              <ServiceCategory
                getNextUpdate={getNextUpdate}
                isGoogleConnected={isGoogleConnected}
              />
            )}
          </div>

          <div className={activeTab === "media" ? "block" : "hidden"}>
            {activeTab === "media" && <Media />}
          </div>

          <div className={activeTab === "attributes" ? "block" : "hidden"}>
            {activeTab === "attributes" && <Attributes />}
          </div>

          <div className={activeTab === "extras" ? "block" : "hidden"}>
            {activeTab === "extras" && <Extras />}
          </div>
        </div>
      </div>
    </div>
  );
}
