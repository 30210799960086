import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, Form, Formik } from "formik";
import { getUserDataByParam, showErrorMsg, showSuccessMsg } from "module/util";
import { contactUsForm, showError } from "module/validation";
import { useDispatch, useSelector } from "react-redux";
import { campaignEnquiry } from "redux/campaign/campaignEnquirySlice";

const ContactUsForm = ({
  selectedCampaignsType = "",
  currentLocationId = 0,
}) => {
  const dispatch = useDispatch();
  const companyId = getUserDataByParam("COMPANY_ID");

  const [{ campaignEnquiryLoading = false }] = useSelector((state) => [
    {
      campaignEnquiryLoading: state?.campaignEnquiry?.loading ?? false,
    },
  ]);

  const initialValues = {
    name: getUserDataByParam("USER_NAME") ?? "",
    email: getUserDataByParam("USER_EMAIL") ?? "",
    comment: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    const formData = {
      name: values?.name ?? "",
      email: values?.email ?? "",
      type: selectedCampaignsType,
      comment: values?.comment ?? "",
      company_id: companyId,
      location_id: currentLocationId,
    };

    dispatch(campaignEnquiry(formData)).then((data) => {
      if (data.payload.code === 200 || data.payload.code === 201) {
        showSuccessMsg("Your inquiry sent successfully");
        resetForm();
      } else {
        showErrorMsg("Something went wrong");
      }
    });
  };

  return (
    <div className="isolate px-6 py-4 sm:py-4 lg:px-8 mt-4">
      <div className="mx-auto max-w-xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Contact Us
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          To know more details.
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={contactUsForm}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          resetForm,
        }) => (
          <Form
            className="w-full max-w-xl mx-auto mt-8"
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              e.key == "Enter" && e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label htmlFor="name" className="input-field-label">
                  Name
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    value={values?.name ?? ""}
                    onChange={handleChange}
                    placeholder="Enter Name"
                    className="input-field"
                  />
                </div>
                {touched?.name && showError(errors?.name)}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label htmlFor="email" className="input-field-label">
                  Email
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    value={values?.email ?? ""}
                    onChange={handleChange}
                    autoComplete="email"
                    placeholder="Enter Email"
                    className="input-field"
                  />
                </div>
                {touched?.email && showError(errors?.email)}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label htmlFor="Comment" className="input-field-label">
                  Comment
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    as="textarea"
                    name="comment"
                    id="comment"
                    value={values?.comment ?? ""}
                    onChange={handleChange}
                    placeholder="Enter comment"
                    className="input-field"
                  />
                </div>
                {touched?.comment && showError(errors?.comment)}
              </div>
            </div>
            <div className="flex justify-center">
              <SpinnerButton
                className="btn-pink"
                type="Submit"
                action={() => {}}
                title="Send"
                loading={campaignEnquiryLoading}
              />
              <button
                type="button"
                className="btn btn-gray ml-2"
                onClick={() => {
                  resetForm();
                }}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactUsForm;
