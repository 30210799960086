import ShowPageTitle from "components/common/ShowPageTitle";
import SplashPreview from "components/companySettings/SplashPreview";
import GuestWifiLoader from "components/front/GuestWifiLoader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDetailByLocation } from "redux/front/getDetailByLocationSlice";

const GuestWifiPage = () => {
  const { locationId, guestId, companyId, companyName, locationName } =
    useParams();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState();

  const isGuestForm = !!guestId ? false : true;

  useEffect(() => {
    const formData = {
      location_id: locationId,
    };
    dispatch(getDetailByLocation(formData));
  }, []);

  return (
    <div className="flex h-full flex-col justify-center sm:px-6 lg:px-8">
      <ShowPageTitle title={"guestWifi"} />
      <div className=" xs:mt-[5%] xs:my-auto xs:mx-auto xs:w-full xs:max-w-[480px] sm:my-auto sm:mx-auto sm:w-full sm:max-w-[480px]">
        <SplashPreview
          currentLocationId={locationId}
          guestId={guestId}
          companyId={companyId}
          locationName={locationName}
          companyName={companyName}
          isPreview={false}
          isGuestForm={isGuestForm}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </div>
      {isLoading && <GuestWifiLoader isModalOpen={true} message="" />}
    </div>
  );
};

export default GuestWifiPage;
