import ReactApexChart from "react-apexcharts";

const ChildrenPresentGraph = ({ demoGraphicData = {} }) => {
  const graphDetails = demoGraphicData?.children_present ?? {};

  const createArrayForSeries = () => {
    const seriesArray = [
      graphDetails?.["Y"]?.[0]?.percent ?? 0,
      graphDetails?.["N"]?.[0]?.percent ?? 0,
      graphDetails?.["Unknown"]?.[0]?.percent ?? 0,
    ];

    return seriesArray;
  };

  // Check if the seriesArray contains any data
  const hasData = createArrayForSeries().some((value) => value > 0);

  const config = {
    series: createArrayForSeries(),
    options: {
      chart: {
        height: 300,
        type: "donut",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: ["#37d6eb", "#a5d583", "#f35288"],
      labels: ["Yes", "No", "Unknown"],
      tooltip: {
        enabled: true,
        y: {
          formatter: (value) => {
            return `${value}%`;
          },
        },
      },
    },
  };

  return (
    <div id="chart">
      {hasData ? (
        <ReactApexChart
          options={config.options}
          series={config.series}
          type="donut"
          height={300}
        />
      ) : (
        <div className="h-72 w-full flex justify-center items-center text-gray-600">
          No Data
        </div>
      )}
    </div>
  );
};

export default ChildrenPresentGraph;
