import { Dialog, Transition } from "@headlessui/react";
import SpinnerButton from "components/common/fields/SpinnerButton";
import {
  checkParagraphWordsLimit,
  getCurrentLocation,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSection } from "redux/gmbLocation/addSectionSlice";
import { getSectionDetail } from "redux/gmbLocation/getSectionDetailSlice";
import { updateSection } from "redux/gmbLocation/updateSectionSlice";

export default function SectionModal({
  isModalOpen,
  setModalOpen,
  menuId = 0,
  handleAction = () => {},
  sectionIdForEdit = 0,
  sectionBasicDetails = {},
}) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const { id: currentLocationId } = getCurrentLocation();
  const [sectionData, setSectionData] = useState({ name: "", description: "" });
  const [isDescriptionLengthy, setIsDescriptionLengthy] = useState(false);

  const addSectionLoading = useSelector(
    (state) => state?.addSection?.loading ?? false
  );
  const updateSectionLoading = useSelector(
    (state) => state?.updateSection?.loading ?? false
  );

  const isLoading = addSectionLoading || updateSectionLoading;

  const handleSubmit = () => {
    const formData = {
      menu_id: menuId,
      section_name: sectionData?.name,
      section_description: sectionData?.description,
      location_id: currentLocationId,
    };
    if (sectionData?.name) {
      if (sectionIdForEdit) {
        dispatch(updateSection({ id: sectionIdForEdit, formData })).then(
          (data) => {
            if (data?.payload?.code == 200) {
              showSuccessMsg(
                data?.payload?.dataObj?.message ??
                  data?.payload?.message ??
                  "Section updated succesfully."
              );
              handleAction();
              setModalOpen(false);
            } else {
              handleServerValidation(data);
            }
          }
        );
      } else {
        dispatch(addSection(formData)).then((data) => {
          if (data?.payload?.code == 200) {
            setModalOpen(false);
            handleAction();
          } else handleServerValidation(data);
        });
      }
    }
  };

  const handleSectionDetail = (id) => {
    const formData = {
      id: id,
      location_id: currentLocationId,
    };
    dispatch(getSectionDetail(formData)).then((data) => {
      const dataObj = data?.payload?.dataObj?.data;
      if (data?.payload?.code == 200) {
        setSectionData({
          name: dataObj?.section_name ?? "",
          description: dataObj?.section_description ?? "",
        });
      }
    });
  };

  const handleUpdateSection = () => {
    dispatch(updateSection(sectionBasicDetails)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Section updated succesfully."
        );
        handleGetSectionList();
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsDescriptionLengthy(checkParagraphWordsLimit(value));
    setSectionData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (sectionIdForEdit) {
      handleSectionDetail(sectionIdForEdit);
    } else {
      setSectionData({
        name: "",
        description: "",
      });
    }
  }, [sectionIdForEdit]);

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-[70%] items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-visible rounded-sm bg-white text-left align-middle shadow-xl transition-all p-5">
                <div className="flex justify-between">
                  <div className="w-full flex gap-2 md:w-1/2   mb-6 md:mb-0">
                    <span className="font-bold text-lg">
                      {sectionIdForEdit ? "Edit" : "Add"} Section
                    </span>
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center outline-0"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="w-full h-[1px] bg-gray-300 mt-1"></div>
                <div className="mt-5">
                  <div className="w-full gap-1 items-center">
                    <label
                      className="font-light leading-6 text-gray-900 text-center w-[30%] md:w-[15.3%] xl:w-[11.5%] 2xl:w-[22.5%]"
                      htmlFor="tag"
                    >
                      Section Name :
                    </label>
                    <div className="w-full">
                      <input
                        id="section_name"
                        name="section_name"
                        type="text"
                        value={sectionData?.name ?? ""}
                        onChange={(e) =>
                          setSectionData({
                            ...sectionData,
                            name: e?.target?.value,
                          })
                        }
                        placeholder="Section Name"
                        className={`input-field`}
                      />{" "}
                    </div>
                  </div>

                  <div className="w-full mt-2 gap-2 items-center">
                    <label
                      className="font-light leading-6 text-gray-900 text-center w-[30%] md:w-[15%] xl:w-[11.3%] 2xl:w-[22%]"
                      htmlFor="description"
                    >
                      Section Desc :
                    </label>

                    <textarea
                      id="section_description"
                      name="section_description"
                      value={sectionData?.section_description ?? ""}
                      onChange={handleChange}
                      placeholder="Section Description"
                      className="input-field h-32 max-h-36"
                    ></textarea>
                    {isDescriptionLengthy && (
                      <div className="text-red-500">
                        {"Description can't exceed 300 words."}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-between  gap-x-4">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <SpinnerButton
                    className="inline-flex w-full justify-center btn-pink"
                    type="button"
                    action={handleSubmit}
                    title="Save"
                    isDisable={!sectionData?.name || isDescriptionLengthy}
                    loading={isLoading}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
