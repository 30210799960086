import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patchApi, postApi, putApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const setCategoryPrimary = createAsyncThunk(
  "gmb/set-category-primary",
  async (formData, { rejectWithValue }) => {
    const { id, location_id } = formData;
    try {
      const response = await postApi(
        `api/update-primary-category?category_id=${id}&location_id=${location_id}`
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const setCategoryPrimarySlice = createSlice({
  name: "setCategoryPrimary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCategoryPrimary.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(setCategoryPrimary.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(setCategoryPrimary.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default setCategoryPrimarySlice.reducer;
