import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";
import { createQueryParams } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getGoogleCategoryList = createAsyncThunk(
  "gmb/google-category-list",
  async (formData, { rejectWithValue }) => {
    const { search, page, perPage } = formData;

    const queryString = createQueryParams({ search, page, perPage });
    try {
      const response = await getApi(
        `api/get-gmb-category${queryString}`,
        formData
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGoogleCategoryListSlice = createSlice({
  name: "getGoogleCategoryList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGoogleCategoryList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGoogleCategoryList.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getGoogleCategoryList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getGoogleCategoryListSlice.reducer;
