import deleteIcon from "assets/images/deleteBoldIcon.svg";
import imagePreview from "assets/images/dummyImage.svg";
import plusIcon from "assets/images/plusIcon.svg";
import classNames from "classnames";
import DropdownCommon from "components/common/fields/DropdownCommon";
import SpinnerButton from "components/common/fields/SpinnerButton";
import PostContentEditor from "components/marketing/posts/PostContentEditor";
import { PostLoading } from "components/marketing/posts/PostFormMain";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { callToActionOptions } from "module/data";
import {
  areArrayObjectValuesValid,
  getCurrentLocation,
  getDataLocalStorage,
  getUserDataByParam,
  isValidArray,
  isValidObject,
  removeDataLocalStorage,
  setDataLocalStorage,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import {
  handleServerValidation,
  serviceCampaignPostDetailsForm,
} from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { getCategoryList } from "redux/gmbLocation/getCategoryListSlice";
import { getServiceList } from "redux/gmbLocation/getServiceListSlice";
import { addServiceCampaignPost } from "redux/post/service/addServiceCampaignPostSlice";
import { getServiceCampaignPostDetails } from "redux/post/service/getServiceCampaignPostDetailsSlice";
import { updateServiceCampaignPost } from "redux/post/service/updateServiceCampaignPostSlice";

export default function ServiceCampaignPostForm({
  generatedPost = [],
  setGeneratedPost = () => {},
  setGeneratePostLoading = () => {},
  currentPostContentIndex = 0,
  generatePostLoading = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId") || "";
  const isEdit = !!campaignId ? campaignId : false;
  const { id: currentLocationId, location_url = "" } = getCurrentLocation();
  const currentCompanyId = getUserDataByParam("COMPANY_ID");

  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [ctaArray, setCTAArray] = useState([
    { cta_btn_text: "", cta_btn_url: location_url },
  ]);
  const [categoryId, setCategoryId] = useState(0);
  const [isPostGenerated, setIsPostGenerated] = useState(false);

  const campaignDetails = getDataLocalStorage("CAMPAIGN_POST_DATA") ?? [];

  const locationId = useSelector(
    (state) => state?.selectedLocation?.value ?? ""
  );
  const getPostDetailsLoading = useSelector(
    (state) => state?.getServiceCampaignPostDetails?.loading ?? false
  );
  const addCampaignPostLoading = useSelector(
    (state) => state?.addServiceCampaignPost?.loading ?? false
  );
  const updateCamapignPostLoading = useSelector(
    (state) => state?.updateServiceCampaignPost?.loading ?? false
  );
  const categoryList = useSelector(
    (state) => state?.getCategoryList?.data?.dataObj?.data ?? []
  );
  const serviceList = useSelector(
    (state) => state?.getServiceList?.data?.dataObj?.data ?? []
  );

  const handleGetCategoryList = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: currentCompanyId,
    };
    dispatch(getCategoryList(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const categoryId = data?.payload?.dataObj?.data[0]?.id ?? 0;
        setCategoryId(categoryId);
        handleServiceList(categoryId);
      }
    });
  };

  const handleServiceList = (categoryId) => {
    const formData = {
      category_id: categoryId,
      location_id: currentLocationId,
    };
    dispatch(getServiceList(formData)).then((data) => {});
  };

  const handleGeneratePost = (serviceArray, buttonsArray) => {
    // Calculate the number of images and buttons
    const totalServices = serviceArray?.length;
    const totalButtons = buttonsArray?.length;
    // Calculate the maximum times each button should be used
    const maxButtonUsage = Math.ceil(totalServices / totalButtons);

    let result = [];

    // Create a map to track button usage
    let buttonUsageMap = {};
    isValidArray(buttonsArray) &&
      buttonsArray?.forEach((button) => {
        buttonUsageMap[button.cta_btn_text] = 0;
      });

    // Assign images and buttons to the result array
    for (let i = 0; i < totalServices; i++) {
      let availableButtons =
        isValidArray(buttonsArray) &&
        buttonsArray?.filter(
          (button) => buttonUsageMap[button.cta_btn_text] < maxButtonUsage
        );

      // Randomly select a button from the available buttons
      let selectedButton =
        availableButtons[Math.floor(Math.random() * availableButtons.length)];

      // Increment the usage count for the selected button
      buttonUsageMap[selectedButton?.cta_btn_text]++;

      // Create a post object with the image and selected button
      const post = {
        post_id: isEdit
          ? generatedPost[i]?.post_id
          : isValidArray(serviceArray) && !!serviceArray[i]?.post_id
          ? serviceArray[i]?.post_id
          : 0,
        service_id: isValidArray(serviceArray) ? serviceArray[i].id : 0,
        post_image: isValidArray(serviceArray)
          ? serviceArray[i].service_image
          : imagePreview,
        description: isEdit
          ? generatedPost[i]?.description
          : isValidArray(serviceArray)
          ? serviceArray[i]?.description
          : "",
        cta_btn_text: !!selectedButton?.cta_btn_text
          ? selectedButton?.cta_btn_text
          : "",
        cta_btn_url: !!selectedButton?.cta_btn_url
          ? selectedButton?.cta_btn_url
          : "",
      };

      // Add the post object to the result array
      result.push(post);
    }
    if (isValidArray(result)) {
      setGeneratedPost(result);
      setIsPostGenerated(true);
    } else {
      setGeneratedPost([
        {
          service_id: 0,
          post_image: "",
          cta_btn_text: "",
          cta_btn_url: "",
        },
      ]);
      setIsPostGenerated(false);
    }

    setTimeout(() => {
      setGeneratePostLoading(false);
    }, 400);
    return result;
  };

  const getUniqueCTAs = (arr) => {
    const uniqueCTAs = new Map();

    isValidArray(arr) &&
      arr.forEach((item) => {
        // If the CTA text is not already in the Map, add it
        if (!uniqueCTAs.has(item.cta_btn_text)) {
          uniqueCTAs.set(item.cta_btn_text, {
            cta_btn_text: item.cta_btn_text,
            cta_btn_url: item.cta_btn_url,
          });
        }
      });

    // Convert the Map values to an array
    return Array.from(uniqueCTAs.values());
  };

  const initialValues = {
    post_to: isValidObject(campaignDetails)
      ? campaignDetails?.post_to
      : "google_business_profile",
    categoryId: isValidObject(campaignDetails)
      ? campaignDetails?.category_id
      : isValidArray(categoryList)
      ? categoryId
      : "",
    writePost: isValidObject(campaignDetails) ? campaignDetails?.writePost : "",
    callToAction: isValidObject(campaignDetails)
      ? getUniqueCTAs(campaignDetails?.writePost)
      : [{ cta_btn_text: "", cta_btn_url: location_url }],
  };

  const handleSubmit = (values) => {
    if (!isPostGenerated) {
      // showErrorMsg("Please generate post.");
      showErrorMsg("Selected items do not contain any images. Please disable the image required toggle.");
      return;
    }

    const formData = {
      location_id: currentLocationId,
      campaign_type: "multi",
      is_structure_data: true,
      post_to: values?.post_to ?? "",
      category_id: values?.categoryId ?? "",
      section_id: values?.section_id === "Whole Menu" ? "" : values?.section_id,
      writePost: isValidArray(generatedPost) ? generatedPost : [],
      post_count: isValidArray(generatedPost) ? generatedPost?.length ?? 0 : 0,
    };

    const handleSaveAsDraft = () => {
      const action = isEdit
        ? updateServiceCampaignPost({
            id: campaignId,
            formData: { ...formData, post_status: "draft" },
          })
        : addServiceCampaignPost({
            ...formData,
            post_status: "draft",
          });

      dispatch(action).then((data) => {
        if (data?.payload?.code === 200) {
          navigate(`/marketing?tab=campaigns`);
          showSuccessMsg(
            data?.payload?.dataObj?.message ?? data?.payload?.message ?? isEdit
              ? "Post updated successfully."
              : "Post drafted successfully."
          );
        } else {
          handleServerValidation(data);
        }
      });
    };

    const handlePost = () => {
      const data = isEdit
        ? { ...campaignDetails, ...formData }
        : { ...formData };

      setDataLocalStorage("CAMPAIGN_POST_DATA", data);
      const basePath = `/marketing?tab=posts&type=multi&form=post-schedule`;
      const path = isEdit ? `${basePath}&campaignId=${campaignId}` : basePath;
      navigate(path, { state: location?.state });
    };

    saveAsDraft ? handleSaveAsDraft() : handlePost();
  };

  const handleGetCampaignPostDetails = () => {
    const formData = { campaign_id: campaignId };

    dispatch(getServiceCampaignPostDetails(formData)).then((data) => {
      const postDetails = data?.payload?.data ?? {};

      const formData = {
        post_to: postDetails?.post_to || "",
        category_id: postDetails?.category_id || "",
        writePost: postDetails?.writePost || [],
        campaign_name: postDetails?.campaign_name || "",
        post_launch_date: postDetails?.post_launch_date || "",
        postSchedule: postDetails?.postSchedule || "",
        dailyPost: postDetails?.dailyPost || "",
        post_start_time: postDetails?.post_start_time || "",
        post_end_time: postDetails?.post_end_time || "",
        weekdays: postDetails?.weekdays || "",
        post_count: postDetails?.post_count || "",
      };

      const cta = getUniqueCTAs(postDetails?.writePost);
      setCTAArray(cta);
      if (
        !isValidObject(location?.state) &&
        typeof location?.state === "string"
      ) {
        setGeneratedPost(postDetails?.writePost);
        setDataLocalStorage("CAMPAIGN_POST_DATA", formData);
      }
      setIsPostGenerated(true);
    });
  };

  useEffect(() => {
    if (areArrayObjectValuesValid(ctaArray) && isValidArray(serviceList)) {
      setGeneratePostLoading(true);
      const posts = handleGeneratePost(serviceList, ctaArray);
      isValidArray(posts) && setGeneratedPost(posts);
    }
  }, [ctaArray]);

  useEffect(() => {
    if (isEdit) {
      handleGetCampaignPostDetails();
    }
    isValidObject(campaignDetails) &&
      setCTAArray(getUniqueCTAs(campaignDetails?.writePost));
    handleGetCategoryList();
    areArrayObjectValuesValid(campaignDetails?.writePost) &&
      setIsPostGenerated(true);
  }, [currentLocationId]);

  return (
    <div className={classNames("w-full min-h-[40rem] flex justify-center")}>
      {getPostDetailsLoading && <PostLoading />}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={serviceCampaignPostDetailsForm()}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          handleBlur,
          resetForm,
          setFieldValue,
          setFieldTouched,
          values,
          handleSubmit,
        }) => (
          <Form
            className="w-full gap-8 p-4 flex flex-col justify-between"
            id="form1"
            name="form1"
            onSubmit={handleSubmit}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          >
            <div className="form-fields">
              {/* Post To Field */}
              <div className="w-full px-3 my-2">
                <label htmlFor="post_to" className="input-field-label">
                  Post To
                </label>
                <div className="mt-2 w-full">
                  <Field
                    as="select"
                    className="input-field"
                    name="post_to"
                    id="post_to"
                    value={values?.post_to ?? ""}
                    onChange={(e) => setFieldValue("post_to", e.target.value)}
                    onBlur={() => setFieldTouched("post_to", true)}
                  >
                    <option value="google_business_profile">
                      Google Business Profile
                    </option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    className="field-error text-red-500"
                    name="post_to"
                  />
                </div>
              </div>

              {/* Category and Services Selectors */}
              <div className="w-full px-3 my-3">
                <div className="flex items-center gap-4 h-[25px]">
                  <label
                    htmlFor="isStructureData"
                    className="input-field-label flex items-center"
                  >
                    USE Structure Data
                  </label>
                </div>
                <div className="mt-2 w-full flex gap-4">
                  {/* Service Selector */}
                  <div className="w-full">
                    <select
                      className="input-field disabled:cursor-not-allowed"
                      name="categoryId"
                      value={values?.categoryId ?? ""}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const { value, selectedOptions } = e.target;
                        const sectionCounts =
                          selectedOptions[0]?.dataset?.sectionsCount;
                        setFieldValue("categoryId", value);
                        handleServiceList(value);
                        setIsPostGenerated(false);
                      }}
                      disabled={isEdit}
                    >
                      <option value="">Select Category</option>
                      {isValidArray(categoryList) &&
                        categoryList?.map((category) => (
                          <option key={category?.id} value={category?.id}>
                            {category?.category_name} (
                            {category?.services_count})
                          </option>
                        ))}
                    </select>
                    <ErrorMessage
                      component="div"
                      className="field-error text-red-500"
                      name="categoryId"
                    />
                  </div>

                  {/* Generate Posts Button */}
                  {!isEdit && (
                    <div
                      className="relative w-56"
                      title={isPostGenerated ? "Post Generated" : ""}
                    >
                      <SpinnerButton
                        title={
                          isPostGenerated ? "Post Generated" : "Generate Posts"
                        }
                        className={classNames(
                          "btn-pink rounded-xs w-full !py-[9px]",
                          {
                            "cursor-default pointer-events-none":
                              isPostGenerated,
                          }
                        )}
                        action={() => {
                          !isPostGenerated && setGeneratePostLoading(true),
                            handleGeneratePost(serviceList, ctaArray);
                        }}
                        loading={generatePostLoading}
                        isDisable={!values?.categoryId}
                      />
                      {!isPostGenerated && values?.categoryId && (
                        <>
                          <div className="animate-pulse h-3 w-3 rounded-xl absolute -top-[5px] -right-[5px] border-green-600 bg-green-600"></div>
                          <div className="animate-ping  h-3 w-3 rounded-xl absolute -top-[5px] -right-[5px] border-green-600 bg-green-600"></div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Post Content Editor */}
              {!!isEdit && (
                <div className="w-full px-3 mt-2">
                  <label htmlFor="writePost" className="input-field-label">
                    Type POST Content
                  </label>
                  <div className="w-full flex items-center gap-x-2 mb-1 mt-2">
                    <div className="w-full">
                      <div>
                        <PostContentEditor
                          value={
                            generatedPost[currentPostContentIndex]
                              ?.description ?? ""
                          }
                          handleOnChange={(value) => {
                            setGeneratedPost((prev) => {
                              const updated = [...prev];
                              updated[currentPostContentIndex] = {
                                ...updated[currentPostContentIndex],
                                description: value,
                              };
                              return updated;
                            });
                          }}
                        />
                      </div>
                      <div className="w-full h-[2px] bg-gray-400 my-3"></div>
                    </div>
                  </div>
                </div>
              )}

              {/* Call To Action Buttons */}
              <div className="w-full px-3 my-3">
                <label
                  htmlFor="post_content"
                  className="text-base font-semibold text-gray-700 mb-3"
                >
                  Select Google Business Profile options
                </label>
                <div className="w-full my-3">
                  <label htmlFor="callToAction" className="input-field-label">
                    Call To Action Button
                  </label>
                  <FieldArray name="callToAction">
                    {({ insert, remove, push }) => (
                      <>
                        {values?.callToAction?.map((item, index) => (
                          <div
                            className="flex items-center gap-x-2 mb-1 mt-2 relative"
                            key={item.id || index}
                          >
                            <div className="flex gap-x-2 w-full">
                              <div className="w-[40%]">
                                <DropdownCommon
                                  className={"custom-input-field"}
                                  name={`callToAction.${index}.cta_btn_text`}
                                  values={item?.cta_btn_text ?? ""}
                                  setFieldValue={(name, value) => {
                                    setFieldValue(name, value);
                                    setFieldTouched(
                                      `callToAction.${index}.cta_btn_text`,
                                      true
                                    );

                                    setCTAArray((prev) => {
                                      const updated = [...prev];
                                      updated[index] = {
                                        ...updated[index],
                                        cta_btn_text: value,
                                      };
                                      return updated;
                                    });
                                  }}
                                  setFieldTouched={setFieldTouched}
                                  options={callToActionOptions}
                                  placeholder={"Select Action Button"}
                                />
                                <ErrorMessage
                                  name={`callToAction.${index}.cta_btn_text`}
                                  component="div"
                                  className="field-error text-red-500"
                                />
                              </div>
                              <div className="w-[95%] pr-8">
                                <input
                                  type="url"
                                  name={`callToAction.${index}.cta_btn_url`}
                                  id={`callToAction.${index}.cta_btn_url`}
                                  value={item?.cta_btn_url ?? ""}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setCTAArray((prev) => {
                                      const updated = [...prev];
                                      updated[index] = {
                                        ...updated[index],
                                        cta_btn_url: e.target.value,
                                      };
                                      return updated;
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="Enter Action URL"
                                  className="input-field"
                                />
                                <ErrorMessage
                                  name={`callToAction.${index}.cta_btn_url`}
                                  component="div"
                                  className="field-error text-red-500"
                                />
                              </div>
                            </div>
                            {index > 0 && (
                              <div className="w-[10%] absolute -right-[52px]">
                                <button
                                  type="button"
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    setGeneratePostLoading(true);
                                    remove(index);
                                    setCTAArray((prev) =>
                                      prev.filter((_, i) => i !== index)
                                    );
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="Delete Icon"
                                    className="w-5 h-5"
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                        {values?.callToAction?.length < 5 && (
                          <button
                            type="button"
                            onClick={() => {
                              push({ cta_btn_text: "", cta_btn_url: "" });
                              setCTAArray((prev) => [
                                ...prev,
                                { cta_btn_text: "", cta_btn_url: "" },
                              ]);
                            }}
                          >
                            <span className="flex justify-center items-center gap-x-1 text-sm text-gray-700 font-semibold">
                              Add More
                              <img
                                src={plusIcon}
                                alt="plus icon"
                                className="w-4 h-4"
                              />
                            </span>
                          </button>
                        )}
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>

            {/* Submit Buttons */}
            <div className="form-submit-buttons w-full flex justify-between items-end mt-2">
              <button
                type="button"
                onClick={() => {
                  removeDataLocalStorage("CAMPAIGN_POST_DATA");
                  navigate(
                    `/marketing?tab=${
                      isValidObject(location?.state)
                        ? location?.state?.path
                        : location?.state
                    }`
                  );
                }}
                className="btn btn-gray"
              >
                Back
              </button>
              <div className="flex gap-2">
                <button
                  type="button"
                  className="text-sm py-2 px-7 text-lime-500 border border-lime-500 w-30 shadow-sm shadow-gray-300 disabled:cursor-not-allowed disabled:opacity-55"
                  onClick={() => {
                    setSaveAsDraft(true);
                    handleSubmit(values);
                  }}
                  disabled={addCampaignPostLoading || updateCamapignPostLoading}
                >
                  {addCampaignPostLoading || updateCamapignPostLoading ? (
                    <span className="h-[20px] flex justify-center items-center">
                      <PulseLoader color="#8bc34a" size={12} />
                    </span>
                  ) : (
                    <span className="h-[20px] flex justify-center items-center">
                      Save Draft
                    </span>
                  )}
                </button>
                <SpinnerButton
                  className="btn-green"
                  title={"Next"}
                  type={"submit"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
