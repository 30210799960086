import ItemModal from "components/location/menu/ItemModal";
import MenuItemList from "components/location/menu/MenuItemList";
import SectionModal from "components/location/menu/SectionModal";
import {
  getCompanyStatus,
  isValidArray,
  isValidObject,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deleteSection } from "redux/gmbLocation/deleteSectionSlice";
import { getSectionDetail } from "redux/gmbLocation/getSectionDetailSlice";
import { getSectionList } from "redux/gmbLocation/getSectionListSlice";
import { updateSection } from "redux/gmbLocation/updateSectionSlice";
import SectionForm from "./SectionForm";
import SectionList from "./SectionList";

export default function SectionManagement({
  currentCompanyId = 0,
  currentLocationId = 0,
  menuPriceShow = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const [sectionBasicDetails, setSectionBasicDetails] = useState({
    section_name: "",
    section_description: "",
  });

  const [addItemModalOpen, setAddItemModalOpen] = useState(false);

  const [idForDelete, setIdForDelete] = useState(0);
  const [sectionIdForEdit, setSectionIdForEdit] = useState(0);
  const [itemsList, setItemList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const menuId = !!searchParams?.get("menu") ? searchParams?.get("menu") : "";
  const sectionId = !!searchParams?.get("sectionId")
    ? searchParams?.get("sectionId")
    : "";
  const isCompanyActive = ["active"].includes(getCompanyStatus());

  const sectionListLoading = useSelector(
    (state) => state?.getSectionList?.loading ?? false,
    shallowEqual
  );

  const sectionUpdateLoading = useSelector(
    (state) => state?.updateSection?.loading ?? false,
    shallowEqual
  );

  const sectionDeleteLoading = useSelector(
    (state) => state?.deleteSection?.loading ?? false,
    shallowEqual
  );

  // Section CRUD's Functions
  const handleGetSectionList = (isDelete = false) => {
    const formData = {
      menu_id: menuId,
      location_id: currentLocationId,
    };
    dispatch(getSectionList(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const currentSectionId = data?.payload?.dataObj?.data[0]?.id ?? 0;
        const sectionListData = data?.payload?.dataObj?.data ?? [];
        const finalId = sectionId
          ? isDelete
            ? currentSectionId
            : sectionId
          : currentSectionId;
        setSearchParams({ tab: "menu", menu: menuId, sectionId: finalId });
        const sectionList = isValidArray(sectionListData)
          ? [...sectionListData].sort((a, b) => a.order - b.order)
          : [];
        setSectionList(sectionList);
        handleSectionDetail(finalId);
      }
    });
  };

  const handleSectionDetail = (id) => {
    const formData = {
      id: id,
      location_id: currentLocationId,
    };
    dispatch(getSectionDetail(formData)).then((data) => {
      const dataObj = data?.payload?.dataObj?.data;
      if (data?.payload?.code == 200) {
        setSectionData({
          id: dataObj?.id ?? 0,
          menu_id: dataObj?.menu_id ?? 0,
          section_name: dataObj?.section_name ?? 0,
          section_description: dataObj?.section_description ?? "",
        });
        setSectionBasicDetails({
          id: dataObj?.id ?? 0,
          menu_id: dataObj?.menu_id ?? 0,
          section_name: dataObj?.section_name ?? "",
          section_description: dataObj?.section_description ?? "",
        });
      }
    });
  };

  const handleUpdateSection = () => {
    dispatch(updateSection(sectionBasicDetails)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg( data?.payload?.dataObj?.message ??
          data?.payload?.message ?? "Section updated succesfully."
        );
        handleGetSectionList();
      } else {
        handleServerValidation(data);
      }
    });
  };

  // const handleDeleteSection = () => {
  //   dispatch(deleteSection(idForDelete)).then((data) => {
  //     if (data?.payload?.code == 200) {
  //       showSuccessMsg(
  //         data?.payload?.message ?? "Section deleted successfully."
  //       );

  //       setSearchParams({ tab: "menu", menu: menuId });
  //       handleGetSectionList(true);
  //       setSectionData({});
  //       setItemList([]);
  //     } else {
  //       handleServerValidation(data);
  //     }
  //   });
  // };

  const areAnyLoadingsTrue = (...loadings) => {
    return loadings.some((loading) => loading);
  };

  useEffect(() => {
    if (menuId) {
      handleGetSectionList();
    }
  }, []);

  return (
    <div className="">
      <div className="w-full min-w-xl border-2 border-gray-400 bg-white flex">
        <SectionList
          sectionData={sectionData}
          itemsList={sectionList}
          setItemsList={setSectionList}
          handleSectionDetail={handleSectionDetail}
          menuId={menuId}
          idForDelete={idForDelete}
          setIdForDelete={setIdForDelete}
          setSectionData={setSectionData}
          handleGetSectionList={handleGetSectionList}
          setItemList={setItemList}
          sectionDeleteLoading={sectionDeleteLoading}
          setIsSectionModalOpen={setIsSectionModalOpen}
          sectionListLoading={sectionListLoading}
          setSectionIdForEdit={setSectionIdForEdit}
        />

        <div className="w-full">
          {isValidObject(sectionData) && (
            <SectionForm
              sectionBasicDetails={sectionBasicDetails}
              setSectionBasicDetails={setSectionBasicDetails}
              isCompanyActive={isCompanyActive}
              handleUpdateSection={handleUpdateSection}
              sectionData={sectionData}
              setAddItemModalOpen={setAddItemModalOpen}
            />
          )}
          <div className="p-0.5">
            <MenuItemList
              sectionData={sectionData}
              sectionId={sectionId}
              setionList={sectionList}
              setAddItemModalOpen={setAddItemModalOpen}
              itemsList={itemsList}
              setItemList={setItemList}
              handleGetSectionList={handleGetSectionList}
              menuPriceShow={menuPriceShow}
            />
          </div>
        </div>
      </div>
      {isSectionModalOpen && (
        <SectionModal
          setModalOpen={setIsSectionModalOpen}
          isModalOpen={isSectionModalOpen}
          currentCompanyId={currentCompanyId}
          currentLocationId={currentLocationId}
          menuId={menuId}
          setSectionIdForEdit={setSectionIdForEdit}
          handleAction={handleGetSectionList}
          sectionBasicDetails={sectionBasicDetails}
          setSectionBasicDetails={setSectionBasicDetails}
          isCompanyActive={isCompanyActive}
          handleUpdateSection={handleUpdateSection}
          sectionIdForEdit={sectionIdForEdit}
        />
      )}
      {/* {areAnyLoadingsTrue(
        sectionListLoading,
        sectionUpdateLoading,
        sectionDeleteLoading
      ) && (
        <GuestWifiLoader
          isModalOpen={areAnyLoadingsTrue(
            sectionListLoading,
            sectionUpdateLoading,
            sectionDeleteLoading
          )}
          message="Please wait a while, Fetching Details..."
        />
      )} */}
      {addItemModalOpen && (
        <ItemModal
          isModalOpen={addItemModalOpen}
          setModalOpen={setAddItemModalOpen}
          sectionList={sectionList}
          handleSectionDetail={handleSectionDetail}
          sectionId={sectionId}
          handleGetSectionList={handleGetSectionList}
        />
      )}
    </div>
  );
}
