import ConfirmationModal from "components/common/ConfirmationModal";
import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import SwitchButton from "components/common/fields/SwitchButton";
import MenuList from "components/location/menu/MenuList";
import MenuModal from "components/location/menu/MenuModal";
import SectionManagement from "components/location/menu/SectionManagement";
import {
  getAuthToken,
  getCompanyStatus,
  getCurrentLocation,
  getUserDataByParam,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deleteMenu } from "redux/gmbLocation/deleteMenuSlice";
import { getMenuDetail } from "redux/gmbLocation/getMenuDetailSlice";
import { getMenuFromGoogle } from "redux/gmbLocation/getMenuFromGoogleSlice";
import { getMenuList } from "redux/gmbLocation/getMenuListSlice";
import { getMenuPricingVisibility } from "redux/gmbLocation/getMenuPricingVisibilitySlice";
import { menuPricingToggle } from "redux/gmbLocation/menuPricingToggleSlice";
import { postToGoogle } from "redux/gmbLocation/postToGoogleSlice";
import { checkExpireToken } from "redux/reputation/checkExpireTokenSlice";

export default function Menu({
  getNextUpdate = () => {},
  // isGoogleConnected = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hoverOnMenu, setHoverOnMenu] = useState(0);
  const [menuId, setMenuId] = useState(0);
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isBackToMenuModalOpen, setIsBackToMenuModalOpen] = useState(false);
  const [isPostToGoogleModal, setPostToGoogleModal] = useState(false);
  const [isGetMenuFromGoogleModal, setIsGetMenuFromGoogleModal] =
    useState(false);
  const [itemsList, setItemList] = useState([]);
  const [menuPriceShow, setMenuPriceShow] = useState(false);
  const isCompanyActive = ["active"].includes(getCompanyStatus());
  const currentCompanyId = getUserDataByParam("COMPANY_ID");
  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();
  const menu = !!searchParams?.get("menu") ? searchParams?.get("menu") : "";

  const MenuOrService =
    google_business_type === "Restaurant" ? "Menu" : "Service";
  // Will be use when centralize Menu/Service Module

  const menuListLoading = useSelector(
    (state) => state?.getMenuList?.loading ?? false
  );
  const deleteMenuLoading = useSelector(
    (state) => state?.deleteMenu?.loading ?? false
  );
  const postToGoogleLoading = useSelector(
    (state) => state?.postToGoogle?.loading ?? false
  );
  const menuDetails = useSelector(
    (state) => state?.getMenuDetail?.data?.dataObj ?? {}
  );
  const getMenuFromGoogleLoading = useSelector(
    (state) => state?.getMenuFromGoogle?.loading ?? false
  );

  const handleGetmenuList = () => {
    const formData = {
      location_id: currentLocationId,
    };
    dispatch(getMenuList(formData)).then((data) => {
      if (isValidArray(data?.payload?.dataObj?.data)) {
        const listData = data?.payload?.dataObj?.data ?? [];
        const menuListData = isValidArray(listData)
          ? [...listData].sort((a, b) => a.order - b.order)
          : [];
        setItemList(menuListData);
      } else {
        setItemList([]);
      }
    });
  };

  const handleDeleteMenu = () => {
    dispatch(deleteMenu({ id: menuId })).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Menu deleted successfully."
        );
        handleGetmenuList();
      } else {
        handleServerValidation(data);
      }
    });
    setIsConfirmationModalOpen(false);
  };

  const handleGetMenuDetails = () => {
    const formData = {
      id: menu,
      location_id: currentLocationId,
    };
    dispatch(getMenuDetail(formData));
  };

  const handleBackToMenuList = () => {
    navigate("/location?tab=menu");
    setHoverOnMenu(0);
    localStorage.removeItem("SECTIONS");
    setIsBackToMenuModalOpen(false);
  };

  const handleSentToGoogle = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: currentCompanyId,
    };
    dispatch(postToGoogle(formData)).then((data) => {
      if (data?.payload?.code === 200) {
        if (data?.payload?.dataObj?.type == "success") {
          getNextUpdate();
          showSuccessMsg(
            data?.payload?.dataObj?.message ??
              data?.payload?.dataObj?.message ??
              "Menu POST to google is successful"
          );
        } else {
          handleServerValidation(data);
        }
        setPostToGoogleModal(false);
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleUpdateMenuPricingToggle = (isPricingShow) => {
    const formData = {
      company_id: currentCompanyId,
      location_id: currentLocationId,
      is_price_show: isPricingShow,
    };
    dispatch(menuPricingToggle(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ?? data?.payload?.message
        );
        handleGetMenuPricingVisibility();
      } else {
        handleServerValidation(data);
      }
    });
  };

  const handleGetMenuPricingVisibility = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: currentCompanyId,
    };
    dispatch(getMenuPricingVisibility(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const pricing = data?.payload?.dataObj?.data?.is_price_show ?? "yes";
        const isPricingShow =
          pricing === "yes" ? true : pricing === "no" ? false : true;
        setMenuPriceShow(isPricingShow);
      }
    });
  };

  useEffect(() => {
    if (menu) {
      handleGetMenuDetails();
    } else {
      handleGetmenuList();
      handleGetMenuPricingVisibility();
    }
  }, [currentLocationId, menu]);

  const handleGetMenuFromGoogle = () => {
    const formData = {
      location_id: currentLocationId,
      company_id: currentCompanyId,
    };
    dispatch(getMenuFromGoogle(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        handleGetmenuList();
        setIsGetMenuFromGoogleModal(false);
        showSuccessMsg(`GMB menus fetched successfully.`);
      } else {
        handleServerValidation(data);
        setIsGetMenuFromGoogleModal(false);
      }
    });
  };

  const findAllMenusWithZeroSections = (itemsList) => {
    const menusWithZeroSections = itemsList
      ?.filter(
        (item) =>
          item?.sections_count === 0 ||
          (item?.sections_count > 0 && item?.food_items_count === 0)
      )
      ?.map((item) => item?.menu_name);

    return menusWithZeroSections?.length > 0 ? menusWithZeroSections : [];
  };

  // useEffect(() => {
  //   if (!isGoogleConnected) {
  //     navigate("/location");
  //     showErrorMsg("Google is not connected.");
  //     return;
  //   }
  // }, [isGoogleConnected]);

  useEffect(() => {
    const fetchData = () => {
      let data = {
        location_id: currentLocationId,
        company_id: currentCompanyId,
        access_token: getAuthToken(),
      };
      dispatch(checkExpireToken(data)).then((data) => {
        const isGoogleConnected = data?.payload?.dataObj?.status;
        if (!isGoogleConnected) {
          navigate("/location");
          showErrorMsg("Google is not connected.");
          return;
        }
      });
    };
    fetchData();

    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="">
      <ShowPageTitle title="locationMenu" />
      <div className={`flex items-center my-2 justify-between`}>
        {menu ? (
          <button
            type="button"
            className="btn btn-light-gray w-20"
            onClick={() => {
              navigate("/location?tab=menu");
            }}
          >
            Back
          </button>
        ) : (
          <div className="flex items-center">
            <span className="text-md leading-6 font-light text-gray-900 w-32">
              {"SHOW PRICING"}
            </span>
            <SwitchButton
              value={menuPriceShow}
              handleToggleForCampaigns={(values, id) => {
                setMenuPriceShow(values);
                const isPriceShow = values ? "yes" : "no";
                handleUpdateMenuPricingToggle(isPriceShow);
              }}
            />
          </div>
        )}
        <div>
          {isCompanyActive && !menu && (
            <>
              <SpinnerButton
                className="btn-pink w-28"
                title={`Add Menu`}
                action={() => {
                  setMenuId("");
                  setIsMenuModalOpen(true);
                }}
                type={"button"}
              />
              <SpinnerButton
                className="btn-pink w-28 ml-2"
                title={`Get Menu`}
                action={() => setIsGetMenuFromGoogleModal(true)}
                type={"button"}
              />
              <SpinnerButton
                className="btn-pink w-28 ml-2"
                title={`Post Menu`}
                action={() => setPostToGoogleModal(true)}
                type={"button"}
              />
            </>
          )}
        </div>
      </div>

      {menu ? (
        <>
          <div className="my-2 w-full min-w-xl border-2 border-gray-400 py-3 px-8 bg-white flex justify-between">
            <div className="flex items-center w-[30%]">
              <label
                className="font-light leading-6 text-gray-900 w-40"
                htmlFor="tag"
              >
                Menu Name :
              </label>
              <div className="w-full">
                <input
                  id="menuName"
                  name="menuName"
                  type="text"
                  className={`input-field font-medium`}
                  value={menuDetails?.menu_name ?? ""}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex items-center">
              <label
                className="font-light leading-6 text-gray-900 w-32"
                htmlFor="tag"
              >
                Visibility :
              </label>
              <div className="w-full">
                <input
                  className={`input-field font-medium`}
                  id="visibility"
                  name="visibility"
                  type="text"
                  value={
                    menuDetails?.menu_visibility == "yes"
                      ? "Visible"
                      : menuDetails?.menu_visibility == "no"
                      ? "Hidden"
                      : ""
                  }
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          <SectionManagement
            currentCompanyId={currentCompanyId}
            currentLocationId={currentLocationId}
            menuPriceShow={menuPriceShow}
          />
        </>
      ) : (
        <MenuList
          menuListLoading={menuListLoading}
          setHoverOnMenu={setHoverOnMenu}
          hoverOnMenu={hoverOnMenu}
          setMenuId={setMenuId}
          setIsMenuModalOpen={setIsMenuModalOpen}
          google_business_type={google_business_type}
          handleDeleteMenu={handleDeleteMenu}
          deleteMenuLoading={deleteMenuLoading}
          itemsList={itemsList}
          setItemList={setItemList}
        />
      )}
      {isMenuModalOpen && (
        <MenuModal
          isModalOpen={isMenuModalOpen}
          setModalOpen={setIsMenuModalOpen}
          currentCompanyId={currentCompanyId}
          currentLocationId={currentLocationId}
          handleAction={() => handleGetmenuList()}
          menuId={menuId}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          setModalOpen={setIsConfirmationModalOpen}
          handleAction={() => handleDeleteMenu()}
          isLoading={deleteMenuLoading}
          message={"You want to delete this menu?"}
        />
      )}
      {isBackToMenuModalOpen && (
        <ConfirmationModal
          isModalOpen={isBackToMenuModalOpen}
          setModalOpen={setIsBackToMenuModalOpen}
          handleAction={() => handleBackToMenuList()}
          message={
            "Please click on post menu button to push the changes you have done so far."
          }
        />
      )}
      {isPostToGoogleModal && (
        <ConfirmationModal
          isModalOpen={isPostToGoogleModal}
          setModalOpen={setPostToGoogleModal}
          handleAction={() => {
            isValidArray(findAllMenusWithZeroSections(itemsList))
              ? setPostToGoogleModal(false)
              : handleSentToGoogle();
          }}
          message={
            isValidArray(findAllMenusWithZeroSections(itemsList))
              ? `There are no any sections or items in ${findAllMenusWithZeroSections(
                  itemsList
                ).join(", ")}, Please add section and items.`
              : `This will overwrite ALL Menu data in Google with the data from Pendio.`
          }
          isLoading={postToGoogleLoading}
        />
      )}
      {isGetMenuFromGoogleModal && (
        <ConfirmationModal
          isModalOpen={isGetMenuFromGoogleModal}
          setModalOpen={setIsGetMenuFromGoogleModal}
          handleAction={() => handleGetMenuFromGoogle()}
          message={`This will overwrite ALL Menu data in Pendio with the data from Googe.`}
          isLoading={getMenuFromGoogleLoading}
        />
      )}
    </div>
  );
}
