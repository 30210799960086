import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getMenuFromGoogle = createAsyncThunk(
  "gmb/get-gmb-menu",
  async (formData, { rejectWithValue }) => {
    const { location_id, company_id } = formData;
    try {
      const response = await getApi(
        `api/get-gmb-menu?location_id=${location_id}&company_id=${company_id}`
      );
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getMenuFromGoogleSlice = createSlice({
  name: "getMenuFromGoogle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenuFromGoogle.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMenuFromGoogle.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getMenuFromGoogle.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getMenuFromGoogleSlice.reducer;
