import eyeIcon from "assets/images/eyeIcon.svg";
import eyeSlashIcon from "assets/images/eyeSlashIcon.svg";
import Logo from "assets/images/logo-pendio.png";
import ShowPageTitle from "components/common/ShowPageTitle";
import SpinnerButton from "components/common/fields/SpinnerButton";
import { Field, Form, Formik } from "formik";
import { setCompanyStatus, showErrorMsg, showSuccessMsg } from "module/util";
import { loginForm, showError } from "module/validation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "redux/front/loginSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPasswordShow, setPasswordShow] = useState(false);

  const isLoading = useSelector((state) => state?.login?.loading ?? false);
  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  const togglePasswordShow = () => setPasswordShow(!isPasswordShow);

  const handleSubmit = (values) => {
    dispatch(loginUser(values))
      .then((data) => {
        if (data?.payload?.code === 200) {
          if (data?.payload?.dataObj?.user_code === 1004) {
            setCompanyStatus(data?.payload?.dataObj?.company_status);
            navigate("/account");
          } else {
            navigate("/dashboard");
          }
          showSuccessMsg("You are logged in successfully");
        } else {
          const errorMsg =
            data?.payload?.dataObj?.message ?? "Something went wrong";
          showErrorMsg(errorMsg);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="flex flex-col justify-center py-12 px-8">
      <ShowPageTitle title="login" />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-30 w-auto" src={Logo} alt="Your Company" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow shadow-gray-400 sm:px-12">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={() => loginForm(validationMsgObj?.login ?? {})}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              errors,
              touched,
              validateField,
              validateForm,
              handleChange,
              values,
              handleSubmit,
              handleBlur,
            }) => (
              <Form
                className="space-y-6"
                onSubmit={handleSubmit}
                // onKeyDown={(e) => {
                //   e.key == "Enter" && e.preventDefault();
                // }}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      autoComplete="email"
                      value={values?.email ?? ""}
                      onChange={handleChange}
                      placeholder="Enter Email"
                      className="input-field"
                    />
                  </div>
                  {touched.email && showError(errors.email)}
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="relative mt-2">
                    <Field
                      id="password"
                      name="password"
                      type={isPasswordShow ? "text" : "password"}
                      autoComplete="current-password"
                      value={values?.password ?? ""}
                      onChange={handleChange}
                      placeholder="Enter Password"
                      className="input-field pr-10"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <img
                        alt="eye"
                        src={isPasswordShow ? eyeIcon : eyeSlashIcon}
                        className="hover:cursor-pointer"
                        onClick={() => togglePasswordShow()}
                      />
                    </div>
                  </div>
                  {touched.password && showError(errors.password)}
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-0 cursor-pointer"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <Link
                      to="/reset-password"
                      className="font-semibold text-pink-600 hover:text-pink-500"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>

                <div>
                  <SpinnerButton
                    className="flex w-full justify-center btn-pink"
                    title={"Sign in"}
                    action={() => {}}
                    type={"submit"}
                    loading={isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <div></div>
        </div>

        <p className="mt-1 text-center text-sm text-gray-500">
          Not a member?{" "}
          <Link
            to="/register"
            className="font-semibold leading-6 text-pink-600 hover:text-pink-500"
          >
            Register
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
