import { ClockIcon } from "@heroicons/react/24/outline";
import {
  convertDateStringIntoTime,
  convertTimeIntoDateString,
} from "module/util";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

export default function CustomTimeInput({
  value = "",
  handleChange = () => {},
  handleBlur = () => {},
  timeFormat24Hr = false,
  timeIntervals = 5,
  placeholder = "Select Time",
  isDisabled = false,
}) {
  const [selectedTime, setSelectedTime] = useState("");
  const [openPicker, setOpenPicker] = useState(false);

  const timeFormat = timeFormat24Hr ? "HH:mm" : "hh:mm a";
  const initialTimeDate = convertTimeIntoDateString(value);

  const handleTimeChange = (timeString) => {
    setSelectedTime(timeString);
    const time = convertDateStringIntoTime(timeString, "12Hr");
    handleChange(time);
  };

  useEffect(() => {
    setSelectedTime(initialTimeDate);
  }, [value]);

  return (
    <div className="w-full relative">
      <ReactDatePicker
        selected={selectedTime ?? ""}
        onChange={handleTimeChange}
        onBlur={handleBlur}
        open={openPicker}
        onInputClick={() => setOpenPicker(true)}
        onClickOutside={() => {
          openPicker && handleBlur(), setOpenPicker(false);
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals}
        timeCaption="Time"
        dateFormat={timeFormat}
        timeFormat={timeFormat}
        className="input-field w-full"
        placeholderText={placeholder}
        disabled={isDisabled}
      />
      <button
        type="button"
        className="absolute bottom-[9px] right-2 text-gray-700"
        onClick={() => setOpenPicker(true)}
        disabled={isDisabled}
      >
        <ClockIcon className="w-5 h-5" />
      </button>
    </div>
  );
}
