import classNames from "classnames";
import ShowPageTitle from "components/common/ShowPageTitle";
import PostFormMain from "components/marketing/posts/PostFormMain";
import ServicePost from "components/marketing/posts/servicePosts/ServicePost";
import {
  getCompanyStatus,
  getCurrentLocation,
  isCompanyUser,
  removeDataLocalStorage,
} from "module/util";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Posts() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const companyStatus = getCompanyStatus();
  const type = searchParams.get("type") ? searchParams.get("type") : "";
  const form = searchParams.get("form") ? searchParams.get("form") : "";
  const { id: currentLocationId, google_business_type = "service" } =
    getCurrentLocation();

  return (
    <>
      {!!type && !!form ? (
        google_business_type === "Restaurant" ? (
          <PostFormMain />
        ) : (
          <ServicePost />
        )
      ) : (
        <div
          className={classNames("w-full p-3 h-[calc(100vh-200px)] mt-4", {
            "pointer-events-none opacity-50":
              isCompanyUser() && companyStatus !== "active",
          })}
        >
          <ShowPageTitle title={`posts`} />
          <div className="flex flex-col sm:flex-row justify-center items-center  gap-6 mt-[10%]">
            <div
              onClick={() => {
                // if (isGoogleConnected) {
                // removeDataLocalStorage("CAMPAIGN_POST_DATA");
                navigate(`/marketing?tab=posts&type=single&form=post-details`);
                // }
              }}
              className={classNames(
                "compliance-ada max-w-[200px] w-full min-h-[150px] flex justify-center items-center cursor-pointer text-center p-3",
                {
                  active: false,
                }
              )}
            >
              <p className="font-bold">Create Post</p>

              <p></p>
            </div>
            <div
              onClick={() => {
                // if (isGoogleConnected) {
                // removeDataLocalStorage("CAMPAIGN_POST_DATA");
                navigate(`/marketing?tab=posts&type=multi&form=post-details`);
                // }
              }}
              className={classNames(
                "free-wifi max-w-[200px] w-full min-h-[150px] flex justify-center items-center cursor-pointer text-center p-3",
                {
                  active: false,
                }
              )}
            >
              <p className="font-bold">Create Campaign</p>

              <p></p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
