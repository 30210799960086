import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi, postApi } from "module/api";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getActionLinkFromGoogle = createAsyncThunk(
  "gmb/get-action-link-from-google",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await postApi(`api/get-action-link`, formData);
      return response?.data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getActionLinkFromGoogleSlice = createSlice({
  name: "getActionLinkFromGoogle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActionLinkFromGoogle.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getActionLinkFromGoogle.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
    builder.addCase(getActionLinkFromGoogle.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default getActionLinkFromGoogleSlice.reducer;
