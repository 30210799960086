import successIcon from "assets/images/successIcon.svg";
import classNames from "classnames";
import SpinnerButton from "components/common/fields/SpinnerButton";
import GuestWifiAuthForm from "components/front/GuestWifiAuthForm";
import { revertManipulatedOTP, showErrorMsg } from "module/util";
import moment from "moment";
import { useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const GuestWifiOtpForm = ({
  isPreview = true,
  guestId = 0,
  companyId = "",
  currentLocationId = 0,
  queryParams = {},
  isLoading = false,
  setLoading = () => {},
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const vCode = revertManipulatedOTP(searchParams.get("vcode") ?? 0);
  const sessionTimeout = searchParams.get("sessionTimeout") ?? "3600";
  const sessionDelay = searchParams.get("sessionDelay") ?? "5000";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const [otp, setOtp] = useState("");
  const [isOtpVerified, setOtpVerified] = useState(false);

  const handleChange = (code) => setOtp(code);

  const isOtpValid = otp?.length === 6;

  const verifyWifiDataLoading = useSelector(
    (state) => state?.verifyOtpForWIfi?.loading ?? false
  );

  const handleSubmit = () => {
    const newQueryParam = {};
    for (const key in queryParams) {
      newQueryParam[key] = decodeURIComponent(queryParams[key]);
    }

    if (isOtpValid) {
      if (vCode === otp) {
        setOtpVerified(true);
        setLoading(true);
        const setTime = moment();
        localStorage?.setItem("SESSION_START", setTime);
        // localStorage.setItem("USER_REGISTERED", "registered");

        setTimeout(() => {
          formRef.current["session_timeout"].value = sessionTimeout;
          formRef.current.submit();
        }, sessionDelay);
      } else {
        showErrorMsg("Invalid OTP");
      }
      // const formData = {
      //   guest_id: guestId,
      //   security_code: otp,
      //   ...newQueryParam,
      // };

      // dispatch(verifyOtpForWIfi(formData)).then((data) => {
      //   if (data?.payload.code === 200) {
      //     setOtpVerified(true);
      //   } else {
      //     handleServerValidation(data);
      //   }
      // });
    }
  };

  return (
    <div className="w-full min-w-xl min-h-[70px]">
      <GuestWifiAuthForm queryParams={queryParams} formRef={formRef} />
      {isOtpVerified ? (
        <div className="flex justify-center items-center  gap-x-2  mt-5 mb-4">
          <span>
            <img src={successIcon} className=" h-10 w-10" alt="success" />
          </span>
          OTP Verified.
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center">
            <label
              htmlFor="remember-me"
              className="ml-3 block text-md   leading-6 text-gray-900"
            >
              We have sent a verification code to registered phone number.
            </label>
          </div>
          <div className="flex items-center justify-center mt-5 mb-8">
            <OTPInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              inputType="tel"
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "50px",
                // height: "50px",
                fontSize: "18px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                marginLeft: "5px",
                padding: "0px",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
              renderInput={(props) => (
                <input {...props} className="h-[40px] sm:h-[50px]" />
              )}
            />
          </div>
          <div className="flex justify-center relative">
            <SpinnerButton
              className={classNames(
                "text-2xl font-bold py-3 px-4 border-2 border-white bg-blue-400 text-white shadow-sm shadow-gray-300  absolute -top-6  rounded-full",
                {
                  "hover:bg-blue-300": !isPreview,
                  "cursor-not-allowed bg-blue-300 opacity-80": !isOtpValid,
                }
              )}
              title={">"}
              action={handleSubmit}
              isDisable={isPreview}
              type={"button"}
              loading={verifyWifiDataLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GuestWifiOtpForm;
