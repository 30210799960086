import { PlusIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import {
  getCurrentLocation,
  isValidArray,
  isValidObject,
  showSuccessMsg,
} from "module/util";
import { handleServerValidation } from "module/validation";
import { useCallback, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMenuItemList } from "redux/gmbLocation/getMenuItemListSlice";
import { reorderItem } from "redux/gmbLocation/reorderItemSlice";
import Items from "./Items";

export const ItemTypes = {
  CARD: "card",
};

export default function MenuItemList({
  sectionData = {},
  sectionId = 0,
  setionList = [],
  setAddItemModalOpen = false,
  itemsList = false,
  setItemList = () => {},
  handleGetSectionList = () => {},
  menuPriceShow = false,
}) {
  const dispatch = useDispatch();

  const { id: currentLocationId } = getCurrentLocation();

  const itemListLoading = useSelector(
    (state) => state?.getMenuItemList?.loading ?? false,
    shallowEqual
  );

  const handleItemList = () => {
    const formData = {
      id: sectionId,
      location_id: currentLocationId,
    };
    dispatch(getMenuItemList(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        const itemData = data?.payload?.dataObj?.data;

        const sortedItemList = isValidArray(itemData)
          ? [...itemData].sort((a, b) => a.order - b.order)
          : [];
        setItemList(sortedItemList);
      }
    });
  };

  useEffect(() => {
    if (isValidObject(sectionData)) {
      handleItemList();
    }
  }, [sectionData]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      setItemList((prevItems) => {
        const updatedItems = [...prevItems];
        const [draggedItem] = updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);

        // Reorder items based on their new position
        return updatedItems.map((item, index) => ({
          ...item,
          order: index,
        }));
      });
    },
    [sectionId]
  );

  const dropCard = useCallback(
    (dragIndex, hoverIndex) => {
      const updatedItems = [...itemsList];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);

      const finalData = updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      handleReOrderItem(finalData);
      // });
    },
    [sectionId, itemsList]
  );

  const handleReOrderItem = async (data) => {
    const formData = {
      data: data,
    };
    await dispatch(reorderItem(formData)).then((data) => {
      if (data?.payload?.code == 200) {
        showSuccessMsg(
          data?.payload?.dataObj?.message ??
            data?.payload?.message ??
            "Items re-ordered successfully."
        );
        handleItemList();
      } else {
        handleServerValidation(data);
      }
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={classNames(
          "h-[65vh] overflow-y-auto flex flex-col gap-1 scroll-smooth",
          {
            " opacity-60": itemListLoading,
          }
        )}
      >
        {itemListLoading ? (
          <div
            role="status"
            className={`h-[calc(100vh-365px)] w-full space-y-4 rounded animate-pulse`}
          >
            <div className="w-full">
              {[...Array(4)]?.map((_, menuIndex) => {
                return (
                  <div key={menuIndex} className="h-[122px]">
                    <div className="h-full bg-gray-300 m-2"></div>
                  </div>
                );
              })}
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        ) : isValidArray(itemsList) ? (
          <div className="w-full h-[calc(100vh-365px)]">
            {itemsList?.map((item, itemIndex) => (
              <Items
                key={item?.id}
                index={itemIndex}
                itemData={item}
                moveCard={moveCard}
                setionList={setionList}
                handleItemList={handleItemList}
                dropCard={dropCard}
                handleGetSectionList={handleGetSectionList}
                menuPriceShow={menuPriceShow}
              />
            ))}
          </div>
        ) : (
          <div className="h-[calc(100vh-310px)] bg-gray-100 flex justify-center items-center font-bold text-gray-800">
            {sectionId ? (
              <button
                className="text-pink-600 hover:text-pink-700 font-semibold mt-5 w-15 flex  justify-center items-center gap-1"
                onClick={() => setAddItemModalOpen(true)}
              >
                <PlusIcon className="w-5 h-5" />

                <span className="text-sm font-bold">Add Items</span>
              </button>
            ) : (
              <span className="text-sm font-bold">No Items</span>
            )}
          </div>
        )}
      </div>
    </DndProvider>
  );
}
