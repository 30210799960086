import dummyImage from "assets/images/previewImage.svg";
import { API_URL } from "module/api";
import { statesArray } from "module/data";
import moment from "moment-timezone";
import { Slide, toast } from "react-toastify";

export const showPerPage = 100;
export const MAP_API_KEY = API_URL?.includes("https")
  ? process.env.REACT_APP_MAP_API_KEY
  : "";
export const MAP_LIBRARIES = ["places", "maps", "visualization"];
export const HOST_URL = `${window.location.protocol}//${
  window.location.hostname
}${window.location.port ? ":" + window.location.port : ""}`;
export const defaultHeatMapConfig = {
  heatMapZoom: 13,
  mapZoom: 17,
  heatMapColor1: "#86b54b",
  heatMapColor2: "#fcbe29",
  heatMapColor3: "#d21e60",
};
export const systemTimeZone = "America/Los_Angeles";

export const getAuthToken = () => {
  return localStorage.getItem("AUTH_TOKEN") ?? "";
};

export const setAuthToken = (data) => {
  return localStorage.setItem("AUTH_TOKEN", data);
};

export const removeAuthToken = () => {
  return localStorage.removeItem("AUTH_TOKEN");
};

export const getUserData = () => {
  return localStorage.getItem("USER_DATA") ?? "";
};

export const setUserData = (data) => {
  return localStorage.setItem("USER_DATA", data);
};
export const removeUserData = () => {
  return localStorage.removeItem("USER_DATA");
};

export const getCompanyStatus = () =>
  localStorage.getItem("COMPANY_STATUS") ?? "";

export const setCompanyStatus = (data) =>
  localStorage.setItem("COMPANY_STATUS", data) ?? false;

export const removeCompanyStatus = () =>
  localStorage.removeItem("COMPANY_STATUS") ?? false;

export const getCurrentLocation = () => {
  const finalData = localStorage.getItem("CURRENT_LOCATION")
    ? JSON.parse(localStorage.getItem("CURRENT_LOCATION"))
    : "";
  return finalData;
};

export const setCurrentLocation = (data) => {
  const finalData = JSON.stringify(data);
  localStorage.setItem("CURRENT_LOCATION", finalData);
};

export const removeCurrentLocation = () => {
  localStorage.removeItem("CURRENT_LOCATION") ?? false;
};

export const getUserTempEmail = () => {
  return localStorage.getItem("USER_TEMP_EMAIL") ?? "";
};

export const setUserTempEmail = (data) => {
  return localStorage.setItem("USER_TEMP_EMAIL", data);
};
export const removeUserTempEmail = () => {
  return localStorage.removeItem("USER_TEMP_EMAIL");
};

export const getDateFormatLocal = () => {
  const dateFormateArray = ["MM/dd/yyyy", "dd/MM/yyyy", "yyyy/MM/dd"];
  const dateFormat = localStorage.getItem("DATE_FORMAT") ?? "";

  return dateFormat && dateFormateArray.includes(dateFormat) ? dateFormat : "";
};

export const setDateFormatLocal = (data) => {
  return localStorage.setItem("DATE_FORMAT", data);
};
export const removeDateFormatLocal = () => {
  return localStorage.removeItem("DATE_FORMAT");
};

export const getHeatMapConfig = () => {
  const data = localStorage.getItem("HEATMAP_CONFIG") ?? "";
  const finalData = !!data ? JSON.parse(data) : defaultHeatMapConfig;

  return finalData;
};

export const setDataLocalStorage = (key, data) => {
  const finalData =
    isValidArray(data) || isValidObject(data) ? JSON.stringify(data) : "";
  return localStorage.setItem(key, finalData);
};
export const removeDataLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const getDataLocalStorage = (key) => {
  const data = localStorage.getItem(key) ?? "";
  const finalData = !!data ? JSON.parse(data) : "";
  return finalData;
};

export const setHeatMapConfig = (data) => {
  return localStorage.setItem("HEATMAP_CONFIG", data);
};
export const removeHeatMapConfig = () => {
  return localStorage.removeItem("HEATMAP_CONFIG");
};

export const doEmptyLocalStorage = () => {
  removeAuthToken();
  removeUserData();
  removeCompanyStatus();
  removeCurrentLocation();
};

export const isValidArray = (data) => {
  return data && Array.isArray(data) && data.length > 0;
};

export const isValidObject = (data) => {
  return (
    typeof data === "object" && data !== null && Object.keys(data).length !== 0
  );
};

export const getUserRole = () => {
  const userData = getUserData();
  const userObj = userData ? JSON.parse(userData) : {};

  if (isValidObject(userObj)) {
    switch (userObj.user_code) {
      case 1000:
        return "SUPER_ADMIN";
      case 1001:
        return "ADMIN";
      case 1003:
        return "COMPANY_USER_SWITCHED";
      case 1004:
        return "COMPANY_USER";
      default:
        return "COMPANY_USER";
    }
  }
};

export const removeUserSuffix = (value = "") => {
  return value ? value.replace("User", "") : value;
};

export const getUserCode = () => {
  const userData = getUserData();
  const userObj = userData ? JSON.parse(userData) : {};
  return userObj.user_code;
};

export const getUserDataByParam = (value = "COMPANY_ID") => {
  const userData = getUserData();
  const userObj = userData ? JSON.parse(userData) : {};

  switch (value) {
    case "COMPANY_ID":
      return userObj?.company_id ?? "";
    case "COMPANY_NAME":
      return userObj?.company_name ?? "";
    case "USER_CODE":
      return userObj?.user_code ?? "";
    case "USER_EMAIL":
      return userObj?.email ?? "";
    case "USER_NAME":
      return userObj?.user_name ? removeUserSuffix(userObj?.user_name) : "";
    case "LOCATION_COUNT":
      return userObj?.location_count ?? "";
    default:
      return userObj?.company_id ?? "";
  }
};

export const isCompanyUser = () => {
  return (
    getUserRole() === "COMPANY_USER" ||
    getUserRole() === "COMPANY_USER_SWITCHED"
  );
};

export const isCompanyUserSwitched = () => {
  return getUserRole() === "COMPANY_USER_SWITCHED";
};

export const isCompanyUserLoggedIn = () => {
  return getUserRole() === "COMPANY_USER";
};

export const isSuperAdmin = () => {
  return getUserRole() === "SUPER_ADMIN" ?? false;
};

export const isAdmin = () => {
  return getUserRole() === "ADMIN" ?? false;
};

export const statusArray = () => {
  return [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "In Active",
      value: "inactive",
    },
  ];
};

export const genderArray = () => {
  return [
    {
      name: "Male",
      value: "male",
    },
    {
      name: "Female",
      value: "female",
    },
    {
      name: "Other",
      value: "other",
    },
  ];
};

export const maritalStatusArray = () => {
  return [
    {
      name: "Married",
      value: "married",
    },
    {
      name: "Un married",
      value: "unmarried",
    },
  ];
};

export const createQueryParams = ({
  search = "",
  sort = 0,
  order = 0,
  companyStatus = "",
  page = 1,
  perPage = showPerPage,
  company_id = "",
  location_id = "",
  type = "",
  with_location = false,
  customer_id = 0,
}) => {
  let queryString = "";

  if (companyStatus) {
    queryString += `${queryString ? "&" : "?"}filter_status=${
      companyStatus ?? ""
    }`;
  }
  if (type) {
    queryString += `${queryString ? "&" : "?"}type=${type ?? ""}`;
  }
  if (company_id) {
    queryString += `${queryString ? "&" : "?"}company_id=${
      company_id ?? company_id
    }`;
  }
  if (location_id) {
    queryString += `${queryString ? "&" : "?"}location_id=${
      location_id ?? location_id
    }`;
  }
  if (customer_id) {
    queryString += `${queryString ? "&" : "?"}customer_id=${customer_id}`;
  }

  if (search) {
    queryString += `${queryString ? "&" : "?"}search=${search ?? ""}`;
  }
  if (sort) {
    queryString += `${queryString ? "&" : "?"}sort=${sort}`;
  }
  if (order === 0 || order) {
    queryString += `${queryString ? "&" : "?"}order=${order}`;
  }
  if (page) {
    queryString += `${queryString ? "&" : "?"}page=${page}`;
  }
  if (perPage) {
    queryString += `${queryString ? "&" : "?"}perPage=${perPage}`;
  }
  if (with_location) {
    queryString += `${queryString ? "&" : "?"}with_location=${with_location}`;
  }

  return queryString;
};

export const showSuccessMsg = (msg = "", isAlert = false) => {
  if (isAlert) {
    toast.success(msg, {
      position: "top-center",
      hideProgressBar: true,
      autoClose: 10000,
      closeOnClick: false,
      transition: Slide,
    });
  } else {
    toast.success(msg);
  }
};

export const showErrorMsg = (msg = "Something went wrong.") => {
  toast.error(msg);
};

export const dismissToast = () => {
  toast.dismiss();
};

export const createFirstLetterCapital = (value) => {
  return value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : "";
};
export const createFirstLetterLower = (value) => {
  return value ? value?.charAt(0)?.toLowerCase() + value?.slice(1) : "";
};

export const convertDate = (value) => {
  var date = moment(value);
  if (date.isValid()) {
    return value ? moment.utc(value).toDate() : new Date();
  } else {
    return new Date();
  }
};

export const convertPostDate = (value) => {
  var date = moment(value);
  if (date.isValid()) {
    return value ? moment(value).toDate() : new Date();
  } else {
    return new Date();
  }
};

export const getCurrentMonthYear = (value) => {
  const currMonth = new Date().toLocaleString([], {
    month: "long",
  });
  const currYear = new Date().getFullYear();

  return (
    <span>
      {currMonth} {currYear}
    </span>
  );
};

export const convertToUSNumber = (value, previousValue) => {
  if (!value) return value;

  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;

    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

export const checkIsNumber = (value = "") =>
  value === "" || (!isNaN(value) && value);

export const handleAddress = (
  address1 = "",
  address2 = "",
  city = "",
  state = "",
  zip = ""
) => {
  let addressString = [];
  if (address1) {
    addressString.push(address1);
  }
  if (address2) {
    addressString.push(address2);
  }
  if (city) {
    addressString.push(city);
  }
  if (state) {
    addressString.push(state);
  }
  if (zip) {
    addressString.push(zip);
  }
  return addressString.join(", ");
};

export const getCampaignImage = (imageName = "") => {
  return `${API_URL}storage/uploads/${imageName}`;
};

export const getCampaignCouponImage = (imageName = "") => {
  return `${API_URL}storage/digital-offer-coupon/${imageName}`;
};

export const getItemImage = (imageName = "") => {
  return imageName ? `${API_URL}food_items/${imageName}` : dummyImage;
};

export const getPostImage = (imageName = "") => {
  return `${API_URL}post_images/${imageName}`;
};

export const dateDiff = (first, second) => {
  if (!!first && !!second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
};

export const parseDate = (str) => {
  if (str) {
    var mdy = str?.split("-");
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }
};

export const getCurrentDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
};

export const getCurrentDateForCampaign = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
};

export const convertLatLongObj = (values = "") => {
  const data = typeof values === "string" ? values.split(",") : [];
  if (isValidArray(data)) {
    return { lat: Number(data[0]), lng: Number(data[1]) };
  }
  return "";
};

export const handleLongText = (data) => {
  return data && data?.length > 50 ? `${data.substring(0, 50)}...` : data;
};

export const convertTimeStampToDate = (data) => {
  if (data) {
    const inputTimestamp = data;
    const dateObj = new Date(inputTimestamp);

    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const dateFormat = getDateFormatLocal();

    switch (dateFormat) {
      case "MM/dd/yyyy":
        return `${formattedMonth}/${formattedDay}/${year}`;
      case "dd/MM/yyyy":
        return `${formattedDay}/${formattedMonth}/${year}`;
      case "yyyy/MM/dd":
        return `${year}/${formattedMonth}/${formattedDay}`;
      default:
        return `${year}/${formattedMonth}/${formattedDay}`;
    }
  }
  return data;
};

export const convertTimeStampToDateMoment = (data) => {
  if (data) {
    const dateObj = moment(data);

    const dateFormat = getDateFormatLocal();

    switch (dateFormat) {
      case "MM/dd/yyyy":
        return dateObj.format("MM/DD/YYYY");
      case "dd/MM/yyyy":
        return dateObj.format("DD/MM/YYYY");
      case "yyyy/MM/dd":
        return dateObj.format("YYYY/MM/DD");
      default:
        return dateObj.format("YYYY/MM/DD");
    }
  }
  return data;
};

export const convertTimeStampToDateTime = (data) => {
  if (data) {
    const dateFormat = getDateFormatLocal();
    const dateObject = new Date(data);
    const parsedDateTime = moment(dateObject);
    // parsedDateTime.tz(systemTimeZone);
    const result = parsedDateTime.format(
      `${dateFormat?.toUpperCase() ?? "MM/dd/yyyy"} h:mm A`
    );
    return result;
  }
  return data;
};

export const getDiffTwoDates = (date1, date2) => {
  if (date1 && date2) {
    const dateFirst = moment(date1);
    const dateSecond = moment(date2);
    const diffDays = dateSecond?.diff(dateFirst, "days") + 1;
    return diffDays;
  }
  return 0;
};

export const changeDateFormat = (date = "") => {
  let dateFormat = getDateFormatLocal();

  switch (dateFormat) {
    case "MM/dd/yyyy":
      dateFormat = "MM/DD/YYYY";
      break;
    case "dd/MM/yyyy":
      dateFormat = "DD/MM/YYYY";
      break;
    case "yyyy/MM/dd":
      dateFormat = "YYYY/MM/DD";
      break;
    default:
      dateFormat = "MM/DD/YYYY";
      break;
  }

  if (!!date) {
    const value = moment(date).format(dateFormat);
    return value;
  }
  return "";
};

export const isNoLocationExist = () => {
  return (
    getCompanyStatus() === "active" && !isValidObject(getCurrentLocation())
  );
};

export const getShortCodeOfStates = (value = "") => {
  const state = statesArray.find(function (option) {
    return !!value
      ? option.value === value || option.value2 === value?.toLowerCase()
      : "";
  });
  return state?.value ?? "";
};

export const hexToRGBAWithOpacity = (colors) => {
  const opacities = [0, 0.8, 0.6, 0.6];

  const convertHexToRGBA = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const rgbaColors = colors.map((color, index) => {
    const opacity = opacities[index];
    return convertHexToRGBA(color, opacity);
  });

  return rgbaColors;
};

function getColorForRange(index) {
  const colors = ["#61c9fa", "#14aef7", "#0498de", "#0292d4", "#0271a6"];
  return colors[index];
}

export const getMaxValueFromArray = (data = []) => {
  let max = 0;

  if (isValidArray(data)) {
    data.forEach((entry) => {
      entry.data.forEach((dataPoint) => {
        const yValue = dataPoint.y;
        max = Math.max(max, yValue);
      });
    });
  }

  const thresholds = [
    500, 1000, 2500, 5000, 10000, 15000, 20000, 25000, 30000, 40000, 50000,
    75000, 100000, 150000, 200000, 300000, 400000, 500000,
  ];

  for (const threshold of thresholds) {
    if (max <= threshold) {
      max = threshold;
      break;
    }
  }

  return max;
};

export const ageSpendRange = (data = []) => {
  const min = 1;
  const max = getMaxValueFromArray(data);
  const numRanges = 5;
  const step = Math.ceil((max - min + 1) / numRanges);
  const ranges = [];

  for (let i = 0; i < numRanges; i++) {
    const rangeMin = min + i * step;
    const rangeMax = Math.min(rangeMin + step - 1, max);
    const rangeName = `${rangeMin}-${rangeMax}`;
    ranges.push({
      from: rangeMin,
      to: rangeMax,
      name: rangeName,
      color: getColorForRange(i),
    });
  }
  return ranges;
};

export const convertStringToObj = (value = "") => {
  if (value) {
    return JSON.parse(value);
  }
  return "";
};

export const calculateDensity = (coords, allCoords, radiusKm) => {
  let count = 0;
  for (const coord of allCoords) {
    const distance = haversine(coords.lat, coords.lng, coord.lat, coord.lng);
    if (distance <= radiusKm) {
      count++;
    }
  }
  return count;
};

export const haversine = (lat1, lon1, lat2, lon2) => {
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const addDecimalToNumber = (value) => {
  if (!!value || value == 0) {
    return value.toFixed(2);
  }
  return value;
};

export const createAllFirstLetterCapital = (value = "") => {
  if (!!value) {
    const words = value.split("-");
    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const formattedString = formattedWords.join(" ");
    return formattedString;
  }
  return "";
};

export const showPrice = (value = 0) => {
  if (!!value) {
    return `$ ${(Math.round(value * 100) / 10000).toFixed(2)}`;
  }
  return 0.0;
};

export const createListOfObject = (data) => {
  const isDataValid = data || {};
  const dataList = Object.entries(isDataValid).map(([id, name]) => ({
    id,
    name,
  }));
  return dataList;
};

export const parsedListOfObject = (data) => {
  if (isValidObject(data)) {
    const objectListData = data || {};

    const objectListValues = Object.values(objectListData);

    const objectList = objectListValues?.map((orderList) => {
      return orderList?.order;
    });

    return objectList;
  }
  return data;
};

export const isTokenExpired = (expiredDate) => {
  const currentDate = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  const expiryDate = new Date(expiredDate).toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  const expiryDateObj = new Date(expiryDate);
  const currentDateObj = new Date(currentDate);

  if (expiredDate) {
    if (expiryDateObj.getDate() > currentDateObj.getDate()) {
      return {
        expired: false,
        message: `Your token will expire on ${convertTimeStampToDate(
          expiredDate
        )} `,
      };
    } else if (expiryDateObj.getDate() === currentDateObj.getDate()) {
      if (expiryDateObj.getHours() > currentDateObj.getHours()) {
        return {
          expired: false,
          message: `Your token will expire in ${
            expiryDateObj.getHours() - currentDateObj.getHours()
          }hr.`,
        };
      } else
        return {
          expired: true,
          message: `Your token is expired! Click to Refresh .`,
        };
    } else {
      return {
        expired: true,
        message: `Your token is expired! Click to Refresh .`,
      };
    }
  } else {
    return {
      expired: false,
      message: `You don't have any connection.`,
    };
  }
};

export const createReactSelectedOptions = (data) => {
  if (isValidArray(data)) {
    return data.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }

  return [];
};

export const getWordCount = (data) => {
  let regex = /\s+/gi;
  return data?.trim().replace(regex, " ").split(" ").length;
};

export const manipulateOTP = (data) => {
  if (!!data) {
    const otp = String(data);
    const firstThreeDigits = otp.slice(0, 3);
    const lastThreeDigits = otp.slice(3, 6);
    const manipulatedOTP = lastThreeDigits + firstThreeDigits;

    return manipulatedOTP.split("").reverse().join("");
  }
  return "";
};

export const revertManipulatedOTP = (data) => {
  if (!!data) {
    const manipulatedOTP = String(data);
    const reversedOTP = manipulatedOTP.split("").reverse().join("");
    const firstThreeDigits = reversedOTP.slice(0, 3);
    const lastThreeDigits = reversedOTP.slice(3, 6);
    const originalOTP = lastThreeDigits + firstThreeDigits;

    return originalOTP;
  }
  return "";
};

export function formatPhoneNumberAlways(phoneNumber = "") {
  if (!!phoneNumber) {
    const cleaned = phoneNumber?.replace(/\D/g, "");

    const formatted =
      cleaned?.length === 11 && cleaned?.startsWith("1")
        ? cleaned?.slice(1)
        : cleaned;

    const areaCode = formatted.substring(0, 3);
    const firstPart = formatted.substring(3, 6);
    const secondPart = formatted.substring(6, 10);

    const formattedPhoneNumber = `+1 (${areaCode}) ${firstPart}-${secondPart}`;

    return formattedPhoneNumber;
  }
  return "";
}

export function checkValueExists(obj, key) {
  return obj[key];
}

export function getKeyByValue(object, value = "") {
  if (value) {
    return Object.keys(object).find((key) => object[key] == value);
  }
  return "";
}

function hasEmptyImageField(inputArray) {
  return inputArray?.some((item) => item.image === "");
}

export const handlePostFormStepCompleted = (data = {}, step) => {
  if (isValidObject(data)) {
    if (step == 1) {
      return !!data?.campaignType ?? false;
    }
    if (step == 2) {
      return !!data?.postType ?? false;
    }
    if (step == 3) {
      let currentStatus = true;
      if (isValidObject(data?.postImage)) {
        const isEmpty = hasEmptyImageField(data?.postImage);
        currentStatus = isEmpty ? false : true;
      }

      return currentStatus;
    }
  }
};
export const generate24TimeOptions = () => {
  const times = [];
  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 5) {
      const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
      const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
      const time = `${hourStr}:${minuteStr}`;
      times.push({ value: time, label: time });
    }
  }
  return times;
};

export const generateTimeOptions = () => {
  const times = [];
  for (let hour = 1; hour <= 12; hour++) {
    for (let minute = 0; minute < 60; minute++) {
      const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
      const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
      const time = `${hourStr}:${minuteStr}`;
      times.push({ value: time, label: time });
    }
  }
  return times;
};

export const generateTimeWithTypeOptions = () => {
  const times = [];
  for (let period of ["AM", "PM"]) {
    for (let hour = 1; hour <= 23; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
        const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
        const time = `${hourStr}:${minuteStr} ${period}`;
        times.push({ value: time, label: time });
      }
    }
  }
  return times;
};

// export const generateTimeOptions = () => {
//   const times = [];
//   for (let period of ["AM", "PM"]) {
//     for (let hour = 1; hour <= 23; hour++) {
//       for (let minute = 0; minute < 60; minute += 5) {
//         const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
//         const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
//         const time = `${hourStr}:${minuteStr} ${period}`;
//         times.push({ value: time, label: time });
//       }
//     }
//   }
//   return times;
// };

export const isObjectValuesValid = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === "" || value === undefined || value === null) {
        return false; // Return false as soon as an invalid value is found
      }
    }
  }
  return true; // All values are valid
};

export const areArrayObjectValuesValid = (arr) => {
  if (isValidArray(arr)) {
    for (let obj of arr) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (value === "" || value === undefined || value === null) {
            return false; // Return false as soon as an invalid value is found
          }
        }
      }
    }
    return true; // All values in all objects are valid
  } else false;
};

export function removeEmptyObjects(arr) {
  return arr.filter((obj) => {
    return Object.values(obj).some((value) => value !== null && value !== "");
  });
}

export function isFutureDateTime(date, time, timeFormat) {
  // Parse the input date
  const parsedDate = moment(new Date(date));

  // Combine date with time and format
  const dateTimeString = `${parsedDate.format(
    "YYYY-MM-DD"
  )} ${time} ${timeFormat}`;
  const combinedDateTime = moment(dateTimeString, "YYYY-MM-DD hh:mm A");

  // Get the current date and time
  const currentDateTime = moment();

  // Check if the input date and time is at least 30 minutes in the future
  const isAtLeast30MinutesInFuture = combinedDateTime.isAfter(
    currentDateTime.add(30, "minutes")
  );

  return isAtLeast30MinutesInFuture;
}

export function addMinutesToDateTime(dateTime) {
  // Use Moment.js to add 15 minutes to the provided dateTime
  const newDateTime = moment(dateTime).tz(systemTimeZone).add(15, "minutes");

  const dateFormat = getDateFormatLocal()
    ? getDateFormatLocal()?.toUpperCase()
    : "MM/DD/YYYY";

  const timeString = newDateTime.format(dateFormat + " h:mm A");

  return timeString;
}

export const convertToPDTFormatDateTimeStamp = (dateString) => {
  // Parse the date string and convert it to PDT
  const parsedDateTime = moment(dateString).tz(systemTimeZone);
  if (!parsedDateTime.isValid()) {
    return "";
  }
  const dateFormat = getDateFormatLocal()
    ? getDateFormatLocal()?.toUpperCase()
    : "MM/DD/YYYY";

  const formattedDate = parsedDateTime.format(dateFormat + " h:mm A");
  return formattedDate;
};

export const removeUnderscore = (str) => {
  return !!str
    ? str
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : str;
};

export const convertPacificTime = (dateString, timeString, timeType) => {
  // Parse the date and time
  const date = moment(dateString, "YYYY-MM-DD").format("MMMM D YYYY") ?? "";
  const time = moment(timeString, "HH:mm:ss").format("HH:mm") ?? "";

  // Determine the timezone (PDT/PST)
  const dateZone = moment.tz(`${dateString} ${timeString}`, systemTimeZone);
  const timezone = dateZone.isDST() ? "PDT" : "PST";
  const pacificTime = `${time} ${timeType} (${timezone})`;

  return { date, pacificTime };
};

export const convertDateTimeType = (dateString) => {
  if (!!dateString) {
    const parsedDate = moment(dateString) ?? "";
    const time = parsedDate.format("hh:mm") ?? "";
    const period = parsedDate.format("A") ?? "";
    return { date: dateString, time, period };
  }
  return "";
};

export const isDateTimeLater = (dateString) => {
  if (!dateString) return false;
  // Parse the provided date string using Moment.js
  const selectedDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss Z");
  // Get the current date and time
  const currentDate = moment();
  // Check if the selected date and time is later than the current date and time
  return selectedDate.isAfter(currentDate);
};

export const isDateTimeLaterThanCurrent = (date, time, type) => {
  // Check if date or time is invalid
  if (!date || !time || !type || !["AM", "PM"].includes(type)) {
    return false;
  }

  // Parse the date
  const parsedDate = moment(
    date,
    "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (India Standard Time)"
  );

  // Combine time and type to create a time string
  const timeString = `${time} ${type}`;

  // Merge the parsed date with the time string
  const combinedDateTime = parsedDate.set({
    hour: moment(timeString, "hh:mm A").hour(),
    minute: moment(timeString, "hh:mm A").minute(),
    second: 0, // Optional, if you want to set seconds to zero
  });

  // Get the current date and time
  const currentDateTime = moment();

  // Compare the combined date-time with the current date-time
  return combinedDateTime.isAfter(currentDateTime);
};

export const convertDateStringIntoTime = (dateString, timeFormat) => {
  const parsedDate = !!dateString ? moment(dateString) : "";
  if (!parsedDate) return "";
  if (timeFormat == "12Hr") {
    const timeIn12HourFormat = parsedDate.format("hh:mm A") ?? "";
    return timeIn12HourFormat;
  } else if (timeFormat == "24Hr") {
    const timeIn24HourFormat = parsedDate.format("HH:mm") ?? "";
    return timeIn24HourFormat;
  }
};

export const convertTimeIntoDateString = (time) => {
  const timeString24Hr = !!time ? moment(time, "hh:mm A").toDate() : "";
  return timeString24Hr;
};

export const isDateCurrent = (dateString) => {
  // Check if dateString is provided
  if (!dateString) {
    return false;
  }

  // Parse the date string into a Moment.js object
  const parsedDate = moment(dateString);

  if (!parsedDate.isValid()) {
    return false;
  }

  const currentDate = moment().startOf("day");
  const parsedDateOnly = parsedDate.startOf("day");

  return parsedDateOnly.isSame(currentDate, "day");
};

export const isTimeLaterThanCurrent = (time, type) => {
  // Validate inputs
  if (!time || !type || !["AM", "PM"].includes(type)) {
    return false;
  }

  const timeString = `${time} ${type}`;

  const parsedTime = moment(timeString, "hh:mm A");

  const currentTime = moment().format("hh:mm A"); // Current time in 12-hour format

  const currentMoment = moment(currentTime, "hh:mm A");

  return parsedTime.isAfter(currentMoment);
};

export const checkParagraphWordsLimit = (paragraph = "", wordLimit = 300) => {
  const trimmedParagraph = paragraph.trim();
  const words = trimmedParagraph.split(/\s+/);
  return words.length > wordLimit;
};
