import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "module/api";
import { showSuccessMsg } from "module/util";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const updateServiceSinglePost = createAsyncThunk(
  "post/service-single-post-update",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await postApi(
        `api/service-post/${id}`,
        { ...formData, _method: "PATCH" },
        true
      );
      if (response?.data?.code === 200) {
        showSuccessMsg(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const updateServiceSinglePostSlice = createSlice({
  name: "updateServiceSinglePost",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateServiceSinglePost.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateServiceSinglePost.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(updateServiceSinglePost.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    });
  },
});

export default updateServiceSinglePostSlice.reducer;
