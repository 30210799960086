import CustomPhoneInput from "components/common/fields/CustomPhoneInput";
import DropdownField from "components/common/fields/DropdownField";
import GoogleAddressField from "components/common/fields/GoogleAddressField";
import SpinnerButton from "components/common/fields/SpinnerButton";
import SwitchButton from "components/common/fields/SwitchButton";
import { Field, Form, Formik } from "formik";
import { showSuccessMsg } from "module/util";
import {
  companyForm,
  handleServerValidation,
  showError,
} from "module/validation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCompanyAccountDetail } from "redux/account/getCompanyAccountDetailSlice";
import { updateCompanyAccount } from "redux/account/updateCompanyAccountSlice";
import { setBreadCrumb } from "redux/common/setBreadCrumbsSlice";
import { addCompany } from "redux/company/addCompanySlice";

const AddCompanyForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const getCompanyAccountData = useSelector(
    (state) => state?.getCompanyAccountDetail?.data ?? []
  );
  const addCompanyLoading = useSelector(
    (state) => state?.addCompany?.loading ?? false
  );

  const updateCompanyLoading = useSelector(
    (state) => state?.updateCompanyAccount?.loading ?? false
  );

  const validationMsgObj = useSelector(
    (state) => state?.getMessages?.data ?? []
  );

  const isEdit = location.pathname.includes("/company/edit") && id;

  const initialValues = {
    company: isEdit ? getCompanyAccountData?.company : "",
    name: isEdit ? getCompanyAccountData?.name : "",
    email: isEdit ? getCompanyAccountData?.email : "",
    address1: isEdit ? getCompanyAccountData?.address1 : "",
    address2: isEdit ? getCompanyAccountData?.address2 : "",
    city: isEdit ? getCompanyAccountData?.city : "",
    state: isEdit ? getCompanyAccountData?.state : "",
    zip: isEdit ? getCompanyAccountData?.zip : "",
    phone: isEdit ? getCompanyAccountData?.phone : "",
    lat_long: "",
    show_door_hangar: isEdit
      ? getCompanyAccountData?.show_door_hangar == "1"
        ? true
        : false
      : false,
    show_direct_mail: isEdit
      ? getCompanyAccountData?.show_direct_mail == "1"
        ? true
        : false
      : false,
  };

  const handleSubmit = (values) => {
    const formData = {
      company: values?.company ?? "",
      name: values?.name ?? "",
      email: values?.email ?? "",
      address1: values?.address1 ?? "",
      address2: values?.address2 ?? "",
      city: values?.city ?? "",
      state: values?.state ?? "",
      zip: values?.zip ?? "",
      phone: values?.phone ?? "",
      status: "active",
      lat_long: values?.lat_long ?? "",
      show_door_hangar: values?.show_door_hangar ? 1 : 0,
      show_direct_mail: values?.show_direct_mail ? 1 : 0,
    };

    if (isEdit) {
      dispatch(updateCompanyAccount({ companyId: id, formData })).then(
        (data) => {
          if (data?.payload?.code === 200) {
            showSuccessMsg(
              data?.payload?.message ?? "Company update successfully"
            );
          } else {
            handleServerValidation(data);
          }
        }
      );
    } else {
      dispatch(addCompany(formData)).then((data) => {
        if (data?.payload?.code === 200) {
          navigate("/company/list");
          showSuccessMsg(
            data?.payload?.message ?? "Company added successfully"
          );
        } else {
          handleServerValidation(data);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Companies List",
          href: "/company/list",
          current: true,
        },
        {
          name: isEdit ? "Edit Company" : "Add Company",
          href: "",
          current: false,
        },
      ])
    );

    if (isEdit) {
      dispatch(getCompanyAccountDetail(id));
    }
  }, []);

  return (
    <div className="flex justify-center">
      <Formik
        initialValues={initialValues}
        validationSchema={() => companyForm(validationMsgObj?.company ?? {})}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        // validateOnBlur={false}
      >
        {({
          errors,
          touched,
          validateField,
          validateForm,
          handleChange,
          handleBlur,
          values,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            className="w-full min-w-xl border border-gray-200 p-3 bg-white"
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              e.key == "Enter" && e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label htmlFor="company" className="input-field-label">
                  Company
                </label>
                <div className="mt-2">
                  <GoogleAddressField
                    name="company"
                    setFieldValue={setFieldValue}
                    value={values?.company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"Enter Company"}
                    isNameField={true}
                    setFieldTouched={setFieldTouched}
                    FieldValues={values}
                  />
                </div>
                {touched?.company && showError(errors?.company)}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label htmlFor="name" className="input-field-label">
                  Name
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    value={values?.name ?? ""}
                    onChange={handleChange}
                    autoComplete="name"
                    placeholder="Enter name"
                    className="input-field"
                  />
                </div>
                {touched?.name && showError(errors?.name)}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label htmlFor="address" className="input-field-label">
                  Address1
                </label>
                <div className="mt-2">
                  <GoogleAddressField
                    name="address1"
                    setFieldValue={setFieldValue}
                    value={values?.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"Enter Address1"}
                    setFieldTouched={setFieldTouched}
                    FieldValues={values}
                  />
                </div>
                {touched?.address1 && showError(errors?.address1)}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label htmlFor="Address2" className="input-field-label">
                  Address2
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="address2"
                    id="Address2"
                    value={values?.address2 ?? ""}
                    onChange={handleChange}
                    autoComplete="Address2"
                    placeholder="Enter Address2"
                    className="input-field"
                  />
                </div>
                {touched?.address2 && showError(errors?.address2)}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label htmlFor="city" className="input-field-label">
                  City
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="city"
                    id="city"
                    value={values?.city ?? ""}
                    onChange={handleChange}
                    autoComplete="address-level2"
                    placeholder="Enter City"
                    className="input-field"
                  />
                </div>
                {touched?.city && showError(errors?.city)}
              </div>

              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label htmlFor="state" className="input-field-label">
                  State
                </label>
                <div className="mt-2">
                  <DropdownField
                    setFieldValue={setFieldValue}
                    values={values}
                    name="state"
                  />
                </div>
                {touched?.state && showError(errors?.state)}
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label htmlFor="zip" className="input-field-label">
                  ZIP / Postal code
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="zip"
                    id="zip"
                    value={values?.zip ?? ""}
                    onChange={handleChange}
                    autoComplete="zip"
                    placeholder="Enter Zip/Postal Code"
                    className="input-field"
                  />
                </div>
                {touched?.zip && showError(errors?.zip)}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label htmlFor="phone" className="input-field-label">
                  Phone
                </label>
                <CustomPhoneInput
                  values={values}
                  name="phone"
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                />
                {touched?.phone && showError(errors?.phone)}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label htmlFor="email" className="input-field-label">
                  Email
                </label>
                <div className="mt-2">
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    value={values?.email ?? ""}
                    onChange={handleChange}
                    autoComplete="email"
                    placeholder="Enter Email"
                    className="input-field"
                  />
                </div>
                {touched?.email && showError(errors?.email)}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/4 px-3">
                <label htmlFor="expires" className="input-field-label">
                  Show Direct Mail Campaign ?
                </label>
                <div className="mt-2 flex items-center">
                  <SwitchButton
                    value={values?.show_direct_mail ?? false}
                    handleToggleForCampaigns={(values, id) => {
                      setFieldValue("show_direct_mail", values);
                    }}
                    id={0}
                    name="show_direct_mail"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/4 px-3">
                <label htmlFor="crossAccept" className="input-field-label">
                  Show Door Hanger Campaign ?
                </label>
                <div className="mt-2 flex items-center">
                  <SwitchButton
                    value={values?.show_door_hangar ?? false}
                    handleToggleForCampaigns={(values, id) => {
                      setFieldValue("show_door_hangar", values);
                    }}
                    id={0}
                    name="show_door_hangar"
                  />
                </div>
                {/* {touched?.crossAccept && showError(errors?.crossAccept)} */}
              </div>
              <div className="w-full md:w-1/2 px-3"></div>
            </div>

            <div className="flex flex-wrap justify-start mb-6">
              <SpinnerButton
                className="btn-pink"
                title={"Save"}
                action={() => {}}
                type={"submit"}
                loading={addCompanyLoading || updateCompanyLoading}
              />
              <button
                onClick={() => navigate("/company/list")}
                type="button"
                className="btn btn-gray ml-2"
                disabled={addCompanyLoading || updateCompanyLoading}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddCompanyForm;
