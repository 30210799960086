import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles

const ImageSwiper = (props) => {
  const { images = [] } = props;
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      keyboard={{
        enabled: true,
      }}
      loop={false}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Keyboard, Pagination, Navigation]}
      className="mySwiper h-full w-full"
    >
      {images?.map((item, index) => {
        return (
          <SwiperSlide key={index}>
            <img src={item} alt={item} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
export default ImageSwiper;
